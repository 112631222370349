import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiSelectable, EuiSelectableOption } from "@elastic/eui";
import { useHistory, useLocation } from "react-router-dom";

import { PmFormStyling } from "../../Forms/PmFormStyling";
import { SavedFilterObject } from "../BaseFilterClasses";
import { BaseSortFilterClass } from "./BaseSortFilterClass";

interface PmSortFilterProps {
  filter: BaseSortFilterClass;
  savedFilter: SavedFilterObject;
  closePopover: () => void;
  isMobile: boolean;
}

/**
 * Used for the 'Sort' buttons on filter button groups.
 * Unlike other filters, changes are auto-applied
 */
const PmSortFilter = ({ filter, closePopover, savedFilter, isMobile }: PmSortFilterProps) => {
  const history = useHistory();
  const location = useLocation();

  const options = filter.getMappedOptions({ location, savedFilter });

  const onOptionsChange = (newOptions: Array<EuiSelectableOption<string>>) => {
    filter.onOptionChange({ history, location, savedFilter, newOptions });
    closePopover();
  };

  return (
    <PmFormStyling error={undefined}>
      <EuiFlexGroup
        direction="column"
        gutterSize="m"
        style={{ width: isMobile ? `min(80vw, ${filter.getPopoverWidth()})` : filter.getPopoverWidth() }}
      >
        <EuiFlexItem grow={false} data-testid="selected-values">
          <EuiSelectable options={options} onChange={onOptionsChange} allowExclusions={false} singleSelection={true}>
            {(list, search) => (
              <EuiFlexItem grow={true}>
                {search}
                {list}
              </EuiFlexItem>
            )}
          </EuiSelectable>
        </EuiFlexItem>
      </EuiFlexGroup>
    </PmFormStyling>
  );
};

export { PmSortFilter };
