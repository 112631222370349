import { useHistory, useLocation } from "react-router-dom";
import { create } from "zustand";

import { BaseSavedFiltersFilterClass } from "@pm-frontend/shared/components/FilterButtons/PmSavedFiltersFilter/BaseSavedFiltersFilterClass";
import { BaseSortFilterClass } from "@pm-frontend/shared/components/FilterButtons/PmSortFilter/BaseSortFilterClass";
import {
  getDefaultMeldFilters,
  getMeldSavedFilterConfig,
  MeldFilterQueryKeys,
} from "@pm-frontend/shared/components/FilterButtons/configs/meld-filter-configs";
import { MeldFilterConfigs } from "@pm-frontend/shared/components/FilterButtons/configs/meld-filter-configs";
import { FilterClassTypes } from "@pm-frontend/shared/components/FilterButtons/BaseFilterClasses";
import { BaseSelectableFilterClass } from "@pm-frontend/shared/components/FilterButtons/PmSelectableFilter/BaseSelectableFilterClass";
import { getParamsMatchingFilters } from "@pm-frontend/shared/components/FilterButtons/utils";
import { MeldManagerStatusLabels, MeldStatus, OpenStatuses } from "@pm-frontend/shared/types/meld";
import { CALENDAR_URL_PARAM_KEYS } from "../utils/hooks";
import { AuthUtils } from "@pm-frontend/shared/utils/auth-utils";
import { StorageUtils } from "@pm-frontend/shared/utils/storage-utils";

// we store a string of the query parameters as the value. This gets parsed
// for actual meld filter params later
const CALENDAR_MELD_FILTER_PARAMS_LOCAL_STORAGE_KEY = `calendar-meld-list-filter-params-user-${AuthUtils.getUserId()}-v1`;
const MAP_MELD_FILTER_PARAMS_LOCAL_STORAGE_KEY = `map-meld-list-filter-params-user-${AuthUtils.getUserId()}-v1`;

function getInitialMeldFilterParams(type: "map" | "calendar"): URLSearchParams | undefined {
  const key = type === "map" ? MAP_MELD_FILTER_PARAMS_LOCAL_STORAGE_KEY : CALENDAR_MELD_FILTER_PARAMS_LOCAL_STORAGE_KEY;
  const { ok, value: rawValue } = StorageUtils.getLocalStorageItem(key);

  if (!ok) {
    return;
  } else if (rawValue) {
    return new URLSearchParams(rawValue);
  } else {
    return;
  }
}

function writeMeldFilterParamsToStorage(type: "map" | "calendar", params: URLSearchParams) {
  const key = type === "map" ? MAP_MELD_FILTER_PARAMS_LOCAL_STORAGE_KEY : CALENDAR_MELD_FILTER_PARAMS_LOCAL_STORAGE_KEY;
  StorageUtils.setLocalStorageItem(key, params.toString());
}

interface CalendarMeldFilterParamState {
  meldFilterParams: {
    calendar: {
      filterClasses: FilterClassTypes[];
      savedFilterClass: BaseSavedFiltersFilterClass;
      sortFilterClass: BaseSortFilterClass;
      type: "read-only" | "read-write";
      priorParams: URLSearchParams;
    };
    map: {
      filterClasses: FilterClassTypes[];
      type: "read-only" | "read-write";
      priorParams: URLSearchParams;
    };
  };
  onApplyFilterAdditionalOnClick: (arg0: URLSearchParams) => void;
  actions: {
    // reading writing calendar/map meld filters stored in url
    setMeldFilterParams: (arg0: {
      newCalendar?: CalendarMeldFilterParamState["meldFilterParams"]["calendar"];
      newMap?: CalendarMeldFilterParamState["meldFilterParams"]["map"];
    }) => void;
    // initial filters don't have backend populated options, after those
    // are loaded this is called to update the stored filters
    populateMeldFilterParamsOptions: (
      arg0: (state: CalendarMeldFilterParamState) => CalendarMeldFilterParamState["meldFilterParams"]["calendar"]
    ) => void;
    // when mounting this page we want to apply the default meld filter params
    // if there are none
    initializePageWithMeldParam: (arg0: {
      location: ReturnType<typeof useLocation>;
      history: ReturnType<typeof useHistory>;
    }) => void;
  };
}

const useCalendarMeldFilterParamStateStore = create<CalendarMeldFilterParamState>((set, get) => {
  // when the filters change we need to update our priorParams
  // so that navigating away/to the calendar preserves our selections
  const onApplyAdditionalOnClick = (newParams: URLSearchParams) => {
    const currentFullParams = new URLSearchParams(window.location.search);
    if (currentFullParams.has(CALENDAR_URL_PARAM_KEYS.MAP_OPEN)) {
      // map is open
      // update url if no meld filter params are present, and set prior params to this value
      set((state) => {
        const [existingMapMeldFilterParams] = getParamsMatchingFilters({
          existingParams: newParams,
          filters: state.meldFilterParams.map.filterClasses,
        });
        writeMeldFilterParamsToStorage("map", existingMapMeldFilterParams);
        return {
          meldFilterParams: {
            ...state.meldFilterParams,
            map: { ...state.meldFilterParams.map, priorParams: existingMapMeldFilterParams },
          },
        };
      });
    } else {
      // calendar is open
      set((state) => {
        // update url if no meld filter params are present, and set prior params to this value
        const [existingCalendarMeldFilterParams] = getParamsMatchingFilters({
          existingParams: newParams,
          filters: state.meldFilterParams.calendar.filterClasses,
          sortFilter: state.meldFilterParams.calendar.sortFilterClass,
          savedFilter: state.meldFilterParams.calendar.savedFilterClass,
        });
        writeMeldFilterParamsToStorage("calendar", existingCalendarMeldFilterParams);
        return {
          meldFilterParams: {
            ...state.meldFilterParams,
            calendar: { ...state.meldFilterParams.calendar, priorParams: existingCalendarMeldFilterParams },
          },
        };
      });
    }
  };
  // we initialize meldFilterParams with readonly instances of the filter classes
  // upon component mount (and api requests to get options) these will be replaced with
  // filter classes populated with options
  const { sortFilter: calendarMeldListSortFilter, filters: calendarMeldListFilters } = getDefaultMeldFilters({
    allMaint: [],
    onApplyAdditionalOnClick,
  });

  const meldCalendarNearbyMeldFilters = [
    new BaseSelectableFilterClass({
      config: MeldFilterConfigs.selectable.status,
      overrides: {
        alwaysShow: true,
        // we limit status filtering options for nearby melds
        options: OpenStatuses.map((status: MeldStatus) => ({
          label: MeldManagerStatusLabels[status],
          queryParamValue: status,
        })),
      },
      onApplyAdditionalOnClick,
    }),
    new BaseSelectableFilterClass({
      config: MeldFilterConfigs.selectable.priority,
      overrides: { alwaysShow: true },
      onApplyAdditionalOnClick,
    }),
  ];

  const calendarMeldListSavedFilter = new BaseSavedFiltersFilterClass({
    config: getMeldSavedFilterConfig({
      savedFilters: [],
      otherFilters: [calendarMeldListSortFilter, ...calendarMeldListFilters],
    }),
    overrides: { alwaysShow: true },
  });

  const meldFilterParams: CalendarMeldFilterParamState["meldFilterParams"] = {
    calendar: {
      sortFilterClass: calendarMeldListSortFilter,
      savedFilterClass: calendarMeldListSavedFilter,
      filterClasses: calendarMeldListFilters,
      type: "read-only",
      // these are the default
      priorParams:
        getInitialMeldFilterParams("calendar") ||
        new URLSearchParams({
          [MeldFilterQueryKeys.status]: OpenStatuses.filter((status) => status !== MeldStatus.PENDING_COMPLETION).join(
            ","
          ),
        }),
    },
    map: {
      // since these filter classes don't require option populate from
      // api class we can initialize it as 'read-write'
      filterClasses: meldCalendarNearbyMeldFilters,
      type: "read-write",
      // these are the default
      priorParams:
        getInitialMeldFilterParams("map") ||
        new URLSearchParams({
          [MeldFilterQueryKeys.status]: [MeldStatus.PENDING_ASSIGNMENT].join(","),
        }),
    },
  };

  return {
    // since both use the url to filter we need to store/retrieve the prior state
    // when switching between the calendar and the map
    // we initialize 'read-only' classes which lack populated filter options, but
    // can read from the url
    meldFilterParams,
    onApplyFilterAdditionalOnClick: onApplyAdditionalOnClick,

    actions: {
      // calendar/map meld filter params
      setMeldFilterParams: (props) =>
        set((state) => {
          const map = "newMap" in props && !!props.newMap ? props.newMap : state.meldFilterParams.map;
          const calendar =
            "newCalendar" in props && !!props.newCalendar ? props.newCalendar : state.meldFilterParams.calendar;
          return {
            meldFilterParams: {
              map,
              calendar,
            },
          };
        }),
      populateMeldFilterParamsOptions: (updateFunc) =>
        set((state) => {
          if (state.meldFilterParams.calendar.type === "read-write") {
            return {};
          }
          const newCalendarState = updateFunc(state);

          return {
            meldFilterParams: {
              map: state.meldFilterParams.map,
              calendar: newCalendarState,
            },
          };
        }),
      initializePageWithMeldParam: ({ location, history }) => {
        const meldFilterParamsState = get().meldFilterParams;
        const currentParams = new URLSearchParams(location.search);
        if (currentParams.has(CALENDAR_URL_PARAM_KEYS.MAP_OPEN)) {
          // map is open
          // update url if no meld filter params are present, and set prior params to this value
          const [existingMapMeldFilterParams, existingMapMeldFilterParamsHasValues] = getParamsMatchingFilters({
            existingParams: currentParams,
            filters: meldFilterParamsState.map.filterClasses,
          });
          if (existingMapMeldFilterParamsHasValues) {
            meldFilterParamsState.map.priorParams = existingMapMeldFilterParams;
          } else {
            // we add the default filter params
            // @ts-expect-error we can in fact iterate
            for (const [key, value] of meldFilterParamsState.map.priorParams) {
              currentParams.set(key, value);
            }
            history.replace({ pathname: location.pathname, search: currentParams.toString() });
          }
        } else {
          // calendar is open
          // update url if no meld filter params are present, and set prior params to this value
          const [existingCalendarMeldFilterParams, existingCalendarMeldFilterParamsHasValues] =
            getParamsMatchingFilters({
              existingParams: currentParams,
              filters: meldFilterParamsState.calendar.filterClasses,
              sortFilter: meldFilterParamsState.calendar.sortFilterClass,
              savedFilter: meldFilterParamsState.calendar.savedFilterClass,
            });
          if (existingCalendarMeldFilterParamsHasValues) {
            meldFilterParamsState.calendar.priorParams = existingCalendarMeldFilterParams;
          } else {
            // we add the stored filter params
            // @ts-expect-error we can in fact iterate
            for (const [key, value] of meldFilterParamsState.calendar.priorParams) {
              currentParams.set(key, value);
            }
            history.replace({ pathname: location.pathname, search: currentParams.toString() });
          }
        }
      },
    },
  };
});

const useCalendarStateMeldFilterParams = () => useCalendarMeldFilterParamStateStore((state) => state.meldFilterParams);
const useCalendarStateMeldFilterApplyOnClick = () =>
  useCalendarMeldFilterParamStateStore((state) => state.onApplyFilterAdditionalOnClick);

const useCalendarMeldFilterParamsStateActions = () => useCalendarMeldFilterParamStateStore((state) => state.actions);

export {
  useCalendarStateMeldFilterParams,
  useCalendarStateMeldFilterApplyOnClick,
  useCalendarMeldFilterParamsStateActions,
  CalendarMeldFilterParamState,
};
