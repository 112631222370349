import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { EuiComboBox, EuiFlexGroup, EuiFlexItem, EuiSuperSelect, EuiComboBoxOptionOption } from "@elastic/eui";

import { PmFormStyling } from "../../Forms/PmFormStyling";
import {
  BaseComboboxFilterClass,
  PmComboboxFilterAllowedOperators,
  PmComboboxFilterOperatorValue,
  PM_COMBOBOX_FILTER_INPUT_TYPES,
  PM_COMBOBOX_FILTER_OPERATORS,
} from "./BaseComboboxFilterClass";
import { SavedFilterObject } from "../BaseFilterClasses";
import { PmFilterButtonsCommitButtons } from "../subcomponents/PmFilterButtonsCommitControls";

interface PmComboboxFilterComponentProps {
  filter: BaseComboboxFilterClass;
  savedFilter: SavedFilterObject;
  closePopover: () => void;
  currentOperatorAndValue: PmComboboxFilterOperatorValue;
  isMobile: boolean;
}

const PmComboboxFilter = ({
  filter,
  savedFilter,
  closePopover,
  currentOperatorAndValue,
  isMobile,
}: PmComboboxFilterComponentProps) => {
  const location = useLocation();
  const history = useHistory();

  const [pendingOperatorAndValue, setPendingOperatorAndValue] = useState<NonNullable<PmComboboxFilterOperatorValue>>(
    () => {
      return (
        currentOperatorAndValue || {
          operator: PM_COMBOBOX_FILTER_OPERATORS.ANY_OF,
          selectedOptions: [],
          filterType: BaseComboboxFilterClass.type,
          type: PM_COMBOBOX_FILTER_INPUT_TYPES.COMBOBOX,
        }
      );
    }
  );

  const onOperatorChange = (newOperator: PmComboboxFilterAllowedOperators) => {
    const newOperatorAndValue = filter.getNewValueFromNewOperator({
      newOperator,
      currentPendingOperatorAndValue: pendingOperatorAndValue,
    });
    setPendingOperatorAndValue(newOperatorAndValue);
  };

  const onOptionsChange = (newOptions: Array<EuiComboBoxOptionOption<string>>) => {
    setPendingOperatorAndValue((state) => {
      newOptions.forEach((opt) => delete opt.isGroupLabelOption);
      return { ...state, selectedOptions: newOptions };
    });
  };

  return (
    <PmFormStyling error={undefined}>
      <EuiFlexGroup
        direction="column"
        gutterSize="m"
        style={{ width: isMobile ? `min(80vw, ${filter.getPopoverWidth()})` : filter.getPopoverWidth() }}
      >
        <EuiFlexItem grow={false}>
          <EuiSuperSelect
            options={filter.getAllowedOperatorOptions()}
            valueOfSelected={pendingOperatorAndValue.operator}
            onChange={onOperatorChange}
            aria-label="Select an operator"
          />
        </EuiFlexItem>
        {"selectedOptions" in pendingOperatorAndValue && (
          <EuiFlexItem grow={false} data-testid="selected-values" style={{ height: "250px" }}>
            <EuiComboBox
              selectedOptions={pendingOperatorAndValue.selectedOptions}
              options={filter.getDefaultMappedOptions()}
              onChange={onOptionsChange}
              isClearable={true}
              aria-label="Select values"
            />
          </EuiFlexItem>
        )}
        <EuiFlexItem grow={false}>
          <PmFilterButtonsCommitButtons
            onApplyClick={filter.getOnApplyClick({
              location,
              savedFilter,
              history,
              pendingOperatorAndValue,
              closePopover,
            })}
            onClearClick={filter.getOnClearClick({ closePopover, location, savedFilter, history })}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </PmFormStyling>
  );
};

export { PmComboboxFilter };
