var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { create } from "zustand";
import { BaseSavedFiltersFilterClass } from "@pm-frontend/shared/components/FilterButtons/PmSavedFiltersFilter/BaseSavedFiltersFilterClass";
import {
  getDefaultMeldFilters,
  getMeldSavedFilterConfig,
  MeldFilterQueryKeys
} from "@pm-frontend/shared/components/FilterButtons/configs/meld-filter-configs";
import { MeldFilterConfigs } from "@pm-frontend/shared/components/FilterButtons/configs/meld-filter-configs";
import { BaseSelectableFilterClass } from "@pm-frontend/shared/components/FilterButtons/PmSelectableFilter/BaseSelectableFilterClass";
import { getParamsMatchingFilters } from "@pm-frontend/shared/components/FilterButtons/utils";
import { MeldManagerStatusLabels, MeldStatus, OpenStatuses } from "@pm-frontend/shared/types/meld";
import { CALENDAR_URL_PARAM_KEYS } from "../utils/hooks";
import { AuthUtils } from "@pm-frontend/shared/utils/auth-utils";
import { StorageUtils } from "@pm-frontend/shared/utils/storage-utils";
const CALENDAR_MELD_FILTER_PARAMS_LOCAL_STORAGE_KEY = `calendar-meld-list-filter-params-user-${AuthUtils.getUserId()}-v1`;
const MAP_MELD_FILTER_PARAMS_LOCAL_STORAGE_KEY = `map-meld-list-filter-params-user-${AuthUtils.getUserId()}-v1`;
function getInitialMeldFilterParams(type) {
  const key = type === "map" ? MAP_MELD_FILTER_PARAMS_LOCAL_STORAGE_KEY : CALENDAR_MELD_FILTER_PARAMS_LOCAL_STORAGE_KEY;
  const { ok, value: rawValue } = StorageUtils.getLocalStorageItem(key);
  if (!ok) {
    return;
  } else if (rawValue) {
    return new URLSearchParams(rawValue);
  } else {
    return;
  }
}
function writeMeldFilterParamsToStorage(type, params) {
  const key = type === "map" ? MAP_MELD_FILTER_PARAMS_LOCAL_STORAGE_KEY : CALENDAR_MELD_FILTER_PARAMS_LOCAL_STORAGE_KEY;
  StorageUtils.setLocalStorageItem(key, params.toString());
}
const useCalendarMeldFilterParamStateStore = create((set, get) => {
  const onApplyAdditionalOnClick = (newParams) => {
    const currentFullParams = new URLSearchParams(window.location.search);
    if (currentFullParams.has(CALENDAR_URL_PARAM_KEYS.MAP_OPEN)) {
      set((state) => {
        const [existingMapMeldFilterParams] = getParamsMatchingFilters({
          existingParams: newParams,
          filters: state.meldFilterParams.map.filterClasses
        });
        writeMeldFilterParamsToStorage("map", existingMapMeldFilterParams);
        return {
          meldFilterParams: __spreadProps(__spreadValues({}, state.meldFilterParams), {
            map: __spreadProps(__spreadValues({}, state.meldFilterParams.map), { priorParams: existingMapMeldFilterParams })
          })
        };
      });
    } else {
      set((state) => {
        const [existingCalendarMeldFilterParams] = getParamsMatchingFilters({
          existingParams: newParams,
          filters: state.meldFilterParams.calendar.filterClasses,
          sortFilter: state.meldFilterParams.calendar.sortFilterClass,
          savedFilter: state.meldFilterParams.calendar.savedFilterClass
        });
        writeMeldFilterParamsToStorage("calendar", existingCalendarMeldFilterParams);
        return {
          meldFilterParams: __spreadProps(__spreadValues({}, state.meldFilterParams), {
            calendar: __spreadProps(__spreadValues({}, state.meldFilterParams.calendar), { priorParams: existingCalendarMeldFilterParams })
          })
        };
      });
    }
  };
  const { sortFilter: calendarMeldListSortFilter, filters: calendarMeldListFilters } = getDefaultMeldFilters({
    allMaint: [],
    onApplyAdditionalOnClick
  });
  const meldCalendarNearbyMeldFilters = [
    new BaseSelectableFilterClass({
      config: MeldFilterConfigs.selectable.status,
      overrides: {
        alwaysShow: true,
        // we limit status filtering options for nearby melds
        options: OpenStatuses.map((status) => ({
          label: MeldManagerStatusLabels[status],
          queryParamValue: status
        }))
      },
      onApplyAdditionalOnClick
    }),
    new BaseSelectableFilterClass({
      config: MeldFilterConfigs.selectable.priority,
      overrides: { alwaysShow: true },
      onApplyAdditionalOnClick
    })
  ];
  const calendarMeldListSavedFilter = new BaseSavedFiltersFilterClass({
    config: getMeldSavedFilterConfig({
      savedFilters: [],
      otherFilters: [calendarMeldListSortFilter, ...calendarMeldListFilters]
    }),
    overrides: { alwaysShow: true }
  });
  const meldFilterParams = {
    calendar: {
      sortFilterClass: calendarMeldListSortFilter,
      savedFilterClass: calendarMeldListSavedFilter,
      filterClasses: calendarMeldListFilters,
      type: "read-only",
      // these are the default
      priorParams: getInitialMeldFilterParams("calendar") || new URLSearchParams({
        [MeldFilterQueryKeys.status]: OpenStatuses.filter((status) => status !== MeldStatus.PENDING_COMPLETION).join(
          ","
        )
      })
    },
    map: {
      // since these filter classes don't require option populate from
      // api class we can initialize it as 'read-write'
      filterClasses: meldCalendarNearbyMeldFilters,
      type: "read-write",
      // these are the default
      priorParams: getInitialMeldFilterParams("map") || new URLSearchParams({
        [MeldFilterQueryKeys.status]: [MeldStatus.PENDING_ASSIGNMENT].join(",")
      })
    }
  };
  return {
    // since both use the url to filter we need to store/retrieve the prior state
    // when switching between the calendar and the map
    // we initialize 'read-only' classes which lack populated filter options, but
    // can read from the url
    meldFilterParams,
    onApplyFilterAdditionalOnClick: onApplyAdditionalOnClick,
    actions: {
      // calendar/map meld filter params
      setMeldFilterParams: (props) => set((state) => {
        const map = "newMap" in props && !!props.newMap ? props.newMap : state.meldFilterParams.map;
        const calendar = "newCalendar" in props && !!props.newCalendar ? props.newCalendar : state.meldFilterParams.calendar;
        return {
          meldFilterParams: {
            map,
            calendar
          }
        };
      }),
      populateMeldFilterParamsOptions: (updateFunc) => set((state) => {
        if (state.meldFilterParams.calendar.type === "read-write") {
          return {};
        }
        const newCalendarState = updateFunc(state);
        return {
          meldFilterParams: {
            map: state.meldFilterParams.map,
            calendar: newCalendarState
          }
        };
      }),
      initializePageWithMeldParam: ({ location, history }) => {
        const meldFilterParamsState = get().meldFilterParams;
        const currentParams = new URLSearchParams(location.search);
        if (currentParams.has(CALENDAR_URL_PARAM_KEYS.MAP_OPEN)) {
          const [existingMapMeldFilterParams, existingMapMeldFilterParamsHasValues] = getParamsMatchingFilters({
            existingParams: currentParams,
            filters: meldFilterParamsState.map.filterClasses
          });
          if (existingMapMeldFilterParamsHasValues) {
            meldFilterParamsState.map.priorParams = existingMapMeldFilterParams;
          } else {
            for (const [key, value] of meldFilterParamsState.map.priorParams) {
              currentParams.set(key, value);
            }
            history.replace({ pathname: location.pathname, search: currentParams.toString() });
          }
        } else {
          const [existingCalendarMeldFilterParams, existingCalendarMeldFilterParamsHasValues] = getParamsMatchingFilters({
            existingParams: currentParams,
            filters: meldFilterParamsState.calendar.filterClasses,
            sortFilter: meldFilterParamsState.calendar.sortFilterClass,
            savedFilter: meldFilterParamsState.calendar.savedFilterClass
          });
          if (existingCalendarMeldFilterParamsHasValues) {
            meldFilterParamsState.calendar.priorParams = existingCalendarMeldFilterParams;
          } else {
            for (const [key, value] of meldFilterParamsState.calendar.priorParams) {
              currentParams.set(key, value);
            }
            history.replace({ pathname: location.pathname, search: currentParams.toString() });
          }
        }
      }
    }
  };
});
const useCalendarStateMeldFilterParams = () => useCalendarMeldFilterParamStateStore((state) => state.meldFilterParams);
const useCalendarStateMeldFilterApplyOnClick = () => useCalendarMeldFilterParamStateStore((state) => state.onApplyFilterAdditionalOnClick);
const useCalendarMeldFilterParamsStateActions = () => useCalendarMeldFilterParamStateStore((state) => state.actions);
export {
  useCalendarStateMeldFilterParams,
  useCalendarStateMeldFilterApplyOnClick,
  useCalendarMeldFilterParamsStateActions
};
