var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiText, EuiLink, EuiSpacer } from "@elastic/eui";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";
import { colors } from "@pm-frontend/styles";
const SingleAction = ({ action }) => {
  if ("href" in action) {
    if (action.internalLink) {
      return /* @__PURE__ */ React.createElement(Link, { to: action.href }, /* @__PURE__ */ React.createElement(EuiLink, { onClick: action.onClick }, /* @__PURE__ */ React.createElement(
        EuiText,
        __spreadValues({
          size: "s",
          css: css`
                font-weight: 400;
              `
        }, action.dataTestId ? { "data-testid": action.dataTestId } : {}),
        action.text
      )));
    } else {
      return /* @__PURE__ */ React.createElement(EuiLink, { href: action.href }, /* @__PURE__ */ React.createElement(
        EuiText,
        __spreadValues({
          size: "s",
          css: css`
              font-weight: 400;
            `
        }, action.dataTestId ? { "data-testid": action.dataTestId } : {}),
        action.text
      ));
    }
  } else if ("onClick" in action) {
    return /* @__PURE__ */ React.createElement(EuiLink, { onClick: action.onClick }, /* @__PURE__ */ React.createElement(
      EuiText,
      __spreadValues({
        size: "s",
        css: css`
            font-weight: 400;
          `
      }, action.dataTestId ? { "data-testid": action.dataTestId } : {}),
      action.text
    ));
  }
  return null;
};
const Actions = ({ actions }) => {
  if (Array.isArray(actions)) {
    return /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", gutterSize: "xs", responsive: false, style: { minWidth: "92.42px" } }, actions.filter((action) => !action.hasOwnProperty("enabled") || action.enabled).map((action, index) => {
      return /* @__PURE__ */ React.createElement(React.Fragment, { key: action.text }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, index > 0 && /* @__PURE__ */ React.createElement("span", { style: { margin: "0 4px" } }, " \u2022 ")), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(SingleAction, { action })));
    }));
  } else {
    if (actions.hasOwnProperty("enabled") && !actions.enabled) {
      return null;
    }
    return /* @__PURE__ */ React.createElement(SingleAction, { action: actions });
  }
};
const PmDataTitle = ({
  text,
  disabled,
  action,
  actionAlignment,
  disableBottomSpacing,
  "data-testid": dataTestId,
  headerComponent = "h4",
  color = colors.neutrals.gray800
}) => {
  let header = null;
  switch (headerComponent) {
    case "h1":
      header = /* @__PURE__ */ React.createElement("h1", null, text);
      break;
    case "h2":
      header = /* @__PURE__ */ React.createElement("h2", null, text);
      break;
    case "h3":
      header = /* @__PURE__ */ React.createElement("h3", null, text);
      break;
    case "h4":
      header = /* @__PURE__ */ React.createElement("h4", null, text);
      break;
    default:
      break;
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    EuiFlexGroup,
    {
      alignItems: "center",
      responsive: false,
      justifyContent: actionAlignment === "right" ? "spaceBetween" : "flexStart",
      style: { flexGrow: 0, gap: "16px", wordBreak: "break-word" }
    },
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiText, { color, "data-testid": dataTestId }, header)),
    action && !disabled && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(Actions, { actions: action }))
  ), disableBottomSpacing ? null : /* @__PURE__ */ React.createElement(EuiSpacer, { size: "m" }));
};
export { PmDataTitle };
