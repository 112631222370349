import { create } from "zustand";
import { matchPath, useLocation } from "react-router-dom";

import { useCalendarDraftModeEnabled } from "@pm-frontend/routes/Calendar/stores/draftModeStore";
import { useGetOpenWorkEntries } from "@pm-frontend/routes/Melds/queries";
import { BaseWorkEntrySerializer } from "@pm-frontend/shared/types/api/meld/serializers/base_serializers";
import { ReactRouterDomRoutes } from "@pm-assets/js/utils/redesign-routing";
import { StorageUtils } from "@pm-frontend/shared/utils/storage-utils";

const IGNORED_WORK_LOGS_LS_KEY = "workLogIdsToIgnore";

const getIgnoredWorkLogIdsLS = () => {
  const defaultValue = {};
  const { ok, value: savedIds } = StorageUtils.getLocalStorageItem(IGNORED_WORK_LOGS_LS_KEY);

  if (!ok) {
    return defaultValue;
  } else if (savedIds) {
    return JSON.parse(savedIds);
  } else {
    return defaultValue;
  }
};

const setIgnoredWorkLogIdsLS = (workLogIds: ManagementGlobalBannerState["workLogIdsToIgnore"]) => {
  StorageUtils.setLocalStorageItem(IGNORED_WORK_LOGS_LS_KEY, JSON.stringify(workLogIds));
};

type ManagementGlobalBannerType =
  | {
      type: "worklogs";
      filteredOpenWorkEntries: BaseWorkEntrySerializer[];
    }
  | {
      type: "calendarDraftMode";
    };

interface ManagementGlobalBannerState {
  workLogIdsToIgnore: Record<number, boolean>;
  actions: {
    ignoreWorkLogId: (workLogId: number) => void;
  };
}

const useManagementGlobalBannerStore = create<ManagementGlobalBannerState>((set) => ({
  workLogIdsToIgnore: getIgnoredWorkLogIdsLS(),
  actions: {
    ignoreWorkLogId: (workLogId) =>
      set((state) => {
        const newState = { ...state.workLogIdsToIgnore, [workLogId]: true };
        setIgnoredWorkLogIdsLS(newState);
        return { workLogIdsToIgnore: newState };
      }),
  },
}));

const CalendarUrls = [
  ReactRouterDomRoutes.meldCalendar,
  ReactRouterDomRoutes.meldCalendarTech,
  ReactRouterDomRoutes.meldCalendarMeldDetails,
  ReactRouterDomRoutes.meldCalendarMeldDetailsBook,
  ReactRouterDomRoutes.meldCalendarMeldAvailabilities,
];

/**
 * Derive which banner should be open
 */
const useGetManagementGlobalBannerVisibility = (): ManagementGlobalBannerType | null => {
  const calendarDraftModeEnabled = useCalendarDraftModeEnabled();
  const location = useLocation();

  const ignoredWorkLogIds = useManagementGlobalBannerStore((state) => state.workLogIdsToIgnore);
  // NOTE: since the global banner gets re-mounted on page transitions we need
  // stale time in any data fetching
  const { data: openWorkEntries } = useGetOpenWorkEntries();

  // Calendar draft mode banner
  if (calendarDraftModeEnabled && matchPath(location.pathname, CalendarUrls)) {
    return { type: "calendarDraftMode" };
  }

  // Open work log banner
  if (openWorkEntries) {
    const filteredOpenWorkEntries = openWorkEntries.filter((entry) => !ignoredWorkLogIds[entry.id]);
    if (filteredOpenWorkEntries.length > 0) {
      return { type: "worklogs", filteredOpenWorkEntries };
    }
  }

  return null;
};

const useManagementGlobalBannerActions = () => useManagementGlobalBannerStore((state) => state.actions);

export { useGetManagementGlobalBannerVisibility, useManagementGlobalBannerActions };
