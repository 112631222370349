import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { EuiFlexGroup, EuiFlexItem, EuiSelectable, EuiSelectableOption } from "@elastic/eui";

import { BaseSavedFiltersFilterClass, PmSavedFiltersFilterValue } from "./BaseSavedFiltersFilterClass";
import { PmFormStyling } from "../../Forms/PmFormStyling";

interface PmSavedFiltersFilterProps {
  filter: BaseSavedFiltersFilterClass;
  currentValue: PmSavedFiltersFilterValue;
  closePopover: () => void;
  isMobile: boolean;
}

/**
Used to present a filter button which allows for applying a saved filter
 */
const PmSavedFiltersFilter = ({ isMobile, filter, closePopover, currentValue }: PmSavedFiltersFilterProps) => {
  const history = useHistory();
  const location = useLocation();

  const [pendingValue, setPendingValue] = useState<PmSavedFiltersFilterValue>(currentValue);

  const onOptionsChange = (newOptions: Array<EuiSelectableOption<string>>) => {
    setPendingValue((state) => ({ ...state, filterId: newOptions[0] }));
    // we auto-apply this selection and close the popover
    filter.applyNewFilter({ location, history, selectedOptions: newOptions, closePopover });
  };

  return (
    <PmFormStyling error={undefined}>
      <EuiFlexGroup
        direction="column"
        gutterSize="m"
        style={{ width: isMobile ? `min(80vw, ${filter.getPopoverWidth()})` : filter.getPopoverWidth() }}
      >
        <EuiFlexItem grow={false} data-testid="selected-values">
          <EuiSelectable
            options={pendingValue.options}
            onChange={onOptionsChange}
            allowExclusions={false}
            searchable={true}
            singleSelection={true}
          >
            {(list, search) => (
              <EuiFlexItem grow={true}>
                {search}
                {list}
              </EuiFlexItem>
            )}
          </EuiSelectable>
        </EuiFlexItem>
      </EuiFlexGroup>
    </PmFormStyling>
  );
};

export { PmSavedFiltersFilter };
