var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { EuiFlexGroup, EuiFlexItem, EuiSelectable } from "@elastic/eui";
import { PmFormStyling } from "../../Forms/PmFormStyling";
const PmSavedFiltersFilter = ({ isMobile, filter, closePopover, currentValue }) => {
  const history = useHistory();
  const location = useLocation();
  const [pendingValue, setPendingValue] = useState(currentValue);
  const onOptionsChange = (newOptions) => {
    setPendingValue((state) => __spreadProps(__spreadValues({}, state), { filterId: newOptions[0] }));
    filter.applyNewFilter({ location, history, selectedOptions: newOptions, closePopover });
  };
  return /* @__PURE__ */ React.createElement(PmFormStyling, { error: void 0 }, /* @__PURE__ */ React.createElement(
    EuiFlexGroup,
    {
      direction: "column",
      gutterSize: "m",
      style: { width: isMobile ? `min(80vw, ${filter.getPopoverWidth()})` : filter.getPopoverWidth() }
    },
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, "data-testid": "selected-values" }, /* @__PURE__ */ React.createElement(
      EuiSelectable,
      {
        options: pendingValue.options,
        onChange: onOptionsChange,
        allowExclusions: false,
        searchable: true,
        singleSelection: true
      },
      (list, search) => /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: true }, search, list)
    ))
  ));
};
export { PmSavedFiltersFilter };
