var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import { EuiButtonEmpty } from "@elastic/eui";
import { Link } from "react-router-dom";
import { BorderColor, colors } from "@pm-frontend/styles";
const backgroundColorMap = {
  primary: colors.brand.white,
  danger: colors.brand.white,
  accent: colors.brand.white,
  // meant to emulate EUI input styling with a button
  // not actually a style-guide color
  input: "#fbfcfd"
};
const borderColorMap = {
  primary: colors.brand.meldBlue,
  danger: colors.interface.red.default,
  accent: colors.neutrals.gray500,
  // meant to emulate EUI input styling with a button
  // not actually a style-guide color
  input: BorderColor
};
const textColorMap = {
  primary: colors.brand.meldBlue,
  danger: colors.interface.red.default,
  accent: colors.neutrals.gray500,
  // meant to emulate EUI input styling with a button
  // not actually a style-guide color
  input: "#333333"
};
const cssButton = {
  "&.emptyButton:hover": {
    backgroundColor: `${colors.brand.lightBlue} !important`
  }
};
const PmEmptyButton = ({
  text,
  textSize = "16px",
  fontWeight = 400,
  padding = "9.5px 9.5px",
  color = "primary",
  "data-testid": dataTestId,
  onClick,
  href,
  formId,
  internalLink,
  hasBorder = false,
  isLoading = false,
  isDisabled = false
}) => {
  const colorChoice = isDisabled ? "accent" : color;
  const style = {
    fontFamily: '"Open Sans", Helvetica, Roboto, Arial, sans- serif',
    backgroundColor: backgroundColorMap[colorChoice],
    border: hasBorder ? `1px solid ${borderColorMap[colorChoice]}` : `0px`,
    borderRadius: "6px",
    textDecoration: "none",
    height: "fit-content",
    padding
  };
  const textProps = {
    style: {
      color: textColorMap[colorChoice],
      fontWeight,
      fontSize: textSize,
      lineHeight: "21px",
      textAlign: "center"
    }
  };
  const contentProps = {
    style: {
      height: "fit-content",
      padding: "0px"
    }
  };
  const defaultProps = {
    className: "emptyButton",
    style,
    textProps,
    contentProps,
    "data-testid": dataTestId,
    css: isDisabled ? "" : cssButton,
    isLoading,
    onClick
  };
  if (href) {
    if (internalLink && !isDisabled) {
      return /* @__PURE__ */ React.createElement(Link, { to: href }, /* @__PURE__ */ React.createElement(EuiButtonEmpty, __spreadValues({}, defaultProps), text));
    } else {
      return /* @__PURE__ */ React.createElement(EuiButtonEmpty, __spreadProps(__spreadValues({}, defaultProps), { href, target: "_blank", isDisabled }), text);
    }
  } else if (formId) {
    return /* @__PURE__ */ React.createElement(EuiButtonEmpty, __spreadProps(__spreadValues({}, defaultProps), { form: formId, type: "submit", isDisabled }), text);
  } else {
    return /* @__PURE__ */ React.createElement(EuiButtonEmpty, __spreadProps(__spreadValues({}, defaultProps), { isDisabled }), text);
  }
};
export { PmEmptyButton };
