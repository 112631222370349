import { ReactRouterDomRoutes } from "@pm-assets/js/utils/redesign-routing";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { RouteUrls } from "@pm-frontend/shared/utils/route-urls";
import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";
import {
  CalendarRecommendEvent,
  useCalendarStateActions,
  useCalendarStateAltEventSelectedAgentsTime,
  useCalendarStatePendingRecommendedMeld,
} from "@pm-frontend/routes/Calendar/stores/calendarStateStore";
import { BaseURLFilter } from "@pm-frontend/shared/components/FilterButtons/BaseFilterClasses";
import { differenceInDays, startOfDay } from "date-fns";
import {
  CompositeId,
  isValidCompositeId,
  isMaintenanceManagementAgent,
} from "@pm-frontend/shared/utils/assignment-utils";
import { CalendarEventMetaData, CalendarMeldMapOpened, track } from "@pm-app/utils/analytics";
import { AggregatedCalendarEvent } from "./aggregated-calendar-events-utils";
import { isAgentInMeldPropertyGroups } from "@pm-frontend/shared/utils/meld-utils";
import { useGetAgentMe } from "@pm-frontend/shared/hooks/queries/useGetAgents";
import {
  CalendarMeldFilterParamState,
  useCalendarMeldFilterParamsStateActions,
  useCalendarStateMeldFilterParams,
} from "../stores/meldFilterParamStore";
import {
  useCalendarStateMinMultiDayColumnWidth,
  useCalendarStateSelectedDate,
  useCalendarStateSelectedTimeFrame,
} from "../stores/timeFrameStore";

const CALENDAR_URL_PARAM_KEYS = {
  EVENT_ID: "event_id",
  AGENT_ID: "agent_id",
  VENDOR_ID: "vendor_id",
  PANE: "pane",
  MAP_OPEN: "map",
} as const;

const CALENDAR_URL_PANE_PARAM_VALUES = {
  MELDS_LIST: "melds_list",
  AGENT_MELD_LIST: "agent_melds_list",
  AGENT_MELD_DETAILS: "agent_melds_details",
  VENDOR_MELD_LIST: "vendor_melds_list",
  VENDOR_MELD_DETAILS: "vendor_melds_details",
  RECOMMEND_MELDS: "recommend",
  RECOMMEND_MELD_DETAIL: "recommend_detail",
  ALTERNATIVE_EVENTS: "altevent",
  GOOGLE_EVENT: "google_event",
  OUTLOOK_EVENT: "outlook_event",
  MAP_EVENT_LIST: "map_event_list",
} as const;

const LARGE_MAP_QUERY_PARAM_VALUE = "large_map";

const CALENDAR_PANE_TYPES = {
  MELD_DETAILS: "meldDetails",
  OFFER_AVAILABILITIES: "offerAvailabilities",
  NULL: "null",
  MELD_LIST: "meldsList",
  AGENT_MELD_LIST: "agentMeldsList",
  AGENT_MELD_DETAILS: "agentMelds_details",
  VENDOR_MELD_LIST: "vendorMeldsList",
  VENDOR_MELD_DETAILS: "vendorMelds_details",
  RECOMMEND: "recommendedMelds",
  RECOMMEND_DETAILS: "recommendedMelds_details",
  ALTERNATIVE_EVENT: "alternativeEvent",
  GOOGLE_EVENT: "googleCalendarEvent",
  OUTLOOK_EVENT: "outlookCalendarEvent",
  MAP_EVENT_LIST: "mapEventList",
} as const;

type CALENDAR_PANE_TYPES = typeof CALENDAR_PANE_TYPES[keyof typeof CALENDAR_PANE_TYPES];

type CalendarPaneState =
  | {
      type: Extract<CALENDAR_PANE_TYPES, "meldDetails">;
      meldId: string;
    }
  | {
      type: Extract<CALENDAR_PANE_TYPES, "recommendedMelds_details">;
      meldId: string;
    }
  | {
      type: Extract<CALENDAR_PANE_TYPES, "offerAvailabilities">;
      meldId: string;
    }
  | {
      // on mobile we need a state where no rightpanes are open
      type: Extract<CALENDAR_PANE_TYPES, "null">;
    }
  | {
      type: Extract<CALENDAR_PANE_TYPES, "meldsList">;
      filterId?: number;
    }
  | {
      // event details kept in calendarStateStore
      type: Extract<CALENDAR_PANE_TYPES, "recommendedMelds">;
    }
  | ({
      type: Extract<CALENDAR_PANE_TYPES, "alternativeEvent">;
    } & (
      | {
          eventId?: never;
          mode: "add";
        }
      | {
          eventId: string;
          mode: "edit";
        }
    ))
  | {
      type: Extract<CALENDAR_PANE_TYPES, "googleCalendarEvent">;
      eventId: string;
    }
  | {
      type: Extract<CALENDAR_PANE_TYPES, "outlookCalendarEvent">;
      eventId: string;
    }
  | {
      type: Extract<CALENDAR_PANE_TYPES, "mapEventList">;
    }
  | {
      type: Extract<CALENDAR_PANE_TYPES, "agentMeldsList">;
      agentId: string;
    }
  | {
      type: Extract<CALENDAR_PANE_TYPES, "agentMelds_details">;
      agentId: string;
      meldId: string;
    }
  | {
      type: Extract<CALENDAR_PANE_TYPES, "vendorMeldsList">;
      vendorId: string;
    }
  | {
      type: Extract<CALENDAR_PANE_TYPES, "vendorMelds_details">;
      vendorId: string;
      meldId: string;
    };

const getSearchParamsFromCalendarPaneState = ({
  newRightpaneState,
  newMapState,
  currentMapState,
  location,
  isMobile,
  meldFilterParams,
}: {
  newMapState: UseGetSetActivePaneAndMapReturn["calendarOrMap"] | undefined;
  currentMapState: UseGetSetActivePaneAndMapReturn["calendarOrMap"];
  newRightpaneState: CalendarPaneState;
  location: ReturnType<typeof useLocation>;
  isMobile: boolean;
  meldFilterParams: CalendarMeldFilterParamState["meldFilterParams"];
}): { pathname: string; search: string } => {
  const search = new URLSearchParams(location.search);
  // remove all params related to this state
  Object.values(CALENDAR_URL_PARAM_KEYS).forEach((key) => {
    search.delete(key);
  });

  let pathname = LinkHelper.normalize(RouteUrls.meldCalendarList); // add relevant params for new state

  switch (newRightpaneState.type) {
    case CALENDAR_PANE_TYPES.MELD_DETAILS:
      pathname = LinkHelper.normalize(RouteUrls.meldCalendar({ id: newRightpaneState.meldId }));
      break;
    case CALENDAR_PANE_TYPES.RECOMMEND_DETAILS:
      pathname = LinkHelper.normalize(RouteUrls.meldCalendar({ id: newRightpaneState.meldId }));
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.RECOMMEND_MELD_DETAIL);
      break;
    case CALENDAR_PANE_TYPES.OFFER_AVAILABILITIES:
      pathname = LinkHelper.normalize(RouteUrls.meldCalendarMeldAvailabilities({ id: newRightpaneState.meldId }));
      break;
    case CALENDAR_PANE_TYPES.RECOMMEND:
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.RECOMMEND_MELDS);
      break;
    case CALENDAR_PANE_TYPES.GOOGLE_EVENT:
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.GOOGLE_EVENT);
      search.set(CALENDAR_URL_PARAM_KEYS.EVENT_ID, newRightpaneState.eventId);
      break;
    case CALENDAR_PANE_TYPES.OUTLOOK_EVENT:
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.OUTLOOK_EVENT);
      search.set(CALENDAR_URL_PARAM_KEYS.EVENT_ID, newRightpaneState.eventId);
      break;
    case CALENDAR_PANE_TYPES.MAP_EVENT_LIST:
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.MAP_EVENT_LIST);
      break;
    case CALENDAR_PANE_TYPES.AGENT_MELD_LIST:
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.AGENT_MELD_LIST);
      search.set(CALENDAR_URL_PARAM_KEYS.AGENT_ID, newRightpaneState.agentId);
      break;
    case CALENDAR_PANE_TYPES.AGENT_MELD_DETAILS:
      pathname = LinkHelper.normalize(RouteUrls.meldCalendar({ id: newRightpaneState.meldId }));
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.AGENT_MELD_DETAILS);
      search.set(CALENDAR_URL_PARAM_KEYS.AGENT_ID, newRightpaneState.agentId);
      break;
    case CALENDAR_PANE_TYPES.VENDOR_MELD_LIST:
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.VENDOR_MELD_LIST);
      search.set(CALENDAR_URL_PARAM_KEYS.VENDOR_ID, newRightpaneState.vendorId);
      break;
    case CALENDAR_PANE_TYPES.VENDOR_MELD_DETAILS:
      pathname = LinkHelper.normalize(RouteUrls.meldCalendar({ id: newRightpaneState.meldId }));
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.VENDOR_MELD_DETAILS);
      search.set(CALENDAR_URL_PARAM_KEYS.VENDOR_ID, newRightpaneState.vendorId);
      break;
    case CALENDAR_PANE_TYPES.ALTERNATIVE_EVENT:
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.ALTERNATIVE_EVENTS);
      if (newRightpaneState.mode === "edit") {
        search.set(CALENDAR_URL_PARAM_KEYS.EVENT_ID, newRightpaneState.eventId);
      }
      break;
    case CALENDAR_PANE_TYPES.NULL:
      break;
    case CALENDAR_PANE_TYPES.MELD_LIST:
      // meld list is the default view on desktop, but must be set on mobile
      if (isMobile) {
        search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.MELDS_LIST);
      }
      if (newRightpaneState.filterId) {
        search.set("saved_filter", newRightpaneState.filterId.toString());
      }
      break;
    default:
      break;
  }

  // open the large map or agent map row
  if (
    newMapState === LARGE_MAP_QUERY_PARAM_VALUE ||
    (newMapState === undefined && currentMapState === LARGE_MAP_QUERY_PARAM_VALUE)
  ) {
    search.set(CALENDAR_URL_PARAM_KEYS.MAP_OPEN, LARGE_MAP_QUERY_PARAM_VALUE);
  } else if (
    (newMapState && isValidCompositeId(newMapState)) ||
    (newMapState === undefined && isValidCompositeId(currentMapState))
  ) {
    search.set(CALENDAR_URL_PARAM_KEYS.MAP_OPEN, newMapState || currentMapState);
  }

  // on map/calendar transition we remove existing meld filter params for that page and add the prior params
  if (newMapState === LARGE_MAP_QUERY_PARAM_VALUE) {
    BaseURLFilter.deleteAllFilterValues({
      paramsToMutate: search,
      filters: meldFilterParams.calendar.filterClasses,
      sortFilter: meldFilterParams.calendar.sortFilterClass,
      savedFilterClass: meldFilterParams.calendar.savedFilterClass,
    });

    const priorParams = meldFilterParams.map.priorParams;
    if (priorParams) {
      // @ts-expect-error TS doesn't handle .entries()
      for (const [key, value] of priorParams.entries()) {
        search.set(key, value);
      }
    }
  } else if (newMapState === "calendar") {
    BaseURLFilter.deleteAllFilterValues({ paramsToMutate: search, filters: meldFilterParams.map.filterClasses });
    const priorParams = meldFilterParams.calendar.priorParams;
    if (priorParams) {
      // @ts-expect-error TS doesn't handle .entries()
      for (const [key, value] of priorParams.entries()) {
        search.set(key, value);
      }
    }
  }
  return { search: search.toString(), pathname };
};

const getCalendarOrMapState = (
  searchParams: URLSearchParams,
  isMobile: boolean
): UseGetSetActivePaneAndMapReturn["calendarOrMap"] => {
  if (isMobile) {
    return "calendar";
  }
  const queryParamValue = searchParams.get(CALENDAR_URL_PARAM_KEYS.MAP_OPEN);

  if (!queryParamValue) {
    return "calendar";
  } else if (queryParamValue === LARGE_MAP_QUERY_PARAM_VALUE) {
    return LARGE_MAP_QUERY_PARAM_VALUE;
  } else if (isValidCompositeId(queryParamValue)) {
    return queryParamValue;
  }
  return "calendar";
};

const shouldClearPendingRecommendedMeldEvent = (
  pendingRecommendedEvent: CalendarRecommendEvent | null,
  newRightpaneState: CalendarPaneState | undefined
): boolean => {
  if (!pendingRecommendedEvent) {
    return false;
  } else if (!newRightpaneState) {
    return false;
  } else if (newRightpaneState.type === "recommendedMelds" || newRightpaneState.type === "recommendedMelds_details") {
    return false;
  }
  return true;
};

interface UseGetSetActivePaneAndMapReturn {
  rightPaneState: CalendarPaneState;
  // the string variant is the composite id of the open
  // agent/vendor on the calendar view
  calendarOrMap: typeof LARGE_MAP_QUERY_PARAM_VALUE | "calendar" | CompositeId;
  updateRightPaneURL: (arg0: {
    newMapState?: typeof LARGE_MAP_QUERY_PARAM_VALUE | "calendar" | CompositeId;
    newRightpaneState?: CalendarPaneState;
    action?: "push" | "replace";
  }) => void;
}

// currently whether the rightpane is open is a mix of url state and in-memory state
const useGetSetActivePaneAndMap = (): UseGetSetActivePaneAndMapReturn => {
  const eventMetaData = useGetCalendarEventMetaData();
  const isMobile = useIsMobile();
  const { updateOnRightPaneChange } = useCalendarStateActions();
  const { setMeldFilterParams } = useCalendarMeldFilterParamsStateActions();
  const pendingRecommendedEvent = useCalendarStatePendingRecommendedMeld();
  const pendingAltEvent = useCalendarStateAltEventSelectedAgentsTime();
  const meldFilterParams = useCalendarStateMeldFilterParams();

  const detailsPaneMatch = useRouteMatch<{ meldId: string }>({ path: ReactRouterDomRoutes.meldCalendarMeldDetails });
  const availabilitiesPaneMatch = useRouteMatch<{ meldId: string }>(
    ReactRouterDomRoutes.meldCalendarMeldAvailabilities
  );
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const calendarOrMap = getCalendarOrMapState(searchParams, isMobile);

  const paneUrlValue = searchParams.get(CALENDAR_URL_PARAM_KEYS.PANE);
  let rightPaneState: CalendarPaneState = { type: "null" };

  // panes used on mobile and desktop
  if (availabilitiesPaneMatch) {
    rightPaneState = { type: "offerAvailabilities", meldId: availabilitiesPaneMatch.params.meldId };
  } else if (detailsPaneMatch) {
    if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.RECOMMEND_MELD_DETAIL) {
      rightPaneState = { type: "recommendedMelds_details", meldId: detailsPaneMatch.params.meldId };
    } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.AGENT_MELD_DETAILS) {
      const agentId = searchParams.get(CALENDAR_URL_PARAM_KEYS.AGENT_ID);
      rightPaneState = { type: "agentMelds_details", meldId: detailsPaneMatch.params.meldId, agentId: agentId || "" };
    } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.VENDOR_MELD_DETAILS) {
      const vendorId = searchParams.get(CALENDAR_URL_PARAM_KEYS.VENDOR_ID);
      rightPaneState = {
        type: "vendorMelds_details",
        meldId: detailsPaneMatch.params.meldId,
        vendorId: vendorId || "",
      };
    } else {
      rightPaneState = { type: "meldDetails", meldId: detailsPaneMatch.params.meldId };
    }
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.ALTERNATIVE_EVENTS) {
    const eventId = searchParams.get(CALENDAR_URL_PARAM_KEYS.EVENT_ID);
    if (eventId) {
      rightPaneState = { type: "alternativeEvent", mode: "edit", eventId };
    } else {
      rightPaneState = { type: "alternativeEvent", mode: "add" };
    }
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.GOOGLE_EVENT) {
    const eventId = searchParams.get(CALENDAR_URL_PARAM_KEYS.EVENT_ID);
    if (eventId) {
      rightPaneState = { type: "googleCalendarEvent", eventId };
    }
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.OUTLOOK_EVENT) {
    const eventId = searchParams.get(CALENDAR_URL_PARAM_KEYS.EVENT_ID);
    if (eventId) {
      rightPaneState = { type: "outlookCalendarEvent", eventId };
    }
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.MELDS_LIST) {
    // meld list pane url value isn't needed on desktop (as it is default) but is on mobile
    rightPaneState = { type: CALENDAR_PANE_TYPES.MELD_LIST };
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.AGENT_MELD_LIST) {
    const agentId = searchParams.get(CALENDAR_URL_PARAM_KEYS.AGENT_ID);
    if (agentId) {
      rightPaneState = { type: CALENDAR_PANE_TYPES.AGENT_MELD_LIST, agentId };
    }
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.VENDOR_MELD_LIST) {
    const vendorId = searchParams.get(CALENDAR_URL_PARAM_KEYS.VENDOR_ID);
    if (vendorId) {
      rightPaneState = { type: CALENDAR_PANE_TYPES.VENDOR_MELD_LIST, vendorId };
    }
  } else if (isMobile) {
    // everything below this isn't implemented for mobile yet
    rightPaneState = { type: "null" };
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.RECOMMEND_MELDS) {
    rightPaneState = { type: CALENDAR_PANE_TYPES.RECOMMEND };
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.MAP_EVENT_LIST) {
    rightPaneState = { type: CALENDAR_PANE_TYPES.MAP_EVENT_LIST };
  } else {
    rightPaneState = { type: CALENDAR_PANE_TYPES.MELD_LIST };
  }

  const updateRightPaneURL: UseGetSetActivePaneAndMapReturn["updateRightPaneURL"] = ({
    newMapState,
    newRightpaneState,
    action = "push",
  }) => {
    const clearPendingRecMeld = shouldClearPendingRecommendedMeldEvent(pendingRecommendedEvent, newRightpaneState);
    const clearAltEvent = newRightpaneState && newRightpaneState.type !== "alternativeEvent" && !!pendingAltEvent;

    // if caller doesn't provide new state we just use existing state
    const { search, pathname } = getSearchParamsFromCalendarPaneState({
      newRightpaneState: newRightpaneState || rightPaneState,
      newMapState,
      currentMapState: calendarOrMap,
      location,
      isMobile,
      meldFilterParams,
    });

    // we persist the currently applied meld filter params on calendar/map transition
    if (newMapState) {
      if (newMapState === LARGE_MAP_QUERY_PARAM_VALUE) {
        track(CalendarMeldMapOpened({ type: "large", ...eventMetaData }));
        const currentCalendarParams = BaseURLFilter.getFilterValues({
          location,
          filters: meldFilterParams.calendar.filterClasses,
          sortFilter: meldFilterParams.calendar.sortFilterClass,
          savedFilterClass: meldFilterParams.calendar.savedFilterClass,
          savedFilter: undefined,
        });
        setMeldFilterParams({
          newCalendar: {
            ...meldFilterParams.calendar,
            priorParams: currentCalendarParams,
          },
        });
        updateOnRightPaneChange({
          shouldClearPendingRecommendedMeldEvent: clearPendingRecMeld,
          shouldClearPendingAltEvent: !!clearAltEvent,
        });
      } else if (newMapState === "calendar") {
        const currentMapParams = BaseURLFilter.getFilterValues({
          location,
          filters: meldFilterParams.map.filterClasses,
          savedFilter: undefined,
        });
        setMeldFilterParams({
          newMap: {
            ...meldFilterParams.map,
            priorParams: currentMapParams,
          },
        });
        updateOnRightPaneChange({
          shouldClearPendingRecommendedMeldEvent: clearPendingRecMeld,
          shouldClearPendingAltEvent: !!clearAltEvent,
        });
      } else if (isValidCompositeId(newMapState)) {
        track(
          CalendarMeldMapOpened({
            type: isMaintenanceManagementAgent({ composite_id: newMapState }) ? "small-agent" : "small-vendor",
            ...eventMetaData,
          })
        );
      }
    }

    if (action === "push") {
      history.push({
        pathname,
        search,
      });
    } else if (action === "replace") {
      history.replace({
        pathname,
        search,
      });
    }
  };

  return { rightPaneState, calendarOrMap, updateRightPaneURL };
};

// convienience hook for getting the state used in all calendar events
const useGetCalendarEventMetaData = (): CalendarEventMetaData => {
  const isMobile = useIsMobile();
  const selectedTimeframe = useCalendarStateSelectedTimeFrame(isMobile);
  const minMultiDayColumnWidth = useCalendarStateMinMultiDayColumnWidth();
  const selectedDate = useCalendarStateSelectedDate();
  const daysOffsetFromCurrentDay = differenceInDays(startOfDay(new Date(selectedDate)), startOfDay(new Date()));

  return {
    isMobile,
    selectedTimeframe,
    daysOffsetFromCurrentDay,
    minMultiDayColumnWidth,
  };
};

function useIsEventViewableDueToPropertyGroups(event: AggregatedCalendarEvent): boolean {
  const { data: currentAgentDetails } = useGetAgentMe();
  // in most cases this query is cached and served instantly. In the case
  // of the Meld Calendar the page waits for it before loading
  if (!currentAgentDetails) {
    return true;
  }

  if (event.meld) {
    return isAgentInMeldPropertyGroups(event.meld, currentAgentDetails);
  } else {
    return true;
  }
}

export {
  useIsEventViewableDueToPropertyGroups,
  useGetCalendarEventMetaData,
  useGetSetActivePaneAndMap,
  getCalendarOrMapState,
  CALENDAR_URL_PANE_PARAM_VALUES,
  CalendarPaneState,
  CALENDAR_PANE_TYPES,
  CALENDAR_URL_PARAM_KEYS,
  UseGetSetActivePaneAndMapReturn,
};
