var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { withRouter } from "react-router-dom";
import { FloatingLargeLoader } from "../../loaders";
import EmptyView from "@pm-shared/components/empty/empty-view";
import React, { useEffect, useState } from "react";
import { estimateLineItemsURL } from "../../meld/apis/estimates-api";
import axios from "axios";
import messageActions from "../../common/actions/message-actions";
import I from "immutable";
import Meld from "../../svg/meld-svg";
import Link from "@pm-shared/utils/link";
import Pin from "../../svg/pin-svg";
import MeldUtils from "../../meld/utils/meld-utils";
import CreateEstimateLineItemsHeaderRow from "./estimate-line-items-header";
import Plus from "../../svg/plus-svg";
import Currency from "../../utils/currency";
import ButtonContainer from "../../../../app/components/containers/ButtonContainer";
import Button, { ButtonTypes } from "../../../../app/components/buttons/Button";
import EstimateLineItem from "../meld/estimate-line-items";
import HistoryUtils from "../../common/utils/history-utils";
import _ from "lodash";
import { BaseLineItemCostTypeEnum } from "../../common/openapi";
import EstimateLineItemsApi from "../../meld/apis/estimate-line-items-api";
import { EstimateStatuses } from "../../../../assets/js/constants";
import ErrorUtils from "@pm-assets/js/utils/error-utils";
import MeldAPI from "@pm-assets/js/meld/apis/meld-api";
import Features from "@pm-assets/js/common/feature-flags";
import { formatDateTimeMonthDDYYYYHHMM_xm } from "@pm-frontend/shared/utils/formatDayMonth";
import AgentApi from "@pm-assets/js/vendor_app/apis/agent-api";
const LastUpdatedLabel = ({ updated, update_by }) => {
  const [agents, setAgents] = useState([]);
  const [compError, setCompError] = useState(null);
  useEffect(() => {
    AgentApi.fetchAll().then((response) => setAgents(response.data)).catch((error) => setCompError(error));
  }, []);
  const getLabelContent = () => {
    var _a, _b;
    if (update_by && !compError) {
      const agent = agents.find((agent2) => agent2.id = update_by);
      if (agent) {
        return `Last edited by ${(_a = agent.user) == null ? void 0 : _a.first_name} ${(_b = agent.user) == null ? void 0 : _b.last_name}  on ${formatDateTimeMonthDDYYYYHHMM_xm(updated)}`;
      }
    }
    return `Last edited on ${formatDateTimeMonthDDYYYYHHMM_xm(updated)}`;
  };
  return /* @__PURE__ */ React.createElement("label", null, getLabelContent());
};
class EstimateDetailTabContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      submitted: false,
      errors: [],
      estimate_line_items: I.fromJS([this.createFreshLineItem()]),
      notes: "",
      // eslint-disable-next-line no-undefined
      days_to_complete: void 0,
      // eslint-disable-next-line no-undefined
      days_until_start: void 0,
      // eslint-disable-next-line no-undefined
      pm_fee: void 0,
      loading: true,
      hasLineItems: false,
      updated: "",
      update_by: void 0
    };
    this.handleDeleteRequested = this.handleDeleteRequested.bind(this);
    this.handleAddNewLineItem = this.handleAddNewLineItem.bind(this);
    this.createFreshLineItem = this.createFreshLineItem.bind(this);
    this.renderLineItems = this.renderLineItems.bind(this);
    this.handleLineItemChange = this.handleLineItemChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.calcTotal = this.calcTotal.bind(this);
    this.getExtraErrors = this.getExtraErrors.bind(this);
    this.onChangeSetNotes = this.onChangeSetNotes.bind(this);
    this.onChangeSetDaysToComplete = this.onChangeSetDaysToComplete.bind(this);
    this.onChangeSetDaysUntilStart = this.onChangeSetDaysUntilStart.bind(this);
  }
  render() {
    var _a;
    const meld = this.props.meld;
    if (meld) {
      const estimate = meld.get("cost_estimate");
      if ((!this.state.hasLineItems || estimate.get("estimate_status") === EstimateStatuses.ESTIMATE_IN_PROGRESS) && !this.props.vendorHub) {
        return /* @__PURE__ */ React.createElement(EmptyView, { "data-test": "estimates_line_items_view.empty_content", message: "No Line Items" });
      }
      const disableLineItemInput = !(this.props.vendorHub && (estimate == null ? void 0 : estimate.get("estimate_status")) === EstimateStatuses.ESTIMATE_IN_PROGRESS);
      const disableFeeInput = !(!this.props.vendorHub && (estimate == null ? void 0 : estimate.get("estimate_status")) === EstimateStatuses.ESTIMATE_SUBMITTED);
      return /* @__PURE__ */ React.createElement("div", { className: "create-invoice" }, /* @__PURE__ */ React.createElement("div", { className: "detail-view" }, /* @__PURE__ */ React.createElement("div", { className: "small-centered small-12 columns detail-content" }, /* @__PURE__ */ React.createElement("div", { className: "detail-header" }, /* @__PURE__ */ React.createElement("div", { className: "row" }, /* @__PURE__ */ React.createElement("div", { className: "columns small-12 heading" }, /* @__PURE__ */ React.createElement("h2", { className: "detail-title" }, "Add Items")))), /* @__PURE__ */ React.createElement("div", { className: "detail-body", "data-test": "detail-view.estimate-line-items-view" }, /* @__PURE__ */ React.createElement("div", { className: "invoice-summary" }, /* @__PURE__ */ React.createElement("span", { className: "invoice-meld-description" }, /* @__PURE__ */ React.createElement(Meld, null), /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(Link, { to: `/meld/${meld.get("id")}/summary/` }, meld.get("brief_description")))), /* @__PURE__ */ React.createElement("span", { className: "invoice-meld-address" }, /* @__PURE__ */ React.createElement(Pin, null), /* @__PURE__ */ React.createElement("span", null, MeldUtils.getAbbreviatedPropertyListLine1(meld), " "), /* @__PURE__ */ React.createElement("span", null, MeldUtils.getAbbreviatedPropertyListLine2(meld)))), /* @__PURE__ */ React.createElement("form", { onSubmit: (e) => e.preventDefault() }, /* @__PURE__ */ React.createElement("div", { className: "invoice-table" }, /* @__PURE__ */ React.createElement("div", { className: "row invoice-header-row" }, /* @__PURE__ */ React.createElement(CreateEstimateLineItemsHeaderRow, null)), /* @__PURE__ */ React.createElement("div", { className: "invoice-line-items" }, this.renderLineItems(disableLineItemInput)), !disableLineItemInput && /* @__PURE__ */ React.createElement(
        "div",
        {
          className: "add-item-row",
          onClick: this.handleAddNewLineItem,
          "data-test": "vendor-estimate-details.add-line-item"
        },
        /* @__PURE__ */ React.createElement("div", { className: "line-item-add" }, /* @__PURE__ */ React.createElement("span", { className: "line-item-add-icon" }, /* @__PURE__ */ React.createElement(Plus, null)), /* @__PURE__ */ React.createElement("span", null, "Add line item"))
      ), /* @__PURE__ */ React.createElement("div", { className: "total-separator" }), /* @__PURE__ */ React.createElement("div", { className: "total row" }, /* @__PURE__ */ React.createElement("div", { className: "columns small-12 large-2 large-push-10" }, /* @__PURE__ */ React.createElement("div", { className: "total" }, /* @__PURE__ */ React.createElement("div", { className: "total-label" }, "Total"), /* @__PURE__ */ React.createElement("div", { className: "total-amount" }, Currency.dollar(this.calcTotal())))))), /* @__PURE__ */ React.createElement("div", { className: "row", style: { marginBottom: "20px" } }, /* @__PURE__ */ React.createElement("div", { className: "columns small-12 large-7 text-right" }, /* @__PURE__ */ React.createElement("label", null, "How many days will you need before beginning the work?")), /* @__PURE__ */ React.createElement("div", { className: "columns small-12 large-5 text-left" }, /* @__PURE__ */ React.createElement(
        "input",
        {
          type: "number",
          "data-test": "estimate-detail.line-items.lead-time",
          value: this.state.days_until_start ? this.state.days_until_start : "",
          onChange: this.onChangeSetDaysUntilStart(),
          placeholder: "Please type the number of days.",
          disabled: disableLineItemInput
        }
      ))), /* @__PURE__ */ React.createElement("div", { className: "row", style: { marginBottom: "20px" } }, /* @__PURE__ */ React.createElement("div", { className: "columns small-12 large-7 text-right" }, /* @__PURE__ */ React.createElement("label", null, "How many days will you need to complete the work?")), /* @__PURE__ */ React.createElement("div", { className: "columns small-12 large-5 text-left" }, /* @__PURE__ */ React.createElement(
        "input",
        {
          type: "number",
          "data-test": "estimate-detail.line-items.completion-time",
          value: this.state.days_to_complete ? this.state.days_to_complete : "",
          onChange: this.onChangeSetDaysToComplete(),
          placeholder: "Please type the number of days.",
          disabled: disableLineItemInput
        }
      ))), !this.props.vendorHub && /* @__PURE__ */ React.createElement("div", { className: "row", style: { marginBottom: "20px" } }, /* @__PURE__ */ React.createElement("div", { className: "columns small-12 large-7 text-right" }, /* @__PURE__ */ React.createElement("label", null, "PM fee:")), /* @__PURE__ */ React.createElement("div", { className: "columns small-12 large-5 text-left" }, /* @__PURE__ */ React.createElement(
        "input",
        {
          type: "number",
          "data-test": "pm-fee-input",
          value: this.state.pm_fee,
          onFocus: ((_a = this.state.pm_fee) == null ? void 0 : _a.toString()) === "0.00" ? (e) => {
            e.target.value = "";
          } : () => {
            return;
          },
          onChange: this.onChangeSetPMFee(),
          placeholder: "Please type the PM fee.",
          disabled: disableFeeInput
        }
      ))), /* @__PURE__ */ React.createElement("div", { className: "row" }, /* @__PURE__ */ React.createElement("div", { className: "columns small-12" }, /* @__PURE__ */ React.createElement("div", { className: "label-container" }, /* @__PURE__ */ React.createElement("label", null, Features.isEstimatesV2Enabled() ? "Memo" : "Notes")), /* @__PURE__ */ React.createElement(
        "textarea",
        {
          name: "notes",
          "data-test": "estimate-detail.line-items.notes",
          className: "invoice-notes",
          placeholder: Features.isEstimatesV2Enabled() ? "Enter memo" : "Enter notes",
          value: this.state.notes ? this.state.notes : "",
          onChange: this.onChangeSetNotes(),
          disabled: disableLineItemInput
        }
      ))), Features.isEstimatesV2Enabled() && /* @__PURE__ */ React.createElement("div", { className: "row" }, /* @__PURE__ */ React.createElement("div", { className: "columns small-12" }, /* @__PURE__ */ React.createElement(LastUpdatedLabel, { updated: this.state.updated, update_by: this.state.update_by }))), /* @__PURE__ */ React.createElement("div", { className: "row" }, /* @__PURE__ */ React.createElement("div", { className: "columns" }, this.renderErrors())), /* @__PURE__ */ React.createElement("div", { className: "footer" }, /* @__PURE__ */ React.createElement("div", { className: "footer-content" }, /* @__PURE__ */ React.createElement(ButtonContainer, null, (!disableLineItemInput || !disableFeeInput) && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
        Button,
        {
          className: "expenditures-cancel-btn",
          buttonType: ButtonTypes.Cancel,
          onClick: this.cancel,
          disabled: this.state.saving
        },
        "Cancel"
      ), /* @__PURE__ */ React.createElement(
        Button,
        {
          className: "expenditures-save-btn",
          "data-test": "estimate-save-draft-btn",
          buttonType: ButtonTypes.Primary,
          onClick: () => this.handleSubmit(true),
          disabled: this.state.saving
        },
        "Save Draft"
      ), /* @__PURE__ */ React.createElement(
        Button,
        {
          className: "expenditures-save-btn",
          "data-test": "estimate-submit-btn",
          buttonType: ButtonTypes.Primary,
          onClick: () => this.handleSubmit(false),
          disabled: this.state.saving
        },
        "Save and Submit"
      ))))))))));
    }
    if (this.state.loading) {
      return /* @__PURE__ */ React.createElement(FloatingLargeLoader, null);
    }
    if (this.state.errors) {
      return /* @__PURE__ */ React.createElement(EmptyView, { message: "The requested line items could not be found." });
    }
  }
  componentDidMount() {
    if (this.props.meld) {
      let estimate = this.props.meld.get("cost_estimate");
      let update_by = void 0;
      if (estimate.get("update_by")) {
        update_by = estimate.get("update_by").get("persona_id");
      }
      if (estimate) {
        this.setState({
          notes: estimate.get("notes"),
          days_to_complete: estimate.get("days_to_complete"),
          days_until_start: estimate.get("days_until_start"),
          pm_fee: estimate.get("pm_fee"),
          updated: estimate.get("updated"),
          update_by
        });
      }
      const lineItemsURL = estimateLineItemsURL(estimate.get("id"));
      axios.get(lineItemsURL).then((response) => {
        if (response.data && response.data.length > 0) {
          this.setState({
            estimate_line_items: I.fromJS(response.data),
            loading: false,
            hasLineItems: true
          });
        }
      }).catch((result) => {
        this.setState({ loading: false, errors: result.messages });
        messageActions.storeError({ body: result.messages[0] });
      });
    }
  }
  createFreshLineItem() {
    const estimateID = this.props.estimateID;
    return I.fromJS({
      cost_estimate: estimateID,
      quantity: 1,
      unit_cost: 0,
      unit_price: 0,
      line_item_type: BaseLineItemCostTypeEnum.LABOR,
      _cid: _.uniqueId("line_item_")
    });
  }
  handleAddNewLineItem() {
    let updatedLineItems = this.state.estimate_line_items.push(this.createFreshLineItem());
    this.setState({ estimate_line_items: updatedLineItems });
  }
  handleDeleteRequested(lineItem) {
    let itemIndex = this._findItemIndex(lineItem);
    let lineItems = this.state.estimate_line_items;
    let updatedLineItems = lineItems.slice(0, itemIndex).concat(lineItems.slice(itemIndex + 1));
    this.setState({ estimate_line_items: updatedLineItems });
  }
  handleLineItemChange(lineItem) {
    let itemIndex = this._findItemIndex(lineItem);
    let updatedLineItems = this.state.estimate_line_items.set(itemIndex, lineItem);
    this.setState({ estimate_line_items: updatedLineItems });
  }
  handleSubmit(isDraft) {
    this.setState({ errors: [] });
    if (this.isValid()) {
      this.setState({ saving: true });
      let xhr;
      let meld = this.props.meld;
      const estimate = meld.get("cost_estimate");
      let estimate_status = estimate.get("estimate_status");
      if (this.state.hasLineItems) {
        xhr = EstimateLineItemsApi.update(estimate.get("id"), this.state.estimate_line_items);
      } else {
        xhr = EstimateLineItemsApi.createLineItems(estimate.get("id"), this.state.estimate_line_items);
      }
      xhr.then(() => {
        this.setState({ saving: false, submitted: true });
      }).catch((result) => {
        this.setState({ saving: false });
        this.setState({ saving: false, errors: result.messages });
      });
      if (!isDraft && estimate_status === EstimateStatuses.ESTIMATE_IN_PROGRESS) {
        estimate_status = EstimateStatuses.ESTIMATE_SUBMITTED;
        let data = {
          is_complete: true,
          date: /* @__PURE__ */ new Date()
        };
        MeldAPI.markComplete(meld.get("id"), data).then(() => {
          this.setState({ submitted: true });
        }).catch((result) => {
          this.setState({ saving: false, errors: result.messages });
        });
      }
      EstimateLineItemsApi.updateEstimate(estimate.get("id"), {
        estimate_status,
        notes: this.state.notes,
        days_to_complete: this.state.days_to_complete,
        days_until_start: this.state.days_until_start,
        pm_fee: this.state.pm_fee
      }).then(() => {
        this.setState({ saving: false });
        HistoryUtils.replace(this.props.history, `meld/${meld.get("id")}/summary`);
        this.props.refreshMeld();
      }).catch((result) => {
        this.setState({ saving: false, errors: result.messages });
      });
    }
  }
  renderLineItems(disableInput) {
    return this.state.estimate_line_items.map((lineItem) => {
      const estimateLineItemsProps = {
        lineItem,
        onLineItemChanged: this.handleLineItemChange,
        onDeleteRequested: this.handleDeleteRequested,
        disableInput
      };
      return /* @__PURE__ */ React.createElement(EstimateLineItem, __spreadValues({ key: lineItem.get("id") || lineItem.get("_cid") }, estimateLineItemsProps));
    });
  }
  cancel() {
    HistoryUtils.replace(this.props.history, `melds/melding/?saved_filter=default`);
  }
  _findItemIndex(lineItem) {
    let _cid = lineItem.get("_cid");
    let id = lineItem.get("id");
    return this.state.estimate_line_items.findIndex((lItem) => {
      if (_cid) {
        return _cid === lItem.get("_cid");
      } else {
        return id === lItem.get("id");
      }
    });
  }
  calcTotal() {
    let total = this.state.estimate_line_items.reduce(
      (sum, lineItem) => sum + lineItem.get("unit_cost") * lineItem.get("quantity"),
      0
    );
    return total || 0;
  }
  getExtraErrors() {
    return this.state.errors;
  }
  renderErrors() {
    if (this.state.errors) {
      return /* @__PURE__ */ React.createElement("div", { className: "row" }, /* @__PURE__ */ React.createElement("div", { className: "columns small-centered" }, /* @__PURE__ */ React.createElement("div", { className: "error", "data-test": "estimate_detail.error" }, this.state.errors)));
    }
  }
  onChangeSetNotes() {
    return (e) => {
      this.setState({ notes: e.target.value });
    };
  }
  onChangeSetDaysToComplete() {
    return (e) => {
      this.setState({ days_to_complete: e.target.value === "" ? void 0 : e.target.value });
    };
  }
  onChangeSetDaysUntilStart() {
    return (e) => {
      this.setState({ days_until_start: e.target.value === "" ? void 0 : e.target.value });
    };
  }
  onChangeSetPMFee() {
    return (e) => {
      this.setState({ pm_fee: e.target.value });
    };
  }
  isValid() {
    const constraints = {
      estimate_line_items: {
        lineItems: {}
      },
      notes: {
        length: {
          maximum: 15e3,
          tooLong: "^ Notes must be under 15,000 characters"
        }
      },
      days_until_start: {
        estimateDays: {
          message: "^ The number of days until starting the estimate must be greater than or equal to zero."
        }
      },
      days_to_complete: {
        estimateDays: {
          message: "^ The number of days to complete the estimate must be greater than or equal to zero."
        }
      },
      pm_fee: {
        estimatePMFee: {
          message: "^ The PM fee estimate must be greater than or equal to zero."
        }
      }
    };
    const errors = I.fromJS(
      ErrorUtils.getValidationErrorList(
        {
          estimate_line_items: this.state.estimate_line_items.toJS(),
          notes: this.state.notes,
          days_until_start: this.state.days_until_start,
          days_to_complete: this.state.days_to_complete,
          pm_fee: this.state.pm_fee
        },
        constraints
      )
    );
    this.setState({ errors });
    return errors.size === 0;
  }
}
export default withRouter(EstimateDetailTabContent);
