import moment, { Moment } from "moment";
import { create } from "zustand";

import { shouldShowCalendarRedesignDueToDesktop } from "@pm-assets/js/utils/redesign-routing";
import { AggregatedCalendarEvent } from "../utils/aggregated-calendar-events-utils";
import { useCalendarMapStateStore } from "./mapStore";
import { useCalendarTimeFrameStateStore } from "./timeFrameStore";
import { useCalendarDragAndDropStateStore } from "./dragDropStore";
import { StorageUtils } from "@pm-frontend/shared/utils/storage-utils";
import { useCalendarDraftModeStateStore } from "./draftModeStore";

// just a utility type for grabbing two specific variants of AggregatedCalendarEvent union
type ExtractMember<U, T extends U[keyof U]> = U extends { type: T } ? U : never;

const CALENDAR_SELECTED_VENDOR_AGENTS_LOCAL_STORAGE_KEY = `meld-calendar-selected-agents-${window.PM.user.id}-${window.PM.user.multitenantId}`;

const setInitialSelectedVendorsOrAgents = (state: CalendarSelectedAgentsVendorsIds) => {
  StorageUtils.setLocalStorageItem(CALENDAR_SELECTED_VENDOR_AGENTS_LOCAL_STORAGE_KEY, JSON.stringify(state));
};

const getInitialSelectedVendorsOrAgents = (): CalendarSelectedAgentsVendorsIds => {
  let result: CalendarSelectedAgentsVendorsIds = { agents: [], vendors: [] };
  const { value } = StorageUtils.getLocalStorageItem(CALENDAR_SELECTED_VENDOR_AGENTS_LOCAL_STORAGE_KEY);
  if (value) {
    const parsed = JSON.parse(value);
    if (Array.isArray(parsed.agents) && Array.isArray(parsed.vendors)) {
      result = parsed;
    }
  }
  return result;
};

interface CalendarSelectedAgentsVendorsIds {
  agents: number[];
  vendors: number[];
}

interface CalendarRecommendEvent {
  personaId: number;
  personaType: "agent" | "vendor";
  previousEvent: {
    id: number;
    type: "management_scheduled" | "alternative_event_scheduled";
  } | null;
  selectedAppointment: {
    start: moment.Moment;
    end: moment.Moment;
  };
}

interface AltEventSelectedAgentsTimes {
  selectedAgents: number[];
  // set if we are editing an event
  eventId?: number;
  eventDescription?: string;
  selectedTime: {
    date: Date;
    start?: Date | undefined;
    end?: Date | undefined;
  };
}
interface MapBoxLocation {
  mapbox_id: string;
  original_string: string;
  name: string;
  full_address: string;
  latitude: number;
  longitude: number;
}

interface CalendarState {
  selectedVendorsAgentsIds: CalendarSelectedAgentsVendorsIds;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  scheduledSegments: any;
  pendingResidentAvailabilities: Array<ExtractMember<AggregatedCalendarEvent, "pending_offered_availability">>;
  altEventSelectedAgentsTime: AltEventSelectedAgentsTimes | null;
  pendingRecommendedMeld: CalendarRecommendEvent | null;
  residentAvailabilitiesMeldId: number | null;
  appointmentToReschedule:
    | ({ meldId: number } & ({ type: "rescheduleMeld"; appointmentId: number } | { type: "add-appointment" }))
    | null;
  actions: {
    setResidentAvailabilityMeldId: (meldId: number | null) => void;
    setPendingRecommendEvent: (event: CalendarRecommendEvent | null) => void;
    // selected vendors and agents - add is for extending the current lists
    setSelectedVendorsAgents: (selected: CalendarSelectedAgentsVendorsIds) => void;
    addSelectedVendorsAgents: (selected: CalendarSelectedAgentsVendorsIds) => void;
    // when the component unmounts we want to reset the state
    resetState: () => void;
    // selected resident availabilities
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addScheduledSegment: (segment: any) => void;
    removeScheduledSegment: (id: number) => void;
    // click to add availabilities and their calendar placeholders
    addPendingResidentAvailability: (
      eventsToAdd: Array<{
        tempId: string;
        agentId: number;
        start: string;
        end: string;
      }>
    ) => void;
    // pending resident availabilities
    upsertPendingResidentAvailability: (props: {
      tempId: string;
      start: Moment;
      end: Moment;
      agents: Array<{ id: number }>;
    }) => void;
    removePendingResidentAvailability: (tempId: string) => void;
    clearPendingResidentAvailabilities: () => void;
    // click to set alternative event form
    initializeAltEventSelectedAgentsTime: (props: {
      agents: AltEventSelectedAgentsTimes["selectedAgents"];
      selectedTime: AltEventSelectedAgentsTimes["selectedTime"];
      eventDescription: string;
    }) => void;
    setAltEventSelectedAgentsTime: (props: {
      newAgents?: AltEventSelectedAgentsTimes["selectedAgents"];
      newSelectedTime?: Partial<AltEventSelectedAgentsTimes["selectedTime"]>;
      eventId?: number;
      eventDescription?: string;
    }) => void;
    updateOnRightPaneChange: (arg0: {
      shouldClearPendingRecommendedMeldEvent: boolean;
      shouldClearPendingAltEvent: boolean;
    }) => void;
    setAppointmentToReschedule: (arg0: CalendarState["appointmentToReschedule"]) => void;
  };
}

// do not export - actions are the interface for interacting with the state
const useCalendarStateStore = create<CalendarState>((set) => ({
  activePane: shouldShowCalendarRedesignDueToDesktop.matches ? { type: "meldsToSchedule" } : { type: "mobile-null" },
  selectedVendorsAgentsIds: getInitialSelectedVendorsOrAgents(),
  scheduledSegments: [],
  dragAndDropState: null,
  pendingResidentAvailabilities: [],
  pendingRecommendedMeld: null,
  residentAvailabilitiesMeldId: null,
  altEventSelectedAgentsTime: null,
  priorRightPaneType: null,
  appointmentToReschedule: null,
  actions: {
    setResidentAvailabilityMeldId: (meldId) => set(() => ({ residentAvailabilitiesMeldId: meldId })),
    setPendingRecommendEvent: (event) => set({ pendingRecommendedMeld: event }),
    setSelectedVendorsAgents: (selected) => {
      setInitialSelectedVendorsOrAgents(selected);
      set((state) => {
        // if the recommended meld agent isn't among the selected agent we revert to the melds list page
        if (state.pendingRecommendedMeld) {
          const currentAgentId = state.pendingRecommendedMeld.personaId;
          if (!selected.agents.some((agentId) => agentId === currentAgentId)) {
            return { selectedVendorsAgentsIds: selected, activePane: { type: "meldsToSchedule" } };
          }
        }
        return { selectedVendorsAgentsIds: selected };
      });
    },
    addSelectedVendorsAgents: (agentsVendorsToAdd) => {
      set((state) => {
        const result = {
          agents: state.selectedVendorsAgentsIds.agents,
          vendors: state.selectedVendorsAgentsIds.vendors,
        };
        agentsVendorsToAdd.agents.forEach((agent) => {
          if (!result.agents.includes(agent)) {
            result.agents.push(agent);
          }
        });
        agentsVendorsToAdd.vendors.forEach((vendor) => {
          if (!result.vendors.includes(vendor)) {
            result.vendors.push(vendor);
          }
        });
        return { selectedVendorsAgentsIds: result };
      });
    },
    resetState: () =>
      set({
        pendingRecommendedMeld: null,
        pendingResidentAvailabilities: [],
        residentAvailabilitiesMeldId: null,
      }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addScheduledSegment: (segment: any) =>
      set((state) => ({
        scheduledSegments: [...state.scheduledSegments, segment],
      })),
    removeScheduledSegment: (id: number) =>
      set((state) => ({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        scheduledSegments: state.scheduledSegments.filter((segment: any) => segment.id !== id),
      })),
    // pending resident availability placeholders
    addPendingResidentAvailability: (pendingEventsToAdd) =>
      set((state) => {
        return {
          pendingResidentAvailabilities: [
            ...state.pendingResidentAvailabilities,
            ...pendingEventsToAdd.map(
              (event) =>
                ({
                  type: "pending_offered_availability",
                  start: event.start,
                  end: event.end,
                  coordinates: undefined,
                  startDate: new Date(event.start),
                  endDate: new Date(event.end),
                  start_moment: moment(event.start),
                  end_moment: moment(event.end),
                  description: "Offer this time to resident",
                  personaId: event.agentId,
                  personaType: "agent",
                  key: event.tempId + event.agentId,
                  tempId: event.tempId,
                } as const)
            ),
          ],
        };
      }),
    removePendingResidentAvailability: (tempId) =>
      set((state) => {
        return {
          pendingResidentAvailabilities: [...state.pendingResidentAvailabilities].filter(
            (e) => e.type !== "pending_offered_availability" || e.tempId !== tempId
          ),
        };
      }),
    upsertPendingResidentAvailability: ({ tempId, start, end, agents }) =>
      set((state) => {
        let updatedAnEvent = false;
        // we map the existing set because if a meld is assigned to multiple techs there can be multiple events
        // with the same tempId
        const updatedAvailabilities = state.pendingResidentAvailabilities.map((existingEvent) => {
          if (existingEvent.type !== "pending_offered_availability" || existingEvent.tempId !== tempId) {
            return existingEvent;
          }
          updatedAnEvent = true;
          const updatedEvent = { ...existingEvent };
          updatedEvent.end_moment = end.clone();
          updatedEvent.end = end.toISOString();
          updatedEvent.start_moment = start.clone();
          updatedEvent.start = start.toISOString();
          return updatedEvent;
        });
        // handle insertions
        if (!updatedAnEvent) {
          agents.forEach((agent) => {
            updatedAvailabilities.push({
              type: "pending_offered_availability",
              coordinates: undefined,
              start: start.toISOString(),
              startDate: start.toDate(),
              end: end.toISOString(),
              endDate: end.toDate(),
              start_moment: start,
              end_moment: end,
              description: "Offer this time to resident",
              personaId: agent.id,
              personaType: "agent",
              key: tempId + agent.id,
              tempId,
            });
          });
        }
        return { pendingResidentAvailabilities: updatedAvailabilities };
      }),
    clearPendingResidentAvailabilities: () => set({ pendingResidentAvailabilities: [] }),
    initializeAltEventSelectedAgentsTime: ({ agents, selectedTime, eventDescription }) => {
      set(() => ({ altEventSelectedAgentsTime: { selectedAgents: agents, selectedTime, eventDescription } }));
    },
    // update alt event data
    setAltEventSelectedAgentsTime: ({ newAgents, newSelectedTime, eventId, eventDescription }) =>
      set((state) => {
        const selectedAgents = newAgents || state.altEventSelectedAgentsTime?.selectedAgents || [];
        const selectedDate =
          (newSelectedTime && "date" in newSelectedTime
            ? newSelectedTime.date
            : state.altEventSelectedAgentsTime?.selectedTime.date) || new Date();
        const selectedStart =
          newSelectedTime && "start" in newSelectedTime
            ? newSelectedTime.start
            : state.altEventSelectedAgentsTime?.selectedTime.start;
        const selectedEnd =
          newSelectedTime && "end" in newSelectedTime
            ? newSelectedTime.end
            : state.altEventSelectedAgentsTime?.selectedTime.end;

        if (!newAgents && !newSelectedTime) {
          return { altEventSelectedAgentsTime: null };
        }
        return {
          altEventSelectedAgentsTime: {
            selectedTime: { date: selectedDate, start: selectedStart, end: selectedEnd },
            eventId: eventId || state.altEventSelectedAgentsTime?.eventId,
            eventDescription: eventDescription || state.altEventSelectedAgentsTime?.eventDescription,
            selectedAgents,
          },
        };
      }),
    updateOnRightPaneChange: (arg) => {
      set(() => {
        const newState: Partial<CalendarState> = {};
        if (arg.shouldClearPendingAltEvent) {
          newState.altEventSelectedAgentsTime = null;
        }
        if (arg.shouldClearPendingRecommendedMeldEvent) {
          newState.pendingRecommendedMeld = null;
        }
        return newState;
      });
    },
    setAppointmentToReschedule: (newAppt) => set({ appointmentToReschedule: newAppt }),
  },
}));

const useCalendarStatePendingRecommendedMeld = () => useCalendarStateStore((state) => state.pendingRecommendedMeld);
const useCalendarStateAltEventSelectedAgentsTime = () =>
  useCalendarStateStore((state) => state.altEventSelectedAgentsTime);
const useCalendarStatePendingOfferedAvailabilities = () =>
  useCalendarStateStore((state) => state.pendingResidentAvailabilities);
const useCalendarStateMeldResidentAvailabilities = () =>
  useCalendarStateStore((state) => state.residentAvailabilitiesMeldId);
const useCalendarStateSelectedVendorAgentIds = (isMobile: boolean): CalendarSelectedAgentsVendorsIds =>
  useCalendarStateStore((state) => {
    if (isMobile) {
      if (state.selectedVendorsAgentsIds.agents.length > 0) {
        return { agents: [state.selectedVendorsAgentsIds.agents[0]], vendors: [] };
      } else if (state.selectedVendorsAgentsIds.vendors.length > 0) {
        return { agents: [], vendors: [state.selectedVendorsAgentsIds.vendors[0]] };
      }
    }
    return state.selectedVendorsAgentsIds;
  });

// non-hook/non-reactive functions to access the state in event handlers
const getCalendarStateActions = () => useCalendarStateStore.getState().actions;

const useCalendarStateActions = () => useCalendarStateStore((state) => state.actions);
const useCalendarStateScheduledSegments = () => useCalendarStateStore((state) => state.scheduledSegments);
const useGetAppointmentToReschedule = () => useCalendarStateStore((state) => state.appointmentToReschedule);

// resets various calendar stores state non-reactively
// (such as when the page component unmounts)
const resetCalendarState = () => {
  useCalendarStateStore.getState().actions.resetState();
  useCalendarTimeFrameStateStore.getState().actions.resetState();
  useCalendarMapStateStore.getState().actions.resetState();
  useCalendarDragAndDropStateStore.getState().actions.resetState();
  useCalendarDraftModeStateStore.getState().actions.resetState();
};

export {
  getCalendarStateActions,
  useCalendarStatePendingOfferedAvailabilities,
  useCalendarStateMeldResidentAvailabilities,
  useCalendarStateAltEventSelectedAgentsTime,
  useCalendarStatePendingRecommendedMeld,
  useCalendarStateActions,
  useCalendarStateSelectedVendorAgentIds,
  useCalendarStateScheduledSegments,
  useGetAppointmentToReschedule,
  CalendarRecommendEvent,
  CalendarSelectedAgentsVendorsIds,
  AltEventSelectedAgentsTimes,
  CALENDAR_SELECTED_VENDOR_AGENTS_LOCAL_STORAGE_KEY,
  CalendarState,
  MapBoxLocation,
  resetCalendarState,
};
