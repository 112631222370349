var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { apiDelete, apiFetch, apiPatch, apiPost } from "@pm-frontend/shared/utils/apiFetch";
import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";
import { ApiUrls } from "@pm-frontend/shared/utils/api-urls";
import { meldKeys } from "../queries";
import { toastMessages, useAddToast } from "@pm-frontend/shared/store/toast";
import { getFilterPropertiesFromQueryParams } from "@pm-frontend/shared/utils/saved-filter-utils";
const MELD_LIST_PAGE_SIZE = 60;
const meldFilterGroupKeys = {
  all: ["meld-filter-groups"],
  editFilter: () => [...meldFilterGroupKeys.all, "edit"]
};
const useGetMeldFilterGroups = () => {
  return useQuery({
    queryKey: meldFilterGroupKeys.all,
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.meldFilterGroupList))
  });
};
const useGetMeldsList = ({ savedFilterId }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const limitParamValue = searchParams.get("limit");
  let limitValue = MELD_LIST_PAGE_SIZE;
  if (limitParamValue) {
    try {
      limitValue = parseInt(limitParamValue, 10);
    } catch (e) {
    }
  }
  const offsetParamValue = searchParams.get("offset");
  let offsetValue = 0;
  if (offsetParamValue) {
    try {
      offsetValue = parseInt(offsetParamValue, 10);
    } catch (e) {
    }
  }
  const pageIndex = Math.floor(offsetValue / limitValue);
  const paginationParams = `limit=${limitValue}&offset=${pageIndex * limitValue}`;
  const filterParams = savedFilterId ? `?saved_filter=${savedFilterId}` : location.search.toString();
  const formattedParams = (filterParams ? `${filterParams}&` : "?") + paginationParams;
  return __spreadProps(__spreadValues({}, useQuery({
    queryKey: meldKeys.list(formattedParams),
    queryFn: () => apiFetch(LinkHelper.normalize(`/api/melds/${formattedParams}`))
  })), {
    pageIndex,
    limitValue
  });
};
const useDeleteMeldFilter = ({ id }) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => apiDelete(LinkHelper.normalize(`${ApiUrls.meldFilterGroupList}${id}`)),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldListSavedFilterDeleteSuccess,
        color: "success"
      });
      return queryClient.invalidateQueries(meldFilterGroupKeys.all);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldListSavedFilterDeleteError,
        color: "danger"
      });
    }
  });
};
const useEditMeldFilter = ({ id }) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: meldFilterGroupKeys.editFilter(),
    mutationFn: (data) => apiPatch(LinkHelper.normalize(ApiUrls.meldFilterGroupDetails(id)), data),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldListSavedFilterEditSuccess,
        color: "success"
      });
      return queryClient.invalidateQueries(meldFilterGroupKeys.all);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldListSavedFilterEditError,
        color: "danger"
      });
    }
  });
};
const useUpdateMeldFilter = () => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();
  const location = useLocation();
  const paramData = getFilterPropertiesFromQueryParams(location);
  return useMutation({
    mutationFn: (id) => apiPatch(LinkHelper.normalize(ApiUrls.meldFilterGroupFilters(id)), paramData),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldListUpdateFilterSuccess,
        color: "success"
      });
      return Promise.all([
        queryClient.invalidateQueries(meldFilterGroupKeys.all),
        queryClient.invalidateQueries(meldKeys.all)
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldListUpdateFilterFailure,
        color: "danger"
      });
    }
  });
};
const useSaveNewMeldFilter = () => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();
  const location = useLocation();
  const paramData = getFilterPropertiesFromQueryParams(location);
  return useMutation({
    mutationFn: (data) => apiPost(LinkHelper.normalize(ApiUrls.meldFilterGroupList), __spreadValues(__spreadValues({}, data), paramData)),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldListSaveNewFilterSuccess,
        color: "success"
      });
      return queryClient.invalidateQueries(meldFilterGroupKeys.all);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldListSaveNewFilterFailure,
        color: "danger"
      });
    }
  });
};
const useReorderMeldFilter = () => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => apiPatch(LinkHelper.normalize(ApiUrls.meldFilterGroupReorder(data.id)), { position: data.position }),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldListSavedFilterReorderSuccess,
        color: "success"
      });
      return queryClient.removeQueries(meldFilterGroupKeys.all);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldListSavedFilterReorderError,
        color: "danger"
      });
    }
  });
};
const useExportMeldListCSV = () => {
  const addToast = useAddToast();
  const location = useLocation();
  return useMutation({
    mutationFn: () => apiFetch(`${LinkHelper.normalize(ApiUrls.createMeldExportCSV)}/${location.search.toString()}`),
    onSuccess: () => addToast({
      text: toastMessages.meldListExportCSVSuccess,
      color: "success"
    }),
    onError: () => addToast({
      text: toastMessages.meldListExportCSVFailure,
      color: "danger"
    })
  });
};
export {
  useGetMeldFilterGroups,
  useGetMeldsList,
  useDeleteMeldFilter,
  useEditMeldFilter,
  useSaveNewMeldFilter,
  useUpdateMeldFilter,
  useReorderMeldFilter,
  useExportMeldListCSV,
  meldFilterGroupKeys
};
