import React, { useState } from "react";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiTextColor,
  EuiContextMenuPanel,
  EuiContextMenuItem,
  EuiPopover,
  EuiBadge,
  EuiHorizontalRule,
  EuiImage,
  EuiTitle,
  EuiIcon,
  EuiButtonIcon,
  EuiLink,
  EuiSpacer,
} from "@elastic/eui";
import { CSSObject } from "@emotion/react";

import URL from "@pm-shared/utils/url";
import { getIdFromText } from "@pm-frontend/shared/utils/idFromText";
import { PmBadge, PmBadgeProps } from "@pm-frontend/shared/components/PmBadge";
import { BorderColor, colors } from "@pm-frontend/styles";
import { PmButtonProps, PmFilledButton } from "../Buttons/PmFilledButton";
import { PmEmptyButton } from "../Buttons/PmEmptyButton";
import { Link, LinkProps } from "react-router-dom";
import { PmIconBadge, PmIconBadgeProps } from "@pm-frontend/shared/components/PmIconBadge";

type LinkAction = {
  target?: "_blank" | "_self" | "_parent" | "_top";
  text: string;
  dataTestId?: string;
  isLoading?: boolean;
  // note that enabled === undefined causes the action to be shown
  enabled?: boolean;
  mobilePrimary?: boolean;
} & PmButtonProps;

type PrimaryAction = {
  text: string;
  dataTestId?: string;
  // note that enabled === undefined causes the action to be shown
  enabled?: boolean;
  mobilePrimary?: boolean;
} & PmButtonProps;

const SecondaryActionIcons = {
  GRAY_EXCLAMATION: "grayExclamation",
} as const;

export type SecondaryActionIcons = typeof SecondaryActionIcons[keyof typeof SecondaryActionIcons];

interface SecondaryAction {
  onClick?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  href?: string | { pathname: string; search?: string; state?: any };
  internalLink?: boolean;
  linkToProps?: LinkProps["to"];
  target?: "_blank" | "_self" | "_parent" | "_top";
  icon?: SecondaryActionIcons;
  text: string;
  dataTestId?: string;
  // note that enabled === undefined causes the action to be shown
  enabled?: boolean;
  state?: object;
  mobilePrimary?: boolean;
}

interface InactiveAction {
  onClick: () => void;
  text: string;
  dataTestId?: string;
}

interface PageHeaderProps {
  subtitle?: {
    text: string;
    href?: string;
  };
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any;
  linkActions?: LinkAction[];
  primaryAction?: PrimaryAction;
  secondaryActions?: SecondaryAction[];
  inactiveAction?: InactiveAction;
  prependBadgeRow?: string;
  badges?: PmBadgeProps[];
  iconBadges?: PmIconBadgeProps[];
  isInactive?: boolean;
  "data-testid"?: string | undefined;
  primaryButtonLoading?: boolean;
  extraContentRow?: React.ReactNode;
  bottomTray?: React.ReactNode;
  disableBottomSpace?: boolean;
  disableHorizontalRule?: boolean;
  titleFontSize?: string;
}

const SecondaryActionIconsSrc: Record<SecondaryActionIcons, string> = {
  grayExclamation: URL.getStatic("icons/gray_exclamation.svg"),
};

const cssPopoverPanel: CSSObject = {
  "&.secondaryActionsPanel": {
    padding: "0px !important",

    ".euiContextMenuPanel": {
      ".euiContextMenuPanelTitle": {
        padding: "16px",
      },
      ".euiContextMenuPanelItem": {
        padding: "10px 12px",
      },
    },
  },
};

const renderMenuItems = (closeMenu: () => void, items: SecondaryAction[] = []) => {
  return items.map((item: SecondaryAction) => {
    let linkPath: string = "";
    if (item.href) {
      linkPath = typeof item.href === "string" ? item.href : item.href.pathname;
    }
    const menuItem = (
      <EuiFlexGroup direction="row" gutterSize="s" style={{ padding: "10px 12px" }} wrap={false}>
        <EuiFlexItem grow={false} style={{ padding: "2px", minWidth: "20px" }}>
          <EuiImage
            src={item.icon ? SecondaryActionIconsSrc[item.icon] : SecondaryActionIconsSrc.grayExclamation}
            alt=""
            size="original"
          />
        </EuiFlexItem>
        <EuiFlexItem
          grow={false}
          style={{
            fontFamily: "Open Sans",
            fontSize: "16px",
            lineHeight: "16px",
          }}
        >
          <EuiTextColor color={colors.neutrals.gray800}>{item.text}</EuiTextColor>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
    if ((linkPath && item.internalLink) || item.linkToProps) {
      return (
        <EuiContextMenuItem
          key={item.text}
          onClick={() => {
            if (item && item.onClick && typeof item.onClick === "function") {
              item.onClick();
            }
            closeMenu();
          }}
          target="_blank"
          style={{
            padding: "0px",
            background: colors.neutrals.gray50,
            textDecoration: "none",
          }}
          data-testid={item && item.dataTestId ? item.dataTestId : undefined}
        >
          <Link to={item.linkToProps || linkPath}>{menuItem}</Link>
        </EuiContextMenuItem>
      );
    } else {
      return (
        <EuiContextMenuItem
          key={item.text}
          onClick={() => {
            if (item && item.onClick && typeof item.onClick === "function") {
              item.onClick();
            }
            closeMenu();
          }}
          href={!item.internalLink && item.href ? linkPath : undefined}
          target="_blank"
          style={{
            padding: "0px",
            background: colors.neutrals.gray50,
            textDecoration: "none",
          }}
          data-testid={item && item.dataTestId ? item.dataTestId : undefined}
        >
          {menuItem}
        </EuiContextMenuItem>
      );
    }
  });
};

const PageHeader = ({
  title,
  subtitle,
  icon,
  linkActions,
  secondaryActions,
  prependBadgeRow,
  badges,
  iconBadges,
  isInactive = false,
  inactiveAction,
  "data-testid": dataTestId,
  primaryButtonLoading,
  extraContentRow,
  bottomTray,
  disableBottomSpace = false,
  disableHorizontalRule = false,
  titleFontSize = "24px",
  ...props
}: PageHeaderProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const closeMenu = () => setIsMenuOpen(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const secondaryActionsTitle = (
    <EuiText color={colors.neutrals.gray800} style={{ fontSize: "14px", fontWeight: 700, lineHeight: "20px" }}>
      Actions
    </EuiText>
  );

  // if the 'enabled' prop is not set the item should be considered enabled
  const primaryActionEnabled =
    // eslint-disable-next-line no-prototype-builtins
    props.primaryAction && (!props.primaryAction.hasOwnProperty("enabled") || props.primaryAction.enabled);
  const filteredSecondaryActions = secondaryActions?.filter(
    // eslint-disable-next-line no-prototype-builtins
    (action) => !action.hasOwnProperty("enabled") || action.enabled
  );
  // eslint-disable-next-line no-prototype-builtins
  const filteredLinkActions = linkActions?.filter((action) => !action.hasOwnProperty("enabled") || action.enabled);

  const isSubtitleSimilar = (titleText: string, subtitleText?: string) => {
    // return boolean if title contains subtitle string
    return subtitleText && titleText?.toLowerCase().includes(subtitleText?.toLowerCase());
  };

  let formattedSubtitle: React.ReactNode;
  if (subtitle && !isSubtitleSimilar(title, subtitle.text)) {
    if (subtitle.href) {
      formattedSubtitle = (
        <span>
          <Link to={subtitle.href}>
            <EuiLink>{subtitle.text}</EuiLink>
          </Link>
        </span>
      );
    } else {
      formattedSubtitle = (
        <EuiText size="s" data-testid="header-subtitle">
          <EuiTextColor color={colors.neutrals.gray700}>{subtitle.text}</EuiTextColor>
        </EuiText>
      );
    }
  }

  return (
    <>
      <EuiFlexGroup alignItems="flexStart" justifyContent="spaceBetween" style={{ flexGrow: 0 }}>
        <EuiFlexGroup direction="column" gutterSize="l">
          {icon && (
            <EuiFlexItem grow={false} style={{ minHeight: "20px" }}>
              <EuiIcon type={icon} />
            </EuiFlexItem>
          )}
          <EuiFlexItem grow={false}>
            {/* Left side of top of header */}
            <EuiFlexGroup justifyContent="spaceBetween" direction="row">
              <EuiFlexItem grow={false}>
                <EuiFlexGroup direction="column" gutterSize="l">
                  <EuiFlexItem grow={false}>
                    <EuiFlexGroup direction="column" gutterSize="s">
                      {formattedSubtitle && <EuiFlexItem grow={false}>{formattedSubtitle}</EuiFlexItem>}
                      <EuiFlexItem grow={false}>
                        <EuiText>
                          <EuiTextColor color={colors.neutrals.gray900}>
                            <EuiTitle>
                              <h2
                                style={{ fontSize: titleFontSize }}
                                {...(dataTestId ? { "data-testid": dataTestId } : {})}
                              >
                                {title}
                              </h2>
                            </EuiTitle>
                          </EuiTextColor>
                        </EuiText>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                  {isInactive && (
                    <EuiFlexItem grow={false}>
                      <EuiFlexGroup gutterSize="s">
                        <EuiBadge color={colors.neutrals.gray700} data-testid="detail-header-inactive-badge">
                          <EuiTextColor color={colors.brand.white}>Inactive</EuiTextColor>
                        </EuiBadge>
                      </EuiFlexGroup>
                    </EuiFlexItem>
                  )}
                </EuiFlexGroup>
              </EuiFlexItem>

              {/* Right side of top of header */}
              {(linkActions || props.primaryAction || secondaryActions || (isInactive && inactiveAction)) && (
                <EuiFlexItem grow={false}>
                  <EuiFlexGroup direction="row" alignItems="flexStart" gutterSize="s">
                    {filteredLinkActions &&
                      filteredLinkActions.length > 0 &&
                      filteredLinkActions.map((linkAction) => {
                        return (
                          <EuiFlexItem key={linkAction.text}>
                            <PmEmptyButton
                              textSize="14px"
                              padding="5.5px 9.5px"
                              {...linkAction}
                              data-testid={linkAction.dataTestId}
                            />
                          </EuiFlexItem>
                        );
                      })}
                    {props.primaryAction && primaryActionEnabled && (
                      <EuiFlexItem>
                        <PmFilledButton
                          {...props.primaryAction}
                          data-testid={props.primaryAction.dataTestId}
                          fontSize="14px"
                          padding="5.5px 12px"
                          isLoading={primaryButtonLoading || false}
                        />
                      </EuiFlexItem>
                    )}
                    {isInactive && inactiveAction && (
                      <EuiFlexItem>
                        <PmEmptyButton
                          textSize="14px"
                          padding="5.5px 9.5px"
                          hasBorder={true}
                          onClick={inactiveAction.onClick}
                          text={inactiveAction.text}
                          data-testid={inactiveAction.dataTestId}
                        />
                      </EuiFlexItem>
                    )}
                    {filteredSecondaryActions && filteredSecondaryActions.length > 0 && (
                      <EuiFlexItem style={{ width: "20px", height: "32px" }}>
                        <EuiPopover
                          data-testid="page-header-secondary-actions"
                          id={getIdFromText(title)}
                          button={
                            <EuiButtonIcon
                              size="s"
                              iconType={URL.getStatic("icons/horizontal-dots.svg")}
                              iconSize="original"
                              onClick={toggleMenu}
                              aria-label="Open secondary action menu"
                              data-testid="secondary-actions-open"
                              style={{ height: "32px", width: "auto" }}
                            />
                          }
                          isOpen={isMenuOpen}
                          closePopover={closeMenu}
                          panelPaddingSize="s"
                          anchorPosition="downRight"
                          panelProps={{ className: "secondaryActionsPanel", css: cssPopoverPanel }}
                          repositionOnScroll={true}
                        >
                          <EuiContextMenuPanel
                            title={secondaryActionsTitle}
                            size="s"
                            items={renderMenuItems(closeMenu, filteredSecondaryActions)}
                          />
                        </EuiPopover>
                      </EuiFlexItem>
                    )}
                  </EuiFlexGroup>
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </EuiFlexItem>
          {(prependBadgeRow || badges || iconBadges || extraContentRow) && (
            <EuiFlexItem grow={false}>
              <EuiFlexGroup gutterSize="s" direction="column">
                {(prependBadgeRow || badges || iconBadges) && (
                  <EuiFlexItem grow={false}>
                    <EuiFlexGroup direction="row" responsive={false} style={{ gap: "16px" }}>
                      {prependBadgeRow && (
                        <EuiFlexItem grow={false} data-testid="meld-reference-id">
                          <EuiText
                            color={colors.neutrals.gray800}
                            style={{ fontSize: "14px", fontWeight: "600" }}
                            grow={false}
                          >
                            {prependBadgeRow}
                          </EuiText>
                        </EuiFlexItem>
                      )}
                      {(badges || iconBadges) && (
                        <EuiFlexItem grow={false}>
                          <EuiFlexGroup gutterSize="s" alignItems="center">
                            {badges?.map((badge) => (
                              <EuiFlexItem key={badge.text} grow={false}>
                                <PmBadge {...badge} />
                              </EuiFlexItem>
                            ))}
                            {iconBadges?.map((iconBadge) => (
                              <EuiFlexItem key={iconBadge.badgeText} grow={false}>
                                <PmIconBadge {...iconBadge} />
                              </EuiFlexItem>
                            ))}
                          </EuiFlexGroup>
                        </EuiFlexItem>
                      )}
                    </EuiFlexGroup>
                  </EuiFlexItem>
                )}
                {extraContentRow && (
                  <EuiFlexGroup gutterSize="s" alignItems="center">
                    {extraContentRow}
                  </EuiFlexGroup>
                )}
              </EuiFlexGroup>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </EuiFlexGroup>
      {!disableHorizontalRule && !bottomTray ? (
        <EuiHorizontalRule margin="s" size="full" style={{ marginBottom: "0px", backgroundColor: BorderColor }} />
      ) : (
        <EuiFlexGroup direction="column" gutterSize="s">
          {bottomTray}
        </EuiFlexGroup>
      )}
      {!disableBottomSpace && <EuiSpacer size="xl" />}
    </>
  );
};

export { PageHeader, PageHeaderProps, PrimaryAction, SecondaryAction, LinkAction, SecondaryActionIconsSrc };
