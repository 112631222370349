/* eslint-disable */
// tslint:disable:no-console

import { AxiosError } from "axios";

import * as Sentry from "@sentry/browser";

import CancelToken from "../../assets/js/common/utils/cancel-token";
import { BrowserTracing } from "@sentry/tracing";

export class ErrorHandler {
  /**
   * Initializes ErrorHandler's error tracking tools.
   *
   * In this case it will initialize Sentry and configure the Sentry scope while also catching onunhandledrejection
   * errors and sending that to sentry as well.
   */
  static initializeHandler() {
    if (!this.dsn) {
      this.dsn = window.PM.sentryDSN;

      if (this.dsn) {
        Sentry.init({
          dsn: this.dsn,
          attachStacktrace: true,
          release: window.PM.env.release,
          integrations: [
            new BrowserTracing(),
            new Sentry.Replay({
              // Additional SDK configuration goes in here, for example:
              maskAllText: true,
              blockAllMedia: true,
            }),
          ],
          tracesSampleRate: 1.0,

          ignoreErrors: [
            "top.GLOBALS",
            // Seems to be some browser extension that is spamming things with random strings (eg. window._rm8182149357162416)
            /.*window._rm.*/,
            "ResizeObserver loop completed with undelivered notifications.",
            "ResizeObserver loop limit exceeded",
            "Request failed with status code 400",
          ],

          allowUrls: [
            window.PM.static_files_cdn,
            /https?:\/\/((app|staging)\.)?propertymeld\.com/,
            /https?:\/\/((propertymeld-.*)\.)?herokuapp\.com/,
          ],

          // This sets the sample rate to be 5%. You may want this to be 100% while
          // in development and sample at a lower rate in production
          replaysSessionSampleRate: 0.05,

          // If the entire session is not sampled, use the below sample rate to sample
          // sessions when an error occurs.
          replaysOnErrorSampleRate: 1.0,

          // beforeSend(event) {
          //   if (event.exception) {
          //     Sentry.showReportDialog({ eventId: event.event_id });
          //   }

          //   return event;
          // }
        });

        Sentry.configureScope((scope) => {
          const { user, sentryDSN, csrf_token, ...windowPM } = window.PM;

          scope.setUser({ ...user });
          scope.setExtras(windowPM);
        });
      }
    }
  }

  /**
   * Used to handle any non-axios errors. Will send the error to Sentry and to the console.
   * If Sentry is not initlized, or there is no Sentry DSN (as in local development) the error will simply be printed to the console.
   *
   * @param error The error you are reporting to sentry
   * @param extras Any extra information you want to send along with this error
   */
  static handleError(error: Error, extras?: { extra: { [key: string]: any } }) {
    if (this.dsn) {
      Sentry.withScope((scope) => {
        if (extras) {
          scope.setExtras(extras);
        }
        scope.setLevel("error");

        if (!CancelToken.isCancel(error)) {
          Sentry.captureException(error);
        }
      });
    }

    console.error(error, extras);
  }

  /**
   * Logs Axios errors depending on the type of error they are.
   *
   * @param error The error
   */
  static handleAxiosError(error: AxiosError) {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log(error.message);

      ErrorHandler.handleError(error);
    }

    console.log(error.config);
  }

  private static dsn: string;
}
