import React, { useState, useEffect } from "react";
import { Box, Checkbox, VStack, Text, Button } from "@chakra-ui/react";

const LANGUAGE_CHOICES = [
  { code: "EN", label: "English" },
  { code: "ES", label: "Spanish" },
  { code: "ZH", label: "Chinese" },
  { code: "KO", label: "Korean" },
  { code: "PT", label: "Portuguese" },
  { code: "FR", label: "French" },
  { code: "RU", label: "Russian" },
  { code: "JA", label: "Japanese" },
];

interface LanguageSelectorProps {
  tenant: boolean;
  updateLanguageSettings: Function;
  userDetails: any;
}

export const LanguageSelector = ({ userDetails, updateLanguageSettings, tenant }: LanguageSelectorProps) => {
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  useEffect(() => {
    if (userDetails && userDetails.default_language) {
      setSelectedLanguage(userDetails.default_language);
    }
  }, [userDetails]);

  const handleCheckboxChange = (languageCode: string) => {
    setIsChanged(true);
    setSelectedLanguage(languageCode);
  };

  const handleSave = () => {
    if (!userDetails) return;

    if (tenant) {
      updateLanguageSettings(selectedLanguage).then(() => {
        setIsChanged(false);
      });
    } else {
      const updatedAgentDetails = { ...userDetails };
      updatedAgentDetails.default_language = selectedLanguage!;
      updatedAgentDetails.prompt_for_mobile = false;
      updateLanguageSettings(updatedAgentDetails).then(() => {
        setIsChanged(false);
      });
    }
  };

  return (
    <VStack spacing={2} alignItems="left" data-test="language-selector">
      <Text fontWeight="bold" data-test="language-label">
        Preferred Language
      </Text>
      {LANGUAGE_CHOICES.map((language) => (
        <Box key={language.code}>
          <Checkbox
            isChecked={selectedLanguage === language.code}
            onChange={() => handleCheckboxChange(language.code)}
            data-test={`${language.label.toLowerCase()}-checkbox`}
          >
            {language.label}
          </Checkbox>
        </Box>
      ))}
      {isChanged && userDetails && (
        <Box alignSelf="flex-end">
          <Button cursor="pointer" variant="primary" onClick={handleSave} data-test="save-button">
            Save
          </Button>
        </Box>
      )}
      {userDetails && userDetails.prompt_for_mobile && (
        <div style={{ fontWeight: "bold" }}>
          Please make sure to add a mobile number in User settings to be able to use language features.
        </div>
      )}
    </VStack>
  );
};
