import React from "react";
import { EuiHeaderSectionItem, EuiIcon } from "@elastic/eui";

import URL from "@pm-shared/utils/url";

interface HeaderHelpItemProps {
  url?: string;
}

const HeaderHelpItem = ({ url }: HeaderHelpItemProps) => {
  return (
    <EuiHeaderSectionItem aria-label="Help Center" key="help-center">
      <a href={url ? url : "https://help.propertymeld.com/hc/en-us"} target="_blank" rel="noreferrer">
        <EuiIcon title="Help" type={URL.getStatic("icons/help_outline.svg")} size="l" />
      </a>
    </EuiHeaderSectionItem>
  );
};

export { HeaderHelpItem };
