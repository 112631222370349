var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
{
}
import I from "immutable";
import _ from "lodash";
import moment from "moment";
import { asImmer } from "../../../../app/utils/decorators";
import { ErrorHandler } from "../../../../app/utils/ErrorHandler";
import { hasPerm } from "../../common/utils/permission-utils";
import PhoneUtils from "../../common/utils/phone-utils";
import * as C from "../../constants";
import AgentUtils from "../../management/utils/agent-utils";
import UnitUtils from "../../property/utils/unit-utils";
import CalendarUtils from "../../utils/calendar-utils";
import { LinkHelper } from "@pm-shared/utils/link";
import VendorUtils from "../../vendor/utils/vendor-utils";
import VendorMeldUtils from "../../vendor_app/meld/utils/vendor-meld-utils";
import AssignmentRequestUtils from "./assignment-request-utils";
import SettingsStore from "../../management/stores/settings-store";
import PropertyUtils from "@pm-assets/js/property/utils/property-utils";
import AddressUtils from "@pm-assets/js/utils/address-utils";
import Features from "@pm-assets/js/common/feature-flags";
const _MeldUtils = class {
  static isImmutableMap(obj) {
    if (obj === void 0) {
      return false;
    }
    return obj.asImmutable !== void 0;
  }
  static immutableMapOrObject(obj) {
    let isI = true;
    if (!this.isImmutableMap(obj)) {
      obj = I.fromJS(obj);
      isI = false;
    }
    return [obj, isI];
  }
  static getMeldID(meld) {
    meld = meld;
    return meld.id;
  }
  static getAllManagementAppointmentStartDates(meld) {
    var _a;
    const events = (_a = meld.managementappointment) == null ? void 0 : _a.map((appointment) => {
      var _a2, _b;
      return (_b = (_a2 = appointment == null ? void 0 : appointment.availability_segment) == null ? void 0 : _a2.event) == null ? void 0 : _b.dtstart;
    }).filter((dtstart) => dtstart !== void 0);
    return events;
  }
  static getAllVendorAppointmentStartDates(meld) {
    var _a;
    const events = (_a = meld.vendorappointment) == null ? void 0 : _a.map((appointment) => {
      var _a2, _b;
      return (_b = (_a2 = appointment == null ? void 0 : appointment.availability_segment) == null ? void 0 : _a2.event) == null ? void 0 : _b.dtstart;
    }).filter((dtstart) => dtstart !== void 0);
    return events;
  }
  static getReminders(meld) {
    let reminders;
    [meld] = this.immutableMapOrObject(meld);
    reminders = meld.get("reminders");
    return reminders;
  }
  static getMeldProject(meld) {
    meld = meld;
    return meld.project;
  }
  static getMeldProjectName(meld) {
    var _a;
    meld = meld;
    return (_a = meld.project) == null ? void 0 : _a.name;
  }
  static getMeldProjectID(meld) {
    var _a;
    meld = meld;
    return (_a = meld.project) == null ? void 0 : _a.id;
  }
  static getMeldDueDate(meld) {
    meld = meld;
    return meld.due_date;
  }
  static getUnit(meld) {
    meld = meld;
    return meld.unit;
  }
  static getMeldReferenceID(meld) {
    meld = meld;
    return meld.reference_id;
  }
  static getNotifyOwner(meld) {
    let notifyOwner;
    [meld] = this.immutableMapOrObject(meld);
    notifyOwner = meld.get("notify_owner");
    return notifyOwner;
  }
  static getTenants(meld) {
    let tenants;
    [meld] = this.immutableMapOrObject(meld);
    tenants = meld.get("tenants");
    return tenants;
  }
  static getTenantPresenceRequired(meld) {
    meld = meld;
    return meld.tenant_presence_required;
  }
  static getMeldStatus(meld) {
    meld = meld;
    return meld.status;
  }
  static getMeldMaintenanceNotes(meld) {
    meld = meld;
    return meld.maintenance_notes;
  }
  static getMeldCompletionNotes(meld) {
    meld = meld;
    return meld.completion_notes;
  }
  static getMeldInvoice(meld) {
    let invoice;
    let isI;
    [meld, isI] = this.immutableMapOrObject(meld);
    invoice = meld.get("meld_invoice");
    return isI ? invoice : invoice == null ? void 0 : invoice.toJS();
  }
  static getMeldCreated(meld) {
    meld = meld;
    return meld.created;
  }
  static getOwnerApprovedBy(meld) {
    meld = meld;
    return meld.owner_approved_by;
  }
  static getMeldManagerCancelled(meld) {
    meld = meld;
    return meld.manager_cancelled;
  }
  static getMeldManagerCancellationReason(meld) {
    meld = meld;
    return meld.manager_cancellation_reason;
  }
  static getMeldTenantRequest(meld) {
    meld = meld;
    return meld.tenant_request;
  }
  static getMeldManagerCanceller(meld) {
    meld = meld;
    return meld.manager_canceller;
  }
  static getMeldCompletionDate(meld) {
    meld = meld;
    return meld.completion_date;
  }
  static getMeldWorkEntry(meld) {
    let workEntry;
    [meld] = this.immutableMapOrObject(meld);
    workEntry = meld.get("open_work_entry");
    return workEntry;
  }
  static getWorkEntryCheckIn(workEntry) {
    workEntry = workEntry;
    return workEntry.checkin;
  }
  static getMeldOwners(meld) {
    let owners;
    [meld] = this.immutableMapOrObject(meld);
    owners = meld.get("owners");
    return owners;
  }
  static getNameOfCloserForMeld(meld) {
    meld = meld;
    let closerString;
    const managementAgentCloser = meld.management_agent_closer;
    const vendorCloser = meld.vendor_closer;
    if (managementAgentCloser) {
      closerString = `by ${AgentUtils.getFullName(managementAgentCloser)}`;
    } else if (vendorCloser) {
      closerString = `by ${vendorCloser.name}`;
    }
    return closerString;
  }
  static getMarkedCompleteString(meld) {
    meld = meld;
    if (meld.marked_complete) {
      return CalendarUtils.formatDayMonthTime(meld.marked_complete, { timeFmt: "h:mma" });
    }
  }
  static getMeldManagementRequester(meld) {
    meld = meld;
    return meld.management_requester;
  }
  static getFormattedAddress(meld) {
    return meld.getIn(["unit", "address", "line_1"]);
  }
  static getPriority(meld) {
    return meld.get("priority");
  }
  static getDescription(meld) {
    meld = meld;
    return meld.description;
  }
  static getBriefDescription(meld) {
    meld = meld;
    return meld.brief_description;
  }
  static getVendorAssignmentRequests(meld) {
    meld = meld;
    return meld.vendor_assignment_requests;
  }
  static getLastVendorRejection(meld) {
    let lastRejection;
    let isI;
    [meld, isI] = this.immutableMapOrObject(meld);
    const vendAssignReqsts = meld.get("vendor_assignment_requests");
    if (vendAssignReqsts && vendAssignReqsts.size) {
      lastRejection = vendAssignReqsts.filter((req) => req.get("rejected") && req.get("reject_reason")).last();
    }
    return isI ? lastRejection : lastRejection == null ? void 0 : lastRejection.toJS();
  }
  static getFormattedStatus(meld) {
    meld = meld;
    const status = meld.status.toString();
    switch (status) {
      case C.MeldStatuses.COMPLETED:
        return "Completed";
      case C.MeldStatuses.MANAGER_CANCELED:
        return "Manager canceled";
      case C.MeldStatuses.PENDING_COMPLETION:
        return "In progress";
      case C.MeldStatuses.TENANT_CANCELED:
        return "Tenant canceled";
      case C.MeldStatuses.PENDING_TENANT_AVAILABILITY:
        return "Pending tenant availability";
      case C.MeldStatuses.OPEN:
        return "Open";
      case C.MeldStatuses.VENDOR_COULD_NOT_COMPLETE:
        return "Vendor could not complete";
      case C.MeldStatuses.MAINTENANCE_COULD_NOT_COMPLETE:
        return "Maintenance could not complete";
      case C.MeldStatuses.PENDING_MORE_VENDOR_AVAILABILITY:
        return "More availability requested";
      case C.MeldStatuses.PENDING_MORE_MANAGEMENT_AVAILABILITY:
        return "More availability requested";
      case C.MeldStatuses.PENDING_ASSIGNMENT:
        return "Pending assignment";
      case C.MeldStatuses.PENDING_VENDOR:
        return "Pending vendor acceptance";
      case C.MeldStatuses.PENDING_ESTIMATES:
        return "Pending Estimates";
      default:
        ErrorHandler.handleError(new Error(`Unknown meld status ${status}`));
    }
  }
  static getFormattedAddressFields(meld) {
    if (!meld.get("unit")) {
      return "";
    }
    return UnitUtils.getDisplayAddressLines(meld.get("unit"));
  }
  static getFormattedPropertyMajorFields(meld) {
    if (!meld.get("unit")) {
      return "";
    }
    return UnitUtils.getDisplayCityStateZip(meld.get("unit"));
  }
  static getFormattedPropertyMinorFields(meld, emptyMsg) {
    if (!meld.get("unit")) {
      return "";
    }
    return UnitUtils.getFormattedBuildingFloorAndUnit(meld.get("unit"), emptyMsg);
  }
  static getApptEventStart(meld, index = 0) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    meld = meld;
    let event = (_d = (_c = (_b = (_a = meld.vendorappointment) == null ? void 0 : _a[index]) == null ? void 0 : _b.availability_segment) == null ? void 0 : _c.event) == null ? void 0 : _d.dtstart;
    if (!event) {
      event = (_h = (_g = (_f = (_e = meld.managementappointment) == null ? void 0 : _e[index]) == null ? void 0 : _f.availability_segment) == null ? void 0 : _g.event) == null ? void 0 : _h.dtstart;
    }
    return event;
  }
  static formatScheduledStartAndEnd(meld, emptyMsg = "Not scheduled", index = 0, previous = false, tenant_app = false, vendor_app = false) {
    const dtstart = _MeldUtils.getApptEventStart(meld, index);
    const dtend = _MeldUtils.getApptEventEnd(meld, index);
    if (!dtstart || !dtend) {
      return emptyMsg;
    }
    if (Features.isMultipleAppointmentsEnabled() && (tenant_app || vendor_app)) {
      const now = /* @__PURE__ */ new Date();
      if (!previous && new Date(dtstart) < now) {
        return;
      } else if (previous && new Date(dtstart) > now) {
        return;
      }
    }
    const start = CalendarUtils.formatDayMonthTime(dtstart, {
      separator: ", ",
      timeFmt: "h:mma"
    });
    const end = CalendarUtils.renderFormattedTime(dtend);
    return `${start} - ${end}`;
  }
  static formatScheduledStartAndEndMultiples(meld, emptyMsg = "Not scheduled") {
    const dtstart = _MeldUtils.getApptEventStart(meld);
    const dtend = _MeldUtils.getApptEventEnd(meld);
    if (!dtstart || !dtend) {
      return emptyMsg;
    }
    const start = CalendarUtils.formatDayMonthTime(_MeldUtils.getApptEventStart(meld), {
      separator: ", ",
      timeFmt: "h:mma"
    });
    const end = CalendarUtils.renderFormattedTime(_MeldUtils.getApptEventEnd(meld));
    return `${start} - ${end}`;
  }
  static getApptEventEnd(meld, index = 0) {
    const getFirstDtend = (appointments) => {
      if (appointments && appointments.size > 0) {
        return appointments.getIn([index, "availability_segment", "event", "dtend"]);
      }
      return null;
    };
    const vendorAppointments = meld.get("vendorappointment");
    if (vendorAppointments && vendorAppointments.size > 0) {
      const event = getFirstDtend(vendorAppointments);
      if (event) {
        return event;
      }
    }
    const managementAppointments = meld.get("managementappointment");
    if (managementAppointments && managementAppointments.size > 0) {
      const event = getFirstDtend(managementAppointments);
      if (event) {
        return event;
      }
    }
    return null;
  }
  static doesNeedAvailability(meld) {
    const status = meld.get("status");
    return status === C.MeldStatuses.PENDING_TENANT_AVAILABILITY || status === C.MeldStatuses.PENDING_MORE_VENDOR_AVAILABILITY;
  }
  static isInProgress(meld) {
    const status = meld.get("status");
    const inProgressStatuses = _MeldUtils.inProgressStatuses;
    return _.includes(inProgressStatuses, status);
  }
  static isClosed(meld) {
    const status = meld.get("status");
    const closedStatuses = _MeldUtils.closedStatuses;
    return _.includes(closedStatuses, status);
  }
  static canMgrEdit(meld) {
    const editableStatuses = /* @__PURE__ */ new Set([
      C.MeldStatuses.PENDING_COMPLETION,
      C.MeldStatuses.OPEN,
      C.MeldStatuses.PENDING_TENANT_AVAILABILITY,
      C.MeldStatuses.PENDING_MORE_VENDOR_AVAILABILITY,
      C.MeldStatuses.PENDING_MORE_MANAGEMENT_AVAILABILITY,
      C.MeldStatuses.PENDING_VENDOR,
      C.MeldStatuses.PENDING_ASSIGNMENT
    ]);
    return editableStatuses.has(meld.get("status"));
  }
  static canVendorScheduleAvailability(meld) {
    const status = meld.get("status");
    return status === C.MeldStatuses.PENDING_TENANT_AVAILABILITY || status === C.MeldStatuses.OPEN || status === C.MeldStatuses.PENDING_COMPLETION || status === C.MeldStatuses.PENDING_MORE_VENDOR_AVAILABILITY;
  }
  static canTenantReview(meld) {
    return meld.get("status") === C.MeldStatuses.COMPLETED;
  }
  static canManagerMarkComplete(meld) {
    meld = meld;
    return meld.managementappointment && meld.status.toString() === C.MeldStatuses.PENDING_COMPLETION;
  }
  static canManagerBypassAndMarkComplete(meld) {
    return _.includes(_MeldUtils.nonClosedStatuses, meld.get("status"));
  }
  static canEditAvailabilities(meld) {
    return meld.get("tenant_presence_required") && meld.get("has_registered_tenant") && _MeldUtils.isInProgress(meld);
  }
  static canMgrEditAvailabilities(meld) {
    return meld.get("managementappointment") && _MeldUtils.canEditAvailabilities(meld);
  }
  static canVendorEditAvailabilities(meld) {
    return meld.get("vendorappointment") && _MeldUtils.getOpenVendorAssignment(meld) && _MeldUtils.canEditAvailabilities(meld);
  }
  static canEditAppointment(meld) {
    return !meld.get("tenant_presence_required") && _MeldUtils.isInProgress(meld);
  }
  static canMgrEditAppointment(meld) {
    return meld.get("managementappointment") && _MeldUtils.canEditAppointment(meld);
  }
  static canVendorEditAppointment(meld) {
    return meld.get("vendorappointment") && _MeldUtils.getOpenVendorAssignment(meld) && _MeldUtils.canEditAppointment(meld);
  }
  static canManagerCancel(meld) {
    return _.includes(_MeldUtils.nonClosedStatuses, meld.get("status"));
  }
  static canVendorInvoice(meld) {
    return _.includes([C.MeldStatuses.COMPLETED, C.MeldStatuses.VENDOR_COULD_NOT_COMPLETE], meld.get("status"));
  }
  static canVendorMarkComplete(meld) {
    return _.includes(
      [
        C.MeldStatuses.PENDING_COMPLETION,
        C.MeldStatuses.PENDING_MORE_VENDOR_AVAILABILITY,
        C.MeldStatuses.PENDING_TENANT_AVAILABILITY
      ],
      meld.get("status")
    );
  }
  static canVendorBypassAndMarkComplete(meld) {
    return _.includes(
      [C.MeldStatuses.PENDING_MORE_VENDOR_AVAILABILITY, C.MeldStatuses.PENDING_TENANT_AVAILABILITY],
      meld.get("status")
    );
  }
  static canAssignmentChange(meld) {
    return _.includes(_MeldUtils.nonClosedStatuses, meld.get("status"));
  }
  static canReassign(meld) {
    return hasPerm(C.Perms.CAN_CHANGE_MELDS) && _MeldUtils.canAssignmentChange(meld) && _MeldUtils.getAssignedMaint(meld).size;
  }
  static canEdit(meld) {
    return _MeldUtils.isInProgress(meld.get("meld"));
  }
  static canAssign(meld) {
    meld = meld;
    const status = meld.status.toString();
    return hasPerm(C.Perms.CAN_CHANGE_MELDS) && status === C.MeldStatuses.PENDING_ASSIGNMENT;
  }
  static canAddAvailabilities(meld) {
    return meld.get("tenant_presence_required") && meld.get("has_registered_tenant") && _MeldUtils.isInProgress(meld) && !_MeldUtils.isScheduledNotProjects(meld) && !_MeldUtils.getAssignedVendor(meld);
  }
  static isSchedulable(meld) {
    meld = meld;
    return _MeldUtils.schedulableStatuses.has(meld.status.toString());
  }
  static isCompleteForTenant(meld) {
    const status = meld.get("status");
    const completeStatuses = _MeldUtils.tenantCompleteStatuses;
    return _.includes(completeStatuses, status);
  }
  static getStatus(meld) {
    let status;
    [meld] = this.immutableMapOrObject(meld);
    status = meld.get("status");
    return status;
  }
  static getCompletionNotes(meld) {
    let completionNotes;
    [meld] = this.immutableMapOrObject(meld);
    completionNotes = meld.get("completion_notes");
    return completionNotes;
  }
  static getReasonCannotComplete(meld) {
    let reasonCannotComplete;
    [meld] = this.immutableMapOrObject(meld);
    reasonCannotComplete = meld.get("reason_cannot_complete");
    return reasonCannotComplete;
  }
  static getTenantRating(meld) {
    meld = meld;
    return meld.tenant_rating;
  }
  static getTenantReview(meld) {
    meld = meld;
    return meld.tenant_review;
  }
  static getTenantReviewDate(meld) {
    meld = meld;
    return meld.tenant_review_date;
  }
  static isScheduled(meld) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    meld = meld;
    const managementAppointmentEdge = (_d = (_c = (_b = (_a = meld.managementappointment) == null ? void 0 : _a.edges) == null ? void 0 : _b[0]) == null ? void 0 : _c.node) == null ? void 0 : _d.availability_segment;
    const vendorAppointmentEdge = (_h = (_g = (_f = (_e = meld.vendorappointment) == null ? void 0 : _e.edges) == null ? void 0 : _f[0]) == null ? void 0 : _g.node) == null ? void 0 : _h.availability_segment;
    return managementAppointmentEdge || vendorAppointmentEdge;
  }
  static isScheduledNotProjects(meld) {
    var _a, _b, _c, _d, _e, _f;
    meld = meld;
    return ((_a = meld.managementappointment) == null ? void 0 : _a[0]) && ((_c = (_b = meld.managementappointment) == null ? void 0 : _b[0]) == null ? void 0 : _c.availability_segment) || ((_d = meld.vendorappointment) == null ? void 0 : _d[0]) && ((_f = (_e = meld.vendorappointment) == null ? void 0 : _e[0]) == null ? void 0 : _f.availability_segment);
  }
  static getScheduledDate(meld) {
    var _a, _b, _c, _d;
    meld = meld;
    if (((_b = (_a = meld.managementappointment) == null ? void 0 : _a.edges) == null ? void 0 : _b[0]) && meld.managementappointment.edges[0].node.availability_segment) {
      return meld.managementappointment.edges[0].node.availability_segment.event.dtstart;
    } else if (((_d = (_c = meld.vendorappointment) == null ? void 0 : _c.edges) == null ? void 0 : _d[0]) && meld.vendorappointment.edges[0].node.availability_segment) {
      return meld.vendorappointment.edges[0].node.availability_segment.event.dtstart;
    }
  }
  static isInactive(meld) {
    meld = meld;
    return new Set(_MeldUtils.inactiveStatuses).has(meld.status.toString());
  }
  static canMgrSelectAppointment(meld) {
    return meld.get("can_mgr_choose_appt") && _MeldUtils.isInProgress(meld);
  }
  static canAcknowledgeApproval(meld) {
    [meld] = this.immutableMapOrObject(meld);
    return !meld.get("owner_approval_acknowledged") && [C.OwnerApprovalStatuses.OWNER_APPROVAL_APPROVED, C.OwnerApprovalStatuses.OWNER_APPROVAL_NOT_APPROVED].includes(
      meld.get("owner_approval_status")
    );
  }
  static isInHouse(meld) {
    meld = meld;
    return meld.in_house_servicers && meld.in_house_servicers.length > 0;
  }
  static getAbbreviatedPropertyListLine1(meld) {
    const unit = _MeldUtils.getUnit(meld);
    if (unit) {
      return UnitUtils.getDisplayStreetAndUnit(unit);
    } else {
      return AddressUtils.getAddressLine1(_MeldUtils.getMeldProperty(meld));
    }
  }
  static getAbbreviatedPropertyListLine2(meld) {
    const unit = _MeldUtils.getUnit(meld);
    if (unit) {
      return UnitUtils.getDisplayCityStateZip(unit);
    } else {
      return AddressUtils.getFormattedCityStateZip(_MeldUtils.getMeldProperty(meld));
    }
  }
  static getOpenVendorAssignment(meld) {
    return meld.get("vendor_assignment_requests").find((assignment) => {
      return AssignmentRequestUtils.isAssigned(assignment);
    });
  }
  static getOpenVendorInviteAssignment(meld) {
    return meld.get("invite_assignments").find((assignment) => {
      return !assignment.get("canceled");
    });
  }
  static getAssignedVendor(meld) {
    let vendor;
    let isI;
    [meld, isI] = this.immutableMapOrObject(meld);
    const vendAssignReqsts = meld.get("vendor_assignment_requests");
    if (vendAssignReqsts && vendAssignReqsts.size) {
      const openAssignment = _MeldUtils.getOpenVendorAssignment(meld);
      if (openAssignment) {
        vendor = openAssignment.get("vendor");
      }
    }
    return isI ? vendor : vendor == null ? void 0 : vendor.toJS();
  }
  static getAssignedInvite(meld) {
    const inviteAssignments = meld.get("invite_assignments");
    if (inviteAssignments && inviteAssignments.size) {
      const openAssignment = _MeldUtils.getOpenVendorInviteAssignment(meld);
      if (openAssignment) {
        return openAssignment;
      }
    }
  }
  static getAssignedMaintenanceName(meld, emptyMsg = "No assigned maintenance") {
    [meld] = _MeldUtils.immutableMapOrObject(meld);
    const maint = _MeldUtils.getAssignedMaint(meld);
    if (maint.size) {
      return maint.map((m) => {
        switch (m.get("type")) {
          case C.MaintTypes.MANAGEMENT_AGENT:
            return AgentUtils.getFullName(m);
          case C.MaintTypes.VENDOR:
            const name = m.get("name");
            const email = m.get("email");
            if (name) {
              return name;
            }
            if (email) {
              return email;
            }
            break;
          case C.MaintTypes.INVITED_VENDOR:
            return m.getIn(["invite_group", "invite", "email"]);
        }
      }).join(", ");
    }
    return emptyMsg;
  }
  static getAssignedMaint(meld) {
    const servicers = _MeldUtils.getAssignedAgents(meld);
    const vendor = _MeldUtils.getAssignedVendor(meld);
    const inviteAssignment = _MeldUtils.getAssignedInvite(meld);
    if (servicers.size) {
      return servicers.map((servicer) => servicer.set("type", C.MaintTypes.MANAGEMENT_AGENT));
    } else if (vendor) {
      return I.List([vendor.set("type", C.MaintTypes.VENDOR)]);
    } else if (inviteAssignment) {
      return I.List([inviteAssignment.set("type", C.MaintTypes.INVITED_VENDOR)]);
    }
    return I.List();
  }
  static getAssignedMaintUrl(meld) {
    let assigned;
    [meld] = this.immutableMapOrObject(meld);
    assigned = _MeldUtils.getAssignedMaint(meld).first();
    if (assigned && assigned.get("type") === C.MaintTypes.VENDOR && assigned.get("managements").includes(Number.parseInt(LinkHelper.getOrgId(), 10))) {
      return `/vendors/${assigned.get("id")}/summary/`;
    }
  }
  static getWorkCategory(meld) {
    meld = meld;
    return _.capitalize(meld.work_category.toLowerCase()).replace(/_/g, " ");
  }
  static getWorkLocation(meld) {
    meld = meld;
    return _.capitalize(meld.work_location.toLowerCase());
  }
  static getMaintenanceCompanyPhone(meld) {
    if (_MeldUtils.isInHouse(meld)) {
      return meld.getIn(["management", "contact", "home_phone"]);
    } else {
      return VendorUtils.getPhone(_MeldUtils.getAssignedVendor(meld));
    }
  }
  static getCancellationNotes(meld, emptyMsg = "No cancellation notes") {
    return meld.get("manager_cancellation_reason") || emptyMsg;
  }
  static getAssignerName(meld, emptyMsg) {
    return AgentUtils.getFullName(meld.get("assigner"), emptyMsg);
  }
  static getAssignerBusinessFormattedPhone(meld, emptyMsg = "No Phone") {
    return _MeldUtils._getFormattedPhone(meld, AgentUtils.getBusinessPhone, emptyMsg);
  }
  static getAssignerCellFormattedPhone(meld, emptyMsg) {
    return _MeldUtils._getFormattedPhone(meld, AgentUtils.getCellPhone, emptyMsg);
  }
  static getAssignerHomeFormattedPhone(meld, emptyMsg) {
    return _MeldUtils._getFormattedPhone(meld, AgentUtils.getHomePhone, emptyMsg);
  }
  static getCoordinatorName(meld, emptyMsg) {
    return AgentUtils.getFullName(meld.get("coordinator"), emptyMsg);
  }
  static getCoordinatorBusinessFormattedPhone(meld, emptyMsg = "No Phone") {
    return _MeldUtils._getFormattedPhone(meld, AgentUtils.getBusinessPhone, emptyMsg, "coordinator");
  }
  static getCoordinatorCellFormattedPhone(meld, emptyMsg) {
    return _MeldUtils._getFormattedPhone(meld, AgentUtils.getCellPhone, emptyMsg, "coordinator");
  }
  static getCoordinatorHomeFormattedPhone(meld, emptyMsg) {
    return _MeldUtils._getFormattedPhone(meld, AgentUtils.getHomePhone, emptyMsg, "coordinator");
  }
  static _getFormattedPhone(meld, phoneFinderFunc, emptyMsg = "No Phone", agent_type = "assigner") {
    const phone = phoneFinderFunc(meld.get(agent_type), void 0);
    if (phone) {
      return PhoneUtils.format(phone);
    } else {
      return emptyMsg;
    }
  }
  static getSchedulerLink(meld) {
    const url = `/calendar/melds/${meld.get("id")}/book/`;
    const openVendorAssignment = _MeldUtils.getOpenVendorAssignment(meld);
    if (openVendorAssignment) {
      return VendorMeldUtils.getSchedulerLink(meld, openVendorAssignment);
    } else {
      return url;
    }
  }
  static getTenantWhoRequested(meld) {
    return meld.getIn(["tenant_request", "tenant"]);
  }
  static isTenantPresenceRequired(meld) {
    return meld.get("tenant_presence_required");
  }
  static hasRegisteredTenant(meld) {
    return meld.get("has_registered_tenant") || !meld.get("started") && meld.get("tenants").some((tenant) => tenant.get("user"));
  }
  static getRegisteredTenants(meld) {
    let isI;
    [meld, isI] = this.immutableMapOrObject(meld);
    const tenants = meld.get("tenants").filter((tenant) => tenant.get("user"));
    return isI ? tenants : tenants.toJS();
  }
  static hasAppointment(meld) {
    return !!_MeldUtils.getAppointmentAvailability(meld);
  }
  static getAppointmentAvailability(meld) {
    return meld.getIn(["managementappointment", "availability_segment"]) || meld.getIn(["vendorappointment", "availability_segment"]);
  }
  static getPetInfo(meld, { unknownMessage = "Not listed", noPetsMessage = "No animals" } = {}) {
    meld = meld;
    let info = unknownMessage;
    const hasPets = meld.has_pets;
    if (hasPets === true) {
      info = meld.pets;
    } else if (hasPets === false) {
      info = noPetsMessage;
    }
    return info;
  }
  static getAssignedAgents(meld) {
    return (meld.get("in_house_servicers") || I.List()).map((servicer) => servicer.get("agent"));
  }
  static canTenantChangePresenceRequired(meld) {
    const presenceNeverRequired = SettingsStore.getCurrent().get("tenant_presence_required") === "NEVER";
    return meld.get("tenant_request") && !presenceNeverRequired && (/* @__PURE__ */ new Set([C.MeldStatuses.PENDING_ASSIGNMENT, C.MeldStatuses.PENDING_VENDOR])).has(meld.get("status"));
  }
  static getMeldProperty(meld) {
    return meld.prop ? meld.prop : meld.unit.prop;
  }
  static getDisplayProperty(meld) {
    return meld.prop ? meld.prop : meld.unit.display_address;
  }
  static getPropertyMaintenanceNotes(meld) {
    const meldProperty = _MeldUtils.getMeldProperty(meld);
    return PropertyUtils.getMaintenanceNotes(meldProperty);
  }
  static getDirectionsUrlString(meld) {
    const meldProperty = _MeldUtils.getDisplayProperty(meld);
    return AddressUtils.getDirectionsUrlString(meldProperty);
  }
  static getMeldUnitName(meld) {
    const unit = _MeldUtils.getUnit(meld);
    if (unit) {
      return `UNIT ${unit.unit}`;
    }
  }
  static getAddressLine1(meld) {
    return AddressUtils.getAddressLine1(_MeldUtils.getMeldProperty(meld));
  }
  static getDisplayAddressLine1(meld) {
    return AddressUtils.getAddressLine1(_MeldUtils.getDisplayProperty(meld));
  }
  static propertyName(meld) {
    return PropertyUtils.maybeGetName(_MeldUtils.getMeldProperty(meld));
  }
  static canChangeResidents(meld) {
    return Boolean(_MeldUtils.getUnit(meld));
  }
  static shouldPreventSchedulingDueToMultiAppt(iMeld) {
    var _a, _b, _c, _d, _e;
    if (!Features.isMultipleAppointmentsEnabled()) {
      return false;
    }
    const meld = iMeld.toJS();
    if (((_a = meld.managementappointment) == null ? void 0 : _a.length) > 1 || ((_b = meld.vendorappointment) == null ? void 0 : _b.length) > 1) {
      return "hasMultipleAppointments";
    }
    const vendorAssignmentRequest = _MeldUtils.getOpenVendorAssignment(iMeld);
    if (((_c = vendorAssignmentRequest == null ? void 0 : vendorAssignmentRequest.toJS()) == null ? void 0 : _c.required_appointments) > 1) {
      return "vendorSelectedMultipleRequired";
    }
    const managementAssignment = (_e = (_d = meld.managementappointment) == null ? void 0 : _d[0]) == null ? void 0 : _e.management_assignment;
    if ((managementAssignment == null ? void 0 : managementAssignment.required_appointments) && managementAssignment.required_appointments > 1) {
      return "managerOfferedAvailabilityMultipleRequired";
    }
    return false;
  }
};
let MeldUtils = _MeldUtils;
MeldUtils.inProgressStatuses = [
  C.MeldStatuses.PENDING_COMPLETION,
  C.MeldStatuses.OPEN,
  C.MeldStatuses.PENDING_TENANT_AVAILABILITY,
  C.MeldStatuses.PENDING_MORE_VENDOR_AVAILABILITY,
  C.MeldStatuses.PENDING_MORE_MANAGEMENT_AVAILABILITY,
  C.MeldStatuses.PENDING_VENDOR
].sort();
MeldUtils.nonClosedStatuses = _MeldUtils.inProgressStatuses.concat(C.MeldStatuses.PENDING_ASSIGNMENT).sort();
MeldUtils.inactiveStatuses = [
  C.MeldStatuses.MANAGER_CANCELED,
  C.MeldStatuses.TENANT_CANCELED,
  C.MeldStatuses.COMPLETED,
  C.MeldStatuses.VENDOR_COULD_NOT_COMPLETE,
  C.MeldStatuses.MAINTENANCE_COULD_NOT_COMPLETE
].sort();
MeldUtils.tenantCompleteStatuses = [
  C.MeldStatuses.COMPLETED,
  C.MeldStatuses.MANAGER_CANCELED,
  C.MeldStatuses.TENANT_CANCELED,
  C.MeldStatuses.VENDOR_COULD_NOT_COMPLETE,
  C.MeldStatuses.MAINTENANCE_COULD_NOT_COMPLETE
].sort();
MeldUtils.closedStatuses = [C.MeldStatuses.MANAGER_CANCELED, C.MeldStatuses.TENANT_CANCELED].sort();
MeldUtils.schedulableStatuses = /* @__PURE__ */ new Set([
  C.MeldStatuses.PENDING_COMPLETION,
  C.MeldStatuses.OPEN,
  C.MeldStatuses.PENDING_TENANT_AVAILABILITY,
  C.MeldStatuses.PENDING_MORE_VENDOR_AVAILABILITY,
  C.MeldStatuses.PENDING_MORE_MANAGEMENT_AVAILABILITY
]);
MeldUtils.requestMoreAvailabilityStatuses = [
  C.MeldStatuses.OPEN,
  C.MeldStatuses.PENDING_TENANT_AVAILABILITY,
  C.MeldStatuses.PENDING_COMPLETION
].sort();
MeldUtils.defaultQuery = { saved_filter: "default" };
MeldUtils.overdueFilter = {
  scheduled__lte: moment().startOf("hour").toDate().getTime(),
  status: _MeldUtils.nonClosedStatuses,
  ordering: C.DEFAULT_MELD_SORT
};
MeldUtils.defaultVendorMeldQuery = {
  status: _MeldUtils.inProgressStatuses,
  ordering: C.DEFAULT_VENDOR_TICKET_SORT
};
MeldUtils.getStatusColor = (meld) => {
  if (meld.status === "VENDOR_COULD_NOT_COMPLETE" || meld.status === "MAINTENANCE_COULD_NOT_COMPLETE") {
    return "negative";
  }
  if (["COMPLETED", "MANAGER_CANCELED", "TENANT_CANCELED"].includes(meld.status)) {
    return "complete";
  }
  if (["PENDING_TENANT_AVAILABILITY", "PENDING_COMPLETION", "PENDING_MORE_VENDOR_AVAILABILITY"].includes(meld.status)) {
    return "affirmative";
  }
  return "neutral";
};
__decorateClass([
  asImmer()
], MeldUtils, "getMeldID", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getAllManagementAppointmentStartDates", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getAllVendorAppointmentStartDates", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getMeldProject", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getMeldProjectName", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getMeldProjectID", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getMeldDueDate", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getUnit", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getMeldReferenceID", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getTenantPresenceRequired", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getMeldStatus", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getMeldMaintenanceNotes", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getMeldCompletionNotes", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getMeldCreated", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getOwnerApprovedBy", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getMeldManagerCancelled", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getMeldManagerCancellationReason", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getMeldTenantRequest", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getMeldManagerCanceller", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getMeldCompletionDate", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getNameOfCloserForMeld", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getMarkedCompleteString", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getMeldManagementRequester", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getDescription", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getBriefDescription", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getVendorAssignmentRequests", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getFormattedStatus", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getApptEventStart", 1);
__decorateClass([
  asImmer()
], MeldUtils, "canManagerMarkComplete", 1);
__decorateClass([
  asImmer()
], MeldUtils, "canAssign", 1);
__decorateClass([
  asImmer()
], MeldUtils, "isSchedulable", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getTenantRating", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getTenantReview", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getTenantReviewDate", 1);
__decorateClass([
  asImmer()
], MeldUtils, "isScheduled", 1);
__decorateClass([
  asImmer()
], MeldUtils, "isScheduledNotProjects", 1);
__decorateClass([
  asImmer()
], MeldUtils, "isInactive", 1);
__decorateClass([
  asImmer()
], MeldUtils, "isInHouse", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getAbbreviatedPropertyListLine1", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getAbbreviatedPropertyListLine2", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getWorkCategory", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getWorkLocation", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getPetInfo", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getMeldProperty", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getDisplayProperty", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getPropertyMaintenanceNotes", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getDirectionsUrlString", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getMeldUnitName", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getAddressLine1", 1);
__decorateClass([
  asImmer()
], MeldUtils, "getDisplayAddressLine1", 1);
__decorateClass([
  asImmer()
], MeldUtils, "propertyName", 1);
__decorateClass([
  asImmer()
], MeldUtils, "canChangeResidents", 1);
export default MeldUtils;
