import React from "react";
import { EuiHeaderSectionItem, EuiIcon } from "@elastic/eui";
import { Link } from "react-router-dom";

import URL from "@pm-shared/utils/url";

const HeaderLogoItem = ({ logoLinkUrl }: { logoLinkUrl: string }) => {
  return (
    <EuiHeaderSectionItem aria-label="PropertyMeld Icon">
      <Link to={logoLinkUrl} style={{ alignSelf: "center", padding: "4px 16px" }}>
        <EuiIcon
          title="Property Meld Logo"
          type={URL.getStatic("icons/propertymeld-logo.png")}
          aria-label="Go to home page"
          style={{ cursor: "pointer", minWidth: "0px" }}
          size="l"
        />
      </Link>
    </EuiHeaderSectionItem>
  );
};

export { HeaderLogoItem };
