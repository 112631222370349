var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { useHistory } from "react-router-dom";
import { badgeColors, GenericStatusBadgeProps } from "@pm-frontend/styles";
import { LinkHelper } from "@pm-shared/utils/link";
import { PriorityStatus, WorkTypeLabel } from "./statuses";
import {
  MeldStatus,
  MeldManagerStatusLabels,
  OpenStatuses,
  SchedulableStatuses,
  ClosedStatuses
} from "../types/meld";
import { getAssignedVendor } from "./assignment-utils";
import { getPropertyDetailsLink, getPropertyNameDistinctFromLine1 } from "./property-utils";
import { WorkCategoryLabel } from "@pm-frontend/shared/utils/statuses";
import { formatDateTimeMonthDDYYYYHHMM_xm } from "@pm-frontend/shared/utils/formatDayMonth";
import { centsToDollars } from "@pm-frontend/shared/utils/currency-utils";
import { RouteUrls } from "@pm-frontend/shared/utils/route-urls";
import { canCreateAssignMelds, canViewEditReassignMelds, canViewVendors } from "./permission-utils";
import * as UnitUtils from "@pm-frontend/shared/utils/unit-utils";
import { formatUnitLabel } from "@pm-frontend/shared/utils/addressFormat";
import { getCoordinatesFromLocation } from "./location-utils";
import URL from "@pm-shared/utils/url";
const InProgressStatuses = {
  [MeldStatus.PENDING_COMPLETION]: true,
  [MeldStatus.OPEN]: true,
  [MeldStatus.PENDING_TENANT_AVAILABILITY]: true,
  [MeldStatus.PENDING_MORE_VENDOR_AVAILABILITY]: true,
  [MeldStatus.PENDING_MORE_MANAGEMENT_AVAILABILITY]: true,
  [MeldStatus.PENDING_VENDOR]: true
};
const NonClosedStatuses = __spreadProps(__spreadValues({}, InProgressStatuses), {
  [MeldStatus.PENDING_ASSIGNMENT]: true
});
const EditableStatuses = {
  [MeldStatus.PENDING_COMPLETION]: true,
  [MeldStatus.OPEN]: true,
  [MeldStatus.PENDING_TENANT_AVAILABILITY]: true,
  [MeldStatus.PENDING_MORE_VENDOR_AVAILABILITY]: true,
  [MeldStatus.PENDING_MORE_MANAGEMENT_AVAILABILITY]: true,
  [MeldStatus.PENDING_VENDOR]: true,
  [MeldStatus.PENDING_ASSIGNMENT]: true
};
const meldTabLinkMap = {
  details: RouteUrls.meldDetails,
  worklog: RouteUrls.meldDetailsWorkLog,
  activity: RouteUrls.meldDetailsActivity,
  contacts: RouteUrls.meldDetailsContacts,
  reminders: RouteUrls.meldDetailsReminders,
  estimates: RouteUrls.meldDetailsEstimates
};
export const getMeldDetailsLink = (meld, tab) => {
  if (!tab || tab === "details") {
    return LinkHelper.normalize(meldTabLinkMap.details(meld));
  }
  if (tab === "chat") {
    return LinkHelper.normalize(RouteUrls.meldDetailsChat(meld));
  }
  if (tab === "addReminder") {
    return LinkHelper.normalize(RouteUrls.meldDetailsRemindersAdd(meld));
  }
  return LinkHelper.normalize(meldTabLinkMap[tab](meld));
};
export const getMeldDetailsEditReminderUrl = (meld, reminderId) => {
  return LinkHelper.normalize(RouteUrls.meldDetailsRemindersEdit(meld, reminderId));
};
export const useGetMeldDetailsTabRedirectOnClick = (meld) => {
  const history = useHistory();
  return (tab) => {
    history.push(LinkHelper.normalize(meldTabLinkMap[tab](meld)));
  };
};
export const getFormattedReferenceId = (meld) => `${meld.reference_id}`;
export const canUploadInvoice = (meld) => {
  return !meld.meld_invoice && meld.meld_type !== "ESTIMATE" && (meld.status === "COMPLETED" || meld.status === "VENDOR_COULD_NOT_COMPLETE") && getAssignedVendor(meld);
};
export const isEstimateMeld = (meld) => {
  return meld.meld_type === "ESTIMATE";
};
export const getIsMeldScheduled = (meld) => {
  var _a, _b;
  const managementScheduled = (_a = meld.managementappointment) == null ? void 0 : _a.some(
    (appointment) => appointment.availability_segment !== null && appointment.availability_segment !== void 0
  );
  const vendorScheduled = (_b = meld.vendorappointment) == null ? void 0 : _b.some(
    (appointment) => appointment.availability_segment !== null && appointment.availability_segment !== void 0
  );
  return managementScheduled || vendorScheduled || false;
};
export const getMeldScheduledStartTime = (meld) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const dtstartManagement = (_d = (_c = (_b = (_a = meld.managementappointment) == null ? void 0 : _a.find(
    (appointment) => {
      var _a2, _b2;
      return ((_b2 = (_a2 = appointment.availability_segment) == null ? void 0 : _a2.event) == null ? void 0 : _b2.dtstart) && !isNaN(Date.parse(appointment.availability_segment.event.dtstart));
    }
  )) == null ? void 0 : _b.availability_segment) == null ? void 0 : _c.event) == null ? void 0 : _d.dtstart;
  const dtstartVendor = (_h = (_g = (_f = (_e = meld.vendorappointment) == null ? void 0 : _e.find(
    (appointment) => {
      var _a2, _b2;
      return ((_b2 = (_a2 = appointment.availability_segment) == null ? void 0 : _a2.event) == null ? void 0 : _b2.dtstart) && !isNaN(Date.parse(appointment.availability_segment.event.dtstart));
    }
  )) == null ? void 0 : _f.availability_segment) == null ? void 0 : _g.event) == null ? void 0 : _h.dtstart;
  if (dtstartManagement) {
    return dtstartManagement;
  }
  if (dtstartVendor) {
    return dtstartVendor;
  }
  return "";
};
export const getMeldScheduledEndTime = (meld) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
  const dtendManagement = (_e = (_d = (_c = (_b = (_a = meld.managementappointment) == null ? void 0 : _a.find(
    (appointment) => {
      var _a2, _b2;
      return ((_b2 = (_a2 = appointment.availability_segment) == null ? void 0 : _a2.event) == null ? void 0 : _b2.dtend) && !isNaN(Date.parse(appointment.availability_segment.event.dtend));
    }
  )) == null ? void 0 : _b.availability_segment) == null ? void 0 : _c.event) == null ? void 0 : _d.dtend) != null ? _e : "";
  const dtendVendor = (_j = (_i = (_h = (_g = (_f = meld.vendorappointment) == null ? void 0 : _f.find(
    (appointment) => {
      var _a2, _b2;
      return ((_b2 = (_a2 = appointment.availability_segment) == null ? void 0 : _a2.event) == null ? void 0 : _b2.dtend) && !isNaN(Date.parse(appointment.availability_segment.event.dtend));
    }
  )) == null ? void 0 : _g.availability_segment) == null ? void 0 : _h.event) == null ? void 0 : _i.dtend) != null ? _j : "";
  if (dtendManagement) {
    return dtendManagement;
  }
  if (dtendVendor) {
    return dtendVendor;
  }
  return "";
};
export const getMeldScheduledBadgeProps = (meld) => {
  if (getIsMeldScheduled(meld)) {
    return {
      text: "Scheduled",
      textSize: "m",
      bgColor: badgeColors.light.softBlue.bg,
      textColor: badgeColors.light.softBlue.text,
      "data-testid": "meld-scheduled-badge"
    };
  }
  return void 0;
};
const statusBadgeFormats = {
  OPEN: __spreadProps(__spreadValues({}, GenericStatusBadgeProps.approved), {
    text: MeldManagerStatusLabels.OPEN,
    "data-testid": "meld-status-badge-open"
  }),
  PENDING_ASSIGNMENT: __spreadProps(__spreadValues({}, GenericStatusBadgeProps.pending), {
    text: MeldManagerStatusLabels.PENDING_ASSIGNMENT,
    "data-testid": "meld-status-badge-pending-assignment"
  }),
  PENDING_VENDOR: __spreadProps(__spreadValues({}, GenericStatusBadgeProps.pending), {
    text: MeldManagerStatusLabels.PENDING_VENDOR,
    "data-testid": "meld-status-badge-pending-vendor"
  }),
  PENDING_TENANT_AVAILABILITY: __spreadProps(__spreadValues({}, GenericStatusBadgeProps.pending), {
    text: MeldManagerStatusLabels.PENDING_TENANT_AVAILABILITY,
    "data-testid": "meld-status-badge-pending-tenant-availability"
  }),
  PENDING_MORE_VENDOR_AVAILABILITY: __spreadProps(__spreadValues({}, GenericStatusBadgeProps.approved), {
    text: MeldManagerStatusLabels.PENDING_MORE_VENDOR_AVAILABILITY,
    "data-testid": "meld-status-badge-pending-more-vendor-availability"
  }),
  PENDING_MORE_MANAGEMENT_AVAILABILITY: __spreadProps(__spreadValues({}, GenericStatusBadgeProps.pending), {
    text: MeldManagerStatusLabels.PENDING_MORE_MANAGEMENT_AVAILABILITY,
    "data-testid": "meld-status-badge-pending-more-management-availability"
  }),
  PENDING_COMPLETION: __spreadProps(__spreadValues({}, GenericStatusBadgeProps.pending), {
    text: MeldManagerStatusLabels.PENDING_COMPLETION,
    "data-testid": "meld-status-badge-pending-completion"
  }),
  VENDOR_COULD_NOT_COMPLETE: __spreadProps(__spreadValues({}, GenericStatusBadgeProps.rejected), {
    text: MeldManagerStatusLabels.VENDOR_COULD_NOT_COMPLETE,
    "data-testid": "meld-status-badge-vendor-could-not-complete"
  }),
  MAINTENANCE_COULD_NOT_COMPLETE: __spreadProps(__spreadValues({}, GenericStatusBadgeProps.rejected), {
    text: MeldManagerStatusLabels.MAINTENANCE_COULD_NOT_COMPLETE,
    "data-testid": "meld-status-badge-maintenance-could-not-complete"
  }),
  MANAGER_CANCELED: __spreadProps(__spreadValues({}, GenericStatusBadgeProps.rejected), {
    text: MeldManagerStatusLabels.MANAGER_CANCELED,
    "data-testid": "meld-status-badge-manager-canceled"
  }),
  TENANT_CANCELED: __spreadProps(__spreadValues({}, GenericStatusBadgeProps.rejected), {
    text: MeldManagerStatusLabels.TENANT_CANCELED,
    "data-testid": "meld-status-badge-tenant-canceled"
  }),
  PENDING_ESTIMATES: __spreadProps(__spreadValues({}, GenericStatusBadgeProps.pending), {
    text: MeldManagerStatusLabels.PENDING_ESTIMATES,
    "data-testid": "meld-status-badge-pending-estimates"
  }),
  COMPLETED: __spreadProps(__spreadValues({}, GenericStatusBadgeProps.approved), {
    text: MeldManagerStatusLabels.COMPLETED,
    "data-testid": "meld-status-badge-completed"
  })
};
const priorityBadgeFormats = {
  LOW: {
    bgColor: badgeColors.light.green.bg,
    textColor: badgeColors.light.green.text,
    text: PriorityStatus.LOW,
    "data-testid": "meld-status-low-badge"
  },
  MEDIUM: {
    bgColor: badgeColors.light.yellow.bg,
    textColor: badgeColors.light.yellow.text,
    text: PriorityStatus.MEDIUM,
    "data-testid": "meld-status-medium-badge"
  },
  HIGH: {
    bgColor: badgeColors.light.red.bg,
    textColor: badgeColors.light.red.text,
    text: PriorityStatus.HIGH,
    "data-testid": "meld-status-high-badge"
  }
};
export const getMeldPriorityBadgeProps = (meld) => {
  return priorityBadgeFormats[meld.priority];
};
export const isMeldAssigned = (meld) => {
  return !!meld.assigned && meld.status !== "PENDING_ASSIGNMENT";
};
export const getMeldStatusBadgeProps = (meld) => {
  return statusBadgeFormats[meld.status];
};
export const isMeldOwnerApproved = (meld) => {
  return meld.owner_approval_status === "OWNER_APPROVAL_APPROVED";
};
export const getMeldOwnerApprovedBadgeProps = (meld) => {
  if (isMeldOwnerApproved(meld)) {
    return {
      text: "Owner Approved",
      textSize: "m",
      bgColor: badgeColors.light.violet.bg,
      textColor: badgeColors.light.violet.text,
      "data-testid": "meld-owner-approved-badge"
    };
  }
  return;
};
export const getMeldHomeWarrantyBadgeProps = (meld) => {
  if (getMeldProperty(meld).home_warranty) {
    return {
      type: URL.getStatic("icons/shield_with_house.svg"),
      badgeText: "Home Warranty",
      "data-testid": "meld-home-warranty-badge"
    };
  }
  return;
};
export const getMeldAnimalPresenceBadgeProps = (meld) => {
  if (meld.has_pets) {
    return {
      type: URL.getStatic("icons/animals_present.svg"),
      badgeText: "Animals Present",
      "data-testid": "meld-animal-presence-badge"
    };
  }
  return;
};
export const getMeldResidentRequiredBadgeProps = (meld) => {
  if (meld.tenant_presence_required) {
    return {
      type: URL.getStatic("icons/residents_gray.svg"),
      badgeText: "Resident Required",
      "data-testid": "meld-resident-required-badge"
    };
  }
  return;
};
export const getMeldPropertyLinkUrl = (meld) => {
  return getPropertyDetailsLink(getMeldProperty(meld).id);
};
export const getCategoryLabel = (category) => {
  return WorkCategoryLabel[category];
};
export const getWorkTypeLabel = (worktype) => {
  return WorkTypeLabel[worktype];
};
export const isAgentInMeldPropertyGroups = (meld, agent) => {
  const meldPropertyGroups = getMeldPropertyGroups(meld);
  if (meldPropertyGroups.length === 0) {
    return true;
  }
  return meldPropertyGroups.some((meldGroup) => {
    return agent.property_groups.some((agentGroup) => {
      if (typeof agentGroup === "number" && typeof meldGroup === "number") {
        return agentGroup === meldGroup;
      } else if (typeof agentGroup === "object" && typeof meldGroup === "number") {
        return agentGroup.id === meldGroup;
      } else if (typeof agentGroup === "number" && typeof meldGroup === "object") {
        return agentGroup === meldGroup.id;
      } else if (typeof agentGroup === "object" && typeof meldGroup === "object") {
        return agentGroup.id === meldGroup.id;
      }
    });
  });
};
export const getMeldPropertyGroups = (meld) => {
  return getMeldProperty(meld).property_groups;
};
export const getMeldRegisteredTenants = (meld) => {
  return meld.tenants.filter((t) => t.user && t.is_active);
};
export const getMeldDueDateFormatted = (meld, emptyMessage = "None provided.") => {
  if (meld.due_date) {
    return formatDateTimeMonthDDYYYYHHMM_xm(meld.due_date);
  } else {
    return emptyMessage;
  }
};
export const getMeldMaintenanceLimit = (meld, emptyMessage = "No maintenance limit provided.") => {
  const maintenanceLimit = getMeldProperty(meld).maintenance_limit;
  if (maintenanceLimit) {
    return centsToDollars(Number(maintenanceLimit));
  } else {
    return emptyMessage;
  }
};
export const isInProgress = (meld) => {
  return !!InProgressStatuses[meld.status];
};
export const isClosed = (meld) => !NonClosedStatuses[meld.status];
export const canManagerCancel = (meld) => {
  return NonClosedStatuses[meld.status];
};
export const isEditable = (meld) => {
  return !!EditableStatuses[meld.status];
};
export const canUnassign = (meld) => {
  return canViewEditReassignMelds && canViewVendors && isInProgress(meld);
};
export const canAssign = (meld) => {
  return (canCreateAssignMelds || canViewEditReassignMelds) && meld.status === MeldStatus.PENDING_ASSIGNMENT;
};
export const canAssignmentChange = (meld) => {
  return !!NonClosedStatuses[meld.status] && !isEstimateMeld(meld);
};
export const canReassign = (meld) => {
  return canViewEditReassignMelds && canAssignmentChange(meld);
};
export const canManagerEdit = (meld) => {
  return canViewEditReassignMelds && isEditable(meld);
};
export const canRequestEstimates = (meld) => {
  const correct_status = meld.status === "PENDING_ASSIGNMENT" || meld.status === "PENDING_ESTIMATES";
  return correct_status && !meld.is_assigned;
};
export const canVendorRequestEstimates = (meld) => {
  const valid_status = [
    "PENDING_ASSIGNMENT",
    "PENDING_ESTIMATES",
    "PENDING_MORE_VENDOR_AVAILABILITY",
    "PENDING_VENDOR"
  ];
  const isValidStatus = valid_status.includes(meld.status);
  return isValidStatus;
};
export const getMeldCreationDateFormatted = (meld) => {
  return `${formatDateTimeMonthDDYYYYHHMM_xm(meld.created)}`;
};
export const isPropertyLevelMeld = (meld) => {
  return meld.prop !== null;
};
export const isMeldOpen = (meld) => {
  return OpenStatuses.includes(meld.status);
};
export const isMeldReschedulable = (meld) => {
  return SchedulableStatuses.RescheduleStatuses.includes(meld.status);
};
export const isMeldSchedulable = (meld) => {
  return SchedulableStatuses.ScheduleStatuses.includes(meld.status);
};
export const isMeldEditable = (meld) => {
  return canViewEditReassignMelds && (meld.status === "PENDING_ASSIGNMENT" || meld.status === "PENDING_MORE_VENDOR_AVAILABILITY" || meld.status === "PENDING_VENDOR" || meld.status === "PENDING_MORE_MANAGEMENT_AVAILABILITY" || meld.status === "PENDING_TENANT_AVAILABILITY" || meld.status === "PENDING_COMPLETION");
};
export const isMeldCancellable = (meld) => {
  return !Object.values(ClosedStatuses).flat().includes(meld.status) && meld.status !== "PENDING_ESTIMATES";
};
export const isMeldMerged = (meld) => {
  return meld.merged_meld_id !== void 0 && meld.merged_meld_id !== null;
};
export const getScheduleRescheduleLink = (meld) => {
  return LinkHelper.normalize(RouteUrls.meldCalendarBook(meld));
};
export const getmeldCalendarLink = (meld) => {
  return LinkHelper.normalize(RouteUrls.meldCalendar(meld));
};
export const canManagerMarkComplete = (meld) => {
  var _a;
  return !!((_a = meld.managementappointment) == null ? void 0 : _a.length) && meld.status === MeldStatus.PENDING_COMPLETION;
};
export const canManagerBypassAndMarkComplete = (meld) => {
  return !isClosed(meld);
};
export const hasOwners = (meld) => {
  return meld.all_owners.length > 0;
};
export const getMeldProperty = (meld) => {
  if (meld.unit) {
    return meld.unit.prop;
  } else if (meld.prop) {
    return meld.prop;
  }
  throw new Error("meld.unit and meld.prop cannot both be null");
};
export const getMeldLocation = (meld) => {
  return getMeldProperty(meld).location;
};
export const getMeldCoordinates = (meld) => {
  const location = getMeldLocation(meld);
  if (!location) {
    return;
  }
  return getCoordinatesFromLocation(location);
};
export const getMeldPropertyHomeWarranty = (meld) => {
  return getMeldProperty(meld).home_warranty;
};
export const hasExcludedPropertyGroup = (meld, vendor) => {
  const propertyGroups = getMeldPropertyGroups(meld);
  return vendor.excluded_property_groups.some((group) => propertyGroups.includes(group));
};
export const getResidentReview = (meld, emptyMessage = "None provided.") => {
  if (meld.tenant_review) {
    return meld.tenant_review;
  }
  return emptyMessage;
};
const getlastVendorRequest = (meld) => {
  return meld.vendor_assignment_requests[meld.vendor_assignment_requests.length - 1];
};
export const getVendorRejectionVendorName = (meld, emptyMessage = "Unknown Vendor.") => {
  const lastRequest = getlastVendorRequest(meld);
  if (lastRequest && lastRequest.vendor.name) {
    return lastRequest.vendor.name;
  }
  return emptyMessage;
};
export const getVendorRejectionDate = (meld, emptyMessage = "Unknown Date.") => {
  const lastRequest = getlastVendorRequest(meld);
  if (lastRequest && lastRequest.rejected) {
    return formatDateTimeMonthDDYYYYHHMM_xm(lastRequest.rejected);
  }
  return emptyMessage;
};
export const getVendorRejectionNotes = (meld, emptyMessage = "None provided.") => {
  const lastRequest = getlastVendorRequest(meld);
  if (lastRequest && lastRequest.reject_reason) {
    return lastRequest.reject_reason;
  }
  return emptyMessage;
};
export const isMeldRejectedByVendor = (meld) => {
  const lastRequest = getlastVendorRequest(meld);
  return meld.vendor_assignment_requests.length > 0 && !!lastRequest.rejected;
};
export const getNotifyOwner = (meld) => {
  return meld.notify_owner;
};
export const getMeldAddress = (meld) => {
  if (meld.unit) {
    return [
      UnitUtils.getPropertyNameDistinctFromLine1(meld.unit),
      meld.unit.display_address.line_1,
      formatUnitLabel(meld.unit)
    ].filter(Boolean).join(", ");
  } else if (meld.prop) {
    return [getPropertyNameDistinctFromLine1(meld.prop), meld.prop.line_1].filter(Boolean).join(", ");
  } else {
    return "";
  }
};
export const getMeldPropertyName = (meld) => {
  return getMeldProperty(meld).property_name;
};
export const getMeldUnitOrPropertyLink = (meld) => {
  if (meld.unit) {
    return LinkHelper.normalize(RouteUrls.unitDetail({ id: meld.unit.id }));
  } else if (meld.prop) {
    return LinkHelper.normalize(RouteUrls.propertyDetail({ id: meld.prop.id }));
  }
  return "";
};
export const getMeldCanAddAvailabilities = (meld) => {
  return meld.tenant_presence_required && meld.has_registered_tenant && isInProgress(meld) && !getIsMeldScheduled(meld) && !getAssignedVendor(meld);
};
export function dedupeMeldInHouseServicers(servicers) {
  const map = /* @__PURE__ */ new Map();
  servicers.forEach((s) => {
    if (typeof s.agent === "number") {
      map.set(s.agent, s);
    } else {
      map.set(s.agent.id, s);
    }
  });
  return Array.from(map.values());
}
export function getMeldInHouseServicers(meld) {
  return dedupeMeldInHouseServicers(meld.in_house_servicers);
}
