import { useHistory } from "react-router-dom";

import { badgeColors, GenericStatusBadgeProps } from "@pm-frontend/styles";
import { LinkHelper } from "@pm-shared/utils/link";
import { PmBadgeProps } from "@pm-frontend/shared/components/PmBadge";
import { OwnerApproval, PriorityStatus, WorkType, WorkTypeLabel } from "./statuses";
import {
  MeldStatus,
  MeldManagerStatusLabels,
  MeldType,
  OpenStatuses,
  SchedulableStatuses,
  ClosedStatuses,
} from "../types/meld";
import { getAssignedVendor } from "./assignment-utils";
import { getPropertyDetailsLink, getPropertyNameDistinctFromLine1 } from "./property-utils";
import { WorkCategory, WorkCategoryLabel } from "@pm-frontend/shared/utils/statuses";
import { formatDateTimeMonthDDYYYYHHMM_xm } from "@pm-frontend/shared/utils/formatDayMonth";
import { centsToDollars } from "@pm-frontend/shared/utils/currency-utils";
import { RouteUrls } from "@pm-frontend/shared/utils/route-urls";
import { canCreateAssignMelds, canViewEditReassignMelds, canViewVendors } from "./permission-utils";
import { MeldTabType } from "@pm-frontend/routes/Melds/MeldDetails/tabs/MeldTabs";
import * as UnitUtils from "@pm-frontend/shared/utils/unit-utils";
import { formatUnitLabel } from "@pm-frontend/shared/utils/addressFormat";
import { getCoordinatesFromLocation, LatLongCoordinates } from "./location-utils";
import {
  ManagementAppointmentSerializer,
  MeldDetailViewSerializerV2,
} from "../types/api/meld/serializers/meld_detail_view_serializer";
import { PmIconBadgeProps } from "@pm-frontend/shared/components/PmIconBadge";
import URL from "@pm-shared/utils/url";

interface BasicMeld {
  id: number;
  reference_id: string;
}

const InProgressStatuses: Partial<Record<MeldStatus, boolean>> = {
  [MeldStatus.PENDING_COMPLETION]: true,
  [MeldStatus.OPEN]: true,
  [MeldStatus.PENDING_TENANT_AVAILABILITY]: true,
  [MeldStatus.PENDING_MORE_VENDOR_AVAILABILITY]: true,
  [MeldStatus.PENDING_MORE_MANAGEMENT_AVAILABILITY]: true,
  [MeldStatus.PENDING_VENDOR]: true,
} as const;

const NonClosedStatuses: Partial<Record<MeldStatus, boolean>> = {
  ...InProgressStatuses,
  [MeldStatus.PENDING_ASSIGNMENT]: true,
} as const;

const EditableStatuses: Partial<Record<MeldStatus, boolean>> = {
  [MeldStatus.PENDING_COMPLETION]: true,
  [MeldStatus.OPEN]: true,
  [MeldStatus.PENDING_TENANT_AVAILABILITY]: true,
  [MeldStatus.PENDING_MORE_VENDOR_AVAILABILITY]: true,
  [MeldStatus.PENDING_MORE_MANAGEMENT_AVAILABILITY]: true,
  [MeldStatus.PENDING_VENDOR]: true,
  [MeldStatus.PENDING_ASSIGNMENT]: true,
} as const;

const meldTabLinkMap: Record<MeldTabType, ({ id }: { id: number }) => string> = {
  details: RouteUrls.meldDetails,
  worklog: RouteUrls.meldDetailsWorkLog,
  activity: RouteUrls.meldDetailsActivity,
  contacts: RouteUrls.meldDetailsContacts,
  reminders: RouteUrls.meldDetailsReminders,
  estimates: RouteUrls.meldDetailsEstimates,
};

export const getMeldDetailsLink = (meld: { id: number }, tab?: MeldTabType | "chat" | "addReminder"): string => {
  if (!tab || tab === "details") {
    return LinkHelper.normalize(meldTabLinkMap.details(meld));
  }
  if (tab === "chat") {
    return LinkHelper.normalize(RouteUrls.meldDetailsChat(meld));
  }
  if (tab === "addReminder") {
    return LinkHelper.normalize(RouteUrls.meldDetailsRemindersAdd(meld));
  }
  return LinkHelper.normalize(meldTabLinkMap[tab](meld));
};

export const getMeldDetailsEditReminderUrl = (meld: { id: number }, reminderId: number): string => {
  return LinkHelper.normalize(RouteUrls.meldDetailsRemindersEdit(meld, reminderId));
};

export const useGetMeldDetailsTabRedirectOnClick = (meld: { id: number }) => {
  const history = useHistory();

  return (tab: MeldTabType) => {
    history.push(LinkHelper.normalize(meldTabLinkMap[tab](meld)));
  };
};

// we intentionally removed the #
export const getFormattedReferenceId = (meld: BasicMeld): string => `${meld.reference_id}`;

export const canUploadInvoice = <T>(meld: {
  id: number;
  meld_invoice?: unknown;
  meld_type: MeldType;
  status: MeldStatus;
  vendor_assignment_requests: Array<{
    id: number;
    vendor: T;
    rejected?: string;
    canceled?: string;
  }>;
}): T | false | undefined => {
  return (
    !meld.meld_invoice &&
    meld.meld_type !== "ESTIMATE" &&
    (meld.status === "COMPLETED" || meld.status === "VENDOR_COULD_NOT_COMPLETE") &&
    getAssignedVendor(meld)
  );
};

export const isEstimateMeld = (meld: { meld_type: MeldType }): boolean => {
  return meld.meld_type === "ESTIMATE";
};

export const getIsMeldScheduled = (meld: {
  managementappointment?: Array<{ availability_segment?: unknown }>;
  vendorappointment?: Array<{ availability_segment?: unknown }>;
}): boolean => {
  const managementScheduled = meld.managementappointment?.some(
    (appointment) => appointment.availability_segment !== null && appointment.availability_segment !== undefined
  );

  const vendorScheduled = meld.vendorappointment?.some(
    (appointment) => appointment.availability_segment !== null && appointment.availability_segment !== undefined
  );

  return managementScheduled || vendorScheduled || false;
};

export const getMeldScheduledStartTime = (meld: {
  managementappointment?: Array<{ availability_segment?: { event?: { dtstart: string } } }>;
  vendorappointment?: Array<{ availability_segment?: { event?: { dtstart: string } } }>;
}): string => {
  const dtstartManagement = meld.managementappointment?.find(
    (appointment) =>
      appointment.availability_segment?.event?.dtstart &&
      !isNaN(Date.parse(appointment.availability_segment.event.dtstart))
  )?.availability_segment?.event?.dtstart;

  const dtstartVendor = meld.vendorappointment?.find(
    (appointment) =>
      appointment.availability_segment?.event?.dtstart &&
      !isNaN(Date.parse(appointment.availability_segment.event.dtstart))
  )?.availability_segment?.event?.dtstart;

  if (dtstartManagement) {
    return dtstartManagement;
  }

  if (dtstartVendor) {
    return dtstartVendor;
  }

  return "";
};

export const getMeldScheduledEndTime = (meld: {
  managementappointment?: Array<{ availability_segment?: { event?: { dtend?: string | null } } }>;
  vendorappointment?: Array<{ availability_segment?: { event?: { dtend?: string | null } } }>;
}): string => {
  const dtendManagement =
    meld.managementappointment?.find(
      (appointment) =>
        appointment.availability_segment?.event?.dtend &&
        !isNaN(Date.parse(appointment.availability_segment.event.dtend))
    )?.availability_segment?.event?.dtend ?? "";

  const dtendVendor =
    meld.vendorappointment?.find(
      (appointment) =>
        appointment.availability_segment?.event?.dtend &&
        !isNaN(Date.parse(appointment.availability_segment.event.dtend))
    )?.availability_segment?.event?.dtend ?? "";

  if (dtendManagement) {
    return dtendManagement;
  }

  if (dtendVendor) {
    return dtendVendor;
  }

  return "";
};

export const getMeldScheduledBadgeProps = (meld: {
  managementappointment?: Array<{ availability_segment?: unknown }>;
  vendorappointment?: Array<{ availability_segment?: unknown }>;
}): PmBadgeProps | undefined => {
  if (getIsMeldScheduled(meld)) {
    return {
      text: "Scheduled",
      textSize: "m",
      bgColor: badgeColors.light.softBlue.bg,
      textColor: badgeColors.light.softBlue.text,
      "data-testid": "meld-scheduled-badge",
    };
  }
  return undefined;
};

const statusBadgeFormats: Record<MeldStatus, PmBadgeProps> = {
  OPEN: {
    ...GenericStatusBadgeProps.approved,
    text: MeldManagerStatusLabels.OPEN,
    "data-testid": "meld-status-badge-open",
  },
  PENDING_ASSIGNMENT: {
    ...GenericStatusBadgeProps.pending,
    text: MeldManagerStatusLabels.PENDING_ASSIGNMENT,
    "data-testid": "meld-status-badge-pending-assignment",
  },
  PENDING_VENDOR: {
    ...GenericStatusBadgeProps.pending,
    text: MeldManagerStatusLabels.PENDING_VENDOR,
    "data-testid": "meld-status-badge-pending-vendor",
  },
  PENDING_TENANT_AVAILABILITY: {
    ...GenericStatusBadgeProps.pending,
    text: MeldManagerStatusLabels.PENDING_TENANT_AVAILABILITY,
    "data-testid": "meld-status-badge-pending-tenant-availability",
  },
  PENDING_MORE_VENDOR_AVAILABILITY: {
    ...GenericStatusBadgeProps.approved,
    text: MeldManagerStatusLabels.PENDING_MORE_VENDOR_AVAILABILITY,
    "data-testid": "meld-status-badge-pending-more-vendor-availability",
  },
  PENDING_MORE_MANAGEMENT_AVAILABILITY: {
    ...GenericStatusBadgeProps.pending,
    text: MeldManagerStatusLabels.PENDING_MORE_MANAGEMENT_AVAILABILITY,
    "data-testid": "meld-status-badge-pending-more-management-availability",
  },
  PENDING_COMPLETION: {
    ...GenericStatusBadgeProps.pending,
    text: MeldManagerStatusLabels.PENDING_COMPLETION,
    "data-testid": "meld-status-badge-pending-completion",
  },
  VENDOR_COULD_NOT_COMPLETE: {
    ...GenericStatusBadgeProps.rejected,
    text: MeldManagerStatusLabels.VENDOR_COULD_NOT_COMPLETE,
    "data-testid": "meld-status-badge-vendor-could-not-complete",
  },
  MAINTENANCE_COULD_NOT_COMPLETE: {
    ...GenericStatusBadgeProps.rejected,
    text: MeldManagerStatusLabels.MAINTENANCE_COULD_NOT_COMPLETE,
    "data-testid": "meld-status-badge-maintenance-could-not-complete",
  },
  MANAGER_CANCELED: {
    ...GenericStatusBadgeProps.rejected,
    text: MeldManagerStatusLabels.MANAGER_CANCELED,
    "data-testid": "meld-status-badge-manager-canceled",
  },
  TENANT_CANCELED: {
    ...GenericStatusBadgeProps.rejected,
    text: MeldManagerStatusLabels.TENANT_CANCELED,
    "data-testid": "meld-status-badge-tenant-canceled",
  },
  PENDING_ESTIMATES: {
    ...GenericStatusBadgeProps.pending,
    text: MeldManagerStatusLabels.PENDING_ESTIMATES,
    "data-testid": "meld-status-badge-pending-estimates",
  },
  COMPLETED: {
    ...GenericStatusBadgeProps.approved,
    text: MeldManagerStatusLabels.COMPLETED,
    "data-testid": "meld-status-badge-completed",
  },
} as const;

const priorityBadgeFormats: Record<PriorityStatus, PmBadgeProps> = {
  LOW: {
    bgColor: badgeColors.light.green.bg,
    textColor: badgeColors.light.green.text,
    text: PriorityStatus.LOW,
    "data-testid": "meld-status-low-badge",
  },
  MEDIUM: {
    bgColor: badgeColors.light.yellow.bg,
    textColor: badgeColors.light.yellow.text,
    text: PriorityStatus.MEDIUM,
    "data-testid": "meld-status-medium-badge",
  },
  HIGH: {
    bgColor: badgeColors.light.red.bg,
    textColor: badgeColors.light.red.text,
    text: PriorityStatus.HIGH,
    "data-testid": "meld-status-high-badge",
  },
};

export const getMeldPriorityBadgeProps = (meld: { priority: PriorityStatus }) => {
  return priorityBadgeFormats[meld.priority];
};

export const isMeldAssigned = (meld: { assigned?: string; status: MeldStatus }): boolean => {
  return !!meld.assigned && meld.status !== "PENDING_ASSIGNMENT";
};

export const getMeldStatusBadgeProps = (meld: { status: MeldStatus }): PmBadgeProps => {
  return statusBadgeFormats[meld.status];
};

export const isMeldOwnerApproved = (meld: { owner_approval_status: OwnerApproval }): boolean => {
  return meld.owner_approval_status === "OWNER_APPROVAL_APPROVED";
};

export const getMeldOwnerApprovedBadgeProps = (meld: {
  owner_approval_status: OwnerApproval;
}): PmBadgeProps | undefined => {
  if (isMeldOwnerApproved(meld)) {
    return {
      text: "Owner Approved",
      textSize: "m",
      bgColor: badgeColors.light.violet.bg,
      textColor: badgeColors.light.violet.text,
      "data-testid": "meld-owner-approved-badge",
    };
  }
  return;
};

export const getMeldHomeWarrantyBadgeProps = (
  meld: GetMeldProperty<{ home_warranty: boolean | undefined }>
): PmIconBadgeProps | undefined => {
  if (getMeldProperty(meld).home_warranty) {
    return {
      type: URL.getStatic("icons/shield_with_house.svg"),
      badgeText: "Home Warranty",
      "data-testid": "meld-home-warranty-badge",
    };
  }
  return;
};

export const getMeldAnimalPresenceBadgeProps = (meld: { has_pets?: boolean }): PmIconBadgeProps | undefined => {
  if (meld.has_pets) {
    return {
      type: URL.getStatic("icons/animals_present.svg"),
      badgeText: "Animals Present",
      "data-testid": "meld-animal-presence-badge",
    };
  }
  return;
};

export const getMeldResidentRequiredBadgeProps = (meld: {
  tenant_presence_required: boolean;
}): PmIconBadgeProps | undefined => {
  if (meld.tenant_presence_required) {
    return {
      type: URL.getStatic("icons/residents_gray.svg"),
      badgeText: "Resident Required",
      "data-testid": "meld-resident-required-badge",
    };
  }
  return;
};

export const getMeldPropertyLinkUrl = <T extends { id: number }>(meld: GetMeldProperty<T>): string => {
  return getPropertyDetailsLink(getMeldProperty(meld).id);
};

export const getCategoryLabel = (category: WorkCategory): WorkCategoryLabel => {
  return WorkCategoryLabel[category];
};

export const getWorkTypeLabel = (worktype: WorkType): WorkTypeLabel => {
  return WorkTypeLabel[worktype];
};

export const isAgentInMeldPropertyGroups = <
  M extends { property_groups: number[] | Array<{ id: number }> },
  A extends { property_groups: number[] | Array<{ id: number }> }
>(
  meld: GetMeldProperty<M>,
  agent: A
): boolean => {
  const meldPropertyGroups = getMeldPropertyGroups(meld);
  // a prop with no property groups is visible to all agents
  if (meldPropertyGroups.length === 0) {
    return true;
  }

  return meldPropertyGroups.some((meldGroup) => {
    return agent.property_groups.some((agentGroup) => {
      if (typeof agentGroup === "number" && typeof meldGroup === "number") {
        return agentGroup === meldGroup;
      } else if (typeof agentGroup === "object" && typeof meldGroup === "number") {
        return agentGroup.id === meldGroup;
      } else if (typeof agentGroup === "number" && typeof meldGroup === "object") {
        return agentGroup === meldGroup.id;
      } else if (typeof agentGroup === "object" && typeof meldGroup === "object") {
        return agentGroup.id === meldGroup.id;
      }
    });
  });
};

export const getMeldPropertyGroups = <T extends { property_groups: number[] | Array<{ id: number }> }>(
  meld: GetMeldProperty<T>
): T["property_groups"] => {
  return getMeldProperty(meld).property_groups;
};

interface GetMeldRegisteredTenants<T> {
  tenants: Array<
    T & {
      is_active: boolean;
      user?: unknown;
    }
  >;
}

export const getMeldRegisteredTenants = <T>(meld: GetMeldRegisteredTenants<T>): T[] => {
  return meld.tenants.filter((t) => t.user && t.is_active);
};

export const getMeldDueDateFormatted = (meld: { due_date?: string }, emptyMessage = "None provided.") => {
  if (meld.due_date) {
    return formatDateTimeMonthDDYYYYHHMM_xm(meld.due_date);
  } else {
    return emptyMessage;
  }
};

export const getMeldMaintenanceLimit = <T extends { maintenance_limit: number | null }>(
  meld: GetMeldProperty<T>,
  emptyMessage = "No maintenance limit provided."
) => {
  const maintenanceLimit = getMeldProperty(meld).maintenance_limit;

  if (maintenanceLimit) {
    return centsToDollars(Number(maintenanceLimit));
  } else {
    return emptyMessage;
  }
};

export const isInProgress = (meld: { status: MeldStatus }) => {
  return !!InProgressStatuses[meld.status];
};

export const isClosed = (meld: { status: MeldStatus }) => !NonClosedStatuses[meld.status];

export const canManagerCancel = (meld: { status: MeldStatus }) => {
  return NonClosedStatuses[meld.status];
};

export const isEditable = (meld: { status: MeldStatus }) => {
  return !!EditableStatuses[meld.status];
};

export const canUnassign = (meld: { status: MeldStatus }) => {
  return canViewEditReassignMelds && canViewVendors && isInProgress(meld);
};

export const canAssign = (meld: { status: MeldStatus }): boolean => {
  return (canCreateAssignMelds || canViewEditReassignMelds) && meld.status === MeldStatus.PENDING_ASSIGNMENT;
};

export const canAssignmentChange = (meld: { status: MeldStatus; meld_type: MeldType }): boolean => {
  return !!NonClosedStatuses[meld.status] && !isEstimateMeld(meld);
};

export const canReassign = (meld: { status: MeldStatus; meld_type: MeldType }): boolean => {
  return canViewEditReassignMelds && canAssignmentChange(meld);
};

export const canManagerEdit = (meld: { status: MeldStatus }): boolean => {
  return canViewEditReassignMelds && isEditable(meld);
};

export const canRequestEstimates = (meld: { status: MeldStatus; is_assigned: boolean }): boolean => {
  const correct_status = meld.status === "PENDING_ASSIGNMENT" || meld.status === "PENDING_ESTIMATES";

  return correct_status && !meld.is_assigned;
};
export const canVendorRequestEstimates = (meld: { status: MeldStatus }): boolean => {
  const valid_status = [
    "PENDING_ASSIGNMENT",
    "PENDING_ESTIMATES",
    "PENDING_MORE_VENDOR_AVAILABILITY",
    "PENDING_VENDOR",
  ];
  const isValidStatus = valid_status.includes(meld.status);

  return isValidStatus;
};

export const getMeldCreationDateFormatted = (meld: { created: string }) => {
  return `${formatDateTimeMonthDDYYYYHHMM_xm(meld.created)}`;
};

export const isPropertyLevelMeld = (meld: { prop?: unknown | null }) => {
  return meld.prop !== null;
};

export const isMeldOpen = (meld: { status: MeldStatus }): boolean => {
  return OpenStatuses.includes(meld.status);
};

export const isMeldReschedulable = (meld: { status: MeldStatus }): boolean => {
  return SchedulableStatuses.RescheduleStatuses.includes(meld.status);
};

export const isMeldSchedulable = (meld: { status: MeldStatus }): boolean => {
  return SchedulableStatuses.ScheduleStatuses.includes(meld.status);
};

export const isMeldEditable = (meld: { status: MeldStatus }): boolean => {
  return (
    canViewEditReassignMelds &&
    (meld.status === "PENDING_ASSIGNMENT" ||
      meld.status === "PENDING_MORE_VENDOR_AVAILABILITY" ||
      meld.status === "PENDING_VENDOR" ||
      meld.status === "PENDING_MORE_MANAGEMENT_AVAILABILITY" ||
      meld.status === "PENDING_TENANT_AVAILABILITY" ||
      meld.status === "PENDING_COMPLETION")
  );
};

export const isMeldCancellable = (meld: { status: MeldStatus }): boolean => {
  return !Object.values(ClosedStatuses).flat().includes(meld.status) && meld.status !== "PENDING_ESTIMATES";
};

export const isMeldMerged = (meld: { merged_meld_id: number | undefined }): boolean => {
  return meld.merged_meld_id !== undefined && meld.merged_meld_id !== null;
};

export const getScheduleRescheduleLink = (meld: { id: number }): string => {
  return LinkHelper.normalize(RouteUrls.meldCalendarBook(meld));
};

export const getmeldCalendarLink = (meld: MeldDetailViewSerializerV2): string => {
  return LinkHelper.normalize(RouteUrls.meldCalendar(meld));
};

export const canManagerMarkComplete = (meld: {
  status: MeldStatus;
  managementappointment?: ManagementAppointmentSerializer[];
}) => {
  return !!meld.managementappointment?.length && meld.status === MeldStatus.PENDING_COMPLETION;
};

export const canManagerBypassAndMarkComplete = (meld: { status: MeldStatus }) => {
  return !isClosed(meld);
};

// tslint:disable-next-line
export const hasOwners = (meld: { all_owners: unknown[] }): boolean => {
  return meld.all_owners.length > 0;
};

type GetMeldProperty<T> =
  | {
      prop: null;
      unit: {
        prop: T;
      };
    }
  | {
      unit: null;
      prop: T;
    };

export const getMeldProperty = <T>(meld: GetMeldProperty<T>): T => {
  if (meld.unit) {
    return meld.unit.prop;
  } else if (meld.prop) {
    return meld.prop;
  }
  throw new Error("meld.unit and meld.prop cannot both be null");
};

export const getMeldLocation = <T extends { location: string | null }>(meld: GetMeldProperty<T>): string | null => {
  return getMeldProperty(meld).location;
};

export const getMeldCoordinates = <T extends { location: string | null }>(
  meld: GetMeldProperty<T>
): LatLongCoordinates | undefined => {
  const location = getMeldLocation(meld);
  if (!location) {
    return;
  }
  return getCoordinatesFromLocation(location);
};

export const getMeldPropertyHomeWarranty = <T extends { home_warranty: boolean }>(
  meld: GetMeldProperty<T>
): boolean => {
  return getMeldProperty(meld).home_warranty;
};

export const hasExcludedPropertyGroup = <T extends { property_groups: number[] }>(
  meld: GetMeldProperty<T>,
  vendor: { excluded_property_groups: number[] }
): boolean => {
  const propertyGroups = getMeldPropertyGroups(meld);
  return vendor.excluded_property_groups.some((group) => propertyGroups.includes(group));
};

export const getResidentReview = (meld: { tenant_review?: string }, emptyMessage = "None provided."): string => {
  if (meld.tenant_review) {
    return meld.tenant_review;
  }
  return emptyMessage;
};

interface VendorAssignmentRequest {
  vendor: { name?: string };
  rejected?: string;
  reject_reason?: string;
}

const getlastVendorRequest = (meld: { vendor_assignment_requests: VendorAssignmentRequest[] }) => {
  return meld.vendor_assignment_requests[meld.vendor_assignment_requests.length - 1];
};

export const getVendorRejectionVendorName = (
  meld: { vendor_assignment_requests: VendorAssignmentRequest[] },
  emptyMessage = "Unknown Vendor."
): string => {
  const lastRequest = getlastVendorRequest(meld);
  if (lastRequest && lastRequest.vendor.name) {
    return lastRequest.vendor.name;
  }
  return emptyMessage;
};

export const getVendorRejectionDate = (
  meld: { vendor_assignment_requests: VendorAssignmentRequest[] },
  emptyMessage = "Unknown Date."
): string => {
  const lastRequest = getlastVendorRequest(meld);
  if (lastRequest && lastRequest.rejected) {
    return formatDateTimeMonthDDYYYYHHMM_xm(lastRequest.rejected);
  }
  return emptyMessage;
};

export const getVendorRejectionNotes = (
  meld: { vendor_assignment_requests: VendorAssignmentRequest[] },
  emptyMessage = "None provided."
): string => {
  const lastRequest = getlastVendorRequest(meld);
  if (lastRequest && lastRequest.reject_reason) {
    return lastRequest.reject_reason;
  }
  return emptyMessage;
};

export const isMeldRejectedByVendor = (meld: { vendor_assignment_requests: VendorAssignmentRequest[] }): boolean => {
  const lastRequest = getlastVendorRequest(meld);
  return meld.vendor_assignment_requests.length > 0 && !!lastRequest.rejected;
};

export const getNotifyOwner = (meld: { notify_owner: boolean }): boolean => {
  return meld.notify_owner;
};

interface GetMeldAddressProps {
  unit:
    | null
    | ({
        display_address: {
          line_1?: string;
        };
        apartment?: string;
        suite?: string;
        unit?: string;
        room?: string;
        department?: string;
      } & (
        | {
            building?: { number: string };
            floor?: { number: string };
          }
        | {
            building?: number;
            floor?: number;
          }
      ));
  prop: null | {
    line_1?: string;
  };
}

export const getMeldAddress = (meld: GetMeldAddressProps): string => {
  if (meld.unit) {
    return [
      UnitUtils.getPropertyNameDistinctFromLine1(meld.unit),
      meld.unit.display_address.line_1,
      formatUnitLabel(meld.unit),
    ]
      .filter(Boolean)
      .join(", ");
  } else if (meld.prop) {
    return [getPropertyNameDistinctFromLine1(meld.prop), meld.prop.line_1].filter(Boolean).join(", ");
  } else {
    return "";
  }
};

export const getMeldPropertyName = <T extends { property_name: string }>(meld: GetMeldProperty<T>): string => {
  return getMeldProperty(meld).property_name;
};

export const getMeldUnitOrPropertyLink = (meld: { unit: { id: number }; prop: { id: number } }) => {
  if (meld.unit) {
    return LinkHelper.normalize(RouteUrls.unitDetail({ id: meld.unit.id }));
  } else if (meld.prop) {
    return LinkHelper.normalize(RouteUrls.propertyDetail({ id: meld.prop.id }));
  }
  return "";
};

export const getMeldCanAddAvailabilities = <T>(meld: {
  tenant_presence_required: boolean;
  has_registered_tenant: boolean;
  status: MeldStatus;
  managementappointment?: Array<{ availability_segment?: unknown }>;
  vendorappointment?: Array<{ availability_segment?: unknown }>;
  vendor_assignment_requests: Array<{
    id: number;
    vendor: T;
    rejected?: string;
    canceled?: string;
  }>;
}) => {
  return (
    meld.tenant_presence_required &&
    meld.has_registered_tenant &&
    isInProgress(meld) &&
    !getIsMeldScheduled(meld) &&
    !getAssignedVendor(meld)
  );
};

// our system allows duplicate meld_inhouseservicers, but we generally
// don't want these duplicates in the UI. There are a small number of Melds
// which have this issue
export function dedupeMeldInHouseServicers<T extends { agent: number | { id: number } }>(servicers: T[]): T[] {
  const map = new Map<number, T>();
  servicers.forEach((s) => {
    if (typeof s.agent === "number") {
      map.set(s.agent, s);
    } else {
      map.set(s.agent.id, s);
    }
  });
  return Array.from(map.values());
}

type GetMeldInHouseServicersProps<T> = {
  in_house_servicers: T[];
};

export function getMeldInHouseServicers<T extends { agent: number | { id: number } }>(
  meld: GetMeldInHouseServicersProps<T>
): T[] {
  return dedupeMeldInHouseServicers(meld.in_house_servicers);
}
