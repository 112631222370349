var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { useState } from "react";
import { EuiIcon, EuiPopover, EuiToolTip } from "@elastic/eui";
import URL from "@pm-shared/utils/url";
import { useIsMobile } from "../hooks/useIsMobile";
const ICON_URL = URL.getStatic("icons/questionmark.svg");
const InfoIconButton = ({ size, iconAriaLabel, onClick }) => {
  return /* @__PURE__ */ React.createElement(EuiIcon, { onClick, type: ICON_URL, size: size || "original", "aria-label": iconAriaLabel });
};
const InfoIcon = ({ size, iconAriaLabel }) => {
  return /* @__PURE__ */ React.createElement(EuiIcon, { type: ICON_URL, size: size || "original", "aria-label": iconAriaLabel });
};
const PmInfoIcon = ({ tooltipProps, size, iconAriaLabel }) => {
  const isMobile = useIsMobile();
  const [isMobilePopoverOpen, setIsMobilePopoverOpen] = useState(false);
  if (isMobile && tooltipProps) {
    const togglePopover = () => setIsMobilePopoverOpen((state) => !state);
    return /* @__PURE__ */ React.createElement(
      EuiPopover,
      {
        button: /* @__PURE__ */ React.createElement(InfoIconButton, { iconAriaLabel, size, onClick: togglePopover }),
        isOpen: isMobilePopoverOpen,
        closePopover: () => setIsMobilePopoverOpen(false)
      },
      tooltipProps.content
    );
  }
  if (tooltipProps) {
    return /* @__PURE__ */ React.createElement(EuiToolTip, __spreadValues({}, tooltipProps), /* @__PURE__ */ React.createElement(InfoIcon, { size, iconAriaLabel }));
  }
  return /* @__PURE__ */ React.createElement(InfoIcon, { size, iconAriaLabel });
};
export { PmInfoIcon };
