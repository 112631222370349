var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useState } from "react";
import {
  EuiFieldNumber,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormControlLayoutDelimited,
  EuiFormRow,
  EuiSuperSelect
} from "@elastic/eui";
import { useHistory, useLocation } from "react-router-dom";
import { PmFormStyling } from "../../Forms/PmFormStyling";
import {
  BaseDecimalFilterClass,
  PM_DECIMAL_FILTER_INPUT_TYPES,
  PM_DECIMAL_FILTER_OPERATORS
} from "./BaseDecimalFilterClass";
import { PmFilterButtonsCommitButtons } from "../subcomponents/PmFilterButtonsCommitControls";
const OneNumberInput = ({
  filter,
  pendingOperatorAndValue,
  setPendingOperatorAndValue
}) => {
  const onChange = (event) => {
    setPendingOperatorAndValue((state) => __spreadProps(__spreadValues({}, state), { value: event.target.value }));
  };
  return /* @__PURE__ */ React.createElement(EuiFormRow, { label: filter.getNumberUnitLabel() }, /* @__PURE__ */ React.createElement(
    EuiFieldNumber,
    {
      value: pendingOperatorAndValue.value || "",
      onChange,
      "data-testid": "first-decimal-filter-input"
    }
  ));
};
const TwoNumberInput = ({
  filter,
  pendingOperatorAndValue,
  setPendingOperatorAndValue
}) => {
  const getOnValueChange = (type) => {
    return (event) => {
      setPendingOperatorAndValue((state) => __spreadProps(__spreadValues({}, state), {
        [type === "first" ? "firstValue" : "secondValue"]: event.target.value
      }));
    };
  };
  return /* @__PURE__ */ React.createElement(EuiFormRow, { label: filter.getNumberUnitLabel() }, /* @__PURE__ */ React.createElement(
    EuiFormControlLayoutDelimited,
    {
      startControl: /* @__PURE__ */ React.createElement(
        "input",
        {
          type: "number",
          placeholder: "",
          className: "euiFieldNumber",
          value: pendingOperatorAndValue.firstValue,
          onChange: getOnValueChange("first"),
          "data-testid": "first-decimal-filter-input"
        }
      ),
      endControl: /* @__PURE__ */ React.createElement(
        "input",
        {
          type: "number",
          placeholder: "",
          className: "euiFieldNumber",
          value: pendingOperatorAndValue.secondValue,
          onChange: getOnValueChange("second"),
          "data-testid": "second-decimal-filter-input"
        }
      )
    }
  ));
};
const PmDecimalFilter = ({
  savedFilter,
  filter,
  closePopover,
  currentOperatorAndValue,
  isMobile
}) => {
  const location = useLocation();
  const history = useHistory();
  let body;
  const [pendingOperatorAndValue, setPendingOperatorAndValue] = useState(
    () => currentOperatorAndValue || {
      operator: PM_DECIMAL_FILTER_OPERATORS.GREATER_THAN,
      value: "",
      type: PM_DECIMAL_FILTER_INPUT_TYPES.ONE_NUMBER,
      filterType: BaseDecimalFilterClass.type
    }
  );
  const onOperatorChange = (newOperator) => {
    const newOperatorAndValue = filter.getNewValueFromNewOperator({
      newOperator,
      currentPendingOperatorAndValue: pendingOperatorAndValue
    });
    setPendingOperatorAndValue(newOperatorAndValue);
  };
  switch (pendingOperatorAndValue.type) {
    case PM_DECIMAL_FILTER_INPUT_TYPES.ONE_NUMBER:
      body = /* @__PURE__ */ React.createElement(
        OneNumberInput,
        {
          filter,
          pendingOperatorAndValue,
          setPendingOperatorAndValue
        }
      );
      break;
    case PM_DECIMAL_FILTER_INPUT_TYPES.TWO_NUMBER:
      body = /* @__PURE__ */ React.createElement(
        TwoNumberInput,
        {
          filter,
          pendingOperatorAndValue,
          setPendingOperatorAndValue
        }
      );
      break;
    case PM_DECIMAL_FILTER_INPUT_TYPES.BOOLEAN:
      break;
    default:
      break;
  }
  return /* @__PURE__ */ React.createElement(PmFormStyling, { error: void 0 }, /* @__PURE__ */ React.createElement(
    EuiFlexGroup,
    {
      direction: "column",
      gutterSize: "m",
      style: { width: isMobile ? `min(80vw, ${filter.getPopoverWidth()})` : filter.getPopoverWidth() }
    },
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
      EuiSuperSelect,
      {
        options: filter.getAllowedOperatorOptions(),
        valueOfSelected: pendingOperatorAndValue.operator,
        onChange: onOperatorChange,
        "aria-label": "Select an operator"
      }
    )),
    body && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, body),
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
      PmFilterButtonsCommitButtons,
      {
        onApplyClick: filter.getOnApplyClick({
          location,
          savedFilter,
          history,
          currentPendingOperatorValue: pendingOperatorAndValue,
          closePopover
        }),
        onClearClick: filter.getOnClearClick({ closePopover, location, savedFilter, history })
      }
    ))
  ));
};
export { PmDecimalFilter };
