var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useState } from "react";
import {
  EuiDatePicker,
  EuiDatePickerRange,
  EuiFieldNumber,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSuperSelect
} from "@elastic/eui";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { parseISO } from "date-fns";
import { PmFormStyling } from "../../Forms/PmFormStyling";
import {
  BaseDateFilterClass,
  DATE_RANGE_FIRST_SUFFIX,
  DATE_RANGE_SECOND_SUFFIX,
  PM_DATE_FILTER_INPUT_TYPES,
  PM_DATE_FILTER_OPERATORS
} from "./BaseDateFilterClass";
import { PmFilterButtonsCommitButtons } from "../subcomponents/PmFilterButtonsCommitControls";
import { MobileDateTimePicker } from "../../DatePickerMobile/MobileDateTimePicker";
const OneDatePicker = ({
  pendingOperatorAndValue,
  setPendingOperatorAndValue,
  isMobile
}) => {
  const dateParamValue = pendingOperatorAndValue.value;
  if (isMobile) {
    const onDateChangeMobile = (e) => {
      const newValue = e.target.value ? parseISO(e.target.value).valueOf().toString() : "";
      setPendingOperatorAndValue((state) => __spreadProps(__spreadValues({}, state), { value: newValue }));
    };
    const parsedParamValueMobile = parseInt(dateParamValue, 10);
    const dateValueMobile = Number.isFinite(parsedParamValueMobile) ? new Date(parsedParamValueMobile) : "";
    return /* @__PURE__ */ React.createElement(
      MobileDateTimePicker,
      {
        type: "date",
        value: dateValueMobile && isNaN(dateValueMobile.getTime()) ? "" : dateValueMobile,
        onChange: onDateChangeMobile
      }
    );
  }
  const parsedParamValue = parseInt(dateParamValue, 10);
  const dateSelectedValue = Number.isFinite(parsedParamValue) ? moment(parsedParamValue) : null;
  const onDateChange = (newDate) => {
    const dateValue = newDate ? moment(newDate).startOf("day").toDate().getTime().toString() : "";
    setPendingOperatorAndValue((state) => __spreadProps(__spreadValues({}, state), { value: dateValue }));
  };
  return /* @__PURE__ */ React.createElement(
    EuiDatePicker,
    {
      selected: dateSelectedValue,
      onChange: onDateChange,
      showTimeSelect: false,
      shouldCloseOnSelect: true,
      id: pendingOperatorAndValue.operator === PM_DATE_FILTER_OPERATORS.BEFORE ? DATE_RANGE_SECOND_SUFFIX : DATE_RANGE_FIRST_SUFFIX,
      "data-testid": pendingOperatorAndValue.operator === PM_DATE_FILTER_OPERATORS.BEFORE ? "two-dates-second-date" : "two-dates-first-date",
      adjustDateOnChange: false,
      autoComplete: "off"
    }
  );
};
const TwoDatePicker = ({
  pendingOperatorAndValue,
  setPendingOperatorAndValue,
  isMobile
}) => {
  const firstParamValue = pendingOperatorAndValue.firstDate;
  const secondParamValue = pendingOperatorAndValue.secondDate;
  if (isMobile) {
    const getOnDateChangeMobile = (type) => {
      return (e) => {
        const newValue = e.target.value ? parseISO(e.target.value).valueOf().toString() : "";
        setPendingOperatorAndValue((state) => __spreadProps(__spreadValues({}, state), {
          [type === "first" ? "firstDate" : "secondDate"]: newValue
        }));
      };
    };
    const parsedFirstValue = parseInt(firstParamValue, 10);
    const firstValueMobile = Number.isFinite(parsedFirstValue) ? new Date(parsedFirstValue) : "";
    const parsedSecondValue = parseInt(secondParamValue, 10);
    const secondValueMobile = Number.isFinite(parsedSecondValue) ? new Date(parsedSecondValue) : "";
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      MobileDateTimePicker,
      {
        type: "date",
        value: firstValueMobile && isNaN(firstValueMobile.getTime()) ? "" : firstValueMobile,
        onChange: getOnDateChangeMobile("first")
      }
    ), /* @__PURE__ */ React.createElement(
      MobileDateTimePicker,
      {
        type: "date",
        value: secondValueMobile && isNaN(secondValueMobile.getTime()) ? "" : secondValueMobile,
        onChange: getOnDateChangeMobile("second")
      }
    ));
  }
  const firstParsedValue = parseInt(firstParamValue, 10);
  const secondParsedValue = parseInt(secondParamValue, 10);
  const firstValue = Number.isFinite(firstParsedValue) ? moment(firstParsedValue) : null;
  const secondValue = Number.isFinite(secondParsedValue) ? moment(secondParsedValue) : null;
  const getOnDateChange = (type) => {
    return (date) => {
      const newDate = date ? moment(date).startOf("day").toDate().getTime().toString() : "";
      setPendingOperatorAndValue((state) => __spreadProps(__spreadValues({}, state), { [type === "first" ? "firstDate" : "secondDate"]: newDate }));
    };
  };
  return /* @__PURE__ */ React.createElement(
    EuiDatePickerRange,
    {
      startDateControl: /* @__PURE__ */ React.createElement(
        EuiDatePicker,
        {
          onChange: getOnDateChange("first"),
          selected: firstValue,
          showTimeSelect: false,
          shouldCloseOnSelect: true,
          id: DATE_RANGE_FIRST_SUFFIX,
          "data-testid": "two-dates-first-date",
          adjustDateOnChange: false,
          autoComplete: "off"
        }
      ),
      endDateControl: /* @__PURE__ */ React.createElement(
        EuiDatePicker,
        {
          onChange: getOnDateChange("second"),
          selected: secondValue,
          showTimeSelect: false,
          shouldCloseOnSelect: true,
          id: DATE_RANGE_SECOND_SUFFIX,
          "aria-label": "two-dates-second-date",
          adjustDateOnChange: false,
          autoComplete: "off"
        }
      )
    }
  );
};
const TimeFrame = ({
  filter,
  pendingOperatorAndValue,
  setPendingOperatorAndValue
}) => {
  const selectedTimeframeValue = pendingOperatorAndValue.timeframeValue;
  const numberFieldValue = pendingOperatorAndValue.numberValue;
  const onSelectedTimeframeChange = (newTimeframe) => {
    setPendingOperatorAndValue((state) => __spreadProps(__spreadValues({}, state), { timeframeValue: newTimeframe }));
  };
  const onNumberChange = (event) => {
    setPendingOperatorAndValue((state) => __spreadProps(__spreadValues({}, state), { numberValue: event.target.value }));
  };
  return /* @__PURE__ */ React.createElement(EuiFlexGrid, { direction: "row", gutterSize: "m", responsive: false, columns: 2 }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFormRow, { label: "Number" }, /* @__PURE__ */ React.createElement(
    EuiFieldNumber,
    {
      value: numberFieldValue,
      onChange: onNumberChange,
      placeholder: "# of",
      step: 1,
      fullWidth: false
    }
  ))), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFormRow, { label: "Timeframe" }, /* @__PURE__ */ React.createElement(
    EuiSuperSelect,
    {
      options: filter.getSelectedTimeframeOptions({ selectedOperator: pendingOperatorAndValue.operator }),
      valueOfSelected: selectedTimeframeValue,
      onChange: onSelectedTimeframeChange,
      fullWidth: false
    }
  ))));
};
const PmDateFilter = ({
  filter,
  savedFilter,
  closePopover,
  currentOperatorAndValue,
  isMobile
}) => {
  const location = useLocation();
  const history = useHistory();
  let body;
  const [pendingOperatorAndValue, setPendingOperatorAndValue] = useState(() => {
    return currentOperatorAndValue || {
      operator: PM_DATE_FILTER_OPERATORS.AFTER,
      value: "",
      type: PM_DATE_FILTER_INPUT_TYPES.ONE_DATE,
      filterType: BaseDateFilterClass.type
    };
  });
  switch (pendingOperatorAndValue.type) {
    case PM_DATE_FILTER_INPUT_TYPES.TWO_DATE:
      body = /* @__PURE__ */ React.createElement(
        TwoDatePicker,
        {
          pendingOperatorAndValue,
          setPendingOperatorAndValue,
          isMobile
        }
      );
      break;
    case PM_DATE_FILTER_INPUT_TYPES.ONE_DATE:
      body = /* @__PURE__ */ React.createElement(
        OneDatePicker,
        {
          pendingOperatorAndValue,
          setPendingOperatorAndValue,
          isMobile
        }
      );
      break;
    case PM_DATE_FILTER_INPUT_TYPES.TIMEFRAME:
      body = /* @__PURE__ */ React.createElement(
        TimeFrame,
        {
          pendingOperatorAndValue,
          setPendingOperatorAndValue,
          filter
        }
      );
      break;
    default:
      break;
  }
  const onOperatorChange = (newOperator) => {
    const newOperatorAndValue = filter.getNewValueFromNewOperator({
      newOperator,
      currentPendingOperatorAndValue: pendingOperatorAndValue
    });
    setPendingOperatorAndValue(newOperatorAndValue);
  };
  return /* @__PURE__ */ React.createElement(PmFormStyling, { error: void 0 }, /* @__PURE__ */ React.createElement(
    EuiFlexGroup,
    {
      direction: "column",
      gutterSize: "s",
      style: { width: isMobile ? `min(80vw, ${filter.getPopoverWidth()})` : filter.getPopoverWidth() }
    },
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
      EuiSuperSelect,
      {
        onChange: onOperatorChange,
        valueOfSelected: pendingOperatorAndValue.operator,
        options: filter.getAllowedOperatorOptions(),
        "aria-label": "Select an operator"
      }
    )),
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, key: pendingOperatorAndValue.operator }, body),
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
      PmFilterButtonsCommitButtons,
      {
        onApplyClick: filter.getOnApplyClick({
          location,
          savedFilter,
          history,
          currentPendingOperatorValue: pendingOperatorAndValue,
          closePopover
        }),
        onClearClick: filter.getOnClearClick({ closePopover, location, savedFilter, history })
      }
    ))
  ));
};
export { PmDateFilter };
