import { differenceInHours, formatDistanceToNow, isSameDay as dateFnIsSameDay } from "date-fns";
import { MeldStatus, MeldType } from "../types/meld";
import { getAssignedVendor } from "./assignment-utils";
import { isEstimateMeld, isInProgress } from "./meld-utils";

export const dateComparator = (d1: Date, d2: Date): number => {
  return d2.getTime() - d1.getTime();
};

export const getDate = (date: Date | string): Date => {
  if (date instanceof Date) {
    return date;
  }
  return new Date(date);
};

export const isInPast = (date: Date | string): boolean => {
  const parsedDate = getDate(date);
  return dateComparator(parsedDate, new Date()) > 0;
};

export const isSameDay = (date1: Date | string, date2: Date | string): boolean => {
  return dateFnIsSameDay(getDate(date1), getDate(date2));
};

/*
 * Returns a string which is a pretty date such as 'less than an hour', '3 weeks'
 * Utilizes the date-fns library
 */
export const getPrettyDuration = (date: string | Date): string => {
  const parsedDate = getDate(date);
  return formatDistanceToNow(parsedDate);
};

export const getHoursDuration = (date1: string | Date, date2: string | Date): number => {
  const parsedDate1 = getDate(date1);
  const parsedDate2 = getDate(date2);
  return differenceInHours(parsedDate1, parsedDate2);
};

interface IsScheduledNotProjectsProps {
  managementappointment?: Array<{
    availability_segment?: unknown;
  }>;
  vendorappointment?: Array<{
    availability_segment?: unknown;
  }>;
}

const isScheduledNotProjects = (meld: IsScheduledNotProjectsProps) => {
  return (
    (meld.managementappointment?.[0] && meld.managementappointment?.[0]?.availability_segment) ||
    (meld.vendorappointment?.[0] && meld.vendorappointment?.[0]?.availability_segment)
  );
};

interface CanAddAvailabilitiesProps extends IsScheduledNotProjectsProps {
  tenant_presence_required: boolean;
  has_registered_tenant: boolean;
  meld_type: MeldType;
  status: MeldStatus;
  vendor_assignment_requests: Array<{
    id: number;
    vendor: unknown;
    rejected?: string;
    canceled?: string;
  }>;
}

export const canAddAvailabilities = (meld: CanAddAvailabilitiesProps): boolean => {
  return (
    meld.tenant_presence_required &&
    meld.has_registered_tenant &&
    isInProgress(meld) &&
    !isScheduledNotProjects(meld) &&
    !getAssignedVendor(meld) &&
    !isEstimateMeld(meld)
  );
};

const ShouldShowAvailabilitiesStatuses: Record<string, boolean> = {
  [MeldStatus.OPEN]: true,
  [MeldStatus.PENDING_TENANT_AVAILABILITY]: true,
  [MeldStatus.PENDING_MORE_VENDOR_AVAILABILITY]: true,
  [MeldStatus.PENDING_MORE_MANAGEMENT_AVAILABILITY]: true,
};

interface ShouldShowAvailabilitiesProps {
  status: MeldStatus;
}

export const shouldShowAvailabilities = (meld: ShouldShowAvailabilitiesProps): boolean => {
  return !!ShouldShowAvailabilitiesStatuses[meld.status];
};
