import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import React from "react";
import { PmEmptyButton } from "../../Buttons/PmEmptyButton";
import { PmFilledButton } from "../../Buttons/PmFilledButton";

interface PmFilterButtonsCommitButtonsProps {
  onApplyClick: () => void;
  onClearClick: () => void;
}
/**
 * The `Apply` and `Clear` buttons in a filter popover
 */
const PmFilterButtonsCommitButtons = ({ onApplyClick, onClearClick }: PmFilterButtonsCommitButtonsProps) => {
  return (
    <EuiFlexGroup direction="row" responsive={false} gutterSize="s">
      <EuiFlexItem grow={false}>
        <PmFilledButton text="Apply" onClick={onApplyClick} isLoading={false} data-testid="filter-apply-button" />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <PmEmptyButton text="Clear" onClick={onClearClick} isLoading={false} />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export { PmFilterButtonsCommitButtons };
