var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useState } from "react";
import { create } from "zustand";
import { EuiFlexGroup, EuiFlexItem, EuiSwitch, EuiText } from "@elastic/eui";
import { colors } from "@pm-frontend/styles";
import Features from "@pm-assets/js/common/feature-flags";
import { ApiUrls } from "../utils/api-urls";
import { apiPatch } from "../utils/apiFetch";
import { LinkHelper } from "../utils/api-helpers";
import { AuthUtils } from "../utils/auth-utils";
const ManagerPageRedesignKeys = ["calendar_redesign"];
const VendorPageRedesignKeys = [];
const OwnerPageRedesignKeys = [];
const ResidentPageRedesignKeys = [];
const SunsetFeatureFlagFunctions = {
  calendar_redesign: Features.isUIRedesignSchedulerSunset
};
const updateAgentPreferences = (currentlyEnabled, key, newValue) => __async(void 0, null, function* () {
  return apiPatch(LinkHelper.normalize(ApiUrls.myAgentPreferences), {
    redesigns_enabled: __spreadProps(__spreadValues({}, currentlyEnabled), { [key]: newValue })
  });
});
const getKeys = () => {
  switch (AuthUtils.getActiveOrgType()) {
    case "m":
      return ManagerPageRedesignKeys;
    default:
      return [];
  }
};
const pageRedesignToggleStore = create((set, get) => ({
  enabled: getKeys().reduce((acc, key) => {
    var _a;
    if (key in window.PM.redesigns_enabled) {
      acc[key] = !!((_a = window.PM.redesigns_enabled) == null ? void 0 : _a[key]);
    }
    return acc;
  }, {}),
  toggleValue: (key) => __async(void 0, null, function* () {
    const currentState = get().enabled[key];
    set((state) => {
      return __spreadProps(__spreadValues({}, state), {
        enabled: __spreadProps(__spreadValues({}, state.enabled), { [key]: !currentState })
      });
    });
    if (AuthUtils.getActiveOrgType() === "m") {
      yield updateAgentPreferences(get().enabled, key, !currentState);
    }
    location.reload();
  })
}));
const isPageRedesignToggleEnabled = (key) => {
  if (SunsetFeatureFlagFunctions[key]()) {
    return true;
  }
  return pageRedesignToggleStore.getState().enabled[key];
};
const useTogglePageRedesign = () => pageRedesignToggleStore((state) => state.toggleValue);
const ToggleTextDisabled = ({ text }) => {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiText, { color: colors.neutrals.gray700, size: "s" }, text)), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement("span", { role: "img", "aria-label": "sheep" }, "\u{1F389}")));
};
const ToggleTextEnabled = ({ text }) => {
  return /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiText, { color: colors.neutrals.gray700, size: "s" }, text));
};
const PageRedesignToggle = ({
  redesignKey,
  toggleEnabledText,
  toggleDisabledText,
  additionalOnToggle
}) => {
  const enabled = isPageRedesignToggleEnabled(redesignKey);
  const toggle = useTogglePageRedesign();
  const [initiallyEnabled] = useState(enabled);
  const [tempEnabled, setTempEnabled] = useState(enabled);
  const onToggle = () => {
    if (additionalOnToggle) {
      additionalOnToggle(enabled);
    }
    setTempEnabled(!enabled);
    toggle(redesignKey);
  };
  return /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", alignItems: "center", justifyContent: "spaceBetween", gutterSize: "xs", responsive: false }, initiallyEnabled ? /* @__PURE__ */ React.createElement(ToggleTextEnabled, { text: toggleEnabledText }) : /* @__PURE__ */ React.createElement(ToggleTextDisabled, { text: toggleDisabledText }), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
    EuiSwitch,
    {
      label: "toggle-ui-redesign",
      showLabel: false,
      checked: tempEnabled,
      onChange: onToggle,
      compressed: true
    }
  )));
};
export { isPageRedesignToggleEnabled, useTogglePageRedesign, PageRedesignToggle };
