export interface LatLongCoordinates {
  latitude: number;
  longitude: number;
}

const DEFAULT_COORDINATES = { latitude: 0, longitude: 0 };

export const parseWKT = (wkt: string): LatLongCoordinates => {
  if (typeof wkt !== "string") {
    return DEFAULT_COORDINATES;
  }

  const match = wkt.match(/POINT \(([^ ]+) ([^ ]+)\)/);
  const parsedLong = match ? parseFloat(match[1]) : undefined;
  const parsedLat = match ? parseFloat(match[2]) : undefined;

  if (!parsedLong || Number.isNaN(parsedLong) || !parsedLat || Number.isNaN(parsedLat)) {
    return DEFAULT_COORDINATES;
  }

  return { latitude: parsedLat, longitude: parsedLong };
};

// intentional use of any, this function for testing arbitrary data
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isValidCoordinates(coordinatesToTest: any): boolean {
  if (typeof coordinatesToTest !== "object") {
    return false;
  }
  return !isNaN(coordinatesToTest.latitude) && !isNaN(coordinatesToTest.longitude);
}

export const getCoordinatesFromLocation = (location: string) => parseWKT(location);

export const areCoordinatesEqual = (coord1: LatLongCoordinates, coord2: LatLongCoordinates): boolean => {
  return coord1.latitude === coord2.latitude && coord1.longitude === coord2.longitude;
};
