import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiIcon } from "@elastic/eui";
import { colors } from "@pm-frontend/styles";
import { PmText } from "@pm-frontend/shared/components/Text/PmText";
import { IconType } from "react-icons";

type PmIconBadgeProps = {
  type: IconType;
  badgeText: string;
  "data-testid"?: string;
};

/** This component introduces a Icon Badges.
 *
 * It contains an icon and a text next to it
 * with a border.
 */
const PmIconBadge = ({ type, badgeText, "data-testid": dataTestId }: PmIconBadgeProps) => {
  return (
    <EuiFlexGroup
      direction="row"
      gutterSize="s"
      alignItems="center"
      style={{
        border: `1px solid ${colors.brand.meldBlue}`,
        borderRadius: "4px",
        padding: "2px 4px",
        width: "fit-content",
      }}
      data-testid={dataTestId}
      responsive={false}
    >
      <EuiFlexItem grow={false}>
        <EuiIcon type={type} size="m" />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <PmText fontSize="p3" style={{ lineHeight: "18px" }}>
          {badgeText}
        </PmText>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export { PmIconBadge, PmIconBadgeProps };
