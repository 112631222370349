import React from "react";
import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";

import { colors } from "@pm-frontend/styles";
import { PmFilledButton } from "@pm-frontend/shared/components/Buttons/PmFilledButton";
import { useCalendarDraftModeActions, useGetCalendarDraftModePendingActions } from "../stores/draftModeStore";
import { PmEmptyButton } from "@pm-frontend/shared/components/Buttons/PmEmptyButton";
import { PmText } from "@pm-frontend/shared/components/Text/PmText";
import { PmBanner } from "@pm-frontend/shared/components/Banners/PmBanner";

const MeldCalendarDraftModeHeader = () => {
  const { setDraftModeEnabled, toggleModal } = useCalendarDraftModeActions();
  const pendingActions = useGetCalendarDraftModePendingActions();

  const onLeaveClick = () => setDraftModeEnabled(false, true);

  return (
    <div>
      <PmBanner data-testid="calendar-batch-mode-banner" showMultipleBanners={false}>
        <EuiFlexGroup direction="row" responsive={false} justifyContent="spaceBetween" alignItems="center">
          <EuiFlexItem grow={false}>
            <PmText fontWeight="bold" color={colors.brand.white}>
              Draft Mode
            </PmText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup direction="row" responsive={false} alignItems="center" gutterSize="s">
              <EuiFlexItem grow={false}>
                <PmFilledButton text="Leave" onClick={onLeaveClick} isLoading={false} />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <PmEmptyButton
                  text={`Schedule changes${
                    pendingActions.length > 0
                      ? " to " + pendingActions.length + " Meld" + (pendingActions.length > 1 ? "s" : "")
                      : ""
                  }`}
                  onClick={() => toggleModal(true)}
                  data-testid="meld-calendar-draft-mode-banner-confirm-button"
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </PmBanner>
    </div>
  );
};
export { MeldCalendarDraftModeHeader };
