import classNames from "classnames";
import Cog from "./svg/cog-svg";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import PureRenderMixin from "react-addons-pure-render-mixin";
import React from "react";
import WindowClickedMixin from "./window-clicked-mixin";

const ActionDropdown = createReactClass({
  mixins: [WindowClickedMixin, PureRenderMixin],

  propTypes: {
    id: PropTypes.string,
    toggleButton: PropTypes.object,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    orientLeft: PropTypes.bool,
  },

  getInitialState() {
    return {
      isMenuOpen: false,
    };
  },

  getDefaultProps() {
    return {
      id: "action-dropdown",
      containerPositionClass: "center-container",
    };
  },

  render() {
    const { toggleButton } = this.props;

    const dropdownClasses = classNames({
      "dropdown-container": true,
      hidden: this.props.enabled === false,
      light: this.props.light,
    });
    const menuClasses = classNames({
      menu: true,
      active: this.state.isMenuOpen,
      "centered-nub": this.props.containerPositionClass === "center-container",
    });
    const toggleClasses = classNames({
      "action-toggle": true,
      "default-toggle": !toggleButton,
    });
    const dropdownContentContainerClasses = classNames({
      "dropdown-content-container": true,
      "drop-up": this.props.dropUp,
      [this.props.containerPositionClass]: true,
    });
    return (
      <div
        id={this.props.id}
        data-test="action_dropdown.action.open"
        data-testid="action_dropdown.action.open"
        className={dropdownClasses}
        onClick={this.onDropdownClicked}
      >
        <div className={toggleClasses}>{toggleButton || <Cog />}</div>
        <div className={dropdownContentContainerClasses}>
          <ul className={menuClasses}>{this.state.isMenuOpen && this.props.children}</ul>
        </div>
      </div>
    );
  },

  onDropdownClicked(e: React.MouseEvent<any>) {
    e.preventDefault();

    /* Since `this.handleWindowClicked` is called right after this method, even though we could be opening
        the menu, set an instance variables to prevent the menu from being closed immediately after opening.
         */
    this.dropdownJustClicked = true;

    this.setState({ isMenuOpen: !this.state.isMenuOpen });

    if (!this.state.isMenuOpen && this.props.onOpen) {
      this.props.onOpen();
    }
  },

  handleWindowClicked() {
    if (this.state.isMenuOpen && !this.dropdownJustClicked) {
      if (this.props.onClose) {
        this.props.onClose();
      }
      this.setState({ isMenuOpen: false });
    }

    this.dropdownJustClicked = false;
  },

  closeDropdown() {
    this.setState({ isMenuOpen: false });
  },
});

export default ActionDropdown;
