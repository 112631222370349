import { CSSObject } from "@emotion/react";
import { Moment } from "moment";
import { uuid } from "uuidv4";

import { BorderRadius, colors } from "@pm-frontend/styles";
import { MeldCalendarModalState } from "../MeldCalendar";
import { getAssignedMaint } from "@pm-frontend/shared/utils/assignment-utils";
import { SchedulableMeldDetailViewSerializer } from "@pm-frontend/shared/types/api/meld/serializers/scheduable_meld_detail_view_serializer";
import {
  hasExcludedPropertyGroup,
  isAgentInMeldPropertyGroups,
  isClosed,
  isEstimateMeld,
  isMeldSchedulable,
  getIsMeldScheduled,
} from "@pm-frontend/shared/utils/meld-utils";
import Features from "@pm-assets/js/common/feature-flags";
import { CalendarPaneState } from "./hooks";
import { MeldStatus } from "@pm-frontend/shared/types/meld";
import { ManagementAgentSerializer } from "@pm-frontend/shared/types/api/manager/serializers/serializers";
import { VendorFilterSerializer } from "@pm-frontend/shared/types/api/vendor/serializers/serializers";
import { isValidCoordinates, LatLongCoordinates } from "@pm-frontend/shared/utils/location-utils";
import URL from "@pm-shared/utils/url";
import { getExistingAppointmentAndSegment } from "../modals/utils";
import { getCanRescheduleAppointment } from "@pm-frontend/shared/utils/appointment-utils";
import {
  CalendarDraftPendingActions,
  getCalendarDraftModeActions,
  getCalendarDraftModeEnabled,
} from "../stores/draftModeStore";

export const DEFAULT_DRAG_TARGET_PLACEHOLDER_SIZE_IN_MIN = 120;

const HOVERING_CLICK_CLASS_NAME = "calendar-click-hovering-over";
const DRAGGING_CLASS_NAME = "calendar-dragging-over";
export const cellDragClickCss: CSSObject = {
  ["&." + DRAGGING_CLASS_NAME]: {
    border: `3px solid ${colors.brand.meldBlue}`,
    borderRadius: BorderRadius,
  },
  ["&." + HOVERING_CLICK_CLASS_NAME]: {
    border: `3px solid ${colors.brand.meldBlue}`,
    borderRadius: BorderRadius,
  },
};

// --------------------------------------------
// |                                          |
// |          Drag and drop utils             |
// |                                          |
// --------------------------------------------
const dragImage = new Image();
dragImage.src = URL.getStatic("img/hover_object_with_opacity.svg");
dragImage.style.zIndex = "100";
// based on the hardcoded image
const DRAG_IMAGE_X_OFFSET = 15;
const DRAG_IMAGE_Y_OFFSET = 40;

interface GetCalendarEventOnDragStartProps {
  meld: { id: number };
  coordinates: LatLongCoordinates | undefined;
  additionalCallback: (id: number, subType: CalendarDraggingEventType) => void;
  appointmentId: number | null;
  type: CalendarDraggingEventType;
  personaType: "agent" | "vendor" | null;
  personaIds: number[] | null;
  draftAction?: CalendarDraftPendingActions | null;
}

// Meld events map correctly here
// Alternative Events anf Google Events will map to:
// {
//   meld: altEvent.id or googleEvent.id
//   appointmentId: Event.id
// }
export const getCalendarEventOnDragStart =
  ({
    meld,
    coordinates,
    additionalCallback,
    appointmentId,
    type,
    personaType,
    personaIds,
    draftAction = null,
  }: GetCalendarEventOnDragStartProps) =>
  (event: React.DragEvent) => {
    event.dataTransfer.setDragImage(dragImage, DRAG_IMAGE_X_OFFSET, DRAG_IMAGE_Y_OFFSET);
    additionalCallback(meld.id, type);
    // data stored in `dataTransfer` is only accessible in `onDrop`, but we need it in `onDragEnter`
    // to show the `FloatingInfo`. We store the serialized data in the `type`, which is accessible in
    // 'onDragEnter' - just make sure not to put sensitive data here
    event.dataTransfer.setData(
      getCalendarDraggableData(
        meld,
        coordinates,
        appointmentId,
        type,
        personaType,
        personaIds,
        draftAction?.uuid || null
      ),
      ""
    );
  };

export const calendarEventOnDragOver = (event: React.DragEvent) => {
  event.preventDefault();
};

export const calendarEventOnDragEnter = (event: React.DragEvent) => {
  const draggableData = getCalendarMeldFromDraggableData(event);
  event.currentTarget.classList.add(DRAGGING_CLASS_NAME);
  return draggableData;
};

export const calendarEventOnDragLeave = (event: React.DragEvent) =>
  event.currentTarget.classList.remove(DRAGGING_CLASS_NAME);

export const getCalendarEventOnDrop = (
  modalState: MeldCalendarModalState,
  dropTargetType: "vendor" | "agent" | "cancelAppointment",
  dropTargetId: number,
  startTime: Moment,
  additionalCallback: () => void
) => {
  return (event: React.DragEvent) => {
    const draftModeEnabled = getCalendarDraftModeEnabled();
    const draggableData = getCalendarMeldFromDraggableData(event);
    if (
      dropTargetType === "cancelAppointment" &&
      draggableData.meldid &&
      draggableData.appointmentid &&
      draggableData.type === "meld" &&
      !!draggableData.personaids
    ) {
      if (draftModeEnabled) {
        if (draggableData.draftactionuuid) {
          getCalendarDraftModeActions().removePendingAction(draggableData.draftactionuuid);
        } else if (draggableData.personatype !== null) {
          getCalendarDraftModeActions().addPendingAction({
            type: "cancelAppointment",
            // don't have date data at this juncture
            date: null,
            meldId: draggableData.meldid,
            appointmentId: draggableData.appointmentid,
            personaType: draggableData.personatype,
            personaIds: draggableData.personaids,
          });
        }
      } else {
        modalState.open({
          type: "cancelAppointment",
          meldId: draggableData.meldid,
          appointmentId: draggableData.appointmentid,
        });
      }
    } else if (draggableData.meldid && dropTargetType === "agent") {
      if (
        (draggableData.type === DraggingEvent.recurringaltevent || draggableData.type === DraggingEvent.altevent) &&
        draggableData.appointmentid
      ) {
        modalState.open({
          type: "updateEvent",
          altEvent: { id: draggableData.meldid },
          event: draggableData.appointmentid,
          targetAgentId: dropTargetId,
          startTime,
        });
      } else if (draggableData.type === DraggingEvent.meld) {
        modalState.open({
          meld: { id: draggableData.meldid },
          type: "meldDroppedOnAgent",
          targetAgentId: dropTargetId,
          startTime,
          appointmentId: draggableData.appointmentid,
          sourceType: "draganddrop",
        });
      }
    } else if (draggableData.meldid && dropTargetType === "vendor") {
      modalState.open({
        meld: { id: draggableData.meldid },
        type: "meldDroppedOnVendor",
        targetVendorId: dropTargetId,
        startTime,
        appointmentId: draggableData.appointmentid,
        sourceType: "draganddrop",
      });
    }
    event.currentTarget.classList.remove(DRAGGING_CLASS_NAME);
    additionalCallback();
  };
};

const getCalendarDraggableData = (
  meld: { id: number },
  coordinates: LatLongCoordinates | undefined,
  appointmentId: number | null,
  type: CalendarDraggingEventType,
  personaType: "agent" | "vendor" | null,
  personaIds: number[] | null,
  draftActionUuid: string | null
): string => {
  const data = {
    meldId: meld.id,
    coordinates,
    appointmentId,
    type,
    personaType,
    personaIds,
    draftActionUuid,
  };
  return JSON.stringify(data);
};

const getCalendarMeldFromDraggableData = (
  event: React.DragEvent
): {
  // these have to be all lowercase due to storing the data in `event.dataTransfer.types`
  meldid: number | null;
  coordinates: LatLongCoordinates | undefined;
  appointmentid: number | null;
  type: CalendarDraggingEventType;
  personatype: "agent" | "vendor" | null;
  personaids: number[] | null;
  draftactionuuid: string | null;
} => {
  const result: ReturnType<typeof getCalendarMeldFromDraggableData> = {
    meldid: null,
    appointmentid: null,
    coordinates: undefined,
    type: "meld",
    personatype: null,
    personaids: null,
    draftactionuuid: null,
  };
  try {
    const draggableData = event.dataTransfer.types[0] || "";
    const parsed = JSON.parse(draggableData);
    if (parsed.meldid) {
      const parsedMeldId = parseInt(parsed.meldid, 10);
      if (!isNaN(parsedMeldId)) {
        result.meldid = parsedMeldId;
      }
    }
    if (parsed.appointmentid) {
      const parsedAppointmentId = parseInt(parsed.appointmentid, 10);
      if (!isNaN(parsedAppointmentId)) {
        result.appointmentid = parsedAppointmentId;
      }
    }
    if (parsed.personatype === "agent" || parsed.personatype === "vendor") {
      result.personatype = parsed.personatype;
    }
    if (parsed.personaids && Array.isArray(parsed.personaids)) {
      result.personaids = parsed.personaids;
    }
    if (parsed.type) {
      result.type = parsed.type;
    }
    if (isValidCoordinates(parsed.coordinates)) {
      result.coordinates = parsed.coordinates;
    }
    if (parsed.draftactionuuid) {
      result.draftactionuuid = parsed.draftactionuuid;
    }
  } catch {
    // JSON.parse failed, just return null result
  }
  return result;
};

// These have to be all lowercase due to the serialization with dataTransfer.setData
export type CalendarDraggingEventType = "meld" | "altevent" | "recurringaltevent";
export const DraggingEvent: Record<CalendarDraggingEventType, CalendarDraggingEventType> = {
  meld: "meld",
  altevent: "altevent",
  recurringaltevent: "recurringaltevent",
};

type InvalidClickDragActionType =
  | "noop-meld-closed"
  | "noop-pending-estimates"
  | "noop-vendor-must-accept"
  | "noop-wrong-property-groups"
  | "noop-vendor-assignment-prohibited"
  | "noop";

type DragClickActionType =
  | InvalidClickDragActionType
  | "assignAndScheduleMeld"
  | "reassignAndScheduleMeld"
  | "reassignAndRescheduleMeld"
  | "reassignAndAddAppointmentMeld"
  | "assignMeld"
  | "reassignMeld"
  | "scheduleMeld"
  | "rescheduleMeld"
  | "addAppointment"
  | "rescheduleOrAddAppointmentMeld";

type GetCalendarDragClickActionTypeArgs = {
  meld: SchedulableMeldDetailViewSerializer;
  existingAppointment: ReturnType<typeof getExistingAppointmentAndSegment>["existingAppointment"];
} & (
  | {
      assigneeType: "agent";
      agent: ManagementAgentSerializer;
    }
  | {
      assigneeType: "vendor";
      vendor: VendorFilterSerializer;
    }
);

/**
 * passed to confirm modals via the `onDragEnd` handler in calendar `Droppables`
 */
export const getCalendarDragClickActionType = (props: GetCalendarDragClickActionTypeArgs): DragClickActionType => {
  if (props.assigneeType === "vendor" && hasExcludedPropertyGroup(props.meld, props.vendor)) {
    return "noop-wrong-property-groups";
  } else if (props.assigneeType === "agent" && !isAgentInMeldPropertyGroups(props.meld, props.agent)) {
    return "noop-wrong-property-groups";
  }

  if (props.assigneeType === "vendor" && props.vendor.vendor_managements.some((vm) => !vm.allow_assignments)) {
    return "noop-vendor-assignment-prohibited";
  }

  if (props.meld.status === MeldStatus.PENDING_ESTIMATES) {
    return "noop-pending-estimates";
  }
  if (isClosed(props.meld)) {
    return "noop-meld-closed";
  }
  if (props.meld.status === MeldStatus.PENDING_ASSIGNMENT) {
    if (props.assigneeType === "agent") {
      return "assignAndScheduleMeld";
    } else if (props.assigneeType === "vendor") {
      // vendors must accept before scheduling
      return "assignMeld";
    }
    return "noop";
  }

  const assignedMaintenance = getAssignedMaint(props.meld);
  const meldIsScheduled = getIsMeldScheduled(props.meld);

  const targetMatchesCurrentAssignee =
    (assignedMaintenance?.type === "ManagementAgent" &&
      props.assigneeType === "agent" &&
      assignedMaintenance.in_house_servicers.some((agent) => agent.id === props.agent.id)) ||
    (assignedMaintenance?.type === "Vendor" &&
      props.assigneeType === "vendor" &&
      assignedMaintenance.vendor.id === props.vendor.id);

  if (isEstimateMeld(props.meld)) {
    // multi-appts not yet implemented on estimates
    if (meldIsScheduled) {
      return "rescheduleMeld";
    } else {
      return "scheduleMeld";
    }
  } else if (!isMeldSchedulable(props.meld)) {
    if (props.meld.status === MeldStatus.PENDING_VENDOR) {
      if (targetMatchesCurrentAssignee && props.assigneeType === "vendor") {
        return "noop-vendor-must-accept";
      }
      if (!targetMatchesCurrentAssignee) {
        if (props.assigneeType === "vendor") {
          return "reassignMeld";
        } else if (props.assigneeType === "agent") {
          return "reassignAndScheduleMeld";
        }
      }
    }
  } else if (!meldIsScheduled) {
    if (targetMatchesCurrentAssignee) {
      return "scheduleMeld";
    } else {
      if (props.assigneeType === "agent") {
        return "reassignAndScheduleMeld";
      } else if (props.assigneeType === "vendor") {
        // vendors must accept before they can be scheduled
        return "reassignMeld";
      }
    }
  } else if (meldIsScheduled) {
    if (targetMatchesCurrentAssignee) {
      if (props.existingAppointment) {
        if (Features.isMultipleAppointmentsEnabled()) {
          return "rescheduleOrAddAppointmentMeld";
        } else {
          return "rescheduleMeld";
        }
      } else {
        return "addAppointment";
      }
    } else {
      if (props.assigneeType === "agent") {
        if (props.existingAppointment === "many") {
          return "reassignAndRescheduleMeld";
        } else if (props.existingAppointment && getCanRescheduleAppointment(props.existingAppointment)) {
          return "reassignAndRescheduleMeld";
        } else {
          return "reassignAndAddAppointmentMeld";
        }
      } else if (props.assigneeType === "vendor") {
        // vendors must accept before they can be scheduled
        return "reassignMeld";
      }
    }
  }
  return "noop";
};

// --------------------------------------------
// |                                          |
// |          Click-to-schedule utils         |
// |                                          |
// --------------------------------------------

export const calendarCellonEnterClick = (event: React.MouseEvent) =>
  event.currentTarget.classList.add(HOVERING_CLICK_CLASS_NAME);

export const calendarCellOnLeaveClick = (event: React.MouseEvent) =>
  event.currentTarget.classList.remove(HOVERING_CLICK_CLASS_NAME);

export const CALENDAR_PENDING_AVAILABILITY_EVENT_NAME = "calendarAddAvailabilityEvent";
export const CALENDAR_SET_ALT_EVENT_NAME = "calendarSetAlternativeEvent";

export type CalendarPendingAvailabilityEventDetail = {
  // used when editing events which have not yet been submitted
  tempId: string;
} & (
  | {
      type: "addPendingAvailability";
      startTime: Moment;
      durationInMin: number;
    }
  | {
      type: "removePendingAvailability";
    }
);

export interface CalendarSetAlternativeEvent {
  type: "setPendingAlternativeEvent";
  startTime: Moment;
  durationInMin: number;
}

export const getCalendarCellOnClickClick = (
  modalState: MeldCalendarModalState,
  meld: { id: number } | undefined,
  personaType: "agent" | "vendor",
  personaId: number,
  startTime: Moment,
  type: "addingAvailabilities" | "scheduling" | "setPendingAlternativeEvent",
  additionalCallback?: () => void
): React.MouseEventHandler => {
  return (event: React.MouseEvent) => {
    additionalCallback?.();
    event.stopPropagation();
    const draftModeEnabled = getCalendarDraftModeEnabled();
    if (type === "addingAvailabilities") {
      if (draftModeEnabled) {
        // TODO: batch action
        return null;
      } else {
        // clicking on the calendar with the availability pane open fires this event,
        // and we use an event listener on the right pane to catch this custom event
        // and add events to the form and calendar
        const addAvailabilityEvent = new CustomEvent<CalendarPendingAvailabilityEventDetail>(
          CALENDAR_PENDING_AVAILABILITY_EVENT_NAME,
          {
            detail: {
              type: "addPendingAvailability",
              tempId: uuid(),
              startTime,
              durationInMin: 120,
            },
          }
        );
        window.dispatchEvent(addAvailabilityEvent);
      }
    } else if (type === "setPendingAlternativeEvent") {
      if (draftModeEnabled) {
        // TODO: batch action
        return null;
      } else {
        // clicking on the calendar with the alternative event pane open fires this event,
        // and we use an event listener on the right pane to catch this custom event
        // and add events to the form and calendar
        const setAltEventEvent = new CustomEvent<CalendarSetAlternativeEvent>(CALENDAR_SET_ALT_EVENT_NAME, {
          detail: {
            type: "setPendingAlternativeEvent",
            startTime,
            durationInMin: 120,
          },
        });
        window.dispatchEvent(setAltEventEvent);
      }
    } else if (meld && type === "scheduling") {
      if (personaType === "agent") {
        modalState.open({
          type: "meldDroppedOnAgent",
          targetAgentId: personaId,
          meld,
          appointmentId: null,
          startTime,
          sourceType: "click",
        });
      } else {
        modalState.open({
          type: "meldDroppedOnVendor",
          targetVendorId: personaId,
          appointmentId: null,
          meld,
          startTime,
          sourceType: "click",
        });
      }
    }
    return null;
  };
};

export const getIsClickSchedulingEnabledForRightpane = (
  rightPaneState: CalendarPaneState,
  activeRightpaneMeld: SchedulableMeldDetailViewSerializer | undefined,
  currentlyActiveAgents: Array<{ id: number }>,
  currentAgentId: number | null
): boolean => {
  if (rightPaneState.type === "offerAvailabilities") {
    // offering availabilities on behalf of vendors is not currently supported
    return !!currentAgentId && currentlyActiveAgents.some((agent) => agent.id === currentAgentId);
  } else if (rightPaneState.type === "alternativeEvent") {
    return true;
  } else if (activeRightpaneMeld) {
    return true;
  }
  return false;
};

export const getClickSchedulingType = (
  rightPaneState: CalendarPaneState
): "addingAvailabilities" | "scheduling" | "setPendingAlternativeEvent" => {
  switch (rightPaneState.type) {
    case "offerAvailabilities":
      return "addingAvailabilities";
    case "alternativeEvent":
      return "setPendingAlternativeEvent";
    default:
      return "scheduling";
  }
};
