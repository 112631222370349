import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

import { apiDelete, apiFetch, apiPatch, apiPost } from "@pm-frontend/shared/utils/apiFetch";
import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";
import { ApiUrls } from "@pm-frontend/shared/utils/api-urls";
import { MeldFilterGroupListViewSerializer } from "@pm-frontend/shared/types/api/saved_filters/serializers/meld_filter_group_list_view_serializer";
import { meldKeys } from "../queries";
import { MeldListViewSerializer } from "@pm-frontend/shared/types/api/meld/serializers/meld_list_view_serializers";
import { toastMessages, useAddToast } from "@pm-frontend/shared/store/toast";
import { getFilterPropertiesFromQueryParams } from "@pm-frontend/shared/utils/saved-filter-utils";

const MELD_LIST_PAGE_SIZE = 60;

const meldFilterGroupKeys = {
  all: ["meld-filter-groups"],
  editFilter: () => [...meldFilterGroupKeys.all, "edit"],
} as const;

const useGetMeldFilterGroups = () => {
  return useQuery<MeldFilterGroupListViewSerializer>({
    queryKey: meldFilterGroupKeys.all,
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.meldFilterGroupList)),
  });
};

interface MeldListPayload {
  count: 0;
  prev: string | null;
  next: string | null;
  results: MeldListViewSerializer[];
}

const useGetMeldsList = ({ savedFilterId }: { savedFilterId: string | null }) => {
  const location = useLocation();

  // the active page is calculated from the limit and offset query params
  const searchParams = new URLSearchParams(location.search);
  const limitParamValue = searchParams.get("limit");
  let limitValue = MELD_LIST_PAGE_SIZE;
  if (limitParamValue) {
    try {
      limitValue = parseInt(limitParamValue, 10);
    } catch {
      // use default
    }
  }
  const offsetParamValue = searchParams.get("offset");
  let offsetValue = 0;
  if (offsetParamValue) {
    try {
      offsetValue = parseInt(offsetParamValue, 10);
    } catch {
      // use default
    }
  }

  const pageIndex = Math.floor(offsetValue / limitValue);

  const paginationParams = `limit=${limitValue}&offset=${pageIndex * limitValue}`;
  const filterParams = savedFilterId ? `?saved_filter=${savedFilterId}` : location.search.toString();

  const formattedParams = (filterParams ? `${filterParams}&` : "?") + paginationParams;

  return {
    ...useQuery<MeldListPayload>({
      queryKey: meldKeys.list(formattedParams),
      queryFn: () => apiFetch(LinkHelper.normalize(`/api/melds/${formattedParams}`)),
    }),
    pageIndex,
    limitValue,
  };
};

const useDeleteMeldFilter = ({ id }: { id: number }) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => apiDelete(LinkHelper.normalize(`${ApiUrls.meldFilterGroupList}${id}`)),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldListSavedFilterDeleteSuccess,
        color: "success",
      });
      return queryClient.invalidateQueries(meldFilterGroupKeys.all);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldListSavedFilterDeleteError,
        color: "danger",
      });
    },
  });
};

interface MeldFilterEditFormData {
  current_agent_default: boolean;
  name: string;
  private: boolean;
}

const useEditMeldFilter = ({ id }: { id: number }) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: meldFilterGroupKeys.editFilter(),
    mutationFn: (data: MeldFilterEditFormData) =>
      apiPatch(LinkHelper.normalize(ApiUrls.meldFilterGroupDetails(id)), data),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldListSavedFilterEditSuccess,
        color: "success",
      });
      return queryClient.invalidateQueries(meldFilterGroupKeys.all);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldListSavedFilterEditError,
        color: "danger",
      });
    },
  });
};

interface MeldListSaveFilterData {
  current_agent_default: boolean;
  private: boolean;
  name: string;
}

const useUpdateMeldFilter = () => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();
  const location = useLocation();

  const paramData = getFilterPropertiesFromQueryParams(location);

  return useMutation({
    mutationFn: (id: string) => apiPatch(LinkHelper.normalize(ApiUrls.meldFilterGroupFilters(id)), paramData),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldListUpdateFilterSuccess,
        color: "success",
      });
      return Promise.all([
        queryClient.invalidateQueries(meldFilterGroupKeys.all),
        queryClient.invalidateQueries(meldKeys.all),
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldListUpdateFilterFailure,
        color: "danger",
      });
    },
  });
};

interface MeldListSaveFilterData {
  current_agent_default: boolean;
  private: boolean;
  name: string;
}

const useSaveNewMeldFilter = () => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();
  const location = useLocation();

  const paramData = getFilterPropertiesFromQueryParams(location);

  return useMutation({
    mutationFn: (data: MeldListSaveFilterData) =>
      apiPost(LinkHelper.normalize(ApiUrls.meldFilterGroupList), { ...data, ...paramData }),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldListSaveNewFilterSuccess,
        color: "success",
      });
      return queryClient.invalidateQueries(meldFilterGroupKeys.all);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldListSaveNewFilterFailure,
        color: "danger",
      });
    },
  });
};

const useReorderMeldFilter = () => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: { id: string; position: number }) =>
      apiPatch(LinkHelper.normalize(ApiUrls.meldFilterGroupReorder(data.id)), { position: data.position }),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldListSavedFilterReorderSuccess,
        color: "success",
      });
      return queryClient.removeQueries(meldFilterGroupKeys.all);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldListSavedFilterReorderError,
        color: "danger",
      });
    },
  });
};

const useExportMeldListCSV = () => {
  const addToast = useAddToast();
  const location = useLocation();

  return useMutation({
    mutationFn: () => apiFetch(`${LinkHelper.normalize(ApiUrls.createMeldExportCSV)}/${location.search.toString()}`),
    onSuccess: () =>
      addToast({
        text: toastMessages.meldListExportCSVSuccess,
        color: "success",
      }),
    onError: () =>
      addToast({
        text: toastMessages.meldListExportCSVFailure,
        color: "danger",
      }),
  });
};

export {
  useGetMeldFilterGroups,
  useGetMeldsList,
  useDeleteMeldFilter,
  useEditMeldFilter,
  MeldFilterEditFormData,
  MeldListSaveFilterData,
  useSaveNewMeldFilter,
  useUpdateMeldFilter,
  useReorderMeldFilter,
  useExportMeldListCSV,
  meldFilterGroupKeys,
};
