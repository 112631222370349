import { create } from "zustand";

import { AuthUtils } from "@pm-frontend/shared/utils/auth-utils";
import { CalendarMapMarkerType } from "../maps/utils";
import { StorageUtils } from "@pm-frontend/shared/utils/storage-utils";

const HIDE_NEARBY_MELD_LOCAL_STORAGE_KEY = `meld-calendar-hide-nearby-melds-m-${AuthUtils.getUserId()}`;
const HIDE_NEARBY_MAP_ROW_MELD_LOCAL_STORAGE_KEY = `meld-calendar-hide-map-row-nearby-melds-m-${AuthUtils.getUserId()}`;

const getInitialHideNearbyMeldsState = (key: string): boolean => {
  const defaultValue = false;
  const { ok, value } = StorageUtils.getLocalStorageItem(key);

  if (!ok) {
    return defaultValue;
  } else if (value === "true") {
    return true;
  } else {
    return defaultValue;
  }
};

const setInitialHideNearbyMeldsState = (key: string, state: boolean) => {
  StorageUtils.setLocalStorageItem(key, state);
};

interface CalendarMapState {
  hideLargeNearbyMelds: boolean;
  hideMapRowNearbyMelds: boolean;
  // passing this via url is such a pain we keep it in state
  mapEventListRightpaneItems: CalendarMapMarkerType[];
  actions: {
    setMapEventListRightpaneItems: (items: CalendarMapMarkerType[]) => void;
    setHideLargeNearbyMelds: (hide: boolean) => void;
    setHideMapRowNearbyMelds: (hide: boolean) => void;
    resetState: VoidFunction;
  };
}

const useCalendarMapStateStore = create<CalendarMapState>((set) => ({
  hideLargeNearbyMelds: getInitialHideNearbyMeldsState(HIDE_NEARBY_MELD_LOCAL_STORAGE_KEY),
  hideMapRowNearbyMelds: getInitialHideNearbyMeldsState(HIDE_NEARBY_MAP_ROW_MELD_LOCAL_STORAGE_KEY),
  calendarOrMap: "calendar",
  mapEventListRightpaneItems: [],
  actions: {
    setMapEventListRightpaneItems: (items) => set({ mapEventListRightpaneItems: items }),
    setHideLargeNearbyMelds: (newValue) => {
      setInitialHideNearbyMeldsState(HIDE_NEARBY_MELD_LOCAL_STORAGE_KEY, newValue);
      set({ hideLargeNearbyMelds: newValue });
    },
    setHideMapRowNearbyMelds: (newValue) => {
      setInitialHideNearbyMeldsState(HIDE_NEARBY_MAP_ROW_MELD_LOCAL_STORAGE_KEY, newValue);
      set({ hideMapRowNearbyMelds: newValue });
    },
    resetState: () => set({ mapEventListRightpaneItems: [] }),
  },
}));

const useCalendarStateMapEventListRightpaneItems = () =>
  useCalendarMapStateStore((state) => state.mapEventListRightpaneItems);

const useCalendarStateHideLargeNearbyMelds = () => useCalendarMapStateStore((state) => state.hideLargeNearbyMelds);
const useCalendarStateHideMapRowNearbyMelds = () => useCalendarMapStateStore((state) => state.hideMapRowNearbyMelds);

const useCalendarMapStateActions = () => useCalendarMapStateStore((state) => state.actions);

export {
  useCalendarStateMapEventListRightpaneItems,
  useCalendarStateHideLargeNearbyMelds,
  useCalendarStateHideMapRowNearbyMelds,
  useCalendarMapStateActions,
  useCalendarMapStateStore,
};
