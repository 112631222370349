var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React from "react";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiText
} from "@elastic/eui";
import { colors } from "@pm-frontend/styles";
import { PmEmptyButton } from "../Buttons/PmEmptyButton";
import { PmFilledButton } from "../Buttons/PmFilledButton";
const parseString = (str) => {
  return /* @__PURE__ */ React.createElement(EuiText, { style: { color: colors.neutrals.gray800, fontSize: "16px", padding: "0px" } }, str);
};
const parseBody = (body) => {
  if (!body) {
    return;
  } else if (typeof body === "string") {
    return parseString(body);
  } else if (Array.isArray(body)) {
    const result = [];
    body.forEach((ele, index) => {
      if (typeof ele === "string") {
        result.push(parseString(ele));
      } else {
        result.push(ele);
      }
      if (index < body.length - 1) {
        result.push(/* @__PURE__ */ React.createElement("br", null));
      }
    }, []);
    return result;
  } else {
    return body;
  }
};
const PmFormModal = (_a) => {
  var _b = _a, {
    closeModal,
    "data-testid": dataTestId,
    title,
    body,
    form,
    secondaryButtonText = "Cancel",
    altActionButtonProps,
    confirmButtonDataTestId,
    hideSecondaryButton = false,
    maxWidth = "448px"
  } = _b, rest = __objRest(_b, [
    "closeModal",
    "data-testid",
    "title",
    "body",
    "form",
    "secondaryButtonText",
    "altActionButtonProps",
    "confirmButtonDataTestId",
    "hideSecondaryButton",
    "maxWidth"
  ]);
  let bodyContent = body;
  if (typeof body === "string") {
    bodyContent = /* @__PURE__ */ React.createElement(EuiText, { style: { color: colors.neutrals.gray800, fontSize: "16px", padding: "0px" } }, parseBody(body));
  }
  const primaryButtonProps = rest.primaryButtonProps ? rest.primaryButtonProps : {
    formId: rest.formId,
    isLoading: rest.primaryButtonLoading,
    text: rest.primaryButtonText,
    color: rest.primaryButtonColor,
    "data-testid": confirmButtonDataTestId
  };
  return /* @__PURE__ */ React.createElement(
    EuiModal,
    {
      className: "pmFormModal",
      onClose: closeModal,
      style: { padding: "24px", gap: "16px", position: "relative", marginTop: "22%" },
      maxWidth,
      "data-testid": dataTestId
    },
    title ? /* @__PURE__ */ React.createElement(EuiModalHeader, { style: { padding: "0px" } }, /* @__PURE__ */ React.createElement(EuiText, { color: colors.neutrals.gray900, style: { fontSize: "24px", fontWeight: "700" } }, title)) : null,
    /* @__PURE__ */ React.createElement(EuiModalBody, { style: { padding: "0px" } }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", style: { gap: "16px" } }, bodyContent ? /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: true }, bodyContent) : null, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: true }, form))),
    /* @__PURE__ */ React.createElement(EuiModalFooter, { style: { padding: "0px" } }, /* @__PURE__ */ React.createElement(
      EuiFlexGroup,
      {
        direction: "row",
        alignItems: "flexStart",
        style: { gap: "16px", backgroundColor: colors.brand.white }
      },
      /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(PmFilledButton, __spreadValues({}, primaryButtonProps))),
      altActionButtonProps && /* @__PURE__ */ React.createElement(PmEmptyButton, __spreadProps(__spreadValues({}, altActionButtonProps), { color: "primary", hasBorder: true })),
      !hideSecondaryButton && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(PmEmptyButton, { onClick: closeModal, text: secondaryButtonText }))
    ))
  );
};
export { PmFormModal };
