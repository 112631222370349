import React, { useEffect, useState } from "react";
import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText } from "@elastic/eui";
import { PmPageLoading } from "@pm-frontend/shared/components/Loaders/PmPageLoading";

export const EmbeddedAnalytics = ({ embedURL }: { embedURL: string }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showAdBlockerMessage, setShowAdBlockerMessage] = useState(false);

  const handleLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      // Check if the component is still loading after a delay
      if (isLoading) {
        // Show the ad-blocker message
        setShowAdBlockerMessage(true);
        setIsLoading(false);
      }
    }, 20000); // delay is ms

    return () => clearTimeout(loadingTimeout);
  }, [isLoading]);

  return (
    <EuiFlexGroup direction="column">
      {isLoading && <PmPageLoading />}
      {showAdBlockerMessage ? (
        <EuiFlexGroup direction="column" alignItems="center" justifyContent="spaceAround">
          <EuiSpacer />
          <EuiFlexItem grow={false}>
            <EuiText>
              <h2>Please try disabling any ad blockers in order to view Insights!</h2>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty onClick={() => window.location.reload()}>Refresh Page</EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
      ) : (
        <EuiFlexItem>
          <iframe width="100%" height="100%" src={embedURL} onLoad={handleLoad} className="test" />
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  );
};
