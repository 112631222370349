import React from "react";
import { EuiHeaderSectionItem, EuiIcon } from "@elastic/eui";

import URL from "@pm-shared/utils/url";

const SiteSearchLogo = ({
  isOpen,
  setSearchIsOpen,
}: {
  isOpen: boolean;
  setSearchIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <EuiHeaderSectionItem aria-label="Search">
      <EuiIcon
        title="Search"
        type={isOpen ? URL.getStatic("icons/search-header_blue.svg") : URL.getStatic("icons/search-header.svg")}
        size="l"
        onClick={() => setSearchIsOpen((state) => !state)}
        data-testid="header-search-icon"
      />
    </EuiHeaderSectionItem>
  );
};

export { SiteSearchLogo };
