var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { useState, useEffect } from "react";
import { Box, Checkbox, VStack, Text, Button } from "@chakra-ui/react";
const LANGUAGE_CHOICES = [
  { code: "EN", label: "English" },
  { code: "ES", label: "Spanish" },
  { code: "ZH", label: "Chinese" },
  { code: "KO", label: "Korean" },
  { code: "PT", label: "Portuguese" },
  { code: "FR", label: "French" },
  { code: "RU", label: "Russian" },
  { code: "JA", label: "Japanese" }
];
export const LanguageSelector = ({ userDetails, updateLanguageSettings, tenant }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  useEffect(() => {
    if (userDetails && userDetails.default_language) {
      setSelectedLanguage(userDetails.default_language);
    }
  }, [userDetails]);
  const handleCheckboxChange = (languageCode) => {
    setIsChanged(true);
    setSelectedLanguage(languageCode);
  };
  const handleSave = () => {
    if (!userDetails)
      return;
    if (tenant) {
      updateLanguageSettings(selectedLanguage).then(() => {
        setIsChanged(false);
      });
    } else {
      const updatedAgentDetails = __spreadValues({}, userDetails);
      updatedAgentDetails.default_language = selectedLanguage;
      updatedAgentDetails.prompt_for_mobile = false;
      updateLanguageSettings(updatedAgentDetails).then(() => {
        setIsChanged(false);
      });
    }
  };
  return /* @__PURE__ */ React.createElement(VStack, { spacing: 2, alignItems: "left", "data-test": "language-selector" }, /* @__PURE__ */ React.createElement(Text, { fontWeight: "bold", "data-test": "language-label" }, "Preferred Language"), LANGUAGE_CHOICES.map((language) => /* @__PURE__ */ React.createElement(Box, { key: language.code }, /* @__PURE__ */ React.createElement(
    Checkbox,
    {
      isChecked: selectedLanguage === language.code,
      onChange: () => handleCheckboxChange(language.code),
      "data-test": `${language.label.toLowerCase()}-checkbox`
    },
    language.label
  ))), isChanged && userDetails && /* @__PURE__ */ React.createElement(Box, { alignSelf: "flex-end" }, /* @__PURE__ */ React.createElement(Button, { cursor: "pointer", variant: "primary", onClick: handleSave, "data-test": "save-button" }, "Save")), userDetails && userDetails.prompt_for_mobile && /* @__PURE__ */ React.createElement("div", { style: { fontWeight: "bold" } }, "Please make sure to add a mobile number in User settings to be able to use language features."));
};
