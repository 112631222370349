import { create } from "zustand";

import { track, UIRedesignToggleDisabled, UIRedesignToggleEnabled } from "@pm-app/utils/analytics";
import AgentPreferencesApi from "@pm-assets/js/management/apis/agent-preferences-api";
import Features from "@pm-assets/js/common/feature-flags";
import { StorageUtils } from "../utils/storage-utils";

interface RedesignToggleState {
  enabled: boolean;
  toggleValue: () => void;
}

// WARN: if you update this key you need to also update the localStorage call
// in the template below to ensure refiner receives the correct data
// propertymeld/templates/base-js.html
const REDESIGN_TOGGLE_SESSION_STORAGE_KEY = `ui-redesign-enabled-${window.PM.user?.id || "karma-test-fix"}`;

const syncToggleStateWithAPI = async (toggleState: boolean | null) => {
  try {
    if (window.PM.redesign_enabled === undefined) {
      // NOTE: I'm using this value of window.PM.redesign_enabled in errors
      // sent to sentry - if you remove this then also remove its usage in
      // app/utils/ErrorHandler.ts
      window.PM.redesign_enabled = toggleState;
      const agent = (await AgentPreferencesApi.fetchMe()).data;
      if (toggleState !== agent.redesign_enabled) {
        if (toggleState === null || agent.redesign_enabled !== null) {
          // update local storage value from api
          StorageUtils.setLocalStorageItem(REDESIGN_TOGGLE_SESSION_STORAGE_KEY, JSON.stringify(agent.redesign_enabled));
        } else {
          // api value is not set
          // update the api state from localStorage
          await AgentPreferencesApi.updateMe({
            redesign_enabled: toggleState,
          });
        }
        location.reload();
      }
    } else if (toggleState !== window.PM.redesign_enabled) {
      await AgentPreferencesApi.updateMe({
        redesign_enabled: toggleState,
      });
    }
  } catch {
    window.PM.redesign_enabled = undefined;
  }
};

// combining reading and syncing was a mistake
const readToggleStateFromLocalStorageWithoutSyncing = () => {
  const defaultValue = null;
  const { ok, value: rawKey } = StorageUtils.getLocalStorageItem(REDESIGN_TOGGLE_SESSION_STORAGE_KEY);
  if (!ok) {
    return defaultValue;
  } else if (rawKey !== null) {
    return !!JSON.parse(rawKey);
  } else {
    return defaultValue;
  }
};

const readToggleStateFromLocalStorage = () => {
  const toggleState = readToggleStateFromLocalStorageWithoutSyncing();

  // skip promise result, we want to return the toggleState before AgentPreferencesApi ends
  // tslint:disable-next-line
  syncToggleStateWithAPI(toggleState);

  return !!toggleState;
};

const writeToggleStateToLocalStorage = (value: boolean) => {
  const result = StorageUtils.setLocalStorageItem(REDESIGN_TOGGLE_SESSION_STORAGE_KEY, JSON.stringify(value));
  if (result.ok) {
    return syncToggleStateWithAPI(value);
  }
};

const useRedesignToggle = create<RedesignToggleState>((set) => ({
  // WARN: if you update this function you need to also update the localStorage call
  // in the template below to ensure refiner receives the correct data
  // propertymeld/templates/base-js.html

  // if the ui redesign is sunset we skip syncing with the api
  // however this initialization occurs before `Features` is initialized, so we use the bootstrap data
  enabled: window.PM.featureFlagBootstrap?.bootstrapData?.["features.ui-redesign-2023-sunset"]
    ? true
    : readToggleStateFromLocalStorage(),
  toggleValue: () => {
    set((state) => {
      // gets the path with number removed - '_/m/_/properties/_/summary'
      const sanitizedPathName = location.pathname.replace(/[\d]+/g, "_");
      const newState = !state.enabled;
      if (newState) {
        track(UIRedesignToggleEnabled(sanitizedPathName));
      } else {
        track(UIRedesignToggleDisabled(sanitizedPathName));
      }
      writeToggleStateToLocalStorage(newState);
      return {
        enabled: newState,
      };
    });
    location.reload();
  },
}));

/**
 * Since this toggle needs to be used in non-functional components this function
 * reads from session storage
 */
const isRedesignToggleEnabled = () => {
  if (Features.isUIRedesignSunset()) {
    return true;
  }
  // WARN: if you update this function you need to also update the localStorage call
  // in the template below to ensure refiner receives the correct data
  // propertymeld/templates/base-js.html
  return readToggleStateFromLocalStorage();
};

const useGetRedesignIsEnabled = (): boolean => {
  // avoid a react error even if sunset flag is True
  const redesignToggleState = useRedesignToggle((state) => state.enabled);
  if (Features.isUIRedesignSunset()) {
    return true;
  }
  return redesignToggleState;
};
const useToggleRedesignIsEnabled = () => useRedesignToggle((state) => state.toggleValue);

export {
  useGetRedesignIsEnabled,
  useToggleRedesignIsEnabled,
  isRedesignToggleEnabled,
  readToggleStateFromLocalStorageWithoutSyncing,
};
