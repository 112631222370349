var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React, { useState } from "react";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiTextColor,
  EuiContextMenuPanel,
  EuiContextMenuItem,
  EuiPopover,
  EuiBadge,
  EuiHorizontalRule,
  EuiImage,
  EuiTitle,
  EuiIcon,
  EuiButtonIcon,
  EuiLink,
  EuiSpacer
} from "@elastic/eui";
import URL from "@pm-shared/utils/url";
import { getIdFromText } from "@pm-frontend/shared/utils/idFromText";
import { PmBadge } from "@pm-frontend/shared/components/PmBadge";
import { BorderColor, colors } from "@pm-frontend/styles";
import { PmFilledButton } from "../Buttons/PmFilledButton";
import { PmEmptyButton } from "../Buttons/PmEmptyButton";
import { Link } from "react-router-dom";
import { PmIconBadge } from "@pm-frontend/shared/components/PmIconBadge";
const SecondaryActionIcons = {
  GRAY_EXCLAMATION: "grayExclamation"
};
const SecondaryActionIconsSrc = {
  grayExclamation: URL.getStatic("icons/gray_exclamation.svg")
};
const cssPopoverPanel = {
  "&.secondaryActionsPanel": {
    padding: "0px !important",
    ".euiContextMenuPanel": {
      ".euiContextMenuPanelTitle": {
        padding: "16px"
      },
      ".euiContextMenuPanelItem": {
        padding: "10px 12px"
      }
    }
  }
};
const renderMenuItems = (closeMenu, items = []) => {
  return items.map((item) => {
    let linkPath = "";
    if (item.href) {
      linkPath = typeof item.href === "string" ? item.href : item.href.pathname;
    }
    const menuItem = /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", gutterSize: "s", style: { padding: "10px 12px" }, wrap: false }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, style: { padding: "2px", minWidth: "20px" } }, /* @__PURE__ */ React.createElement(
      EuiImage,
      {
        src: item.icon ? SecondaryActionIconsSrc[item.icon] : SecondaryActionIconsSrc.grayExclamation,
        alt: "",
        size: "original"
      }
    )), /* @__PURE__ */ React.createElement(
      EuiFlexItem,
      {
        grow: false,
        style: {
          fontFamily: "Open Sans",
          fontSize: "16px",
          lineHeight: "16px"
        }
      },
      /* @__PURE__ */ React.createElement(EuiTextColor, { color: colors.neutrals.gray800 }, item.text)
    ));
    if (linkPath && item.internalLink || item.linkToProps) {
      return /* @__PURE__ */ React.createElement(
        EuiContextMenuItem,
        {
          key: item.text,
          onClick: () => {
            if (item && item.onClick && typeof item.onClick === "function") {
              item.onClick();
            }
            closeMenu();
          },
          target: "_blank",
          style: {
            padding: "0px",
            background: colors.neutrals.gray50,
            textDecoration: "none"
          },
          "data-testid": item && item.dataTestId ? item.dataTestId : void 0
        },
        /* @__PURE__ */ React.createElement(Link, { to: item.linkToProps || linkPath }, menuItem)
      );
    } else {
      return /* @__PURE__ */ React.createElement(
        EuiContextMenuItem,
        {
          key: item.text,
          onClick: () => {
            if (item && item.onClick && typeof item.onClick === "function") {
              item.onClick();
            }
            closeMenu();
          },
          href: !item.internalLink && item.href ? linkPath : void 0,
          target: "_blank",
          style: {
            padding: "0px",
            background: colors.neutrals.gray50,
            textDecoration: "none"
          },
          "data-testid": item && item.dataTestId ? item.dataTestId : void 0
        },
        menuItem
      );
    }
  });
};
const PageHeader = (_a) => {
  var _b = _a, {
    title,
    subtitle,
    icon,
    linkActions,
    secondaryActions,
    prependBadgeRow,
    badges,
    iconBadges,
    isInactive = false,
    inactiveAction,
    "data-testid": dataTestId,
    primaryButtonLoading,
    extraContentRow,
    bottomTray,
    disableBottomSpace = false,
    disableHorizontalRule = false,
    titleFontSize = "24px"
  } = _b, props = __objRest(_b, [
    "title",
    "subtitle",
    "icon",
    "linkActions",
    "secondaryActions",
    "prependBadgeRow",
    "badges",
    "iconBadges",
    "isInactive",
    "inactiveAction",
    "data-testid",
    "primaryButtonLoading",
    "extraContentRow",
    "bottomTray",
    "disableBottomSpace",
    "disableHorizontalRule",
    "titleFontSize"
  ]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const closeMenu = () => setIsMenuOpen(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const secondaryActionsTitle = /* @__PURE__ */ React.createElement(EuiText, { color: colors.neutrals.gray800, style: { fontSize: "14px", fontWeight: 700, lineHeight: "20px" } }, "Actions");
  const primaryActionEnabled = (
    // eslint-disable-next-line no-prototype-builtins
    props.primaryAction && (!props.primaryAction.hasOwnProperty("enabled") || props.primaryAction.enabled)
  );
  const filteredSecondaryActions = secondaryActions == null ? void 0 : secondaryActions.filter(
    // eslint-disable-next-line no-prototype-builtins
    (action) => !action.hasOwnProperty("enabled") || action.enabled
  );
  const filteredLinkActions = linkActions == null ? void 0 : linkActions.filter((action) => !action.hasOwnProperty("enabled") || action.enabled);
  const isSubtitleSimilar = (titleText, subtitleText) => {
    return subtitleText && (titleText == null ? void 0 : titleText.toLowerCase().includes(subtitleText == null ? void 0 : subtitleText.toLowerCase()));
  };
  let formattedSubtitle;
  if (subtitle && !isSubtitleSimilar(title, subtitle.text)) {
    if (subtitle.href) {
      formattedSubtitle = /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(Link, { to: subtitle.href }, /* @__PURE__ */ React.createElement(EuiLink, null, subtitle.text)));
    } else {
      formattedSubtitle = /* @__PURE__ */ React.createElement(EuiText, { size: "s", "data-testid": "header-subtitle" }, /* @__PURE__ */ React.createElement(EuiTextColor, { color: colors.neutrals.gray700 }, subtitle.text));
    }
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(EuiFlexGroup, { alignItems: "flexStart", justifyContent: "spaceBetween", style: { flexGrow: 0 } }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", gutterSize: "l" }, icon && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, style: { minHeight: "20px" } }, /* @__PURE__ */ React.createElement(EuiIcon, { type: icon })), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { justifyContent: "spaceBetween", direction: "row" }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", gutterSize: "l" }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", gutterSize: "s" }, formattedSubtitle && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, formattedSubtitle), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiText, null, /* @__PURE__ */ React.createElement(EuiTextColor, { color: colors.neutrals.gray900 }, /* @__PURE__ */ React.createElement(EuiTitle, null, /* @__PURE__ */ React.createElement(
    "h2",
    __spreadValues({
      style: { fontSize: titleFontSize }
    }, dataTestId ? { "data-testid": dataTestId } : {}),
    title
  ))))))), isInactive && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { gutterSize: "s" }, /* @__PURE__ */ React.createElement(EuiBadge, { color: colors.neutrals.gray700, "data-testid": "detail-header-inactive-badge" }, /* @__PURE__ */ React.createElement(EuiTextColor, { color: colors.brand.white }, "Inactive")))))), (linkActions || props.primaryAction || secondaryActions || isInactive && inactiveAction) && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", alignItems: "flexStart", gutterSize: "s" }, filteredLinkActions && filteredLinkActions.length > 0 && filteredLinkActions.map((linkAction) => {
    return /* @__PURE__ */ React.createElement(EuiFlexItem, { key: linkAction.text }, /* @__PURE__ */ React.createElement(
      PmEmptyButton,
      __spreadProps(__spreadValues({
        textSize: "14px",
        padding: "5.5px 9.5px"
      }, linkAction), {
        "data-testid": linkAction.dataTestId
      })
    ));
  }), props.primaryAction && primaryActionEnabled && /* @__PURE__ */ React.createElement(EuiFlexItem, null, /* @__PURE__ */ React.createElement(
    PmFilledButton,
    __spreadProps(__spreadValues({}, props.primaryAction), {
      "data-testid": props.primaryAction.dataTestId,
      fontSize: "14px",
      padding: "5.5px 12px",
      isLoading: primaryButtonLoading || false
    })
  )), isInactive && inactiveAction && /* @__PURE__ */ React.createElement(EuiFlexItem, null, /* @__PURE__ */ React.createElement(
    PmEmptyButton,
    {
      textSize: "14px",
      padding: "5.5px 9.5px",
      hasBorder: true,
      onClick: inactiveAction.onClick,
      text: inactiveAction.text,
      "data-testid": inactiveAction.dataTestId
    }
  )), filteredSecondaryActions && filteredSecondaryActions.length > 0 && /* @__PURE__ */ React.createElement(EuiFlexItem, { style: { width: "20px", height: "32px" } }, /* @__PURE__ */ React.createElement(
    EuiPopover,
    {
      "data-testid": "page-header-secondary-actions",
      id: getIdFromText(title),
      button: /* @__PURE__ */ React.createElement(
        EuiButtonIcon,
        {
          size: "s",
          iconType: URL.getStatic("icons/horizontal-dots.svg"),
          iconSize: "original",
          onClick: toggleMenu,
          "aria-label": "Open secondary action menu",
          "data-testid": "secondary-actions-open",
          style: { height: "32px", width: "auto" }
        }
      ),
      isOpen: isMenuOpen,
      closePopover: closeMenu,
      panelPaddingSize: "s",
      anchorPosition: "downRight",
      panelProps: { className: "secondaryActionsPanel", css: cssPopoverPanel },
      repositionOnScroll: true
    },
    /* @__PURE__ */ React.createElement(
      EuiContextMenuPanel,
      {
        title: secondaryActionsTitle,
        size: "s",
        items: renderMenuItems(closeMenu, filteredSecondaryActions)
      }
    )
  )))))), (prependBadgeRow || badges || iconBadges || extraContentRow) && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { gutterSize: "s", direction: "column" }, (prependBadgeRow || badges || iconBadges) && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", responsive: false, style: { gap: "16px" } }, prependBadgeRow && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, "data-testid": "meld-reference-id" }, /* @__PURE__ */ React.createElement(
    EuiText,
    {
      color: colors.neutrals.gray800,
      style: { fontSize: "14px", fontWeight: "600" },
      grow: false
    },
    prependBadgeRow
  )), (badges || iconBadges) && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { gutterSize: "s", alignItems: "center" }, badges == null ? void 0 : badges.map((badge) => /* @__PURE__ */ React.createElement(EuiFlexItem, { key: badge.text, grow: false }, /* @__PURE__ */ React.createElement(PmBadge, __spreadValues({}, badge)))), iconBadges == null ? void 0 : iconBadges.map((iconBadge) => /* @__PURE__ */ React.createElement(EuiFlexItem, { key: iconBadge.badgeText, grow: false }, /* @__PURE__ */ React.createElement(PmIconBadge, __spreadValues({}, iconBadge)))))))), extraContentRow && /* @__PURE__ */ React.createElement(EuiFlexGroup, { gutterSize: "s", alignItems: "center" }, extraContentRow))))), !disableHorizontalRule && !bottomTray ? /* @__PURE__ */ React.createElement(EuiHorizontalRule, { margin: "s", size: "full", style: { marginBottom: "0px", backgroundColor: BorderColor } }) : /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", gutterSize: "s" }, bottomTray), !disableBottomSpace && /* @__PURE__ */ React.createElement(EuiSpacer, { size: "xl" }));
};
export { PageHeader, SecondaryActionIconsSrc };
