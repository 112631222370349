import React from "react";

import { useToasts, useDismissToast } from "../store/toast";
import { EuiGlobalToastList } from "@elastic/eui";
import { Global, css } from "@emotion/react";
import { colors } from "@pm-frontend/styles";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";

const TOAST_LIFETIME_IN_MS = 4000;

const ToastCloseIconColor: Record<"success" | "danger" | "primary" | "warning", string> = {
  success: colors.interface.green.default,
  primary: colors.brand.meldBlue,
  warning: colors.brand.meldBlue,
  danger: colors.interface.red.default,
};

const getTopValue = (length: number, isMobile: boolean): string => {
  if (length === 0) {
    return "110% !important";
  } else {
    return isMobile ? "10% !important" : "2% !important";
  }
};

const ApplicationToastList = () => {
  const toasts = useToasts();
  const dismissToast = useDismissToast();
  const isMobile = useIsMobile();

  let toastCloseColor: string = colors.brand.meldBlue;
  if (toasts[0] !== undefined) {
    toastCloseColor = ToastCloseIconColor[toasts[0].color || "success"];
  }

  // eui does not provide an easy way to change text color based on toast.color
  // so toast text coloring is handled in frontend/src/shared/store/toast.tsx
  const toastCss = css`
    .pmToastList {
      .euiToast {
        padding: 14px 16px 16px;
        width: ${isMobile ? "310px" : "413px"};
        div {
          margin: 0px !important;
        }
        top: 20px;
        .euiText {
          font-weight: 600;
          font-size: 16px;
          padding-right: ${isMobile ? "22px" : "70px"};
        }
        .euiButtonIcon {
          align-items: flex-start;
        }
        button.euiButtonIcon > svg.euiButtonIcon__icon > path {
          fill: ${toastCloseColor} !important;
        }
      }
    }
  `;

  // for moving the toast list on/off screen based on the presence of toasts
  // this is overwriting built-in eui styles for the EuiGlobalToastList
  const toastListStyles = css`
    position: fixed;
    top: ${getTopValue(toasts.length, isMobile)};
    left: 50% !important;
    transform: translate(-50%, -50%);
    width: auto;
    z-index: 9999;
  `;

  return (
    <>
      <Global styles={toastCss} />
      <EuiGlobalToastList
        css={toastListStyles}
        className="pmToastList"
        toasts={toasts}
        dismissToast={dismissToast}
        toastLifeTimeMs={TOAST_LIFETIME_IN_MS}
      />
    </>
  );
};

export { ApplicationToastList };
