import { hasPerm } from "@pm-assets/js/common/utils/permission-utils";
import { Perms } from "@pm-assets/js/constants";

// settings related permissions
export const canViewAgents = hasPerm(Perms.CAN_VIEW_AGENTS);
export const canAddReports = hasPerm(Perms.CAN_ADD_MELDREPORT);
export const canChangeIntegrations = hasPerm(Perms.CAN_CHANGE_INTEGRATIONS);

export const canViewInsights = hasPerm(Perms.CAN_VIEW_INSIGHTS);

export const canViewEditInvoices = hasPerm(Perms.CAN_CHANGE_MELDINVOICES);

export const canViewAllMelds = hasPerm(Perms.CAN_VIEW_MELDS);
export const canCreateAssignMelds = hasPerm(Perms.CAN_ADD_MELDS);
export const canViewEditReassignMelds = hasPerm(Perms.CAN_CHANGE_MELDS);
export const canScheduleMelds = hasPerm(Perms.CAN_SCHEDULE_MELDS);

export const canViewOwners = hasPerm(Perms.CAN_VIEW_OWNERS);
export const canEditOwners = hasPerm(Perms.CAN_ADD_EDIT_DEACTIVATE_OWNERS);

export const canViewPropertyGroups = hasPerm(Perms.CAN_VIEW_PROPERTY_GROUPS);
export const canEditPropertyGroups = hasPerm(Perms.CAN_ADD_EDIT_DELETE_PROPERTY_GROUPS);

export const canViewProjects = hasPerm(Perms.CAN_VIEW_PROJECTS);
export const canAddEditProjects = hasPerm(Perms.CAN_CHANGE_PROJECTS);

export const canViewAddPropertyCarePlus = hasPerm(Perms.CAN_ADD_OWNER_SERVICES);

export const canCreateRecurringMelds = hasPerm(Perms.CAN_ADD_RECURRINGMELDS);
export const canViewEditDeleteRecurringMelds = hasPerm(Perms.CAN_CHANGE_RECURRINGMELDS);

export const canViewResidents = hasPerm(Perms.CAN_VIEW_TENANTS);
export const canEditResidents = hasPerm(Perms.CAN_EDIT_TENANTS);

// both units and properties and controlled by these permissions
export const canViewUnitsProperties = hasPerm(Perms.CAN_VIEW_UNITS);
export const canEditUnitsProperties = hasPerm(Perms.CAN_ADD_EDIT_DEACTIVATE_UNITS);

export const canViewVendors = hasPerm(Perms.CAN_VIEW_VENDORS);
export const canAddVendors = hasPerm(Perms.CAN_ADD_VENDORS);

export const canManageWorkflows = hasPerm(Perms.CAN_MANAGE_WORKFLOWS);

export const canAddWorkEntry = hasPerm(Perms.CAN_ADD_WORK_ENTRY);
export const canChangeOtherAgentWorkEntry = hasPerm(Perms.CAN_CHANGE_OTHER_USERS_WORKLOG);
export const canEditWorkEntry = hasPerm(Perms.CAN_CHECK_TIMER_AND_EDIT_WORKENTRY_DETAILS);
export const canEditWorkEntryHours = hasPerm(Perms.CAN_EDIT_WORKENTRY_HOURS);
export const canDeleteWorkEntry = hasPerm(Perms.CAN_DELETE_WORK_ENTRY);
export const canViewWorkEntry = hasPerm(Perms.CAN_VIEW_WORK_ENTRY);

export const canCreateEditDeleteSharedFilters = hasPerm(Perms.CAN_CREATE_EDIT_DELETE_SHARED_FILTERS);

// payment related permissions
export const canViewExpenditure = hasPerm(Perms.CAN_VIEW_MELDEXPENDITURES);
export const canDeleteExpenditure = hasPerm(Perms.CAN_DELETE_MELDEXPENDITURES);
export const canUpdateStatusExpenditure = hasPerm(Perms.CAN_UPDATE_STATUS_MELDEXPENDITURES);
export const canAddEditExpenditures = hasPerm(Perms.CAN_EDIT_MELDEXPENDITURES);

// estimate related permissions
export const canSubmitEstimates = hasPerm(Perms.CAN_SUBMIT_ESTIMATES);
export const canViewOwnerApprovals = hasPerm(Perms.CAN_VIEW_OWNER_APPROVALS_ESTIMATES);
export const canRequestOwnerApprovals = hasPerm(Perms.CAN_REQUEST_OWNER_APPROVALS_ESTIMATES);
export const canViewEditLineItemMarkups = hasPerm(Perms.CAN_VIEW_EDIT_LINE_ITEM_MARKUPS_ESTIMATES);
