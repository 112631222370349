var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { EuiComboBox, EuiFlexGroup, EuiFlexItem, EuiSuperSelect } from "@elastic/eui";
import { PmFormStyling } from "../../Forms/PmFormStyling";
import {
  BaseComboboxFilterClass,
  PM_COMBOBOX_FILTER_INPUT_TYPES,
  PM_COMBOBOX_FILTER_OPERATORS
} from "./BaseComboboxFilterClass";
import { PmFilterButtonsCommitButtons } from "../subcomponents/PmFilterButtonsCommitControls";
const PmComboboxFilter = ({
  filter,
  savedFilter,
  closePopover,
  currentOperatorAndValue,
  isMobile
}) => {
  const location = useLocation();
  const history = useHistory();
  const [pendingOperatorAndValue, setPendingOperatorAndValue] = useState(
    () => {
      return currentOperatorAndValue || {
        operator: PM_COMBOBOX_FILTER_OPERATORS.ANY_OF,
        selectedOptions: [],
        filterType: BaseComboboxFilterClass.type,
        type: PM_COMBOBOX_FILTER_INPUT_TYPES.COMBOBOX
      };
    }
  );
  const onOperatorChange = (newOperator) => {
    const newOperatorAndValue = filter.getNewValueFromNewOperator({
      newOperator,
      currentPendingOperatorAndValue: pendingOperatorAndValue
    });
    setPendingOperatorAndValue(newOperatorAndValue);
  };
  const onOptionsChange = (newOptions) => {
    setPendingOperatorAndValue((state) => {
      newOptions.forEach((opt) => delete opt.isGroupLabelOption);
      return __spreadProps(__spreadValues({}, state), { selectedOptions: newOptions });
    });
  };
  return /* @__PURE__ */ React.createElement(PmFormStyling, { error: void 0 }, /* @__PURE__ */ React.createElement(
    EuiFlexGroup,
    {
      direction: "column",
      gutterSize: "m",
      style: { width: isMobile ? `min(80vw, ${filter.getPopoverWidth()})` : filter.getPopoverWidth() }
    },
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
      EuiSuperSelect,
      {
        options: filter.getAllowedOperatorOptions(),
        valueOfSelected: pendingOperatorAndValue.operator,
        onChange: onOperatorChange,
        "aria-label": "Select an operator"
      }
    )),
    "selectedOptions" in pendingOperatorAndValue && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, "data-testid": "selected-values", style: { height: "250px" } }, /* @__PURE__ */ React.createElement(
      EuiComboBox,
      {
        selectedOptions: pendingOperatorAndValue.selectedOptions,
        options: filter.getDefaultMappedOptions(),
        onChange: onOptionsChange,
        isClearable: true,
        "aria-label": "Select values"
      }
    )),
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
      PmFilterButtonsCommitButtons,
      {
        onApplyClick: filter.getOnApplyClick({
          location,
          savedFilter,
          history,
          pendingOperatorAndValue,
          closePopover
        }),
        onClearClick: filter.getOnClearClick({ closePopover, location, savedFilter, history })
      }
    ))
  ));
};
export { PmComboboxFilter };
