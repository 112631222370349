import React, { useState } from "react";
import {
  EuiFieldNumber,
  EuiFieldNumberProps,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormControlLayoutDelimited,
  EuiFormRow,
  EuiSuperSelect,
} from "@elastic/eui";
import { useHistory, useLocation } from "react-router-dom";

import { PmFormStyling } from "../../Forms/PmFormStyling";
import {
  BaseDecimalFilterClass,
  PmDecimalFilterAllowedOperators,
  PmDecimalFilterOperatorValue,
  PM_DECIMAL_FILTER_INPUT_TYPES,
  PM_DECIMAL_FILTER_OPERATORS,
} from "./BaseDecimalFilterClass";
import { SavedFilterObject } from "../BaseFilterClasses";
import { PmFilterButtonsCommitButtons } from "../subcomponents/PmFilterButtonsCommitControls";

// just a utility type for grabbing specific variants of a union
type ExtractMember<U, T extends U[keyof U]> = U extends { type: T } ? U : never;

const OneNumberInput = ({
  filter,
  pendingOperatorAndValue,
  setPendingOperatorAndValue,
}: {
  filter: BaseDecimalFilterClass;
  pendingOperatorAndValue: ExtractMember<NonNullable<PmDecimalFilterOperatorValue>, "one-decimal-input">;
  setPendingOperatorAndValue: React.Dispatch<React.SetStateAction<NonNullable<PmDecimalFilterOperatorValue>>>;
}) => {
  const onChange: EuiFieldNumberProps["onChange"] = (event) => {
    setPendingOperatorAndValue((state) => ({ ...state, value: event.target.value }));
  };

  return (
    <EuiFormRow label={filter.getNumberUnitLabel()}>
      <EuiFieldNumber
        value={pendingOperatorAndValue.value || ""}
        onChange={onChange}
        data-testid="first-decimal-filter-input"
      />
    </EuiFormRow>
  );
};

const TwoNumberInput = ({
  filter,
  pendingOperatorAndValue,
  setPendingOperatorAndValue,
}: {
  filter: BaseDecimalFilterClass;
  pendingOperatorAndValue: ExtractMember<NonNullable<PmDecimalFilterOperatorValue>, "two-decimal-input">;
  setPendingOperatorAndValue: React.Dispatch<React.SetStateAction<NonNullable<PmDecimalFilterOperatorValue>>>;
}) => {
  const getOnValueChange: (type: "first" | "second") => React.ChangeEventHandler<HTMLInputElement> = (type) => {
    return (event) => {
      setPendingOperatorAndValue((state) => ({
        ...state,
        [type === "first" ? "firstValue" : "secondValue"]: event.target.value,
      }));
    };
  };

  return (
    <EuiFormRow label={filter.getNumberUnitLabel()}>
      <EuiFormControlLayoutDelimited
        startControl={
          <input
            type="number"
            placeholder=""
            className="euiFieldNumber"
            value={pendingOperatorAndValue.firstValue}
            onChange={getOnValueChange("first")}
            data-testid="first-decimal-filter-input"
          />
        }
        endControl={
          <input
            type="number"
            placeholder=""
            className="euiFieldNumber"
            value={pendingOperatorAndValue.secondValue}
            onChange={getOnValueChange("second")}
            data-testid="second-decimal-filter-input"
          />
        }
      />
    </EuiFormRow>
  );
};

interface PmDecimalFilterComponentProps {
  savedFilter: SavedFilterObject;
  filter: BaseDecimalFilterClass;
  closePopover: () => void;
  currentOperatorAndValue: PmDecimalFilterOperatorValue;
  isMobile: boolean;
}

const PmDecimalFilter = ({
  savedFilter,
  filter,
  closePopover,
  currentOperatorAndValue,
  isMobile,
}: PmDecimalFilterComponentProps) => {
  const location = useLocation();
  const history = useHistory();
  let body: React.ReactNode;

  const [pendingOperatorAndValue, setPendingOperatorAndValue] = useState<NonNullable<PmDecimalFilterOperatorValue>>(
    () =>
      currentOperatorAndValue || {
        operator: PM_DECIMAL_FILTER_OPERATORS.GREATER_THAN,
        value: "",
        type: PM_DECIMAL_FILTER_INPUT_TYPES.ONE_NUMBER,
        filterType: BaseDecimalFilterClass.type,
      }
  );

  const onOperatorChange = (newOperator: PmDecimalFilterAllowedOperators) => {
    const newOperatorAndValue = filter.getNewValueFromNewOperator({
      newOperator,
      currentPendingOperatorAndValue: pendingOperatorAndValue,
    });
    setPendingOperatorAndValue(newOperatorAndValue);
  };

  switch (pendingOperatorAndValue.type) {
    case PM_DECIMAL_FILTER_INPUT_TYPES.ONE_NUMBER:
      body = (
        <OneNumberInput
          filter={filter}
          pendingOperatorAndValue={pendingOperatorAndValue}
          setPendingOperatorAndValue={setPendingOperatorAndValue}
        />
      );
      break;
    case PM_DECIMAL_FILTER_INPUT_TYPES.TWO_NUMBER:
      body = (
        <TwoNumberInput
          filter={filter}
          pendingOperatorAndValue={pendingOperatorAndValue}
          setPendingOperatorAndValue={setPendingOperatorAndValue}
        />
      );
      break;
    case PM_DECIMAL_FILTER_INPUT_TYPES.BOOLEAN:
      // not yet implemented
      break;
    default:
      break;
  }

  return (
    <PmFormStyling error={undefined}>
      <EuiFlexGroup
        direction="column"
        gutterSize="m"
        style={{ width: isMobile ? `min(80vw, ${filter.getPopoverWidth()})` : filter.getPopoverWidth() }}
      >
        <EuiFlexItem grow={false}>
          <EuiSuperSelect
            options={filter.getAllowedOperatorOptions()}
            valueOfSelected={pendingOperatorAndValue.operator}
            onChange={onOperatorChange}
            aria-label="Select an operator"
          />
        </EuiFlexItem>
        {body && <EuiFlexItem grow={false}>{body}</EuiFlexItem>}
        <EuiFlexItem grow={false}>
          <PmFilterButtonsCommitButtons
            onApplyClick={filter.getOnApplyClick({
              location,
              savedFilter,
              history,
              currentPendingOperatorValue: pendingOperatorAndValue,
              closePopover,
            })}
            onClearClick={filter.getOnClearClick({ closePopover, location, savedFilter, history })}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </PmFormStyling>
  );
};

export { PmDecimalFilter };
