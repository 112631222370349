import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import moment, { Moment } from "moment";

import { ApiUrls } from "@pm-frontend/shared/utils/api-urls";
import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";
import { apiDelete, apiFetch, apiPatch, apiPost } from "@pm-frontend/shared/utils/apiFetch";
import { SchedulableMeldDetailViewSerializer } from "@pm-frontend/shared/types/api/meld/serializers/scheduable_meld_detail_view_serializer";
import { ManagementEventListSerializer } from "@pm-frontend/shared/types/api/availability/serializers/management_event_list_serializer";
import { VendorEventListSerializer } from "@pm-frontend/shared/types/api/availability/serializers/vendor_event_list_serializer";
import { toastMessages, useAddToast } from "@pm-frontend/shared/store/toast";
import { meldKeys } from "../Melds/queries";
import { AllVendorsListRegisteredOnlyFalse } from "@pm-frontend/shared/types/api/maintenance/api";
import { getIsMeldScheduled } from "@pm-frontend/shared/utils/meld-utils";
import {
  getAssignedMaint,
  GetAssignedMaintenanceProps,
  getCompositeIdFromAssignedMaint,
  getOpenVendorAssignment,
  InviteProps,
  isMaintenanceManagementAgent,
} from "@pm-frontend/shared/utils/assignment-utils";
import { useGetAllMaintenance } from "@pm-frontend/shared/hooks/queries/useGetAllMaintenance";
import Features from "@pm-assets/js/common/feature-flags";
import {
  AlternativeEventDetailSerializer,
  AlternativeEventListSerializer,
} from "@pm-frontend/shared/types/api/availability/serializers/alternative_event_list_serializers";
import { LatLongCoordinates } from "@pm-frontend/shared/utils/location-utils";
import { MeldCalendarProximityListViewSerializer } from "@pm-frontend/shared/types/api/meld/serializers/calendar_meld_proximity_list_serializer";
import { CalendarMeldFilterParamState } from "./stores/meldFilterParamStore";
import { BaseURLFilter } from "@pm-frontend/shared/components/FilterButtons/BaseFilterClasses";
import { CalendarMeldSchedule, track } from "@pm-app/utils/analytics";
import { useGetCalendarEventMetaData, useGetSetActivePaneAndMap } from "./utils/hooks";
import { getCalendarDragClickActionType } from "./utils/click-drag-and-drop-utils";
import {
  GoogleCalendarEventListView,
  OutlookCalendarEventListView,
} from "@pm-frontend/shared/types/api/calendar_integrations/api/api";
import { SchedulableMeldListViewSerializer } from "@pm-frontend/shared/types/api/meld/serializers/scheduable_meld_list_view_serializer";
import {
  loadCalendarAppliedFilterFromStorage,
  MeldCalendarListQuickFilterOptions,
} from "./rightpane/MeldCalendarMeldsList";
import { getFirstColumnMoment, getNumberCalendarDateColumns } from "./utils/utils";
import { CALENDAR_TIMEFRAMES, CALENDAR_TIMEFRAMES_TYPE } from "./stores/timeFrameStore";
import { useCalendarStateActions } from "./stores/calendarStateStore";
import { CalendarDraftPendingActions } from "./stores/draftModeStore";

const getDateSeachParams = (selectedTimeFrame: CALENDAR_TIMEFRAMES_TYPE, startTime: number): URLSearchParams => {
  const searchParams = new URLSearchParams();

  const startOfRange =
    selectedTimeFrame === CALENDAR_TIMEFRAMES.WEEK
      ? getFirstColumnMoment(selectedTimeFrame, startTime)
      : moment(startTime);

  // still using moment due to timzone support
  searchParams.set("dtstart__gte", startOfRange.toISOString());
  searchParams.set(
    "dtend__lt",
    startOfRange.add(getNumberCalendarDateColumns(selectedTimeFrame), "days").toISOString()
  );

  return searchParams;
};

const calendarMeldKeys = {
  all: () => [...meldKeys.all, "calendar-melds"],
  meldsListAll: () => [...calendarMeldKeys.all(), "saved_filter"],
  meldsList: (filterParams: URLSearchParams | undefined) => [
    ...calendarMeldKeys.meldsListAll(),
    "filterParams",
    filterParams?.toString() || "none",
  ],
  meldsListRightPane: (
    filterParams: URLSearchParams | undefined,
    appliedQuickFilter: MeldCalendarListQuickFilterOptions | undefined
  ) => [
    ...calendarMeldKeys.meldsListAll(),
    "filterParams",
    filterParams?.toString() || "none",
    "quickFilter",
    appliedQuickFilter || "none",
  ],
  meldsAssignedList: (agentId: string, previousMeldID: string) => [
    ...calendarMeldKeys.all(),
    "recommended_assigned",
    "agent",
    agentId,
    "previousMeld",
    previousMeldID,
  ],
  meldsProximityList: (
    coordinates: LatLongCoordinates | undefined,
    distanceInMi: string,
    queryParams: URLSearchParams,
    enabled: boolean
  ) => [
    ...calendarMeldKeys.meldsList(queryParams),
    "proximity",
    "coordinates",
    coordinates ? `${coordinates.latitude} ${coordinates.longitude}` : "none",
    "distanceInMi",
    distanceInMi,
    "enabled",
    enabled.toString(),
  ],
  meldDetailAll: () => [...calendarMeldKeys.all(), "meld"],
  meldDetail: (meldId: number | string | undefined) => [
    ...calendarMeldKeys.meldDetailAll(),
    meldId?.toString() || "none",
  ],
  events: () => [...calendarMeldKeys.all(), "events"],
  alternativeScheduledEvents: (start: number, timeframe: CALENDAR_TIMEFRAMES_TYPE) => [
    ...calendarMeldKeys.events(),
    "start",
    start.toString(),
    "timeframe",
    timeframe.toString(),
  ],
  alternativeScheduledEventDetails: (altEventId: number | string | undefined) => [
    ...calendarMeldKeys.events(),
    "alt_event_details",
    altEventId?.toString() || "none",
  ],
  alternativeEventEvent: (eventId: string) => [...calendarMeldKeys.events(), "alt_event_event_details", eventId],
  managerScheduledEventDetails: (eventId: string) => [...calendarMeldKeys.events(), "event_details", eventId],
  managerScheduledEventsAll: () => [...calendarMeldKeys.events(), "manager"],
  managerScheduledEvents: (start: number, timeframe: CALENDAR_TIMEFRAMES_TYPE) => [
    ...calendarMeldKeys.managerScheduledEventsAll(),
    "start",
    start.toString(),
    "timeframe",
    timeframe.toString(),
  ],
  vendorScheduledEventsAll: () => [...calendarMeldKeys.events(), "vendor"],
  vendorScheduledEvents: (start: number, timeframe: CALENDAR_TIMEFRAMES_TYPE) => [
    ...calendarMeldKeys.vendorScheduledEventsAll(),
    "start",
    start.toString(),
    "timeframe",
    timeframe.toString(),
  ],
  googleCalendarEventsList: (agentIds: number[], start: number, timeFrame: CALENDAR_TIMEFRAMES_TYPE) => [
    ...calendarMeldKeys.all(),
    "google_calendar_events",
    "agent",
    agentIds.toString(),
    "start",
    start.toString(),
    "timeFrame",
    timeFrame.toString(),
  ],
  outlookCalendarEventsList: (agentIds: number[], start: number, timeFrame: CALENDAR_TIMEFRAMES_TYPE) => [
    ...calendarMeldKeys.all(),
    "outlook_calendar_events",
    "agent",
    agentIds,
    "start",
    start.toString(),
    "timeFrame",
    timeFrame.toString(),
  ],
  mutations: {
    batchSchedule: () => [...calendarMeldKeys.all(), "batch-schedule-events"],
    scheduleMutation: (meldId: string) => [...calendarMeldKeys.events(), "scheduling", meldId],
    bookSegment: (meldId: string) => [...calendarMeldKeys.events(), "book", meldId],
    assignAndSchedule: (id: string) => [...calendarMeldKeys.meldDetail(id), "assignAndSchedule"],
  },
} as const;

interface GetCalendarMeldsPaginated {
  results: SchedulableMeldListViewSerializer[];
  previous: string | null;
  next: string | null;
  count: number;
}

// TODO: in a later PR I'll switch to this type
// interface GetCalendarMeldsPaginatedV2 {
//   results: SchedulableMeldListViewSerializerV2[];
//   previous: string | null;
//   next: string | null;
//   count: number;
// }

const fetchCalendarMelds = <T>(
  nextPage: string | null,
  initialUrl: string,
  filterParams: URLSearchParams | undefined
): Promise<T> => {
  // the nextPage value already contains the filter params
  return apiFetch(nextPage || LinkHelper.normalize(initialUrl), { params: nextPage ? undefined : filterParams });
};

// TODO: update quick filters to the v2 endpoint
function getCalendarMeldsListRightpaneURL(appliedQuickFilter: MeldCalendarListQuickFilterOptions | undefined): string {
  switch (appliedQuickFilter) {
    case "allmelds":
      return Features.isMeldCalendarMeldListEndpointV2Enabled() ? ApiUrls.calendarMeldsV2 : ApiUrls.calendarMelds;
    case "review":
      return ApiUrls.calendarMeldsQuickFilterReview;
    case "dispatch":
      return ApiUrls.calendarMeldsQuickFilterPriorityOpen;
    default:
      return Features.isMeldCalendarMeldListEndpointV2Enabled() ? ApiUrls.calendarMeldsV2 : ApiUrls.calendarMelds;
  }
}

type UseGetCalendarMeldsListProps =
  | {
      meldFilterParams?: CalendarMeldFilterParamState["meldFilterParams"];
      appliedQuickFilter: MeldCalendarListQuickFilterOptions;
      searchParams?: never;
    }
  | {
      meldFilterParams?: never;
      appliedQuickFilter?: never;
      searchParams?: URLSearchParams;
    };

const useGetCalendarMeldsList = (props: UseGetCalendarMeldsListProps) => {
  const location = useLocation();
  const searchParams =
    (props.meldFilterParams &&
      props.appliedQuickFilter === "allmelds" &&
      BaseURLFilter.getFilterValues({
        savedFilterClass: props.meldFilterParams.calendar.savedFilterClass,
        sortFilter: props.meldFilterParams.calendar.sortFilterClass,
        filters: props.meldFilterParams.calendar.filterClasses,
        savedFilter: undefined,
        location,
      })) ||
    props.searchParams;

  return useInfiniteQuery({
    queryKey: calendarMeldKeys.meldsListRightPane(searchParams, props.appliedQuickFilter),
    // pageParam is the full url of the next set of results
    queryFn: ({ pageParam }) =>
      fetchCalendarMelds<GetCalendarMeldsPaginated>(
        pageParam,
        getCalendarMeldsListRightpaneURL(props.appliedQuickFilter),
        searchParams
      ),
    getNextPageParam: (lastPage) => lastPage.next,
    // prevents unnecessary refetching of current results when view more is clicked
    staleTime: 3000,
  });
};

const useGetUnscheduledMelds = (previousEventID: number | null, agentId: number, type: string | null) => {
  const pendingAvailStatus = "PENDING_MORE_MANAGEMENT_AVAILABILITY";
  const pendingAssignStatus = "PENDING_ASSIGNMENT";

  const baseURL = `${ApiUrls.calendarRecommendMelds}?status%5B%5D=${pendingAvailStatus}&status%5B%5D=${pendingAssignStatus}`;
  const prevFilter = previousEventID && type ? `&prev=${previousEventID}&type=${type}` : "";
  const url = `${baseURL}${prevFilter}`;

  return useInfiniteQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: calendarMeldKeys.meldsAssignedList(agentId.toString(), previousEventID?.toString() || "all"),
    queryFn: ({ pageParam }) => fetchCalendarMelds<GetCalendarMeldsPaginated>(pageParam, url, undefined),
    getNextPageParam: (lastPage: GetCalendarMeldsPaginated) => lastPage.next,
    keepPreviousData: true,
    staleTime: 5000,
  });
};

const useGetCalendarMeldDetails = (meldId: number | string | undefined) => {
  return useQuery<SchedulableMeldDetailViewSerializer>({
    queryKey: calendarMeldKeys.meldDetail(meldId),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.calendarMeldDetail(meldId))),
    enabled: !!meldId,
  });
};

const useGetCalendarManagerEventDetails = (
  event: {
    id: number;
    type: "management_scheduled" | "alternative_event_scheduled";
  } | null
) => {
  return useQuery<ManagementEventListSerializer>({
    queryKey: calendarMeldKeys.managerScheduledEventDetails(event?.id.toString() || "none"),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.managementEventDetail(event?.id.toString()))),
    enabled: !!event && event?.type === "management_scheduled",
  });
};

const useGetCalendarAlternativeEventDetails = (
  event: {
    id: number;
    type: "management_scheduled" | "alternative_event_scheduled";
  } | null
) => {
  return useQuery<ManagementEventListSerializer>({
    queryKey: calendarMeldKeys.alternativeEventEvent(event?.id.toString() || "none"),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.alternativeEventEventDetail(event?.id.toString()))),
    enabled: !!event && event.type === "alternative_event_scheduled",
  });
};

const useGetManagementScheduledEvents = (startTime: number, selectedTimeFrame: CALENDAR_TIMEFRAMES_TYPE) => {
  const searchParam: URLSearchParams = getDateSeachParams(selectedTimeFrame, startTime);

  return useQuery<ManagementEventListSerializer[]>({
    queryKey: calendarMeldKeys.managerScheduledEvents(startTime, selectedTimeFrame),
    queryFn: () => apiFetch(`${LinkHelper.normalize(ApiUrls.managementEventList)}?${searchParam.toString()}`),
  });
};

const useGetVendorScheduledEvents = (startTime: number, selectedTimeFrame: CALENDAR_TIMEFRAMES_TYPE) => {
  const searchParam = getDateSeachParams(selectedTimeFrame, startTime);

  return useQuery<VendorEventListSerializer[]>({
    queryKey: calendarMeldKeys.vendorScheduledEvents(startTime, selectedTimeFrame),
    queryFn: () => apiFetch(`${LinkHelper.normalize(ApiUrls.vendorEventList)}?${searchParam.toString()}`),
  });
};

const useGetAlternativeScheduledEvents = (startTime: number, selectedTimeFrame: CALENDAR_TIMEFRAMES_TYPE) => {
  const searchParam: URLSearchParams = getDateSeachParams(selectedTimeFrame, startTime);

  return useQuery<AlternativeEventListSerializer[]>({
    queryKey: calendarMeldKeys.alternativeScheduledEvents(startTime, selectedTimeFrame),
    queryFn: () => apiFetch(`${LinkHelper.normalize(ApiUrls.alternativeEventList)}?${searchParam.toString()}`),
    enabled: Features.isAlternativeEventsEnabled(),
  });
};

const useGetAlternativeEventDetails = (altEventId: number | string | undefined) => {
  return useQuery<AlternativeEventDetailSerializer>({
    queryKey: calendarMeldKeys.alternativeScheduledEventDetails(altEventId),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.alternativeEventDetail(altEventId))),
    enabled: !!altEventId,
  });
};

const useGetManagementGoogleCalendarEvents = (
  agentIds: number[],
  start: number,
  selectedTimeFrame: CALENDAR_TIMEFRAMES_TYPE
) => {
  const searchParam = new URLSearchParams();
  agentIds.forEach((id) => searchParam.append("agent__id", id.toString()));

  const startOfRange =
    selectedTimeFrame === CALENDAR_TIMEFRAMES.WEEK ? getFirstColumnMoment(selectedTimeFrame, start) : moment(start);

  searchParam.set("start__gte", startOfRange.toISOString());
  searchParam.set("end__lt", startOfRange.add(getNumberCalendarDateColumns(selectedTimeFrame), "days").toISOString());

  return useQuery<GoogleCalendarEventListView>({
    queryKey: calendarMeldKeys.googleCalendarEventsList(agentIds, start, selectedTimeFrame),
    queryFn: () =>
      apiFetch(`${LinkHelper.normalize(ApiUrls.googleCalendarApi.googleCalendarEventsList)}?${searchParam.toString()}`),
    enabled: Features.isGoogleCalendarReadIntegrationEnabled(),
  });
};

const useGetManagementOutlookCalendarEvents = (
  agentIds: number[],
  start: number,
  selectedTimeFrame: CALENDAR_TIMEFRAMES_TYPE
) => {
  const searchParam = new URLSearchParams();
  agentIds.forEach((id) => searchParam.append("agent__id", id.toString()));

  const startOfRange = getFirstColumnMoment(selectedTimeFrame, start);
  searchParam.set("start__gte", startOfRange.toISOString());
  searchParam.set("end__lt", startOfRange.add(getNumberCalendarDateColumns(selectedTimeFrame), "days").toISOString());

  return useQuery<OutlookCalendarEventListView>({
    queryKey: calendarMeldKeys.outlookCalendarEventsList(agentIds, start, selectedTimeFrame),
    queryFn: () =>
      apiFetch(
        `${LinkHelper.normalize(ApiUrls.outlookCalendarApi.outlookCalendarEventsList)}?${searchParam.toString()}`
      ),
    enabled: Features.isOutlookCalendarReadIntegrationEnabled(),
  });
};

interface AgentEvents {
  event: {
    dtend: string;
    dtstart: string;
    managementavailabilitysegment: {
      meld: {
        in_house_servicers: Array<{
          agent: { id: number; created: string };
        }>;
      };
    };
  };
}

interface Events {
  event: {
    dtend: string;
    dtstart: string;
  };
}

interface ScheduleEvent {
  id: number | undefined;
  date: Moment;
  startTime: Moment | undefined;
  endTime: Moment | undefined;
}

interface AgentScheduleMeldDataAccept {
  mark_scheduled: boolean;
  segments_to_keep: number[];
  management_availability_segments: AgentEvents[];
}

interface AgentRescheduleMeldData {
  appointment: number | null;
  segments_to_keep: number[];
}

interface AgentScheduleMeldDataAdd {
  new_segments: Events[];
}

interface AgentScheduleMeldDataReschedule {
  mark_scheduled: boolean;
  segments_to_keep: number[];
  new_segments: AgentEvents[];
}

interface VendorEvent {
  event: {
    dtend: string;
    dtstart: string;
  };
  vendoravailabilitysegment: {
    assignment_request: {
      vendor: {
        id: number;
      };
    };
  };
}

interface VendorScheduleMeldDataAccept {
  mark_scheduled: boolean;
  segments_to_keep: number[];
  new_segments: VendorEvent[];
}

interface AgentScheduleMeldDataAcceptMultiple {
  mark_scheduled: boolean;
  segments_to_keep: number[];
  multiple_segments_to_book: Events[];
  new_segments: VendorEvent[];
}

interface VendorRescheduleMeldData {
  appointment: number | null;
  requestedSegment: object;
}

const formatEventDataToPatch = (
  startTime: Moment,
  durationInMin: number,
  assignees: AllVendorsListRegisteredOnlyFalse,
  type: "accept" | "reschedule",
  events: ScheduleEvent[] | null = null,
  isAddingAppointment: boolean = false,
  isReschedulingAppointment: boolean = false,
  appointmentId: number | null
):
  | AgentScheduleMeldDataAccept
  | AgentScheduleMeldDataReschedule
  | VendorScheduleMeldDataAccept
  | AgentScheduleMeldDataAdd
  | AgentRescheduleMeldData
  | VendorRescheduleMeldData
  | AgentScheduleMeldDataAcceptMultiple
  | null => {
  const dtend = startTime.clone().add(durationInMin, "minutes").toISOString();
  const dtstart = startTime.toISOString();
  if (assignees.length === 0) {
    return null;
  }
  if (assignees.every((a) => a.type === "ManagementAgent")) {
    if (isReschedulingAppointment) {
      return {
        appointment: appointmentId,
        new_segments: [
          {
            event: {
              dtend,
              dtstart,
            },
          },
        ],
      };
    } else if (isAddingAppointment) {
      return {
        new_segments: [
          {
            event: {
              dtend,
              dtstart,
            },
          },
        ],
      };
    } else if (type === "accept") {
      if (!Features.isMultipleAppointmentsEnabled() || events == null || events.length === 0) {
        return {
          mark_scheduled: true,
          segments_to_keep: [],
          management_availability_segments: [
            {
              event: {
                dtend,
                dtstart,
                managementavailabilitysegment: {
                  meld: {
                    in_house_servicers: assignees.map((a) => ({ agent: a })),
                  },
                },
              },
            },
          ],
        };
      } else if (Features.isMultipleAppointmentsEnabled() && events && events.length > 0) {
        return {
          mark_scheduled: true,
          segments_to_keep: [],
          management_availability_segments: [
            {
              event: {
                dtend,
                dtstart,
                managementavailabilitysegment: {
                  meld: {
                    in_house_servicers: assignees.map((a) => ({ agent: a })),
                  },
                },
              },
            },
          ],
          multiple_segments_to_book: events
            .filter((event) => event.startTime)
            .map((event) => ({
              event: {
                dtend: event
                  .startTime!.clone()
                  .add((event.endTime && event.endTime.diff(event.startTime, "minutes")) || 0, "minutes")
                  .toISOString(),
                dtstart: event.startTime!.toISOString(),
              },
            })),
        };
      }
    } else if (type === "reschedule") {
      if (!Features.isMultipleAppointmentsEnabled() || events == null || events.length === 0) {
        return {
          mark_scheduled: true,
          segments_to_keep: [],
          new_segments: [
            {
              event: {
                dtend,
                dtstart,
                managementavailabilitysegment: {
                  meld: {
                    in_house_servicers: assignees.map((a) => ({ agent: a })),
                  },
                },
              },
            },
          ],
        };
      } else if (Features.isMultipleAppointmentsEnabled() && events && events.length > 0) {
        return {
          mark_scheduled: true,
          segments_to_keep: [],
          new_segments: [
            {
              event: {
                dtend,
                dtstart,
                managementavailabilitysegment: {
                  meld: {
                    in_house_servicers: assignees.map((a) => ({ agent: a })),
                  },
                },
              },
            },
          ],
          multiple_segments_to_book: events
            .filter((event) => event.startTime)
            .map((event) => ({
              event: {
                dtend: event
                  .startTime!.clone()
                  .add((event.endTime && event.endTime.diff(event.startTime, "minutes")) || 0, "minutes")
                  .toISOString(),
                dtstart: event.startTime!.toISOString(),
              },
            })),
        };
      }
    }
  } else if (assignees[0].type === "Vendor") {
    if (isReschedulingAppointment) {
      return {
        appointment: appointmentId,
        requestedSegment: {
          event: {
            dtend,
            dtstart,
          },
        },
      };
    } else if (isAddingAppointment) {
      return {
        new_segments: [
          {
            event: {
              dtend,
              dtstart,
            },
          },
        ],
      };
    }
    if (!Features.isMultipleAppointmentsEnabled() || events == null || events.length === 0) {
      return {
        mark_scheduled: true,
        segments_to_keep: [],
        new_segments: [
          {
            event: {
              dtend,
              dtstart,
            },
            vendoravailabilitysegment: {
              assignment_request: {
                vendor: {
                  id: assignees[0].id,
                },
              },
            },
          },
        ],
      };
    } else if (Features.isMultipleAppointmentsEnabled() && events && events.length > 0) {
      return {
        mark_scheduled: true,
        segments_to_keep: [],
        new_segments: [],
        multiple_segments_to_book: events
          .filter((event) => event.startTime)
          .map((event) => ({
            event: {
              dtend: event
                .startTime!.clone()
                .add((event.endTime && event.endTime.diff(event.startTime, "minutes")) || 0, "minutes")
                .toISOString(),
              dtstart: event.startTime!.toISOString(),
            },
          })),
      };
    }
  }
  return null;
};

const getScheduleURL = (
  assignees: AllVendorsListRegisteredOnlyFalse,
  type: "accept" | "reschedule",
  meld: MeldToSchedule,
  isAddingAppointment: boolean = false,
  isReschedulingAppointment: boolean = false,
  appointmentId: number | null = null
): string | null => {
  if (assignees.length === 0) {
    return null;
  }
  if (assignees.every((a) => a.type === "ManagementAgent")) {
    if (isAddingAppointment) {
      return LinkHelper.normalize(ApiUrls.meldAddSegments(meld.id));
    }
    if (isReschedulingAppointment) {
      return LinkHelper.normalize(ApiUrls.meldRescheduleAppointmentSegment(meld.id));
    }
    if (type === "accept") {
      return LinkHelper.normalize(ApiUrls.managerScheduleMeld(meld.id));
    } else if (type === "reschedule") {
      return LinkHelper.normalize(ApiUrls.meldRescheduleSegments(meld.id));
    }
  } else if (assignees[0].type === "Vendor") {
    if (isReschedulingAppointment && appointmentId) {
      return LinkHelper.normalize(ApiUrls.meldRescheduleVendorAppointmentSegment(appointmentId));
    }
    if (isAddingAppointment) {
      return LinkHelper.normalize(ApiUrls.addNewVendorAppointment(meld.id));
    }
    const openAssignment = getOpenVendorAssignment(meld);
    if (openAssignment) {
      return LinkHelper.normalize(ApiUrls.vendorUpdateSegments(openAssignment.id));
    }
  }
  return null;
};

interface MeldToSchedule {
  id: number;
  in_house_servicers: Array<{
    agent: { id: number; created: string };
  }>;
  managementappointment: Array<{ id: number; availability_segment?: unknown }>;
  vendorappointment: Array<{ id: number; availability_segment?: unknown }>;
  vendor_assignment_requests: Array<{ id: number; vendor: { id: number; rejected?: string; canceled?: string } }>;
  started: string | undefined | null;
}

const getAssignees = <
  A extends { id: number; first_name?: string; last_name?: string; created?: string },
  V extends { id: number; name?: string; email?: string; created?: string },
  I extends InviteProps
>(
  meld: GetAssignedMaintenanceProps<A, V, I>,
  allMaintenance: AllVendorsListRegisteredOnlyFalse
) => {
  const assignedMaintenance = getAssignedMaint(meld);

  switch (assignedMaintenance?.type) {
    case "ManagementAgent":
      return assignedMaintenance.in_house_servicers
        .map((servicer) =>
          allMaintenance?.find((maint) => maint.type === "ManagementAgent" && maint.id === servicer.id)
        )
        .filter(Boolean) as AllVendorsListRegisteredOnlyFalse;
    case "Vendor":
      return [assignedMaintenance.vendor]
        .map((vendor) => allMaintenance?.find((maint) => maint.type === "Vendor" && maint.id === vendor.id))
        .filter(Boolean) as AllVendorsListRegisteredOnlyFalse;
    default:
      return;
  }
};

const useScheduleMeld = (
  meld: MeldToSchedule,
  trackData: {
    initiator:
      | "recommened"
      | "draganddrop"
      | "click"
      | "form"
      | "offeredAvailabilityRightpane"
      | "offeredAvailabilityCalendarEvent";
    actionType: ReturnType<typeof getCalendarDragClickActionType> | "none";
  }
) => {
  const eventMetaData = useGetCalendarEventMetaData();
  const { rightPaneState, calendarOrMap } = useGetSetActivePaneAndMap();
  const { setAppointmentToReschedule } = useCalendarStateActions();

  const addToast = useAddToast();
  const queryClient = useQueryClient();
  // some actions, like reassigning a meld, can leave a meld which is not scheduled
  // but must call the 'reschedule' endpoint
  const type: "accept" | "reschedule" = meld.started || getIsMeldScheduled(meld) ? "reschedule" : "accept";

  const { data: allMaintenance } = useGetAllMaintenance({ refetchOnMount: false });

  return useMutation({
    mutationKey: calendarMeldKeys.mutations.scheduleMutation(meld?.id.toString() || "-"),
    mutationFn: ({
      startTime,
      durationInMin,
      events = [],
      isAddingAppointment = false,
      isReschedulingAppointment = false,
      appointmentId = null,
    }: {
      startTime: Moment;
      durationInMin: number;
      events: ScheduleEvent[] | null;
      isAddingAppointment: boolean;
      isReschedulingAppointment: boolean;
      appointmentId: number | null;
      extraOnSuccess?: () => void;
    }) => {
      // this query is cached indefinitely and should always have returned
      // before someone triggers a schedule
      if (!allMaintenance) {
        return Promise.reject();
      }
      const assignees = getAssignees(meld, allMaintenance);
      if (!assignees) {
        return Promise.reject();
      }
      const data = formatEventDataToPatch(
        startTime,
        durationInMin,
        assignees,
        type,
        events,
        isAddingAppointment,
        isReschedulingAppointment,
        appointmentId
      );
      const url = getScheduleURL(assignees, type, meld, isAddingAppointment, isReschedulingAppointment, appointmentId);
      if (!data || !url) {
        return Promise.reject();
      }
      if (isAddingAppointment && assignees[0].type === "Vendor") {
        return apiPost(url, data);
      }
      return apiPatch(url, data);
    },
    onError: (_, mutationProps) => {
      if (mutationProps.isReschedulingAppointment) {
        addToast({
          text: toastMessages.rescheduleMeldFailure,
          color: "danger",
        });
      } else {
        addToast({
          text: toastMessages.scheduleMeldFailure,
          color: "danger",
        });
      }
    },
    onSuccess: (_, mutationProps) => {
      // if we reschedule from an incoming link we clear out the value
      setAppointmentToReschedule(null);
      if (mutationProps.isReschedulingAppointment) {
        addToast({
          text: toastMessages.rescheduleMeldSuccess,
          color: "success",
        });
      } else {
        addToast({
          text: toastMessages.scheduleMeldSuccess,
          color: "success",
        });
      }
      const assignees = getAssignedMaint(meld);

      track(
        CalendarMeldSchedule({
          activeRightpane: rightPaneState.type,
          isMapOpen:
            (calendarOrMap === "large_map" && "large") ||
            (assignees && getCompositeIdFromAssignedMaint(assignees).includes(calendarOrMap) && "small") ||
            "none",
          initiator: trackData.initiator,
          target: assignees?.type === "ManagementAgent" ? "agent" : "vendor",
          actionType: trackData.actionType,
          appliedQuickFilter: loadCalendarAppliedFilterFromStorage(),
          ...eventMetaData,
        })
      );

      // extraOnSuccess (optional) is an additional event that can be run before
      // queryClient.resetQueries and queryClient.invalidateQueries runs below.
      // These two calls create a race condition with the unmount/mount and the
      // external mutation 'Success' in the component.
      if (mutationProps.extraOnSuccess) {
        mutationProps.extraOnSuccess();
      }
      // the schedule form needs to be reset after this mutation.  `resetQueries`
      // clears the cache entry and forces a refresh, which unmounts/mounts
      // the details right pane and the form is initialized with the new events
      return Promise.all([
        queryClient.resetQueries(calendarMeldKeys.meldDetail(meld.id.toString())),
        queryClient.invalidateQueries(calendarMeldKeys.all()),
      ]);
    },
  });
};

const usePatchAssignScheduleMeldMaintenance = (
  meld: MeldToSchedule,
  personaCompositeId: string,
  eventData: {
    initiator: "recommened" | "draganddrop" | "click" | "form";
    actionType: ReturnType<typeof getCalendarDragClickActionType> | "none";
  },
  typeOverride: "accept" | "reschedule" | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  const eventMetaData = useGetCalendarEventMetaData();
  const { rightPaneState, calendarOrMap } = useGetSetActivePaneAndMap();

  const type: "accept" | "reschedule" =
    typeOverride || (meld.started || getIsMeldScheduled(meld) ? "reschedule" : "accept");
  const allMaintenanceQuery = useGetAllMaintenance({ refetchOnMount: false });
  const maintenance = allMaintenanceQuery.data?.find((m) => m.composite_id === personaCompositeId);

  return {
    allMaintenanceQuery,
    assignedMaintenance: maintenance,
    ...useMutation({
      mutationKey: calendarMeldKeys.mutations.assignAndSchedule(meld.id.toString()),
      mutationFn: async ({
        startTime,
        durationInMin,
        events = [],
        isAddingAppointment = false,
        isReschedulingAppointment = false,
        appointmentId = null,
        extraOnSuccess,
      }: {
        startTime: Moment;
        durationInMin: number;
        events: ScheduleEvent[] | null;
        isAddingAppointment: boolean;
        isReschedulingAppointment: boolean;
        appointmentId: number | null;
        extraOnSuccess?: () => void;
      }) => {
        if (!maintenance) {
          return Promise.reject();
        }

        const data = formatEventDataToPatch(
          startTime,
          durationInMin,
          [maintenance],
          type,
          events,
          isAddingAppointment,
          isReschedulingAppointment,
          appointmentId
        );
        const url = getScheduleURL(
          [maintenance],
          type,
          meld,
          isAddingAppointment,
          isReschedulingAppointment,
          appointmentId
        );

        if (!data || !url) {
          return Promise.reject();
        }

        try {
          // First API call: PATCH to assign maintenance
          await apiPatch(LinkHelper.normalize(ApiUrls.assignMaintenance(meld.id)), {
            user_groups: [],
            maintenance: [maintenance],
          });

          try {
            // Second API call: Either POST or PATCH based on the condition
            if (isAddingAppointment && maintenance.type === "Vendor") {
              await apiPost(url, data);
            } else {
              await apiPatch(url, data);
            }
          } catch {
            // If the second call fails, rollback and unassign the assigned
            try {
              await apiPatch(LinkHelper.normalize(ApiUrls.assignMaintenance(meld.id)), {
                user_groups: [],
                maintenance: [],
              });

              // eslint-disable-next-line no-empty
            } catch {}
            // If rollback unassignment fails, then the meld is just assigned now...
            throw new Error("mutation fails");
          }

          addToast({
            text: toastMessages.meldAssignedScheduleMeldSuccess,
            color: "success",
          });

          track(
            CalendarMeldSchedule({
              activeRightpane: rightPaneState.type,
              isMapOpen:
                (calendarOrMap === "large_map" && "large") ||
                (calendarOrMap === personaCompositeId && "small") ||
                "none",
              initiator: eventData.initiator,
              target: isMaintenanceManagementAgent({ composite_id: personaCompositeId }) ? "agent" : "vendor",
              actionType: eventData.actionType,
              appliedQuickFilter: loadCalendarAppliedFilterFromStorage(),
              ...eventMetaData,
            })
          );

          // extraOnSuccess (optional) is an additional event that can be run before
          // queryClient.resetQueries and queryClient.invalidateQueries runs below.
          // These two calls create a race condition with the unmount/mount and the
          // external mutation 'Success' in the component.
          if (extraOnSuccess) {
            extraOnSuccess();
          }
        } catch {
          addToast({
            text: toastMessages.meldAssignedScheduleMeldFailure,
            color: "danger",
          });
        }
        // make sure we have the correct state
        return Promise.all([
          queryClient.invalidateQueries(meldKeys.all),
          queryClient.resetQueries(calendarMeldKeys.meldDetail(meld.id.toString())),
          queryClient.invalidateQueries(calendarMeldKeys.all()),
        ]);
      },
    }),
  };
};

const useBookResidentAvailability = (
  meld: { id: number },
  trackData: {
    initiator: "offeredAvailabilityRightpane" | "offeredAvailabilityCalendarEvent";
  },
  personaCompositeId?: string
) => {
  const eventMetaData = useGetCalendarEventMetaData();
  const { rightPaneState, calendarOrMap } = useGetSetActivePaneAndMap();
  const addToast = useAddToast();
  const queryClient = useQueryClient();

  const allMaintenanceQuery = useGetAllMaintenance({ refetchOnMount: false, enabled: !!personaCompositeId });
  const maintenance = allMaintenanceQuery.data?.find((m) => m.composite_id === personaCompositeId);

  return useMutation({
    mutationKey: calendarMeldKeys.mutations.bookSegment(meld.id.toString()),
    mutationFn: ({ ids, isAssigning = false }: { ids: number[]; isAssigning?: boolean }) => {
      const firstAppt = ids[0];

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const payload: { ids?: number[]; maintenance?: any } = {};
      if (ids.length > 1) {
        payload["ids"] = ids;
      }
      if (isAssigning && maintenance) {
        payload["maintenance"] = [maintenance];
      }

      return apiPatch(LinkHelper.normalize(ApiUrls.bookRequestedSegment(firstAppt)), payload);
    },
    onError: () =>
      addToast({
        text: toastMessages.scheduleMeldFailure,
        color: "danger",
      }),
    onSuccess: () => {
      // @ts-expect-error just needed for tracking
      const assignees = getAssignedMaint(meld);

      track(
        CalendarMeldSchedule({
          activeRightpane: rightPaneState.type,
          initiator: trackData.initiator,
          isMapOpen:
            (calendarOrMap === "large_map" && "large") ||
            (assignees && getCompositeIdFromAssignedMaint(assignees).includes(calendarOrMap) && "small") ||
            "none",
          target: assignees?.type === "ManagementAgent" ? "agent" : "vendor",
          actionType: "bookAvailability",
          appliedQuickFilter: loadCalendarAppliedFilterFromStorage(),
          ...eventMetaData,
        })
      );
      addToast({
        text: toastMessages.scheduleMeldSuccess,
        color: "success",
      });
      // the schedule form needs to be reset after this mutation.  `resetQueries`
      // clears the cache entry and forces a refresh, which unmounts/mounts
      // the details right pane and the form is initialized with the new events
      return Promise.all([
        queryClient.resetQueries(calendarMeldKeys.meldDetail(meld.id.toString())),
        queryClient.invalidateQueries(calendarMeldKeys.all()),
      ]);
    },
  });
};

interface AddManagerAvailabiltiesPayloadAccept {
  management_availability_segments: Array<{
    event: {
      dtstart: string;
      dtend: string;
    };
    managementavailabilitysegment: {
      meld: {
        in_house_servicers: number[];
      };
    };
  }>;
  required_appointments?: number;
}

interface AddManagerAvailabiltiesPayloadReschedule {
  required_appointments?: number;
  mark_scheduled: boolean;
  new_segments: AddManagerAvailabiltiesPayloadAccept["management_availability_segments"];
  segments_to_keep: number[];
}

type AvailabilityMutationProps =
  | {
      type: "accept";
      payload: AddManagerAvailabiltiesPayloadAccept;
    }
  | {
      type: "reschedule";
      payload: AddManagerAvailabiltiesPayloadReschedule;
    };

const useAddManagerAvailabilities = (meld: { id: number }) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (props: AvailabilityMutationProps) => {
      const url =
        props.type === "accept" ? ApiUrls.managerScheduleMeld(meld.id) : ApiUrls.meldRescheduleSegments(meld.id);
      return apiPatch(LinkHelper.normalize(url), props.payload);
    },
    onSuccess: () => {
      addToast({ color: "success", text: toastMessages.calendarAddManagerAvailabilitiesSuccess });
      return Promise.all([
        queryClient.invalidateQueries(calendarMeldKeys.events()),
        queryClient.invalidateQueries(calendarMeldKeys.meldsListAll()),
        queryClient.resetQueries(calendarMeldKeys.meldDetail(meld.id.toString())),
      ]);
    },
    onError: () => addToast({ color: "danger", text: toastMessages.calendarAddManagerAvailabilitiesFailure }),
  });
};

interface AddressDataProps {
  line_1: string;
  line_2?: string;
  line_3?: string;
  city: string;
  county_province: string;
  postcode: string | number;
  latitude?: number | null;
  longitude?: number | null;
}

interface EventDataProps {
  id?: number;
  description?: string;
  dtstart: string;
  dtend: string;
  rrule?: RRuleProps;
}

interface RRuleProps {
  freq: string;
  interval: number;
  until?: string;
}

interface AlternativeEventCreateProps {
  id?: number;
  management_attendees: number[];
  location_data?: AddressDataProps | string;
  event: EventDataProps;
  extraOnSuccess?: () => void;
}

const useDeleteAlternativeEvent = (altEventId: string) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();

  return useMutation({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutationFn: (data: any & { extraOnSuccess?: () => void }) =>
      apiDelete(LinkHelper.normalize(ApiUrls.alternativeEventDetail(altEventId)), data),
    onSuccess: (_, args) => {
      addToast({
        text: toastMessages.alternativeEventDeleteSuccess,
        color: "success",
      });
      args.extraOnSuccess?.();

      return Promise.all([
        queryClient.invalidateQueries(calendarMeldKeys.events()),
        queryClient.resetQueries(calendarMeldKeys.alternativeScheduledEventDetails("none")),
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.alternativeEventDeleteFailure,
        color: "danger",
      });
    },
  });
};

const useScheduleAlternativeEvent = () => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: AlternativeEventCreateProps) => {
      return apiPost(LinkHelper.normalize(ApiUrls.alternativeEventCreate), {
        ...data,
      });
    },
    onSuccess: (_, mutationProps) => {
      addToast({ color: "success", text: toastMessages.alternativeEventCreateSuccess });

      // extraOnSuccess (optional) is an additional event that can be run before
      // queryClient.resetQueries and queryClient.invalidateQueries runs below.
      // These two calls create a race condition with the unmount/mount and the
      // external mutation 'Success' in the component.
      if (mutationProps.extraOnSuccess) {
        mutationProps.extraOnSuccess();
      }

      return Promise.all([
        queryClient.invalidateQueries(calendarMeldKeys.events()),
        queryClient.resetQueries(calendarMeldKeys.alternativeScheduledEventDetails("none")),
      ]);
    },
    onError: () => addToast({ color: "danger", text: toastMessages.alternativeEventCreateFailure }),
  });
};

interface AlternativeEventModalProps {
  type: string;
  payload: Omit<AlternativeEventCreateProps, "management_attendees"> & {
    management_attendees?: AlternativeEventCreateProps["management_attendees"];
  };
}

const useEditAlternativeEvent = (altEvent: { id: number } | null) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: AlternativeEventModalProps) => {
      let url = ApiUrls.alternativeEventDetail(altEvent?.id);
      if (data.type === "single") {
        url = ApiUrls.alternativeEventRescheduleSingle(altEvent?.id);
      } else if (data.type === "following") {
        url = ApiUrls.alternativeEventRescheduleFollowing(altEvent?.id);
      } else if (data.type === "all") {
        url = ApiUrls.alternativeEventRescheduleAll(altEvent?.id);
      }
      return apiPatch(LinkHelper.normalize(url), data.payload);
    },
    onSuccess: (_, mutationProps) => {
      addToast({ color: "success", text: toastMessages.alternativeEventSaveSuccess });

      // extraOnSuccess (optional) is an additional event that can be run before
      // queryClient.resetQueries and queryClient.invalidateQueries runs below.
      // These two calls create a race condition with the unmount/mount and the
      // external mutation 'Success' in the component.
      if (mutationProps.payload.extraOnSuccess) {
        mutationProps.payload.extraOnSuccess();
      }

      return Promise.all([
        queryClient.invalidateQueries(calendarMeldKeys.events()),
        queryClient.resetQueries(calendarMeldKeys.alternativeScheduledEventDetails(altEvent?.id.toString() || "none")),
      ]);
    },
    onError: () => addToast({ color: "danger", text: toastMessages.alternativeEventSaveFailure }),
  });
};

const useMeldProximityList = ({
  coordinates,
  distanceInMiles,
  filterQueryParams,
  enabled = true,
}: {
  coordinates: LatLongCoordinates | undefined;
  distanceInMiles: number | undefined;
  filterQueryParams: URLSearchParams;
  enabled?: boolean;
}) => {
  if (coordinates && distanceInMiles) {
    filterQueryParams.set("distanceInMiles", distanceInMiles.toString());
    filterQueryParams.set("longitude", coordinates.longitude.toString());
    filterQueryParams.set("latitude", coordinates.latitude.toString());
  }

  return useQuery<MeldCalendarProximityListViewSerializer[]>({
    queryKey: calendarMeldKeys.meldsProximityList(
      coordinates,
      distanceInMiles?.toString() || "",
      filterQueryParams,
      enabled
    ),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.calendarMeldsProximityList), { params: filterQueryParams }),
    staleTime: Infinity,
    enabled: Features.isUIRedesignSchedulerNearbyEnabled() && enabled && !!coordinates && !!distanceInMiles,
  });
};

const useCommitCalendarDraftSchedulingActions = () => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: calendarMeldKeys.mutations.batchSchedule(),
    mutationFn: (data: CalendarDraftPendingActions[]) =>
      apiPost(LinkHelper.normalize(ApiUrls.calendarBatchSchedule), data),
    // TODO: get toast text
    onSuccess: () => {
      addToast({
        text: toastMessages.calendarBatchScheduleSuccess,
        color: "success",
      });
      return Promise.all([
        queryClient.invalidateQueries(calendarMeldKeys.all()),
        queryClient.resetQueries(calendarMeldKeys.meldDetailAll()),
      ]);
    },
    onError: () =>
      addToast({
        text: toastMessages.calendarBatchScheduleFailure,
        color: "danger",
      }),
  });
};

export {
  useGetCalendarMeldsList,
  useGetCalendarMeldDetails,
  usePatchAssignScheduleMeldMaintenance,
  useGetUnscheduledMelds,
  useGetManagementScheduledEvents,
  useGetManagementGoogleCalendarEvents,
  useGetManagementOutlookCalendarEvents,
  useGetAlternativeScheduledEvents,
  useGetAlternativeEventDetails,
  useGetVendorScheduledEvents,
  calendarMeldKeys,
  useScheduleMeld,
  useBookResidentAvailability,
  useGetCalendarManagerEventDetails,
  useAddManagerAvailabilities,
  GetCalendarMeldsPaginated,
  AvailabilityMutationProps,
  AddManagerAvailabiltiesPayloadReschedule,
  AddManagerAvailabiltiesPayloadAccept,
  AlternativeEventModalProps,
  AlternativeEventCreateProps,
  AddressDataProps,
  useScheduleAlternativeEvent,
  useEditAlternativeEvent,
  useDeleteAlternativeEvent,
  useMeldProximityList,
  useGetCalendarAlternativeEventDetails,
  useCommitCalendarDraftSchedulingActions,
};
