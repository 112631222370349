var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { apiFetch, apiPatch } from "@pm-frontend/shared/utils/apiFetch";
import { ApiUrls } from "@pm-frontend/shared/utils/api-urls";
import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";
let timezoneHasBeenSet = false;
export function autoUpdateNotificationTimzone() {
  if (timezoneHasBeenSet) {
    return;
  }
  setTimeout(() => __async(this, null, function* () {
    const url = LinkHelper.normalize(ApiUrls.notificationSettings);
    const notificationSettings = yield apiFetch(url);
    timezoneHasBeenSet = true;
    if (!notificationSettings) {
      return;
    }
    if (notificationSettings.timezone_has_been_set) {
      return;
    }
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    yield apiPatch(url, __spreadProps(__spreadValues({}, notificationSettings), { timezone_has_been_set: true, timezone }));
  }), 0);
}
