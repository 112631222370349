import React from "react";
import { Global, css } from "@emotion/react";
import { colors } from "@pm-frontend/styles";
import { EuiCallOut, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";

const classNameTypeMap: Record<FormTypes, string> = {
  default: "pmEuiFormDefault",
  descriptionList: "pmEuiFormDescriptionList",
  fullPageForm: "pmFullPageForm",
};

const formCss = css`
  .${classNameTypeMap.default} {
    input,
    select,
    textarea {
      font-size: 16px;
    }
  }
  .${classNameTypeMap.descriptionList} {
    input,
    select,
    label,
    textarea {
      font-size: 14px;
    }
  }
  .${classNameTypeMap.fullPageForm} {
    input,
    select,
    label,
    textarea {
      font-size: 14px;
    }
  }

  & span.euiContextMenuItem__text {
    font-size: 14px;
  }

  .pmEuiForm {
    input,
    select,
    textarea {
      font-family: "Open Sans", Helvetica, Roboto, Arial, sans-serif;
      background-color: ${colors.brand.white};
      color: ${colors.neutrals.gray800};
    }

    button.euiSuperSelectControl {
      font-family: "Open Sans", Helvetica, Roboto, Arial, sans-serif;
      background-color: ${colors.brand.white};
    }

    .euiComboBox .euiComboBox__inputWrap {
      font-family: "Open Sans", Helvetica, Roboto, Arial, sans-serif;
      background-color: ${colors.brand.white};
      color: ${colors.neutrals.gray800};
    }

    .euiFormErrorText {
      font-size: 14px;
      color: ${colors.interface.red.default};
    }

    .euiComboBoxPill {
      .euiBadge__text {
        font-size: 14px;
        font-weight: 400;
      }
      /* add this class to make the 'x' close button black */
      button.euiBadge__iconButton > svg > path {
        fill: #000000 !important;
      }
    }
    /* The x on combo pills is black by default, this makes it visble */
    /* this is for badges that are meld blue */
    .white-close-icon .euiComboBoxPill {
      button.euiBadge__iconButton > svg > path {
        fill: ${colors.brand.white} !important;
      }
    }

    .euiFormControlLayout__prepend {
      font-weight: 400 !important;
      font-size: 14px !important;
      background: ${colors.neutrals.gray200} !important;
    }

    .euiRadio__label {
      font-weight: 400;
    }

    .euiCheckbox__label {
      font-weight: 400;
      color: ${colors.neutrals.gray800};
    }
  }

  input:disabled {
    background: ${colors.neutrals.gray100} !important;
    color: ${colors.neutrals.gray500} !important;
  }

  /* style EuiButtonGroup as links */
  .pm-eui-button-group-link {
    width: 100%;

    .euiButtonGroupButton {
      height: 16px;
      font-size: 14px;
      margin: 10px 0;
      background-color: transparent;

      &:not(.euiButtonGroupButton-isSelected) {
        box-shadow: -1px 0 0 0 ${colors.brand.meldBlue} !important;
        padding: 0 3px;
      }

      &:first-child {
        box-shadow: none !important;
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      .eui-textTruncate {
        color: ${colors.brand.meldBlue};
        text-decoration: none;
      }
    }
  }
`;

// for applying different styles
// The "descriptionList" variant matches text sizes in a PmDescriptionList
type FormTypes = "default" | "descriptionList" | "fullPageForm";

interface PmFormStylingProps {
  children: React.ReactNode;
  error: string | undefined;
  type?: FormTypes;
}

const PmFormStyling = ({ children, error, type = "default" }: PmFormStylingProps) => {
  return (
    <>
      <Global styles={formCss} />
      <EuiFlexGroup direction="column" gutterSize="l">
        {error && (
          <EuiFlexItem>
            <EuiCallOut iconType="alert" color="danger" title={error} />
          </EuiFlexItem>
        )}
        <EuiFlexItem className={`pmEuiForm ${classNameTypeMap[type]}`}>{children}</EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};

export { PmFormStyling };
