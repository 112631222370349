import BaseApi from "../../utils/base-api";
import ApiUrls from "../../utils/api-urls";

class AgentApi extends BaseApi {
  static fetchAll() {
    return this.get(ApiUrls.agentList);
  }

  static update(agentId: number, data: any) {
    return this.patch(ApiUrls.agentDetail(agentId), data);
  }

  static updateMe(agentData: any) {
    return this.patch(ApiUrls.agentMeDetail, agentData);
  }
}

export default AgentApi;
