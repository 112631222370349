import Features from "@pm-assets/js/common/feature-flags";
import { hasPerm } from "@pm-assets/js/common/utils/permission-utils";
import { Perms } from "@pm-assets/js/constants";
import { numOrStr } from "@pm-assets/js/types/common";
import { AuthUtils } from "@pm-assets/js/utils/auth-utils";
import { decrementXHRCount, incrementXHRCount } from "@pm-assets/js/utils/xhr-counter";
import { CALENDAR_TIMEFRAMES_TYPE } from "@pm-frontend/routes/Calendar/stores/timeFrameStore";
import { loadCalendarAppliedFilterFromStorage } from "../../frontend/src/routes/Calendar/rightpane/MeldCalendarMeldsList";
import { getCalendarDragClickActionType } from "@pm-frontend/routes/Calendar/utils/click-drag-and-drop-utils";
import { CALENDAR_PANE_TYPES } from "@pm-frontend/routes/Calendar/utils/hooks";

export interface SegmentEvent {
  // x is a generic catch-all
  // see : https://www.typescriptlang.org/docs/handbook/2/objects.html#index-signatures
  // there are multiple pre-existing examples throughout the app
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

function getClient() {
  return window.analytics;
}

export function page() {
  const client = getClient();

  if (client) {
    client.page();
  }
}

export function track(event: SegmentEvent, callback?: () => void) {
  const client = getClient();

  if (client) {
    const { name, ...props } = event;

    incrementXHRCount();
    props.email = AuthUtils.getUserEmail();
    props.mid = AuthUtils.getActiveOrg() || "NA";
    props.is_legacy = Features.isLegacyCustomer();
    props.can_manage_subscription = hasPerm(Perms.CAN_MANAGE_SUBSCRIPTION);
    props.management_name = AuthUtils.getManagementName();

    const defaultOptions = {
      integrations: {
        All: true,
      },
    };

    if (window.PM.user.orgType !== "m" && window.Appcues?.track) {
      // Appcues only loaded for management
      window.Appcues.track(name, props);
    }

    if (window.PM.job_title) {
      // we may want to use the built-in segment 'title' reserved field,
      // but at this time I'm not sure how that will handle null values,
      // which we still have for some users' titles
      // https://segment.com/docs/connections/spec/identify/#traits
      props.job_title = window.PM.job_title;
    }

    client.track(name, props, defaultOptions, () => {
      decrementXHRCount();

      if (callback) {
        callback();
      }
    });
  }
}

export const ActivitiesFiltered = (meld_id: numOrStr, filters: string[]) => {
  return { name: "Activities Filtered", meld_id, filters };
};
export const AssignOpened = (meld_id: numOrStr) => {
  return { name: "Assign Opened", meld_id };
};
export const FinishMeldOpened = (meld_id: numOrStr) => {
  return { name: "Finish Meld Opened", meld_id };
};
export const EditWorkEntryOpened = (meld_id: numOrStr) => {
  return { name: "Edit Work Entry Opened", meld_id };
};
export const NewWorkEntryOpened = (meld_id: numOrStr) => {
  return { name: "New Work Entry Opened", meld_id };
};
export const AssignRecommended = (index: number, meld_id: numOrStr) => {
  return { meld_id, name: "Recommendation Assigned", index };
};
export const RecommendSelected = (user_id: numOrStr) => {
  return { name: "Selected Recommendations", user_id };
};
export const ScheduleRecommended = (meld_id: numOrStr) => {
  return { name: "Recommendation Scheduled", meld_id };
};

interface OwnerEventType {
  pageName: string;
  ownerId: number;
  meldId: number;
}

interface VendorEventType {
  pageName?: string;
  vendorId?: number;
  meldId?: number;
  meldFiles?: File[];
}

interface OwnerApprovalEventType {
  ownerComment: string;
  approvalAmount: string;
}

interface OwnerFileEventType {
  meldFiles: number;
  tenantMeldFiles: number;
  vendorMeldFiles: number;
}

export const TrackPWAInstall = () => {
  return {
    name: "PWA install",
    email: AuthUtils.getUserEmail(),
    vendor_id: AuthUtils.getActiveOrg(),
  };
};

export const OwnerChatSent = ({ pageName, ownerId, meldId }: OwnerEventType) => {
  return { name: "Owner Chat Sent", owner_id: ownerId, meld_id: meldId, page_name: pageName };
};

export const OwnerMarkMeldAsRead = ({ pageName, ownerId, meldId }: OwnerEventType) => {
  return { name: "Owner Mark Meld As Read", owner_id: ownerId, meld_id: meldId, page_name: pageName };
};

export const OwnerApprovalApproved = ({
  pageName,
  ownerId,
  meldId,
  ownerComment,
  approvalAmount,
}: OwnerEventType & OwnerApprovalEventType) => {
  return {
    name: "Owner Approval Approved",
    owner_id: ownerId,
    meld_id: meldId,
    page_name: pageName,
    owner_comment: ownerComment,
    approval_amount: approvalAmount,
  };
};

export const OwnerApprovalRejected = ({
  pageName,
  ownerId,
  meldId,
  ownerComment,
  approvalAmount,
}: OwnerEventType & OwnerApprovalEventType) => {
  return {
    name: "Owner Approval Rejected",
    owner_id: ownerId,
    meld_id: meldId,
    page_name: pageName,
    owner_comment: ownerComment,
    approval_amount: approvalAmount,
  };
};

export const OwnerFilesDownloaded = ({
  pageName,
  ownerId,
  meldId,
  meldFiles,
  tenantMeldFiles,
  vendorMeldFiles,
}: OwnerEventType & OwnerFileEventType) => {
  return {
    name: "Owner Files Downloaded",
    owner_id: ownerId,
    meld_id: meldId,
    page_name: pageName,
    meld_files: meldFiles,
    tenant_meld_files: tenantMeldFiles,
    vendor_meld_files: vendorMeldFiles,
  };
};

export const OwnerHubMeldDetailOpened = ({ ownerId, meldId, pageName }: OwnerEventType) => {
  return { name: "Owner Hub Meld Detail Opened", owner_id: ownerId, meld_id: meldId, page_name: pageName };
};

export const VendorMeldDetailsOpened = ({ meldId, pageName }: VendorEventType) => {
  // "vendor_hub>incoming_requests"
  // "vendor_hub>melds_list"
  // "vendor_hub>invoices"
  // "vendor_hub>global_search"
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Meld Details Opened",
    meld_id: meldId,
    page_name: pageName,
  };
};
export const VendorFiltersApplied = ({ meldId, pageName }: VendorEventType) => {
  // "vendor_hub>melds_list"
  // "vendor_hub>melds_invoices"
  return { vendor_id: AuthUtils.getActiveOrg(), name: "Vendor Filters Applied", meld_id: meldId, page_name: pageName };
};
export const VendorFiltersCleared = ({ pageName }: VendorEventType) => {
  // "vendor_hub>melds_list"
  // "vendor_hub>invoices"
  return { vendor_id: AuthUtils.getActiveOrg(), name: "Vendor Filters Cleared", page_name: pageName };
};
export const VendorSortByOpened = ({ meldId, pageName }: VendorEventType) => {
  // "vendor_hub>invoices"
  // "vendor_hub>invoices"
  return { vendor_id: AuthUtils.getActiveOrg(), name: "Vendor Sort By Opened", meld_id: meldId, page_name: pageName };
};
export const VendorContactsOpened = ({ meldId, pageName }: VendorEventType) => {
  // "vendor_hub>meld_details>information"
  // "vendor_hub>meld_details"
  return { vendor_id: AuthUtils.getActiveOrg(), name: "Vendor Contacts Opened", meld_id: meldId, page_name: pageName };
};
export const VendorSchedulingCalendarOpened = ({ meldId, pageName }: VendorEventType) => {
  // "vendor_hub>meld_details>information>schedule"
  // "vendor_hub>meld_details>select_availability"
  // "vendor_hub>melds_list>add_more_availability"
  // "vendor_hub>meld_details>mark_scheduled"
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Scheduling Calendar Opened",
    meld_id: meldId,
    page_name: pageName,
  };
};
export const VendorChatOpened = ({ meldId, pageName }: VendorEventType) => {
  // "vendor_hub>meld_details"
  // "vendor_hub>meld_details>information"
  return { vendor_id: AuthUtils.getActiveOrg(), name: "Vendor Chat Opened", meld_id: meldId, page_name: pageName };
};
export const VendorInvoiceDraftSaved = ({ meldId, pageName }: VendorEventType) => {
  // "vendor_hub>upload_invoice"
  // "vendor_hub>create_invoice"
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Invoice Draft Saved",
    meld_id: meldId,
    page_name: pageName,
  };
};
export const VendorInvoiceSubmitted = ({ meldId, pageName }: VendorEventType) => {
  // "vendor_hub>create_invoice"
  // "vendor_hub>upload_invoice"
  // "vendor_hub>invoices"
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Invoice Submitted",
    meld_id: meldId,
    page_name: pageName,
  };
};
export const VendorInvoiceCanceled = ({ meldId, pageName }: VendorEventType) => {
  // "vendor_hub>upload_invoice"
  // "vendor_hub>create_invoice"
  return { vendor_id: AuthUtils.getActiveOrg(), name: "Vendor Invoice Canceled", meld_id: meldId, page_name: pageName };
};
export const VendorEditInvoiceOpened = ({ meldId, pageName }: VendorEventType) => {
  // "vendor_hub>invoices"
  // "vendor_hub>melds_list>create_invoice"
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Edit Invoice Opened",
    meld_id: meldId,
    page_name: pageName,
  };
};

export const VendorMeldAccepted = ({ meldId }: VendorEventType) => {
  // DONE
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Meld Accepted",
    meld_id: meldId,
    page_name: "vendor_hub>incoming_requests",
  };
};
export const VendorMeldRejected = ({ meldId }: VendorEventType) => {
  // DONE
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Meld Rejected",
    meld_id: meldId,
    page_name: "vendor_hub>incoming_requests",
  };
};
export const VendorInProgressFilterApplied = () => {
  // DONE
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor In Progress Filter Applied",
    page_name: "vendor_hub>melds_list",
  };
};
export const VendorInactiveFilterApplied = () => {
  // DONE
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Inactive Filter Applied",
    page_name: "vendor_hub>melds_list",
  };
};
export const VendorDraftsFilterApplied = () => {
  // DONE
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Drafts Filter Applied",
    page_name: "vendor_hub>invoices",
  };
};
export const VendorSubmittedFilterApplied = () => {
  // DONE
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Submitted Filter Applied",
    page_name: "vendor_hub>invoices",
  };
};
export const VendorClosedFilterApplied = () => {
  // DONE
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Closed Filter Applied",
    page_name: "vendor_hub>invoices",
  };
};
export const VendorMeldInformationOpened = () => {
  // DONE
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Meld Information Opened",
    page_name: "vendor_hub>meld_details",
  };
};
export const VendorDirectionsOpened = ({ meldId }: VendorEventType) => {
  // DONE
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Directions Opened",
    meld_id: meldId,
    page_name: "vendor_hub>meld_details>information",
  };
};
export const VendorFilesUploadedSuccess = ({ meldId, meldFiles }: VendorEventType) => {
  // DONE
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Files Uploaded Success",
    meld_id: meldId,
    page_name: "vendor_hub>meld_details>information",
    meld_files: meldFiles,
  };
};
export const VendorFilesUploadedFailed = ({ meldId, meldFiles }: VendorEventType) => {
  // DONE
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Files Uploaded Failed",
    meld_id: meldId,
    page_name: "vendor_hub>meld_details>information",
    meld_files: meldFiles,
  };
};
export const VendorMeldFinished = ({ meldId }: VendorEventType) => {
  // DONE
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Meld Finished",
    meld_id: meldId,
    page_name: "vendor_hub>meld_details",
  };
};
export const VendorMeldPrinted = ({ meldId }: VendorEventType) => {
  // DONE
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Meld Printed",
    meld_id: meldId,
    page_name: "vendor_hub>meld_details",
  };
};
export const VendorChatSent = ({ meldId }: VendorEventType) => {
  // DONE
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Vendor Chat Sent",
    meld_id: meldId,
    page_name: "vendor_hub>meld_details>chat",
  };
};
export const VendorChatReceiverSelected = ({ meldId }: VendorEventType) => {
  // DONE
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Chat Receiver Selected",
    meld_id: meldId,
    page_name: "vendor_hub>meld_details>chat",
  };
};
export const VendorAppointmentWindowChanged = ({ meldId }: VendorEventType) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Appointment Window Changed",
    meld_id: meldId,
    page_name: "vendor_hub>scheduling_calendar>availability",
  };
};
export const VendorAvailabilitySubmitted = ({ meldId }: VendorEventType) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Availability Submitted",
    meld_id: meldId,
    page_name: "vendor_hub>scheduling_calendar>availability",
  };
};
export const VendorChangesCleared = ({ meldId }: VendorEventType) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Changes Cleared",
    meld_id: meldId,
    page_name: "vendor_hub>scheduling_calendar>availability",
  };
};
export const VendorAvailabilityDeferred = ({ meldId }: VendorEventType) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Availability Deferred",
    meld_id: meldId,
    page_name: "vendor_hub>scheduling_calendar>availability",
  };
};
export const VendorMeldScheduled = ({ meldId }: VendorEventType) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Meld Scheduled",
    meld_id: meldId,
    page_name: "vendor_hub>scheduling_calendar>instant_schedule",
  };
};
export const VendorSchedulingDeferred = ({ meldId }: VendorEventType) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Scheduling Deferred",
    meld_id: meldId,
    page_name: "vendor_hub>scheduling_calendar>instant_schedule",
  };
};
export const VendorInvoiceCreationOpened = ({ meldId }: VendorEventType) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Invoice Creation Opened",
    meld_id: meldId,
    page_name: "vendor_hub>melds_list>create_invoice",
  };
};
export const VendorCreateInvoiceOpened = ({ meldId }: VendorEventType) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Create Invoice Opened",
    meld_id: meldId,
    page_name: "vendor_hub>invoice_creation",
  };
};
export const VendorUploadInvoiceOpened = ({ meldId }: VendorEventType) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Upload Invoice Opened",
    meld_id: meldId,
    page_name: "vendor_hub>invoice_creation",
  };
};

export const VendorImportQuickBooksOpened = ({ meldId }: VendorEventType) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Import From QuickBooks opened",
    meld_id: meldId,
    page_name: "vendor_hub>import_from_QuickBooks",
  };
};

export const VendorImportQuickBooksCanceled = ({ meldId }: VendorEventType) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Import From QuickBooks Canceled",
    meld_id: meldId,
    page_name: "import_from_QuickBooks_canceled>vendor_hub",
  };
};

export const VendorImportQuickBooksSubmitted = ({ meldId }: VendorEventType) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Import From QuickBooks Submitted",
    meld_id: meldId,
    page_name: "invoice_from_QuickBooks_submitted>vendor_hub",
  };
};

export const VendorQuickBooksInvoiceSuggested = ({ meldId }: VendorEventType) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Import From QuickBooks Invoice Suggested",
    meld_id: meldId,
    page_name: "invoice_from_QuickBooks_suggested",
  };
};

export const VendorImportQuickBooksInvoiceSelected = ({ meldId }: VendorEventType) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Import From QuickBooks Invoice Selected",
    meld_id: meldId,
    page_name: "invoice_from_QuickBooks_selected",
  };
};

export const VendorInvoiceDownloaded = ({ meldId }: VendorEventType) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Invoice Downloaded",
    meld_id: meldId,
    page_name: "vendor_hub>invoices",
  };
};
export const VendorNotificationsOpened = ({ meldId }: VendorEventType) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Notifications Opened",
    meld_id: meldId,
    page_name: "vendor_hub",
  };
};
export const VendorAccountSettingsOpened = ({ meldId }: VendorEventType) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Account Settings Opened",
    meld_id: meldId,
    page_name: "vendor_hub>global_search",
  };
};
export const VendorUserManagementOpened = ({ meldId }: VendorEventType) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor User Management Opened",
    meld_id: meldId,
    page_name: "vendor_hub>global_search",
  };
};
export const VendorHelpCenterOpened = ({ meldId }: VendorEventType) => {
  return {
    vendor_id: AuthUtils.getActiveOrg(),
    name: "Vendor Help Center Opened",
    meld_id: meldId,
    page_name: "vendor_hub>global_search",
  };
};

export const EstimateDetailsOpened = ({ meldId, pageName }: VendorEventType) => {
  return { vendor_id: AuthUtils.getActiveOrg(), name: "Estimate Details Opened", meld_id: meldId, page_name: pageName };
};

export const EstimateCreated = (meldId: number, vendorId: number) => {
  return {
    management_id: AuthUtils.getActiveOrg(),
    name: "Estimate Created",
    vendor_id: vendorId,
    meld_id: meldId,
    page_name: "meld_details>estimates>request_estimate",
  };
};

export const DownloadBillsModalOpened = () => {
  return {
    name: "Download Bills Modal Opened",
    page_name: "reporting",
  };
};

export const BillsBulkDownloaded = (startDate?: Date, endDate?: Date) => {
  return {
    name: "Bills Bulk Downloaded",
    page_name: "reporting",
    startDate,
    endDate,
  };
};

// Workflows

export const WorkflowToggleModalOpened = (id: number, pg: "list" | "form") => {
  return {
    name: "Workflow Toggled Modal Opened",
    page_name: `workflows_${pg}`,
    workflow_id: id,
  };
};

export const WorkflowToggled = (id: number, enabled: string | null, pg: "list" | "form", name: string | null) => {
  return {
    name: `Workflow Toggled ${enabled ? "On" : "Off"}`,
    page_name: `workflows_${pg}`,
    workflow_id: id,
    workflow_title: name,
  };
};

export const WorkflowErrorOnCreate = (templateId?: number, title?: string) => {
  return {
    name: "Workflow Error On Save",
    page_name: "workflows_form",
    workflow_template_id: templateId,
    workflow_title: title,
  };
};

export const WorkflowErrorOnUpdate = (id?: number, title?: string) => {
  return {
    name: "Workflow Error On Update",
    page_name: "workflows_form",
    workflow_id: id,
    workflow_title: title,
  };
};

// Owner Services Management-Hub

export const PCPNavigationToggled = () => {
  return {
    name: "PCP Opened",
    page_name: "management_hub > services_index",
  };
};

export const PCPNavigationLandingDisplayed = () => {
  return {
    name: "PCP Landing Displayed",
    page_name: "management_hub > services_landing",
  };
};

export const PCPLearnMoreClicked = () => {
  return {
    name: "PCP Learn More Selected",
    page_name: "management_hub > services_index",
  };
};

export const PCPCreateServiceForm = () => {
  return {
    name: "Service Creation Form Opened",
    page_name: "management_hub > services_index",
  };
};

export const PCPServiceDetailsClicked = () => {
  return {
    name: "Service Details Opened",
    page_name: "management_hub > services_index > see_details",
  };
};

export const PCPServiceCreationCanceled = () => {
  return {
    name: "Service Creation Canceled",
    page_name: "management_hub > service_creation_form",
  };
};

export const PCPServiceUpdateCanceled = () => {
  return {
    name: "Service Update Canceled",
    page_name: "management_hub > service_edit_form",
  };
};

export const PCPServiceCreatedClicked = () => {
  return {
    name: "Service Created",
    page_name: "management_hub > service_creation_form",
  };
};

export const PCPServiceUpdatedClicked = () => {
  return {
    name: "Service Updated",
    page_name: "management_hub > service_edit_form",
  };
};

export const PCPServiceDeleteModalOpened = () => {
  return {
    name: "Service Delete Modal Opened",
    page_name: "management_hub > service_details",
  };
};

export const PCPDeleteServiceClicked = () => {
  return {
    name: "Service Deleted",
    page_name: "management_hub > service_details",
  };
};

export const PCPDeleteServiceCancled = () => {
  return {
    name: "Service Deletion Canceled",
    page_name: "management_hub > service_details",
  };
};

export const PCPServiceEditFormOpened = () => {
  return {
    name: "Service Edit Form Opened",
    page_name: "management_hub > service_details",
  };
};

export const PCPOwnerDetailsClicked = () => {
  return {
    name: "Owner Details Opened from Service",
    page_name: "management_hub > service_details",
  };
};

export const PCPRemoveOwnerModalOpened = () => {
  return {
    name: "Remove Owner Modal Opened",
    page_name: "management_hub > service_details",
  };
};

export const PCPRemoveOwnerClicked = () => {
  return {
    name: "Owner Removed",
    page_name: "management_hub > service_details",
  };
};

export const PCPRemoveOwnerCanceled = () => {
  return {
    name: "Owner Removal Canceled",
    page_name: "management_hub > service_details",
  };
};

export const PCPBackToServiceIndexClicked = () => {
  return {
    name: "Services Index Opened",
    page_name: "management_hub > service_details",
  };
};

// in the UI redesign this does not exist and is just PCPServiceUpdateCanceled
export const PCPBackToServiceDetailClicked = () => {
  return {
    name: "Service Details Opened",
    page_name: "management_hub > service_edit_form",
  };
};

export const PCPCreateServiceFromLandingClicked = () => {
  return {
    name: "PCP Create Service Selected From Landing",
    page_name: "management_hub > services_landing",
  };
};

export const PCPLearnMoreFromLandingClicked = () => {
  return {
    name: "PCP Learn More Selected From Landing",
    page_name: "management_hub > services_landing",
  };
};

export const PCPIntroMaybeLaterClicked = () => {
  return {
    name: "PCP Maybe Later Selected From Landing",
    page_name: "management_hub > services_landing",
  };
};

export const PCPPublishClicked = () => {
  return {
    name: "Service Published",
    page_name: "management_hub > propertycare+ > [service_index | service_details]",
  };
};

export const PCPPublishNotNowClicked = () => {
  return {
    name: "Publish Service Canceled",
    page_name: "management_hub > propertycare+ > [service_index | service_details]",
  };
};

export const PCPUnpublishedClicked = () => {
  return {
    name: "Service Unpublished",
    page_name: "management_hub > propertycare+ > [service_index | service_details]",
  };
};

export const PCPUnpublishedNotNowClicked = () => {
  return {
    name: "Unpublish Service Canceled",
    page_name: "management_hub > propertycare+ > [service_index | service_details]",
  };
};

export const PCPPublishedTabClicked = () => {
  return {
    name: "PCP Published Tab Selected",
    page_name: "management_hub > propertycare+ > services_index",
  };
};

export const PCPDraftsTabClicked = () => {
  return {
    name: "PCP Draft Tab Selected",
    page_name: "management_hub > propertycare+ > services_index",
  };
};

export const PCPPublishFromIndexCardClicked = () => {
  return {
    name: "Service Publish Modal Opened from Index",
    page_name: "management_hub > propertycare+ > services_index",
  };
};

export const PCPPublishFromDetailsCardClicked = () => {
  return {
    name: "Service Publish Modal Opened from Details",
    page_name: "management_hub > propertycare+ > services_details",
  };
};

export const PCPUnpublishFromIndexCardClicked = () => {
  return {
    name: "Service Unpublish Modal Opened from Index",
    page_name: "page_name: management_hub > propertycare+ > services_index",
  };
};

export const PCPUnpublishFromDetailsCardClicked = () => {
  return {
    name: "Service Unpublish Modal Opened from Details",
    page_name: "management_hub > propertycare+ > services_details",
  };
};

export const PCPServiceTemplateTabClicked = () => {
  return {
    name: "Service Templates Tab Opened",
    page_name: "management_hub > propertycare+ > services_index",
  };
};

export const PCPConfigureTemplateButtonClicked = () => {
  return {
    name: "Configure Service with template clicked",
    page_name: "management_hub > propertycare+ > services_index",
  };
};

export const PCPConfigureTemplateButtonClickedFromLanding = () => {
  return {
    name: "Service Templates Index Opened from Landing",
    page_name: "management_hub > propertycare+ > landing_page",
  };
};

export const PCPConfigureTemplateButtonClickedFromTemplateIndex = () => {
  return {
    name: "Service Template Configured from Templates Index",
    page_name: "management_hub > propertycare+ > templates_index",
  };
};

// Owner Services Owner-Hub

export const PCPOwnerNavigationToggled = () => {
  return {
    name: "PCP Owner-Hub Opened",
    page_name: "owner_hub > navigation",
  };
};

export const PCPOwnerNavigationLandingDisplayed = () => {
  return {
    name: "PCP Landing Displayed",
    page_name: "owner_hub > services_landing",
  };
};

export const PCPOwnerLearnMoreClicked = () => {
  return {
    name: "PCP Learn More Selected from Owner Hub index",
    page_name: "owner_hub > services_index",
  };
};

export const PCPOwnerLearnMoreFromLandingClicked = () => {
  return {
    name: "PCP Learn More Selected From Owner Hub Landing",
    page_name: "owner_hub > services_landing",
  };
};

export const PCPOwnerServiceDetailsClicked = () => {
  return {
    name: "Service Owner-Hub Details Opened",
    page_name: "owner_hub > services_index > learn_more",
  };
};

export const PCPOptinNModalOpened = () => {
  return {
    name: "Opt-in Modal Opened",
    page_name: "owner_hub > services_index",
  };
};

export const PCPOptinNModalOpenedFromDetails = () => {
  return {
    name: "Opt-in Modal Opened From Details",
    page_name: "owner_hub > service_details",
  };
};

export const PCPOptInNowClicked = () => {
  return {
    name: "Opt-in Now Selected",
    page_name: "owner_hub > service_opt-in_confirmation",
  };
};

export const PCPOptInNowCanceled = () => {
  return {
    name: "Opt-in Confirmation Canceled",
    page_name: "owner_hub > service_opt-in_confirmation",
  };
};

export const PCPOptedInSeeDetailsClicked = () => {
  return {
    name: "Opted-In Service Details",
    page_name: "owner_hub > services_index > Opted-In Services",
  };
};

export const WLOpened = () => {
  return {
    name: "WL Settings Opened",
    page_name: "management_hub > account_settings > white_labeling",
  };
};

export const WLSubscriptionSettingsOpened = () => {
  return {
    name: "Subscription Settings Opened from WL",
    page_name: "management_hub > account_settings > white_labeling",
  };
};

export const WLSupportContacted = () => {
  return {
    name: "WL Support Contacted",
    page_name: "management_hub > account_settings > white_labeling",
  };
};

export const WLUpgradeLearnMore = () => {
  return {
    name: "Upgrade Learn More Opened from WL",
    page_name: "management_hub > account_settings > white_labeling",
  };
};

export const WLLearnMore = () => {
  return {
    name: "Upgrade Learn More Opened from WL",
    page_name: "management_hub > account_settings > white_labeling",
  };
};

export const WLBannerLearnMore = () => {
  return {
    name: "Upgrade Learn More Opened from WL",
    page_name: "management_hub > bannner",
  };
};

export const WLLogoUploaded = () => {
  return {
    name: "WL Logo Uploaded",
    page_name: "management_hub > account_settings > white_labeling",
  };
};

export const WLOrChooseAnotherPhoto = () => {
  return {
    name: "WL Or Choose Another Photo",
    page_name: "management_hub > account_settings > white_labeling",
  };
};

export const WLLogoSaved = () => {
  return {
    name: "WL Logo Saved",
    page_name: "management_hub > account_settings > white_labeling",
  };
};

export const WLEnabled = () => {
  return {
    name: "White Labeling Enabled",
    page_name: "management_hub > account_settings > white_labeling",
  };
};

export const WLEnabledConfirmed = () => {
  return {
    name: "White Labeling Enabled Confirmed",
    page_name: "management_hub > account_settings > white_labeling",
  };
};

export const WLDisabled = () => {
  return {
    name: "White Labeling Disabled",
    page_name: "management_hub > account_settings > white_labeling",
  };
};

export const WLDisabledConfirmed = () => {
  return {
    name: "White Labeling Disabled Confirmed",
    page_name: "management_hub > account_settings > white_labeling",
  };
};

export const WLEnablementModalClosed = () => {
  return {
    name: "White Labeling Enable Disable Modal Closed",
    page_name: "management_hub > account_settings > white_labeling",
  };
};
// Property group modal
export const PGModalOKClicked = () => {
  return {
    name: "Bulk Property Group Modal Ok selected",
    page_name: "properties > properties",
    user: window.PM.user,
  };
};

export const PGModalCancelClicked = () => {
  return {
    name: "Bulk Property Group Modal Cancel selected",
    page_name: "properties > properties",
  };
};

export const PGModalRemoveAllClicked = () => {
  return {
    name: "Bulk Property Group Modal remove all property groups selected was checked",
    page_name: "properties > properties",
  };
};

export const PGModalAddSelectedClicked = () => {
  return {
    name: "Bulk Property Group Modal add selected property groups to the selected properties was checked",
    page_name: "properties > properties",
  };
};

export const PGModalRemoveSelectedClicked = () => {
  return {
    name: "Bulk Property Group Modal remove selected property groups from the selected properties was checked",
    page_name: "properties > properties",
  };
};

export const IntegrationCardClicked = (integration_name: string) => {
  return {
    name: "Integration Card Clicked",
    integration_name: { integration_name },
    page_name: "management_hub > integrations ",
  };
};

export const IntegrationEnabledClicked = (integration_name: string) => {
  return {
    name: "Integration Enabled Clicked",
    integration_name: { integration_name },
    page_name: "management_hub > integrations",
  };
};

export const BuildiumSetupIntegrationClicked = () => {
  return {
    name: "Buildium Setup Integration Button Clicked",
    page_name: "management_hub > integrations > summary",
  };
};

export const BuildiumContactSupportClicked = () => {
  return {
    name: "Buildium Setup Integration Button Clicked",
    page_name: "management_hub > integrations > summary",
  };
};

export const BuildiumMaybeLaterClicked = () => {
  return {
    name: "Buildium Setup Integration Button Clicked",
    page_name: "management_hub > integrations > summary",
  };
};

export const WFUpsellBannerClosedClicked = () => {
  return {
    name: "Legacy Upgrade - Workflows - Banner Closed",
    page_name: "management_hub > banner",
  };
};

export const WFUpsellBannerLearnHowClicked = () => {
  return {
    name: "Legacy Upgrade - Workflows - Banner Learn How - Clicked",
    page_name: "management_hub > banner",
  };
};

export const WFUpsellBannerSetupWorkflowClicked = () => {
  return {
    name: "Legacy Upgrade - Workflows - Setup Workflow Clicked",
    page_name: "management_hub > upsell modal > setup workflow",
  };
};

export const WFUpsellWorkflowContextUpgradeTodayClicked = () => {
  return {
    name: "Legacy Upgrade - Workflows - Banner Learn How - Clicked",
    page_name: "management_hub > workflows > context count > upgrade today",
  };
};

export const WFUpsellWorkflowModalConfirmClicked = () => {
  return {
    name: "Legacy Upgrade - Workflows - Confirm Upgrade Clicked",
    page_name: "management_hub > workflows > modal > confirm",
  };
};

export const WFUpsellWorkflowUpgradeSuccessCompleted = (userType: string) => {
  return {
    name: `Legacy Upgrade - Workflows - ${userType} - Upgrade Success Completed`,
    page_name: "management_hub > workflows > context count > upgrade today",
  };
};

export const WFUpsellWorkflowContextPopoverLearnMoreClicked = () => {
  return {
    name: "Legacy Upgrade - Workflows - Workflow Context Popover Learn More Clicked",
    page_name: "management_hub > workflows > context count > popover > learn more",
  };
};

export const WFUpsellSettingsUpgradeTodayClicked = () => {
  return {
    name: "Legacy Upgrade - Workflows - Modal - Upgrade Today Clicked",
    page_name: "management_hub > upsell modal > upgrade today",
  };
};

export const WFUpsellPopoverOpened = (location: string) => {
  return {
    name: "Legacy Upgrade - Workflows - Popover Opened",
    page_name: "management_hub > workflows > popover > open",
    popover_location: location,
  };
};

export const WFUpsellPopoverClosed = (location: string) => {
  return {
    name: "Legacy Upgrade - Workflows - Popover Closed",
    page_name: "management_hub > workflows > popover > close",
    popover_location: location,
  };
};

export const WFUpsellSupportContacted = () => {
  return {
    name: "Legacy Upgrade - Workflows - Support Contacted",
    page_name: "management_hub > upsell > contact us",
  };
};

export const WFUpsellRequestAccessClicked = () => {
  return {
    name: "Legacy Upgrade - Workflows - Request Access",
    page_name: "management_hub > upsell > request access",
  };
};

export const WFUpsellTimeSavingsSliderUsed = () => {
  return {
    name: "Legacy Upgrade - Workflows - Time Savings Slider Used",
    page_name: "management_hub > upsell > modal",
  };
};

// GPT

export const ViewChatSummaryClicked = () => {
  return {
    name: "Managemnt Viewed Chat Summary",
    page_name: "management_hub > meld > summary > messages",
  };
};

export const ChatModifyClicked = () => {
  return {
    name: "Management Modified Chat",
    page_name: "management_hub > meld > summary > messages",
  };
};

export const VendorTranslateToOtherClicked = (language: string) => {
  return {
    name: `Vendor Translated Chat to ${language}`,
    page_name: "vendor_hub > meld > summary > messages",
  };
};

export const VendorTranslateToEnglishClicked = () => {
  return {
    name: "Vendor Translated Chat to English",
    page_name: "vendor_hub > meld > summary > messages",
  };
};

export const TenantTranslateToOtherClicked = (language: string) => {
  return {
    name: `Tenant Translated Chat to ${language}`,
    page_name: "tenant_hub > meld > summary > messages",
  };
};

export const TenantTranslateToEnglishClicked = () => {
  return {
    name: "Tenant Translated Chat to English",
    page_name: "tenant_hub > meld > summary > messages",
  };
};

export const UIRedesignToggleEnabled = (pageURL: string) => {
  return {
    name: "UI Redesign Toggle Enabled",
    page_name: pageURL,
  };
};

export const UIRedesignToggleDisabled = (pageURL: string) => {
  return {
    name: "UI Redesign Toggle Disabled",
    page_name: pageURL,
  };
};

// Actionable Insights
export const AIUpsellGetInTouchClicked = () => {
  return {
    name: "Insights Pro - Get in Touch Button Clicked",
    page_name: "management_hub > Actionable Insights > button",
  };
};

// Merge Meld
export const MergeMeldTransitionToConfirmation = () => {
  return {
    name: "Merge Meld - Button that leads to confirmation view clicked.",
    page_name: "management_hub > Meld Detail > Assign flyout > Merge Meld Button",
  };
};

export const MergeMeldActual = (destId: number, sourceId: number) => {
  return {
    name: `Merge Meld - Merge Meld button on confirmation Screen Clicked`,
    page_name: `management_hub > Meld Detail > Assign flyout > Merge Meld Button > Confirmation flyout Merge Meld Button | source:${sourceId} -> destination:${destId}`,
  };
};

export const MergeMeldAlertLinkClicked = (destId: number) => {
  return {
    name: `Merge Meld - Merged Meld Alert link to destination clicked`,
    page_name: `management_hub > Meld Detail > Merged Meld Alert > Link to destination meld | source -> destination:${destId}`,
  };
};

// Integrations

export const CreateUnlinkedUnitClicked = () => {
  return {
    name: "Created unlinked unit",
    page_name: "management_hub > Properties > Units > New Unit",
  };
};

export const CreateUnlinkedPropertyClicked = () => {
  return {
    name: "Created unlinked property",
    page_name: "management_hub > Properties > Properties > New Property",
  };
};

// Nearby Melds

export const NearbyMeldsClicked = () => {
  return {
    name: "User viewed nearby melds",
    page_name: "management_hub > meld > summary",
  };
};

// Global Search (UI Redesign)

interface SearchEvent {
  query_text: string;
  selected_filter: string;
  my_property_groups_filter: boolean;
  mobile: boolean;
}

type SearchFilterSelectedEvent = SearchEvent & { link_location: "sidebar" | "Just * link" };
export const SearchFilterSelected = (
  event: SearchFilterSelectedEvent
): { name: string } & SearchFilterSelectedEvent => {
  return {
    ...event,
    name: "Search Filter Selected - Management App",
  };
};

type SearchResultClickedEvent = SearchEvent & { result_type: string; sub_result_type_clicked: string | undefined };
export const SearchResultClicked = (event: SearchResultClickedEvent): { name: string } & SearchResultClickedEvent => {
  return {
    ...event,
    name: "Search Result Clicked - Management App",
  };
};

type SearchResultViewMoreClickedEvent = SearchEvent & { current_page: number; total_result_count: number };
export const SearchViewMoreClicked = (
  event: SearchResultViewMoreClickedEvent
): { name: string } & SearchResultViewMoreClickedEvent => {
  return {
    ...event,
    name: "Search View More Clicked",
  };
};

type SearchPgCheckboxClickedEvent = SearchEvent;
export const SearchPgCheckboxClicked = (
  event: SearchPgCheckboxClickedEvent
): { name: string } & SearchPgCheckboxClickedEvent => {
  return {
    ...event,
    name: "Search Property Group Checkbox Clicked",
  };
};

// Logs Usage

export const retryAFExportClicked = (logId: number) => {
  return {
    name: `Retry AF Export Clicked`,
    log_id: logId,
    page_name: "management_hub > integrations > appfolio-api > Logs > Retry Export Button",
  };
};
export const LinkVendorFromAFLogsClicked = (logId: number) => {
  return {
    name: `Link Vendor From AF Logs Clicked`,
    log_id: logId,
    page_name: "management_hub > integrations > appfolio-api > Logs > Link Vendor Button",
  };
};

export const fixGLFromAFLogsClicked = (logId: number) => {
  return {
    name: `Fix GL From AF Logs Clicked`,
    log_id: logId,
    page_name: "management_hub > integrations > appfolio-api > Logs > Fix GL Button",
  };
};

interface NexusSearchEventType {
  name: string;
  page_name?: string;
  nexus_id?: number;
  service_area?: string;
  document_type?: string;
  recommend_type?: string;
}

export const NexusPartnerSearchSelected = (): NexusSearchEventType => {
  return {
    name: "Nexus Find New Vendors Clicked",
    page_name: "management_hub > vendors > find_new_vendors",
  };
};

export const NexusPartnerDetailInspected = (nexus_id: number): NexusSearchEventType => {
  return {
    name: "Nexus Vendor Details Clicked",
    page_name: `management_hub > vendors > find_new_vendors > learn_more_about_this_vendor > ${nexus_id}`,
    nexus_id,
  };
};

export const NexusPartnerDetailServiceAreaViewed = (nexus_id: number, service_area: string): NexusSearchEventType => {
  return {
    name: "Nexus Vendor Detail Service Area Clicked",
    page_name: `management_hub > vendors > find_new_vendors > learn_more_about_this_vendor > ${nexus_id} > ${service_area}`,
    nexus_id,
    service_area,
  };
};

export const NexusPartnerDetailFileViewed = (nexus_id: number, document_type: string): NexusSearchEventType => {
  return {
    name: "Nexus Vendor Detail Document Clicked",
    page_name: `management_hub > vendors > find_new_vendors > learn_more_about_this_vendor > ${nexus_id} > ${document_type}`,
    nexus_id,
    document_type,
  };
};

export const NexusPartnerAssignRecommended = (nexus_id: number, recommend_type: string): NexusSearchEventType => {
  return {
    name: "Nexus Vendor Recommended during Assign Action",
    nexus_id,
    recommend_type,
  };
};

export const NexusPartnerAssigned = (nexus_id: number, recommend_type: string): NexusSearchEventType => {
  return {
    name: "Nexus Vendor Assigned",
    nexus_id,
    recommend_type,
  };
};

export const NexusPartnerConnectViewed = (nexus_id: number, recommend_type: string): NexusSearchEventType => {
  return {
    name: "Nexus Vendor Connect Clicked",
    nexus_id,
    recommend_type,
  };
};

// Multi-Appointments

export const AgentCreatedMultipleAppointmentsOnInitialSchedule = (number_of_appointments: number) => {
  return {
    name: "Agent Created Multiple Appointments On Initial Schedule",
    page_name: "management_hub > meld_summary > calendar > Schedule",
    number_of_appointments,
  };
};

export const AgentCreatedAdditionalAppointment = () => {
  return {
    name: "Agent Created Additional Appointment",
    page_name: "management_hub > meld_summary > calendar > add another > schedule",
  };
};

export const AgentRescheduledAppointment = () => {
  return {
    name: "Agent Reschedulued Appointment",
    page_name: "management_hub > meld_summary > calendar > reschedulue",
  };
};

export const AgentSentAvailabilityWithRequiredAmountOfAppointments = (number_of_required_appointments: number) => {
  return {
    name: "Agent Sent Availability With Required Amount Of Appointments",
    page_name: "management_hub > meld_summary > calendar > offer availability to resident > offer availability",
    number_of_required_appointments,
  };
};

export const VendorCreatedMultipleAppointmentsOnInitialSchedule = (number_of_appointments: number) => {
  return {
    name: "Vendor Created Multiple Appointments On Initial Schedule",
    page_name: "vendor_hub > meld_summary > schedule",
    number_of_appointments,
  };
};

export const VendorCreatedAdditionalAppointment = () => {
  return {
    name: "Vendor Created Additional Appointment",
    page_name: "vendor_hub > meld_summary > schedule",
  };
};

export const VendorRescheduledAppointment = () => {
  return {
    name: "Vendor Reschedulued Appointment",
    page_name: "vendor_hub > meld_summary > schedule",
  };
};

export const VendorSentAvailabilityWithRequiredAmountOfAppointments = (number_of_required_appointments: number) => {
  return {
    name: "Vendor Sent Availability With Required Amount Of Appointments",
    page_name: "vendor_hub > meld_summary > schedule",
    number_of_required_appointments,
  };
};

/*
----------------------
|                    |
|  Scheduler events  |
|                    |
----------------------
*/

export interface CalendarEventMetaData {
  isMobile: boolean;
  selectedTimeframe: CALENDAR_TIMEFRAMES_TYPE;
  daysOffsetFromCurrentDay: number;
  minMultiDayColumnWidth: number;
}

/**
 * Meta data we want to capture for all calendar events
 */
const getCalendarEventMetaData = (props: CalendarEventMetaData) => {
  return {
    isMobile: props.isMobile,
    // what is the most popular view to use the calendar with?
    selectedTimeframe: props.selectedTimeframe,
    // how far in the future are people working?
    daysOffsetFromCurrentDay: props.daysOffsetFromCurrentDay,
    minMultiDayColumnWidth: props.minMultiDayColumnWidth,
  };
};

export const CalendarMeldSchedule = (
  props: CalendarEventMetaData & {
    activeRightpane: CALENDAR_PANE_TYPES;
    initiator:
      | "recommened"
      | "draganddrop"
      | "click"
      | "form"
      | "offeredAvailabilityRightpane"
      | "offeredAvailabilityCalendarEvent";
    isMapOpen: "large" | "small" | "none";
    target: "agent" | "vendor";
    actionType: ReturnType<typeof getCalendarDragClickActionType> | "bookAvailability" | "none";
    appliedQuickFilter: ReturnType<typeof loadCalendarAppliedFilterFromStorage>;
  }
) => {
  return {
    name: "Calendar Meld pane scheduled",
    activeRightpane: props.activeRightpane,
    initiator: props.initiator,
    isMapOpen: props.isMapOpen,
    action: props.actionType,
    appliedQuickFilter: props.appliedQuickFilter,
    ...getCalendarEventMetaData(props),
  };
};

export const CalendarMeldAssign = (
  props: CalendarEventMetaData & {
    personaType: "agent" | "vendor";
  }
) => ({
  name: "Calendar Meld assigned in rightpane",
  personaType: props.personaType,
  ...getCalendarEventMetaData(props),
});

// this is something we'd like to minimize - needing to visit
// the meld details pane before scheduling
export const CalendarMeldViewDetailsClicked = (
  props: CalendarEventMetaData & {
    meldId: number;
  }
) => ({
  name: "Calendar Meld - 'View Details' clicked in rightpane",
  meldId: props.meldId,
  ...getCalendarEventMetaData(props),
});

export const CalendarMeldMapOpened = (
  props: CalendarEventMetaData & {
    type: "large" | "small-agent" | "small-vendor";
  }
) => ({
  name: "Calendar Meld - Map opened",
  type: props.type,
  ...getCalendarEventMetaData(props),
});

// useful in flow analysis to find out if users are scheduling
// nearby melds
export const CalendarMeldMapNearbyMeldOpened = (
  props: CalendarEventMetaData & {
    type: "large" | "small-agent" | "small-vendor";
  }
) => ({
  name: "Calendar Meld - Map - Nearby Meld opened",
  type: props.type,
  ...getCalendarEventMetaData(props),
});

export const CalendarMeldMapNearbyMeldsHidden = (props: CalendarEventMetaData) => ({
  name: "Calendar Meld - Map - Nearby Meld hidden",
  ...getCalendarEventMetaData(props),
});

export const CalendarRedesignToggledOn = (isMobile: boolean) => ({
  name: "Calendar Redesign Toggled On",
  isMobile,
});

export const CalendarRedesignToggledOff = (isMobile: boolean) => ({
  name: "Calendar Redesign Toggled Off",
  isMobile,
});

export const CalendarRedesignPageLoaded = (
  isMobile: boolean,
  agentTitle: string,
  selectedTimeframe: CALENDAR_TIMEFRAMES_TYPE
) => ({
  name: "Calendar Meld - New Calendar Page Opened",
  isMobile,
  agentTitle,
  selectedTimeframe,
});

export const CalendarRedesignDraftModeToggled = (selectedTimeframe: CALENDAR_TIMEFRAMES_TYPE, newValue: boolean) => ({
  name: `Calendar Meld - Draft Mode ${newValue ? "enabled" : "disabled without saving"}`,
  selectedTimeframe,
});

export const MaxLoadError = (message: string, status: number | string) => ({
  name: "Max Load Error",
  message,
  status,
});

export const LegacyMeldTimeDuration = (durationInSeconds: number) => ({
  name: "Management Legacy Meld - Time Spent",
  durationInSeconds,
});
