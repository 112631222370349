import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";

class TenantApi extends BaseApi {
  static save(tenant: any, { options }: any = {}) {
    return this.post(ApiUrls.tenantList, tenant, options);
  }

  static fetchAll({ options }: any = {}) {
    return this.get(ApiUrls.tenantList, options);
  }

  static fetchOne(id: number) {
    return this.get(ApiUrls.tenantDetail(id));
  }

  static update(tenant: { id: number }) {
    return this.put(ApiUrls.tenantDetail(tenant.id), tenant);
  }

  static fetchAllUnits(tenantId: number) {
    return this.get(ApiUrls.tenantUnits(tenantId));
  }

  static deleteTenant(id: number) {
    return this.delete(ApiUrls.tenantDetail(id));
  }

  static sendResetPasswordTenant(id: number) {
    return this.post(ApiUrls.tenantSendResetPassword(id));
  }

  static fetchMaxEmbedToken() {
    return this.get(ApiUrls.maxEmbedToken);
  }

  static search(
    query: string,
    {
      tenantIdExclusions,
      unregistered,
      unit,
    }: {
      tenantIdExclusions?: number[];
      unregistered?: boolean;
      unit?: number;
    } = {}
  ) {
    return this.get(ApiUrls.tenantSearchList, {
      params: {
        query,
        exclude_ids: tenantIdExclusions,
        unregistered,
        unit,
      },
    });
  }

  static testBulkInvite(includeIds: number[], excludeIds: number[]) {
    return this.post(ApiUrls.tenantBulkInviteTest, {
      include_ids: includeIds,
      exclude_ids: excludeIds,
    });
  }

  static sendBulkInvite(includeIds: number[], excludeIds: number[]) {
    return this.post(ApiUrls.tenantBulkInvite, {
      include_ids: includeIds,
      exclude_ids: excludeIds,
    });
  }
}

export default TenantApi;
