import { apiFetch, apiPatch } from "@pm-frontend/shared/utils/apiFetch";
import { ApiUrls } from "@pm-frontend/shared/utils/api-urls";
import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";
import { NotificationSettingsDetailViewSerializer } from "@pm-frontend/shared/types/api/manager/serializers/notification_settings_detail_view_serializer";

let timezoneHasBeenSet = false;

/**
 * If a user has never set their timezeone (check via NotificationSettings.timezone_has_been_set)
 * then we update their timzeone with their browser timezone
 */
export function autoUpdateNotificationTimzone() {
  if (timezoneHasBeenSet) {
    return;
  }

  // we want everything else on the event loop to finish first
  setTimeout(async () => {
    const url = LinkHelper.normalize(ApiUrls.notificationSettings);
    const notificationSettings: NotificationSettingsDetailViewSerializer | undefined = await apiFetch(url);
    timezoneHasBeenSet = true;
    if (!notificationSettings) {
      return;
    }
    if (notificationSettings.timezone_has_been_set) {
      return;
    }

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    await apiPatch(url, { ...notificationSettings, timezone_has_been_set: true, timezone });
  }, 0);
}
