import React from "react";
import moment from "moment";

import { PmText } from "@pm-frontend/shared/components/Text/PmText";
import { colors } from "@pm-frontend/styles";
import { PmBanner } from "@pm-frontend/shared/components/Banners/PmBanner";
import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";
import { RouteUrls } from "@pm-frontend/shared/utils/route-urls";
import { WorkEntryOpenListViewSerializer } from "@pm-frontend/shared/types/api/meld/serializers/work_entry_open_list_view_serializer";
import { GlobalBannerEmptySpace } from "@pm-frontend/shared/layouts/GlobalBanners";
import { useManagementGlobalBannerActions } from "./managementGlobalBannerStore";

interface ManagementWorkLogCheckoutBannerProps {
  workEntries: WorkEntryOpenListViewSerializer[];
}

const ManagementWorkLogCheckoutBanner = ({ workEntries }: ManagementWorkLogCheckoutBannerProps) => {
  if (workEntries.length === 0) {
    return <GlobalBannerEmptySpace />;
  }
  const { ignoreWorkLogId } = useManagementGlobalBannerActions();

  const currentOpenWorkEntry = workEntries[0];
  const now = moment();
  const checkinTime = moment(currentOpenWorkEntry.checkin);
  const openWorkEntryDuration = moment.duration(now.diff(checkinTime));
  const hours = Math.floor(openWorkEntryDuration.asHours());
  const minutes = openWorkEntryDuration.minutes();

  const hourText = hours === 1 ? "hour" : "hours";
  const minuteText = minutes === 1 ? "minute" : "minutes";

  const workLogCheckOutReminderBannerBody: React.ReactNode = (
    <PmText fontSize="p2" color={colors.neutrals.gray50}>
      A work log has been open for{" "}
      <b>
        {" "}
        {hours} {hourText}
      </b>
      ,{" "}
      <b>
        {minutes} {minuteText}
      </b>
      . Be sure to check out when work is complete.
    </PmText>
  );

  const worklogUrl: string =
    LinkHelper.normalize(RouteUrls.meldDetailsWorkLog({ id: currentOpenWorkEntry.meld })) +
    `?worklog=${currentOpenWorkEntry.id}`;

  return (
    <div>
      <PmBanner
        data-testid="open-work-log-banner-management"
        bannerButtonProps={{
          text: "Check Out",
          isLoading: false,
          href: worklogUrl,
          dataTestId: "open-work-log-banner-button",
          internalLink: true,
        }}
        showMultipleBanners={workEntries.length > 1}
        onClose={() => ignoreWorkLogId(currentOpenWorkEntry.id)}
      >
        {workLogCheckOutReminderBannerBody}
      </PmBanner>
    </div>
  );
};

export { ManagementWorkLogCheckoutBanner };
