var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React from "react";
import { EuiPanel, EuiFlexGroup, EuiFlexItem, EuiLink, EuiImage } from "@elastic/eui";
import { PmEmptyButton } from "@pm-frontend/shared/components/Buttons/PmEmptyButton";
import { colors } from "@pm-frontend/styles";
import URL from "@pm-shared/utils/url";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";
const DEFAULT_SUBMIT_BUTTON_TEXT = "Okay";
const BannerContent = (_a) => {
  var _b = _a, {
    backgroundColor = colors.brand.meldBlue,
    bannerButtonProps,
    children,
    onClose
  } = _b, rest = __objRest(_b, [
    "backgroundColor",
    "bannerButtonProps",
    "children",
    "onClose"
  ]);
  const isMobile = useIsMobile();
  return /* @__PURE__ */ React.createElement(
    EuiPanel,
    __spreadValues({
      hasBorder: true,
      hasShadow: false,
      style: { backgroundColor: backgroundColor || colors.brand.meldBlue, padding: "11px" }
    }, rest),
    isMobile ? /* @__PURE__ */ React.createElement(EuiFlexGroup, { gutterSize: "s", direction: "column", responsive: false }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { gutterSize: "s", direction: "row", responsive: false }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: true }, children), onClose && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, style: { minWidth: "1px" } }, /* @__PURE__ */ React.createElement(EuiLink, { onClick: onClose }, /* @__PURE__ */ React.createElement(
      EuiImage,
      {
        src: backgroundColor !== colors.brand.meldBlue ? URL.getStatic("icons/close_no_fill.svg") : URL.getStatic("icons/close_white.svg"),
        alt: "Close banner",
        style: { minWidth: "16px" }
      }
    ))))), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { gutterSize: "s", direction: "row", responsive: false }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, bannerButtonProps && /* @__PURE__ */ React.createElement(
      PmEmptyButton,
      __spreadValues({}, __spreadProps(__spreadValues({}, bannerButtonProps), {
        color: "primary",
        "data-testid": bannerButtonProps.dataTestId || "banner-submit-button",
        text: bannerButtonProps.text || DEFAULT_SUBMIT_BUTTON_TEXT,
        textSize: "14px",
        padding: "5px 22.29px"
      }))
    ))))) : /* @__PURE__ */ React.createElement(EuiFlexGroup, { gutterSize: "s", direction: "row", alignItems: "center", responsive: false }, /* @__PURE__ */ React.createElement(EuiFlexItem, null, children), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { gutterSize: "s", direction: "row", responsive: false }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, bannerButtonProps && /* @__PURE__ */ React.createElement(
      PmEmptyButton,
      __spreadValues({}, __spreadProps(__spreadValues({}, bannerButtonProps), {
        color: "primary",
        "data-testid": bannerButtonProps.dataTestId || "banner-submit-button",
        text: bannerButtonProps.text || DEFAULT_SUBMIT_BUTTON_TEXT,
        textSize: "14px",
        padding: "5px 22.29px"
      }))
    )), onClose && /* @__PURE__ */ React.createElement(EuiFlexItem, { style: { justifyContent: "center" }, grow: false }, /* @__PURE__ */ React.createElement(EuiLink, { onClick: onClose }, /* @__PURE__ */ React.createElement(
      EuiImage,
      {
        src: backgroundColor !== colors.brand.meldBlue ? URL.getStatic("icons/close_no_fill.svg") : URL.getStatic("icons/close_white.svg"),
        alt: "Close banner",
        style: { minWidth: "16px" }
      }
    ))))))
  );
};
const PmBanner = (_c) => {
  var _d = _c, {
    backgroundColor = colors.brand.meldBlue,
    showMultipleBanners,
    bannerButtonProps,
    children,
    onClose
  } = _d, rest = __objRest(_d, [
    "backgroundColor",
    "showMultipleBanners",
    "bannerButtonProps",
    "children",
    "onClose"
  ]);
  return /* @__PURE__ */ React.createElement(EuiFlexGroup, { gutterSize: "none", direction: "column", responsive: false }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
    BannerContent,
    __spreadValues({
      backgroundColor,
      bannerButtonProps,
      onClose
    }, rest),
    children
  )), showMultipleBanners && /* @__PURE__ */ React.createElement(
    EuiFlexItem,
    {
      grow: false,
      style: {
        backgroundColor,
        height: "12px",
        marginLeft: "11px",
        marginRight: "11px",
        borderBottomLeftRadius: "6px",
        borderBottomRightRadius: "6px"
      }
    }
  ));
};
export { PmBanner };
