import { useState } from "react";

// for more concise declaration of states for toggling modals and flyouts
const useOpenCloseState = (initialState: boolean): [boolean, () => void, () => void] => {
  const [state, setState] = useState(initialState);
  const setStateTrue = () => setState(true);
  const setStateFalse = () => setState(false);

  return [state, setStateTrue, setStateFalse];
};

interface ToggleState {
  isOpen: boolean;
  close: () => void;
  open: () => void;
}

/**
 * A custom hook for controlling the state of modals/flyouts/etc
 * @returns
 *   An object of type `ToggleState` with the following properties
 *   isOpen: boolean
 *   open: () => void
 *   close: () => void
 */
const useOpenCloseStateObject = (initialState: boolean): ToggleState => {
  const [state, setState] = useState(initialState);

  return {
    isOpen: state,
    open: () => setState(true),
    close: () => setState(false),
  };
};

export { useOpenCloseState, useOpenCloseStateObject, ToggleState };
