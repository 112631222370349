var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { create } from "zustand";
import { matchPath, useLocation } from "react-router-dom";
import { useCalendarDraftModeEnabled } from "@pm-frontend/routes/Calendar/stores/draftModeStore";
import { useGetOpenWorkEntries } from "@pm-frontend/routes/Melds/queries";
import { ReactRouterDomRoutes } from "@pm-assets/js/utils/redesign-routing";
import { StorageUtils } from "@pm-frontend/shared/utils/storage-utils";
const IGNORED_WORK_LOGS_LS_KEY = "workLogIdsToIgnore";
const getIgnoredWorkLogIdsLS = () => {
  const defaultValue = {};
  const { ok, value: savedIds } = StorageUtils.getLocalStorageItem(IGNORED_WORK_LOGS_LS_KEY);
  if (!ok) {
    return defaultValue;
  } else if (savedIds) {
    return JSON.parse(savedIds);
  } else {
    return defaultValue;
  }
};
const setIgnoredWorkLogIdsLS = (workLogIds) => {
  StorageUtils.setLocalStorageItem(IGNORED_WORK_LOGS_LS_KEY, JSON.stringify(workLogIds));
};
const useManagementGlobalBannerStore = create((set) => ({
  workLogIdsToIgnore: getIgnoredWorkLogIdsLS(),
  actions: {
    ignoreWorkLogId: (workLogId) => set((state) => {
      const newState = __spreadProps(__spreadValues({}, state.workLogIdsToIgnore), { [workLogId]: true });
      setIgnoredWorkLogIdsLS(newState);
      return { workLogIdsToIgnore: newState };
    })
  }
}));
const CalendarUrls = [
  ReactRouterDomRoutes.meldCalendar,
  ReactRouterDomRoutes.meldCalendarTech,
  ReactRouterDomRoutes.meldCalendarMeldDetails,
  ReactRouterDomRoutes.meldCalendarMeldDetailsBook,
  ReactRouterDomRoutes.meldCalendarMeldAvailabilities
];
const useGetManagementGlobalBannerVisibility = () => {
  const calendarDraftModeEnabled = useCalendarDraftModeEnabled();
  const location = useLocation();
  const ignoredWorkLogIds = useManagementGlobalBannerStore((state) => state.workLogIdsToIgnore);
  const { data: openWorkEntries } = useGetOpenWorkEntries();
  if (calendarDraftModeEnabled && matchPath(location.pathname, CalendarUrls)) {
    return { type: "calendarDraftMode" };
  }
  if (openWorkEntries) {
    const filteredOpenWorkEntries = openWorkEntries.filter((entry) => !ignoredWorkLogIds[entry.id]);
    if (filteredOpenWorkEntries.length > 0) {
      return { type: "worklogs", filteredOpenWorkEntries };
    }
  }
  return null;
};
const useManagementGlobalBannerActions = () => useManagementGlobalBannerStore((state) => state.actions);
export { useGetManagementGlobalBannerVisibility, useManagementGlobalBannerActions };
