import React, { useState } from "react";
import {
  EuiDatePicker,
  EuiDatePickerProps,
  EuiDatePickerRange,
  EuiFieldNumber,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSuperSelectProps,
  EuiSuperSelect,
  EuiFieldNumberProps,
} from "@elastic/eui";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { parseISO } from "date-fns";

import { PmFormStyling } from "../../Forms/PmFormStyling";
import {
  BaseDateFilterClass,
  DATE_RANGE_FIRST_SUFFIX,
  DATE_RANGE_SECOND_SUFFIX,
  PmDateFilterAllowedOperators,
  PmDateFilterOperatorValue,
  PmDateFilterTimeframeOptions,
  PM_DATE_FILTER_INPUT_TYPES,
  PM_DATE_FILTER_OPERATORS,
} from "./BaseDateFilterClass";
import { PmFilterButtonsCommitButtons } from "../subcomponents/PmFilterButtonsCommitControls";
import { MobileDateTimePicker, MobileDateTimePickerProps } from "../../DatePickerMobile/MobileDateTimePicker";

// just a utility type for grabbing two specific variants of a union
type ExtractMember<U, T extends U[keyof U]> = U extends { type: T } ? U : never;

const OneDatePicker = ({
  pendingOperatorAndValue,
  setPendingOperatorAndValue,
  isMobile,
}: {
  pendingOperatorAndValue: ExtractMember<NonNullable<PmDateFilterOperatorValue>, "one-date-input">;
  setPendingOperatorAndValue: React.Dispatch<React.SetStateAction<NonNullable<PmDateFilterOperatorValue>>>;
  isMobile: boolean;
}) => {
  const dateParamValue = pendingOperatorAndValue.value;

  if (isMobile) {
    const onDateChangeMobile: MobileDateTimePickerProps["onChange"] = (e) => {
      const newValue = e.target.value ? parseISO(e.target.value).valueOf().toString() : "";
      setPendingOperatorAndValue((state) => ({ ...state, value: newValue }));
    };
    const parsedParamValueMobile = parseInt(dateParamValue, 10);
    const dateValueMobile = Number.isFinite(parsedParamValueMobile) ? new Date(parsedParamValueMobile) : "";

    return (
      <MobileDateTimePicker
        type="date"
        value={dateValueMobile && isNaN(dateValueMobile.getTime()) ? "" : dateValueMobile}
        onChange={onDateChangeMobile}
      />
    );
  }
  const parsedParamValue = parseInt(dateParamValue, 10);
  const dateSelectedValue: EuiDatePickerProps["selected"] = Number.isFinite(parsedParamValue)
    ? moment(parsedParamValue)
    : null;

  const onDateChange: EuiDatePickerProps["onChange"] = (newDate) => {
    const dateValue = newDate ? moment(newDate).startOf("day").toDate().getTime().toString() : "";
    setPendingOperatorAndValue((state) => ({ ...state, value: dateValue }));
  };

  return (
    <EuiDatePicker
      selected={dateSelectedValue}
      onChange={onDateChange}
      showTimeSelect={false}
      shouldCloseOnSelect={true}
      id={
        pendingOperatorAndValue.operator === PM_DATE_FILTER_OPERATORS.BEFORE
          ? DATE_RANGE_SECOND_SUFFIX
          : DATE_RANGE_FIRST_SUFFIX
      }
      data-testid={
        pendingOperatorAndValue.operator === PM_DATE_FILTER_OPERATORS.BEFORE
          ? "two-dates-second-date"
          : "two-dates-first-date"
      }
      adjustDateOnChange={false}
      autoComplete="off"
    />
  );
};

const TwoDatePicker = ({
  pendingOperatorAndValue,
  setPendingOperatorAndValue,
  isMobile,
}: {
  pendingOperatorAndValue: ExtractMember<NonNullable<PmDateFilterOperatorValue>, "two-date-input">;
  setPendingOperatorAndValue: React.Dispatch<React.SetStateAction<NonNullable<PmDateFilterOperatorValue>>>;
  isMobile: boolean;
}) => {
  const firstParamValue = pendingOperatorAndValue.firstDate;
  const secondParamValue = pendingOperatorAndValue.secondDate;

  if (isMobile) {
    const getOnDateChangeMobile: (type: "first" | "second") => MobileDateTimePickerProps["onChange"] = (type) => {
      return (e) => {
        const newValue = e.target.value ? parseISO(e.target.value).valueOf().toString() : "";
        setPendingOperatorAndValue((state) => ({
          ...state,
          [type === "first" ? "firstDate" : "secondDate"]: newValue,
        }));
      };
    };
    const parsedFirstValue = parseInt(firstParamValue, 10);
    const firstValueMobile = Number.isFinite(parsedFirstValue) ? new Date(parsedFirstValue) : "";

    const parsedSecondValue = parseInt(secondParamValue, 10);
    const secondValueMobile = Number.isFinite(parsedSecondValue) ? new Date(parsedSecondValue) : "";

    return (
      <>
        <MobileDateTimePicker
          type="date"
          value={firstValueMobile && isNaN(firstValueMobile.getTime()) ? "" : firstValueMobile}
          onChange={getOnDateChangeMobile("first")}
        />
        <MobileDateTimePicker
          type="date"
          value={secondValueMobile && isNaN(secondValueMobile.getTime()) ? "" : secondValueMobile}
          onChange={getOnDateChangeMobile("second")}
        />
      </>
    );
  }

  const firstParsedValue = parseInt(firstParamValue, 10);
  const secondParsedValue = parseInt(secondParamValue, 10);

  const firstValue = Number.isFinite(firstParsedValue) ? moment(firstParsedValue) : null;
  const secondValue = Number.isFinite(secondParsedValue) ? moment(secondParsedValue) : null;

  const getOnDateChange: (type: "first" | "second") => EuiDatePickerProps["onChange"] = (type) => {
    return (date) => {
      const newDate = date ? moment(date).startOf("day").toDate().getTime().toString() : "";
      setPendingOperatorAndValue((state) => ({ ...state, [type === "first" ? "firstDate" : "secondDate"]: newDate }));
    };
  };

  return (
    <EuiDatePickerRange
      startDateControl={
        <EuiDatePicker
          onChange={getOnDateChange("first")}
          selected={firstValue}
          showTimeSelect={false}
          shouldCloseOnSelect={true}
          id={DATE_RANGE_FIRST_SUFFIX}
          data-testid="two-dates-first-date"
          adjustDateOnChange={false}
          autoComplete="off"
        />
      }
      endDateControl={
        <EuiDatePicker
          onChange={getOnDateChange("second")}
          selected={secondValue}
          showTimeSelect={false}
          shouldCloseOnSelect={true}
          id={DATE_RANGE_SECOND_SUFFIX}
          aria-label="two-dates-second-date"
          adjustDateOnChange={false}
          autoComplete="off"
        />
      }
    />
  );
};

const TimeFrame = ({
  filter,
  pendingOperatorAndValue,
  setPendingOperatorAndValue,
}: {
  filter: BaseDateFilterClass;
  pendingOperatorAndValue: ExtractMember<NonNullable<PmDateFilterOperatorValue>, "timeframe">;
  setPendingOperatorAndValue: React.Dispatch<React.SetStateAction<NonNullable<PmDateFilterOperatorValue>>>;
}) => {
  const selectedTimeframeValue = pendingOperatorAndValue.timeframeValue;
  const numberFieldValue = pendingOperatorAndValue.numberValue;

  const onSelectedTimeframeChange: EuiSuperSelectProps<PmDateFilterTimeframeOptions>["onChange"] = (newTimeframe) => {
    setPendingOperatorAndValue((state) => ({ ...state, timeframeValue: newTimeframe }));
  };

  const onNumberChange: EuiFieldNumberProps["onChange"] = (event) => {
    setPendingOperatorAndValue((state) => ({ ...state, numberValue: event.target.value }));
  };

  return (
    <EuiFlexGrid direction="row" gutterSize="m" responsive={false} columns={2}>
      <EuiFlexItem grow={false}>
        <EuiFormRow label="Number">
          <EuiFieldNumber
            value={numberFieldValue}
            onChange={onNumberChange}
            placeholder="# of"
            step={1}
            fullWidth={false}
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFormRow label="Timeframe">
          <EuiSuperSelect
            options={filter.getSelectedTimeframeOptions({ selectedOperator: pendingOperatorAndValue.operator })}
            valueOfSelected={selectedTimeframeValue}
            onChange={onSelectedTimeframeChange}
            fullWidth={false}
          />
        </EuiFormRow>
      </EuiFlexItem>
    </EuiFlexGrid>
  );
};

interface PmDateComponentProps {
  filter: BaseDateFilterClass;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  savedFilter: Record<string, any | any[]> | undefined;
  closePopover: () => void;
  currentOperatorAndValue: PmDateFilterOperatorValue;
  isMobile: boolean;
}

const PmDateFilter = ({
  filter,
  savedFilter,
  closePopover,
  currentOperatorAndValue,
  isMobile,
}: PmDateComponentProps) => {
  const location = useLocation();
  const history = useHistory();
  let body: React.ReactNode;

  const [pendingOperatorAndValue, setPendingOperatorAndValue] = useState<NonNullable<PmDateFilterOperatorValue>>(() => {
    return (
      currentOperatorAndValue || {
        operator: PM_DATE_FILTER_OPERATORS.AFTER,
        value: "",
        type: PM_DATE_FILTER_INPUT_TYPES.ONE_DATE,
        filterType: BaseDateFilterClass.type,
      }
    );
  });

  switch (pendingOperatorAndValue.type) {
    case PM_DATE_FILTER_INPUT_TYPES.TWO_DATE:
      body = (
        <TwoDatePicker
          pendingOperatorAndValue={pendingOperatorAndValue}
          setPendingOperatorAndValue={setPendingOperatorAndValue}
          isMobile={isMobile}
        />
      );
      break;
    case PM_DATE_FILTER_INPUT_TYPES.ONE_DATE:
      body = (
        <OneDatePicker
          pendingOperatorAndValue={pendingOperatorAndValue}
          setPendingOperatorAndValue={setPendingOperatorAndValue}
          isMobile={isMobile}
        />
      );
      break;
    case PM_DATE_FILTER_INPUT_TYPES.TIMEFRAME:
      body = (
        <TimeFrame
          pendingOperatorAndValue={pendingOperatorAndValue}
          setPendingOperatorAndValue={setPendingOperatorAndValue}
          filter={filter}
        />
      );
      break;
    default:
      break;
  }

  const onOperatorChange = (newOperator: PmDateFilterAllowedOperators) => {
    const newOperatorAndValue = filter.getNewValueFromNewOperator({
      newOperator,
      currentPendingOperatorAndValue: pendingOperatorAndValue,
    });
    setPendingOperatorAndValue(newOperatorAndValue);
  };

  return (
    <PmFormStyling error={undefined}>
      <EuiFlexGroup
        direction="column"
        gutterSize="s"
        style={{ width: isMobile ? `min(80vw, ${filter.getPopoverWidth()})` : filter.getPopoverWidth() }}
      >
        <EuiFlexItem grow={false}>
          <EuiSuperSelect
            onChange={onOperatorChange}
            valueOfSelected={pendingOperatorAndValue.operator}
            options={filter.getAllowedOperatorOptions()}
            aria-label="Select an operator"
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false} key={pendingOperatorAndValue.operator}>
          {body}
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <PmFilterButtonsCommitButtons
            onApplyClick={filter.getOnApplyClick({
              location,
              savedFilter,
              history,
              currentPendingOperatorValue: pendingOperatorAndValue,
              closePopover,
            })}
            onClearClick={filter.getOnClearClick({ closePopover, location, savedFilter, history })}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </PmFormStyling>
  );
};

export { PmDateFilter };
