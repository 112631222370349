import React from "react";
import { EuiButton, EuiLink } from "@elastic/eui";

import { colors } from "@pm-frontend/styles";
import { Link, LinkProps } from "react-router-dom";
import { CSSObject } from "@emotion/css";

type PmButtonProps =
  | {
      onClick: () => void;
      href?: never;
      internalLink?: never;
      formId?: never;
      linkToProps?: never;
    }
  | {
      onClick?: () => void;
      href: string;
      internalLink?: boolean;
      formId?: never;
      linkToProps?: never;
    }
  | {
      onClick?: () => void;
      href?: never;
      internalLink?: never;
      formId: string;
      linkToProps?: never;
    }
  | {
      // this variant is for specifying the to attribute of a <Link>
      // https://v5.reactrouter.com/web/api/Link/to-object
      onClick?: () => void;
      href?: never;
      internalLink?: never;
      formId?: never;
      linkToProps: LinkProps["to"];
    };

type PmFilledButtonProps = {
  text: string;
  color?: "primary" | "danger";
  fontSize?: string;
  padding?: string;
  "data-testid"?: string;
  isEnabled?: boolean;
  isLoading: boolean;
  fullWidth?: boolean;
  minWidth?: string | undefined;
} & PmButtonProps;

const classNameMap: Record<"danger" | "primary", string> = {
  danger: "dangerFillButton",
  primary: "primaryFillButton",
};

const backgroundColorMap: Record<"danger" | "primary" | "accent", string> = {
  danger: colors.interface.red.default,
  primary: colors.brand.meldBlue,
  accent: colors.neutrals.gray500,
};

const cssButton: CSSObject = {
  "&.primaryFillButton:hover": {
    backgroundColor: `${colors.brand.darkHover} !important`,
  },

  "&.dangerFillButton:hover": {
    textDecoration: "none",
    backgroundColor: `${colors.interface.red.dark} !important`,
  },
};

const PmFilledButton = ({
  text,
  color = "primary",
  fontSize = "16px",
  padding = "10px 16px",
  "data-testid": dataTestId,
  isEnabled = true,
  isLoading,
  fullWidth = false,
  minWidth,
  ...props
}: PmFilledButtonProps) => {
  const className = classNameMap[color];
  const backgroundColor = backgroundColorMap[color];
  const style = {
    background: backgroundColor,
    color: colors.brand.white,
    textDecoration: "none",
    fontWeight: "400",
    minWidth: minWidth || "112px",
    height: "fit-content",
    padding,
    fontSize,
    transform: "none",
  };
  if (props.href) {
    const button = (
      <EuiButton
        fill={false}
        className={className}
        fullWidth={fullWidth}
        style={style}
        data-testid={dataTestId}
        contentProps={{ style: { height: "fit-content" } }}
        textProps={{ style: { textAlign: "center" } }}
        css={cssButton}
        onClick={props.onClick}
      >
        {text}
      </EuiButton>
    );
    if (props.internalLink) {
      return <Link to={props.href}>{button}</Link>;
    } else {
      return <EuiLink href={props.href}>{button}</EuiLink>;
    }
  } else if (props.linkToProps) {
    return (
      <Link to={props.linkToProps}>
        <EuiButton
          fill={false}
          className={className}
          fullWidth={fullWidth}
          style={style}
          data-testid={dataTestId}
          contentProps={{ style: { height: "fit-content" } }}
          textProps={{ style: { textAlign: "center" } }}
          css={cssButton}
          onClick={props.onClick}
        >
          {text}
        </EuiButton>
      </Link>
    );
  } else if (props.onClick) {
    return (
      <EuiButton
        fill={true}
        className={className}
        fullWidth={fullWidth}
        style={style}
        onClick={props.onClick}
        {...(dataTestId ? { "data-testid": dataTestId } : {})}
        isLoading={isLoading}
        disabled={!isEnabled}
        contentProps={{ style: { height: "fit-content" } }}
        textProps={{ style: { textAlign: "center" } }}
        css={cssButton}
      >
        {text}
      </EuiButton>
    );
  }

  if (props.formId) {
    return (
      <EuiButton
        fill={true}
        className={className}
        fullWidth={fullWidth}
        style={style}
        data-testid={dataTestId}
        isLoading={isLoading}
        disabled={!isEnabled}
        form={props.formId}
        type="submit"
        contentProps={{ style: { height: "fit-content" } }}
        textProps={{ style: { textAlign: "center" } }}
        css={cssButton}
      >
        {text}
      </EuiButton>
    );
  }
  return (
    <EuiButton
      fill={true}
      className={className}
      fullWidth={fullWidth}
      style={style}
      {...(dataTestId ? { "data-testid": dataTestId } : {})}
      contentProps={{ style: { height: "fit-content" } }}
      textProps={{ style: { textAlign: "center" } }}
      css={cssButton}
    >
      {text}
    </EuiButton>
  );
};

export { PmFilledButton, PmButtonProps, PmFilledButtonProps };
