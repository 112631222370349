import React, { useState } from "react";
import { create } from "zustand";
import { EuiFlexGroup, EuiFlexItem, EuiSwitch, EuiText } from "@elastic/eui";

import { colors } from "@pm-frontend/styles";
import Features from "@pm-assets/js/common/feature-flags";
import { ApiUrls } from "../utils/api-urls";
import { apiPatch } from "../utils/apiFetch";
import { LinkHelper } from "../utils/api-helpers";
import { AuthUtils } from "../utils/auth-utils";

const ManagerPageRedesignKeys = ["calendar_redesign" as const];
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const VendorPageRedesignKeys = [] as const;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OwnerPageRedesignKeys = [] as const;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ResidentPageRedesignKeys = [] as const;

type PageRedesignKeys =
  | typeof ManagerPageRedesignKeys[number]
  | typeof VendorPageRedesignKeys[number]
  | typeof ResidentPageRedesignKeys[number]
  | typeof OwnerPageRedesignKeys[number];

// what sunset feature flag should be checked for each key
const SunsetFeatureFlagFunctions: Record<PageRedesignKeys, () => boolean> = {
  calendar_redesign: Features.isUIRedesignSchedulerSunset,
};

const updateAgentPreferences = async (
  currentlyEnabled: PageRedesignToggleState["enabled"],
  key: PageRedesignKeys,
  newValue: boolean
) =>
  apiPatch(LinkHelper.normalize(ApiUrls.myAgentPreferences), {
    redesigns_enabled: { ...currentlyEnabled, [key]: newValue },
  });

const getKeys = () => {
  switch (AuthUtils.getActiveOrgType()) {
    case "m":
      return ManagerPageRedesignKeys;
    // TODO: add other org types after adding backend support
    default:
      return [] as string[];
  }
};

interface PageRedesignToggleState {
  enabled: Record<PageRedesignKeys, boolean>;
  toggleValue: (key: PageRedesignKeys) => void;
}

const pageRedesignToggleStore = create<PageRedesignToggleState>((set, get) => ({
  enabled: getKeys().reduce((acc: Record<PageRedesignKeys, boolean>, key: PageRedesignKeys) => {
    // only use keys actually present in the `redesigns_enabled` object
    // simplest way to allow this to be used by m/v/t/o
    if (key in window.PM.redesigns_enabled) {
      acc[key] = !!window.PM.redesigns_enabled?.[key];
    }
    return acc;
  }, {} as Record<PageRedesignKeys, boolean>),
  toggleValue: async (key) => {
    const currentState = get().enabled[key];
    set((state) => {
      return {
        ...state,
        enabled: { ...state.enabled, [key]: !currentState },
      };
    });
    if (AuthUtils.getActiveOrgType() === "m") {
      await updateAgentPreferences(get().enabled, key, !currentState);
    }
    location.reload();
  },
}));

/**
 * Since this toggle needs to be used in non-functional components this function
 * from the store directly. Since we force page reloads on change we don't
 * need to subscribe to the store (for reactive updates)
 */
const isPageRedesignToggleEnabled = (key: PageRedesignKeys) => {
  if (SunsetFeatureFlagFunctions[key]()) {
    return true;
  }
  return pageRedesignToggleStore.getState().enabled[key];
};

const useTogglePageRedesign = () => pageRedesignToggleStore((state) => state.toggleValue);

const ToggleTextDisabled = ({ text }: { text: string }) => {
  return (
    <>
      <EuiFlexItem grow={false}>
        <EuiText color={colors.neutrals.gray700} size="s">
          {text}
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <span role="img" aria-label="sheep">
          🎉
        </span>
      </EuiFlexItem>
    </>
  );
};

const ToggleTextEnabled = ({ text }: { text: string }) => {
  return (
    <EuiFlexItem grow={false}>
      <EuiText color={colors.neutrals.gray700} size="s">
        {text}
      </EuiText>
    </EuiFlexItem>
  );
};
interface PageRedesignToggleProps {
  redesignKey: PageRedesignKeys;
  toggleEnabledText: string;
  toggleDisabledText: string;
  // often used for analytics tracking
  additionalOnToggle?: (currentState: boolean) => void;
}

/**
 * A component for creating toggles that read from `window.PM.redesigns_enabled`.
 * Since toggles are often re-used in a few places I recommend making
 * components for each specific redesing instead of using this directly.
 */
const PageRedesignToggle = ({
  redesignKey,
  toggleEnabledText,
  toggleDisabledText,
  additionalOnToggle,
}: PageRedesignToggleProps) => {
  const enabled = isPageRedesignToggleEnabled(redesignKey);
  const toggle = useTogglePageRedesign();

  // the toggle reloads the page, so we just do this to keep the
  // text from changing when toggling
  const [initiallyEnabled] = useState(enabled);
  // allows decoupling the zustand state from the switch state
  const [tempEnabled, setTempEnabled] = useState(enabled);

  const onToggle = () => {
    if (additionalOnToggle) {
      additionalOnToggle(enabled);
    }
    setTempEnabled(!enabled);
    toggle(redesignKey);
  };

  return (
    <EuiFlexGroup direction="row" alignItems="center" justifyContent="spaceBetween" gutterSize="xs" responsive={false}>
      {initiallyEnabled ? (
        <ToggleTextEnabled text={toggleEnabledText} />
      ) : (
        <ToggleTextDisabled text={toggleDisabledText} />
      )}
      <EuiFlexItem grow={false}>
        <EuiSwitch
          label="toggle-ui-redesign"
          showLabel={false}
          checked={tempEnabled}
          onChange={onToggle}
          compressed={true}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export { isPageRedesignToggleEnabled, useTogglePageRedesign, PageRedesignToggle };
