import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiText, EuiLink, EuiSpacer } from "@elastic/eui";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

import { colors } from "@pm-frontend/styles";

export type TextAction = {
  text: string;
  dataTestId?: string;
  enabled?: boolean;
} & (
  | {
      onClick?: () => void;
      href: string;
      internalLink: boolean;
    }
  | {
      onClick: () => void;
    }
);

interface PmDataTitleProps {
  text: string;
  disabled?: boolean;
  disableBottomSpacing?: boolean;
  action?: TextAction | TextAction[];
  actionAlignment?: "left" | "right";
  headerComponent?: "h1" | "h2" | "h3" | "h4";
  "data-testid"?: string;
  color?: string;
}

const SingleAction = ({ action }: { action: TextAction }) => {
  if ("href" in action) {
    if (action.internalLink) {
      return (
        <Link to={action.href}>
          <EuiLink onClick={action.onClick}>
            <EuiText
              size="s"
              css={css`
                font-weight: 400;
              `}
              {...(action.dataTestId ? { "data-testid": action.dataTestId } : {})}
            >
              {action.text}
            </EuiText>
          </EuiLink>
        </Link>
      );
    } else {
      return (
        <EuiLink href={action.href}>
          <EuiText
            size="s"
            css={css`
              font-weight: 400;
            `}
            {...(action.dataTestId ? { "data-testid": action.dataTestId } : {})}
          >
            {action.text}
          </EuiText>
        </EuiLink>
      );
    }
  } else if ("onClick" in action) {
    return (
      <EuiLink onClick={action.onClick}>
        <EuiText
          size="s"
          css={css`
            font-weight: 400;
          `}
          {...(action.dataTestId ? { "data-testid": action.dataTestId } : {})}
        >
          {action.text}
        </EuiText>
      </EuiLink>
    );
  }
  return null;
};

const Actions = ({ actions }: { actions: TextAction | TextAction[] }) => {
  if (Array.isArray(actions)) {
    return (
      <EuiFlexGroup direction="row" gutterSize="xs" responsive={false} style={{ minWidth: "92.42px" }}>
        {actions
          // eslint-disable-next-line no-prototype-builtins
          .filter((action) => !action.hasOwnProperty("enabled") || action.enabled)
          .map((action, index) => {
            return (
              <React.Fragment key={action.text}>
                <EuiFlexItem grow={false}>{index > 0 && <span style={{ margin: "0 4px" }}>{" • "}</span>}</EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <SingleAction action={action} />
                </EuiFlexItem>
              </React.Fragment>
            );
          })}
      </EuiFlexGroup>
    );
  } else {
    // eslint-disable-next-line no-prototype-builtins
    if (actions.hasOwnProperty("enabled") && !actions.enabled) {
      return null;
    }
    return <SingleAction action={actions} />;
  }
};

const PmDataTitle = ({
  text,
  disabled,
  action,
  actionAlignment,
  disableBottomSpacing,
  "data-testid": dataTestId,
  headerComponent = "h4",
  color = colors.neutrals.gray800,
}: PmDataTitleProps) => {
  let header: React.ReactNode = null;
  switch (headerComponent) {
    case "h1":
      header = <h1>{text}</h1>;
      break;
    case "h2":
      header = <h2>{text}</h2>;
      break;
    case "h3":
      header = <h3>{text}</h3>;
      break;
    case "h4":
      header = <h4>{text}</h4>;
      break;
    default:
      break;
  }

  return (
    <>
      <EuiFlexGroup
        alignItems="center"
        responsive={false}
        justifyContent={actionAlignment === "right" ? "spaceBetween" : "flexStart"}
        style={{ flexGrow: 0, gap: "16px", wordBreak: "break-word" }}
      >
        <EuiFlexItem grow={false}>
          <EuiText color={color} data-testid={dataTestId}>
            {header}
          </EuiText>
        </EuiFlexItem>
        {action && !disabled && (
          <EuiFlexItem grow={false}>
            <Actions actions={action} />
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
      {disableBottomSpacing ? null : <EuiSpacer size="m" />}
    </>
  );
};

export { PmDataTitle, PmDataTitleProps };
