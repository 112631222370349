import React from "react";

declare global {
  interface Window {
    PM: any;
    analytics: any;
    Appcues: any;
  }
}

export const inActiveText = "(Inactive) ";

export enum MgmtOnlyWorkCategories {
  FOR_RENT_SIGN = "For Rent Sign",
  EVALUATION = "Evaluation",
  TURNOVER = "Turnover",
  VIOLATIONS = "Violations",
}

export enum UpdateVendorToastMessages {
  ACCEPTED = "Your company information has been updated",
  CANCELED = "Changes to your company information have been canceled",
  NO_CHANGE = "No changes were made",
}

export enum ListViewPreferences {
  CARD = "CARD",
  LIST = "LIST",
}

export enum TenantWorkCategories {
  APPLIANCES = "Appliances",
  BLINDS_WINDOW_TREATMENTS = "Blinds/Window treatments",
  CARPENTRY = "Carpentry",
  CIRCUIT_BREAKER = "Circuit Breaker",
  CLEANING = "Cleaning",
  DOORS = "Doors",
  DRIVEWAY = "Driveway",
  DRYWALL = "Drywall",
  ELECTRICAL = "Electrical",
  EXTERIOR = "Exterior",
  FIREPLACE = "Fireplace",
  FLOORING = "Flooring",
  GARAGE_DOOR = "Garage Door",
  GARBAGE_DISPOSAL = "Garbage Disposal",
  GENERAL = "General",
  HARDWARE = "Hardware",
  HVAC = "Heating/AC",
  INTERIOR = "Interior",
  LANDSCAPING = "Landscaping",
  LOCKS = "Locks",
  OTHER_WORK_CATEGORY = "Other",
  OUTSIDE_WATER_SPIGOT = "Outside Water Spigot",
  PAINTING = "Painting",
  PEST_CONTROL = "Pest control",
  PLUMBING = "Plumbing",
  POOL = "Pool",
  ROOFING = "Roofing",
  SEWER = "Sewer",
  SHOWER = "Shower",
  SIDING = "Siding",
  SMOKE_DETECTOR_CO_DETECTORS = "Smoke Detector/CO detectors",
  SOFFIT_FASCIA = "Soffit/Fascia",
  STAIRS = "Stairs",
  TOILET = "Toilet",
  TOWEL_BARS = "Towel bars",
  WASHER_DRYER = "Washer/Dryer",
  WATER_DAMAGE = "Water Damage",
  WATER_HEATER = "Water Heater",
  WATER_SOFTENER = "Water Softener",
  WINDOWS = "Windows",
}

export const workCategory = Object.assign({}, MgmtOnlyWorkCategories, TenantWorkCategories);

export enum Priority {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}

export enum PriorityReadable {
  LOW = "Low",
  MEDIUM = "Medium",
  HIGH = "High",
}

export enum ProjectType {
  MITIGATION = "MITIGATION",
  TURN = "TURN",
  RENOVATION = "RENOVATION",
  OTHER = "OTHER",
}

export enum ProjectTypeReadable {
  MITIGATION = "Mitigation",
  OTHER = "Other",
  RENOVATION = "Renovation",
  TURN = "Turn",
}

export const rrule = {
  freq: {
    SECONDLY: "SECONDLY",
    MINUTELY: "MINUTELY",
    HOURLY: "HOURLY",
    DAILY: "DAILY",
    WEEKLY: "WEEKLY",
    MONTHLY: "MONTHLY",
    YEARLY: "YEARLY",
  },
};

export enum TenantMeldStatus {
  OPEN = "OPEN",
  TENANT_CANCELED = "TENANT_CANCELED",
  MANAGER_REJECTED = "MANAGER_REJECTED",
}

export enum VendorSpecialtiesText {
  APPLIANCE = "Appliance Repair",
  FENCING = "Fencing",
  FLOORING = "Flooring",
  GUTTER_CONTRACTOR = "Gutter Contractor",
  HVAC = "HVAC",
  INSULATION = "Insulation",
  MASON = "Mason/Concrete",
  PLUMBER = "Plumber",
  REMODELER = "Remodeler",
  ROOFING = "Roofing",
  SIDING = "Siding",
  TREE_SERVICE = "Tree Service",
  WINDOW_DOOR = "Window/Door",
  CABINETS_COUNTERTOPS = "Cabinets/Countertops",
  CARPENTER = "Carpenter",
  CLEANING_EXTERIOR = "Cleaning - Exterior",
  CLEANINGS_INTERIOR_MAID_SERVICE = "Cleaning - Interior/Maid",
  DECK_BUILDER = "Deck Builder",
  DRYWALL_PLASTER = "Drywall/Plaster",
  ELECTRICIAN = "Electrician",
  GARAGE_DOOR = "Garage Door",
  HANDYMAN = "Handyman",
  HOME_INSPECTOR = "Home Inspector",
  HOME_MEDIA = "Home media",
  PEST_CONTROL = "Pest Control",
  LANDSCAPER = "Landscaper",
  OTHER = "Other",
  PAINTER = "Painter",
  POOLS = "Pools",
  SOLAR = "Solar",
  TILE = "Tile",
}

export enum Filters {
  WITH_TENANTS = "WITH_TENANTS",
  WITHOUT_TENANTS = "WITHOUT_TENANTS",
}

export enum IsActiveFilters {
  IS_ACTIVE = "True",
  IS_NOT_ACTIVE = "False",
}

// Used for ensuring navs and subnavs are properly highlighted for meld routes
// See usages in ./header.js and ./meld-app.js
export const MeldBaseRoutes = ["melds", "meld", "owner-services", "projects", "workflows"];

export enum MeldStatuses {
  OPEN = "OPEN",
  MANAGER_CANCELED = "MANAGER_CANCELED",
  TENANT_CANCELED = "TENANT_CANCELED",
  PENDING_ESTIMATES = "PENDING_ESTIMATES",
  PENDING_TENANT_AVAILABILITY = "PENDING_TENANT_AVAILABILITY",
  PENDING_MORE_VENDOR_AVAILABILITY = "PENDING_MORE_VENDOR_AVAILABILITY",
  PENDING_MORE_MANAGEMENT_AVAILABILITY = "PENDING_MORE_MANAGEMENT_AVAILABILITY",
  PENDING_COMPLETION = "PENDING_COMPLETION",
  COMPLETED = "COMPLETED",
  VENDOR_COULD_NOT_COMPLETE = "VENDOR_COULD_NOT_COMPLETE",
  MAINTENANCE_COULD_NOT_COMPLETE = "MAINTENANCE_COULD_NOT_COMPLETE",
  PENDING_VENDOR = "PENDING_VENDOR",
  PENDING_ASSIGNMENT = "PENDING_ASSIGNMENT",
}

export enum MeldType {
  MELD = "MELD",
  ESTIMATE = "ESTIMATE",
}

export enum EstimateStatuses {
  ESTIMATE_PENDING_ACCEPTANCE = "ESTIMATE_PENDING_ACCEPTANCE",
  ESTIMATE_IN_PROGRESS = "ESTIMATE_IN_PROGRESS",
  ESTIMATE_SUBMITTED = "ESTIMATE_SUBMITTED",
  ESTIMATE_VENDOR_REJECTED = "ESTIMATE_VENDOR_REJECTED",
  ESTIMATE_OWNER_REJECTED = "ESTIMATE_OWNER_REJECTED",
  ESTIMATE_CLOSED = "ESTIMATE_CLOSED",
  ESTIMATE_PENDING_APPROVAL = "ESTIMATE_PENDING_APPROVAL",
  ESTIMATE_APPROVED = "ESTIMATE_APPROVED",
}

export enum EstimateStatusesReadable {
  ESTIMATE_PENDING_ACCEPTANCE = "Pending Vendor Acceptance",
  ESTIMATE_IN_PROGRESS = "In Progress",
  ESTIMATE_SUBMITTED = "Submitted",
  ESTIMATE_VENDOR_REJECTED = "Vendor Rejected",
  ESTIMATE_OWNER_REJECTED = "Owner Rejected",
  ESTIMATE_CLOSED = "Closed",
  ESTIMATE_PENDING_APPROVAL = "Pending Approval",
  ESTIMATE_APPROVED = "Approved",
}

export enum ClosedMeldStatuses {
  TENANT_CANCELED = "TENANT_CANCELED",
  MANAGER_CANCELED = "MANAGER_CANCELED",
  COMPLETED = "COMPLETED",
  MAINTENANCE_COULD_NOT_COMPLETE = "MAINTENANCE_COULD_NOT_COMPLETE",
  VENDOR_COULD_NOT_COMPLETE = "VENDOR_COULD_NOT_COMPLETE",
}

export const MeldOpenStatuses = [
  "PENDING_ESTIMATES",
  "PENDING_TENANT_AVAILABILITY",
  "PENDING_MORE_VENDOR_AVAILABILITY",
  "PENDING_MORE_MANAGEMENT_AVAILABILITY",
  "PENDING_COMPLETION",
  "PENDING_VENDOR",
  "PENDING_ASSIGNMENT",
];

export enum MeldScheduling {
  NO_CURRENT = "NO_CURRENT",
}

export const LOADING_GIF_SRC = `${window.PM.env.staticUrl}static/img/loader.gif`;

export const COMPRESSED_CALENDAR_MORNING_START_HOUR = 6;
export const COMPRESSED_CALENDAR_EVENING_END_HOUR = 19;
export const MESSAGE_TIMEOUT = 5500;

export const DEFAULT_EVENT_TYPE = "default";

export enum ExpenditureStatuses {
  DRAFT = "DRAFT",
  IN_REVIEW = "IN_REVIEW",
  HOLD = "HOLD",
  APPROVED = "APPROVED",
  BILLED = "BILLED",
}

export enum ExpenditureSelections {
  DRAFT = "Place in draft",
  REVIEW = "Place in review",
  HOLD = "Place on hold",
  APPROVE = "Approve",
  BILL = "Mark billed",
}

export enum ExpenditureSelectionsReadable {
  DRAFT = "Draft",
  REVIEW = "Review",
  HOLD = "Hold",
  APPROVE = "Approve",
  BILL = "Bill",
}

export const ExpenditureStatusesReadable = {
  DRAFT: "Draft",
  IN_REVIEW: "In Review",
  HOLD: "Hold",
  APPROVED: "Approved",
  BILLED: "Billed",
};

export enum ExpenditureStatusesFullReadable {
  DRAFT = "Place in draft",
  IN_REVIEW = "Place in review",
  HOLD = "Place on hold",
  APPROVED = "Approve",
  BILLED = "Mark billed",
}

export enum InvoiceStatuses {
  DRAFT = "DRAFT",
  PAID = "PAID",
  SUBMITTED = "SUBMITTED",
  IN_REVIEW = "IN_REVIEW",
  HOLD = "HOLD",
  DECLINED = "DECLINED",
  APPROVED = "APPROVED",
}

export const InvoiceStatusesReadable = {
  DRAFT: "Draft",
  PAID: "Paid",
  SUBMITTED: "Submitted",
  IN_REVIEW: "In Review",
  HOLD: "Hold",
  DECLINED: "Declined",
  APPROVED: "Approved",
};

export enum OwnerApprovalStatuses {
  OWNER_APPROVAL_NOT_REQUESTED = "OWNER_APPROVAL_NOT_REQUESTED",
  OWNER_APPROVAL_REQUESTED = "OWNER_APPROVAL_REQUESTED",
  OWNER_APPROVAL_APPROVED = "OWNER_APPROVAL_APPROVED",
  OWNER_APPROVAL_NOT_APPROVED = "OWNER_APPROVAL_NOT_APPROVED",
}

export enum OwnerApprovalStatusesReadable {
  OWNER_APPROVAL_NOT_REQUESTED = "Not Requested",
  OWNER_APPROVAL_REQUESTED = "Requested",
  OWNER_APPROVAL_APPROVED = "Approved",
  OWNER_APPROVAL_NOT_APPROVED = "Not Approved",
}

export enum PaymentMethods {
  CASH = "CASH",
  CHECK = "CHECK",
  CC = "CC",
  ACH = "ACH",
}

export const DEFAULT_MGR_TICKET_SORT = "-created";
export const DEFAULT_VENDOR_TICKET_SORT = "scheduled";
export const DEFAULT_INVOICE_SORT = "-payment_requested";
export const DEFAULT_MELD_SORT = "-created";
export const DEFAULT_TENANT_SORT = ["last_name", "first_name"];
export const DEFAULT_TENANT_INVITE_SORT = "name";

export enum BillingTypes {
  PER_UNIT_BILLING = "PER_UNIT_BILLING",
  FLAT_BILLING = "FLAT_BILLING",
}

export enum SubscriptionStatuses {
  TRIALING = "trialing",
  ACTIVE = "active",
  PAST_DUE = "past_due",
  CANCELED = "canceled",
  UNPAID = "unpaid",
}

export enum Roles {
  MANAGEMENT_ADMIN = "MANAGEMENT_ADMIN",
  MANAGEMENT_AGENT = "MANAGEMENT_AGENT",
  MAINTENANCE = "MAINTENANCE",
}

// TO ADD MORE DEPARTMENTS AND TITLES, ADD TO ENUMS BELOW AND
// assets/js/authentication/account-agent-settings-tab.js, MAPPING FOR FRONTEND
export enum Titles {
  ACCOUNTANT = "Accountant",
  SUPERVISOR = "Supervisor",
  OTHER = "Other",
  PROPERTY_MANAGER = "Property Manager",
  ADMINISTRATIVE_ASSISTANT = "Administrative Assistant",
  COORDINATOR = "Coordinator",
  TECHNICIAN = "Technician",
  PROJECT_COORDINATOR = "Project Coordinator",
  INSPECTOR = "Inspector",
  LEASING_AGENT = "Leasing Agent",
  BROKER_OWNER = "Broker/Owner",
  EXECUTIVE = "Executive",
}

export enum Departments {
  ACCOUNTING = "Accounting",
  OPERATIONS = "Operations",
  MAINTENANCE = "Maintenance",
  LEASING_MARKETING = "Leasing/Marketing",
  LEADERSHIP = "Leadership",
  OTHER = "Other",
}

export const MIN_UNITS_IN_SUBSCRIPTION = 40;

export enum Perms {
  CAN_MANAGE_SUBSCRIPTION = "can_manage_subscription",
  CAN_EDIT_MANAGEMENT_AGENTS = "edit_managementagent",
  CAN_DELETE_MANAGEMENT_AGENTS = "delete_managementagent",
  CAN_CHANGE_MANAGEMENTAGENTPROPERTYGROUPS = "change_managementagentpropertygroup",
  CAN_VIEW_AGENTS = "view_managementagent",
  CAN_ADD_AGENTS = "add_managementagent",
  CAN_VIEW_TENANTS = "view_tenant",
  CAN_ADD_EDIT_DEACTIVATE_UNITS = "add_edit_deactivate_unit",
  CAN_VIEW_UNITS = "view_unit",
  CAN_VIEW_VENDORS = "view_vendor",
  CAN_ADD_VENDORS = "add_vendor",
  ALLOW_OR_PROHIBIT_VENDOR_ASSIGNMENTS = "allow_or_prohibit_vendor_assignments",
  CAN_DELETE_VENDORS = "delete_vendor",
  CAN_CHANGE_INTEGRATIONS = "change_integrations",
  CAN_ADD_MELDREPORT = "add_meldreport",
  CAN_EDIT_APPSETTINGS = "edit_appsettings",
  CAN_EDIT_MANAGEMENTS = "edit_management",
  CAN_EDIT_EMERGENCYCONTACTSETTINGS = "edit_emergencycontactsettings",
  CAN_CHANGE_RECURRINGMELDS = "change_recurringmeld",
  CAN_ADD_RECURRINGMELDS = "add_recurringmeld",
  CAN_CHANGE_MELDINVOICES = "change_meldinvoice",
  CAN_VIEW_MELDEXPENDITURES = "view_meldexpenditure",
  CAN_EDIT_MELDEXPENDITURES = "edit_meldexpenditure",
  CAN_UPDATE_STATUS_MELDEXPENDITURES = "update_status_meldexpenditure",
  CAN_DELETE_MELDEXPENDITURES = "delete_meldexpenditure",
  CAN_VIEW_MELDS = "view_meld",
  CAN_ADD_MELDS = "add_meld",
  CAN_CHANGE_MELDS = "change_meld",
  CAN_SCHEDULE_MELDS = "schedule_meld",
  CAN_EDIT_TENANTS = "edit_tenant",
  CAN_ADD_EDIT_DELETE_PROPERTY_GROUPS = "add_edit_delete_property_group",
  CAN_VIEW_PROPERTY_GROUPS = "view_property_group",
  CAN_ADD_EDIT_DEACTIVATE_PROPERTIES = "add_edit_deactivate_property",
  CAN_VIEW_PROPERTIES = "view_property",
  CAN_ADD_TAGS = "add_tag",
  CAN_CHANGE_TAGS = "change_tag",
  CAN_DELETE_TAGS = "delete_tag",
  CAN_ADD_COORDINATOR = "add_coordinator",
  CAN_MANAGE_WORKFLOWS = "manage_workflows",
  CAN_ADD_MELD_TAGS = "add_meldtag",
  CAN_DELETE_MELD_TAGS = "delete_meldtag",
  CAN_ADD_MELD_COORDINATOR = "add_meldcoordinator",
  CAN_DELETE_MELD_COORDINATOR = "add_meldcoordinator",
  CAN_ADD_ROLES = "add_role",
  CAN_CHANGE_ROLES = "change_role",
  CAN_DELETE_ROLES = "delete_role",
  CAN_VIEW_WORK_ENTRY = "view_workentry",
  CAN_ADD_WORK_ENTRY = "add_workentry",
  CAN_DELETE_WORK_ENTRY = "delete_workentry",
  CAN_CHANGE_OTHER_USERS_WORKLOG = "change_other_users_worklog",
  CAN_CHECK_TIMER_AND_EDIT_WORKENTRY_DETAILS = "check_timer_and_edit_workentry_details",
  CAN_EDIT_WORKENTRY_HOURS = "edit_workentry_hours",
  CAN_VIEW_TENANTINVITEREQUEST = "view_tenantinviterequest",
  CAN_BULK_DELETE_UNITS = "bulk_delete_units",
  CAN_CHANGE_PROJECT_TEMPLATES = "change_project_template",
  CAN_VIEW_PROJECT_TEMPLATES = "view_project_template",
  CAN_VIEW_PROJECTS = "view_project",
  CAN_CHANGE_PROJECTS = "change_project",
  CAN_ADD_EDIT_DEACTIVATE_OWNERS = "add_edit_deactivate_owner",
  CAN_VIEW_OWNERS = "view_owner",
  CAN_BULK_DELETE_OWNERS = "bulk_delete_owners",
  CAN_ADD_OWNER_SERVICES = "add_owner_service",
  CAN_EDIT_WHITE_LABELING = "edit_white_labeling",
  CAN_CREATE_EDIT_DELETE_SHARED_FILTERS = "create_edit_delete_shared_filters",
  CAN_VIEW_INSIGHTS = "view_insights",
  CAN_SUBMIT_ESTIMATES = "submit_estimates",
  CAN_VIEW_OWNER_APPROVALS_ESTIMATES = "view_owner_approval_estimates",
  CAN_REQUEST_OWNER_APPROVALS_ESTIMATES = "view_request_approval_estimates",
  CAN_VIEW_EDIT_LINE_ITEM_MARKUPS_ESTIMATES = "view_edit_line_item_markups_estimates",
}

export enum EmailInviteStatuses {
  SENT = "SENT",
  DELIVERY_FAILED = "DELIVERY_FAILED",
  OPENED = "OPENED",
}

export enum EmailInviteStatusText {
  SENT = "Sent",
  DELIVERY_FAILED = "Invite failed",
  OPENED = "Opened",
}

export enum IntegrationSlugs {
  GATHER_KUDOS = "gather-kudos",
  RENT_MANAGER = "rent-manager",
  GRADE_US = "grade-us",
  PROPERTYWARE = "propertyware",
  RESMAN = "resman",
  REPUTATION = "reputation",
  APPFOLIO_API = "appfolio-api",
  BUILDIUM = "buildium",
}

export enum LinkableIntegrationServices {
  Propertyware = "propertyware",
  RentManager = "rent-manager",
  AppFolio = "appfolio", // TODO: deprecate AA-2611

  AppFolioAPI = "appfolio-api",
  Buildium = "buildium",
}

export enum LinkableTypes {
  Tenant = "tenant",
  Unit = "unit",
  Owner = "owner",
  Property = "prop",
  Vendor = "vendor",
  User = "user",
}

export enum IntegrationStatuses {
  ENABLED = "ENABLED",
}

export enum SyncStatuses {
  LINK = "LINK",
  NOT_SYNCED = "NOT_SYNCED",
  IMPORT = "IMPORT",
}

export enum ImportPersistence {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  UPDATE_CREATE = "UPDATE_CREATE",
}

export enum PersistenceOperation {
  CREATE = 1,
  UPDATE = 2,
  NO_CHANGE = 3,
}

export enum ImportProviders {
  NONE = "NONE",
  APPFOLIO = "APPFOLIO",
  APPFOLIO_INEXACT = "APPFOLIO_INEXACT",
  PROPERTY_WARE = "PROPERTY_WARE",
  PROPERTY_BOSS = "PROPERTY_BOSS",
  BUILDIUM = "BUILDIUM",
}

export const ImportProviderLabels: { [key: string]: string } = {
  [ImportProviders.NONE]: "None",
  [ImportProviders.APPFOLIO]: "Appfolio",
  [ImportProviders.APPFOLIO_INEXACT]: "Appfolio (old)",
  [ImportProviders.PROPERTY_WARE]: "Propertyware",
  [ImportProviders.PROPERTY_BOSS]: "Property Boss",
  [ImportProviders.BUILDIUM]: "Buildium",
};

export enum ImportStatus {
  GENERATING_OPS = "GENERATING_OPS",
  OPS_GENERATED = "OPS_GENERATED",
  EXECUTING_OPS = "EXECUTING_OPS",
  OPS_EXECUTED = "OPS_EXECUTED",
}

export const SMALL_BREAKPOINT = 640;
export const MEDIUM_BREAKPOINT = 1025;

export const emptyPlaceholder = String.fromCharCode(0x2014);

export enum MaintTypes {
  VENDOR = "Vendor",
  MANAGEMENT_AGENT = "ManagementAgent",
  MANAGEMENT = "Management",
  VENDOR_PREFERENCE_LIST = "VendorPreferenceList",
  INVITED_VENDOR = "VendorInvite",
}

export enum MaintenanceClasses {
  INTERNAL = "1",
  EXTERNAL = "2",
}

export enum CalendarDuration {
  DAY = "DAY",
  FOUR_DAY = "FOUR_DAY",
  WEEK = "WEEK",
}

export const CAL_QUARTER_HOUR_HEIGHT = 13;
export const COMPRESSED_CAL_NUM_QUARTER_HOURS = 13 * 4;
export const COMPRESSED_CAL_START_HOUR = 6;

export const DEFAULT_MELD_FILTER_GROUP_NAME = "default";

export enum ReportType {
  MELDS_CUSTOM = "melds_custom",
  MELDS = "melds",
  UNITS = "units",
  TENANTS = "tenants",
  INVOICES = "invoices",
  WORK_LOGS = "work_logs",
  OWNERS = "owners",
  DETAILED_EXPENDITURES = "detailed_expenditures",
  EXPENDITURES = "expenditures",
  TECHNICIAN = "technician",
  DOWNLOAD_BILLS = "download_bills",
}

export const TIME_FORMAT = "h:mma";

export const RTE_DEFAULT_TOOLBAR_CONFIG = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "LINK_BUTTONS", "HISTORY_BUTTONS"],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD" },
    { label: "Italic", style: "ITALIC" },
    { label: "Monospace", style: "CODE" },
    { label: "Underline", style: "UNDERLINE" },
  ],

  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
    { label: "Blockquote", style: "blockquote" },
  ],
};

export const RTE_MINI_TOOLBAR_CONFIG = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "LINK_BUTTONS", "HISTORY_BUTTONS"],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD" },
    { label: "Italic", style: "ITALIC" },
    { label: "Monospace", style: "CODE" },
    { label: "Underline", style: "UNDERLINE" },
  ],

  BLOCK_TYPE_DROPDOWN: [],

  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
    { label: "Blockquote", style: "blockquote" },
  ],
};

export const OWNER_APPROVAL_CONTEXT_VARS = {
  owner_first_name: "Jordan",
  owner_last_name: "Smith",
  management_name: "Essex Management Company",
  brief_description: "Meld brief description",
  description: "Description",
  estimate: "123.00",
  addr_lines: "45 Hudson St",
  addr_designators: "Unit 123",
  addr_city_state_zip: "Rochelle Park, NJ 07662",
};

export const ERROR_SUPPORT_MESSAGE =
  "Sorry, it looks like we are having a problem. If the problem persists please contact us at support@propertymeld.com";

export const ERROR_NETWORK_MESSAGE =
  "Oops, we could not complete your request. Check your internet connection and try again.";

export const ERROR_403 =
  "Oops, we could not complete your request. It looks like you are signed out. Try refreshing the page.";

export const ERROR_404 = "Oops, we could not complete your request. Try refreshing the page.";

export const ERROR_NOT_COMPLETE_REQUEST =
  "Oops, we could not complete your request. Please contact support via support@propertymeld.com.";

export function getDeleteError(itemName: string) {
  return (
    <>
      Oops, we could not delete<strong> {itemName}</strong>. Please try again or contact support via
      support@propertymeld.com.
    </>
  );
}

export const REQUIRED_APPOINTMENT_WINDOWS = 5;
