import React from "react";

import { ManagementWorkLogCheckoutBanner } from "@pm-frontend/apps/management/banners/ManagementWorkLogCheckoutBanner";
import { GlobalBannerEmptySpace } from "@pm-frontend/shared/layouts/GlobalBanners";
import { useGetManagementGlobalBannerVisibility } from "../banners/managementGlobalBannerStore";
import { MeldCalendarDraftModeHeader } from "@pm-frontend/routes/Calendar/subcomponents/MeldCalendarDraftModeHeader";

const ManagementGlobalBanners = () => {
  const isBannerVisible = useGetManagementGlobalBannerVisibility();

  if (isBannerVisible === null) {
    return <GlobalBannerEmptySpace />;
  } else if (isBannerVisible.type === "calendarDraftMode") {
    return <MeldCalendarDraftModeHeader />;
  } else if (isBannerVisible.type === "worklogs") {
    return <ManagementWorkLogCheckoutBanner workEntries={isBannerVisible.filteredOpenWorkEntries} />;
  } else {
    return null;
  }
};

export { ManagementGlobalBanners };
