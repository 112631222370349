import React from "react";
import { EuiPageTemplate } from "@elastic/eui";

import { ApplicationToastList } from "./ApplicationToastList";
import { MobileInnerPageContentHeight, PageContentHeight } from "@pm-frontend/styles";
import { useIsMobile } from "../hooks/useIsMobile";

interface ApplicationLayoutProps {
  children: React.ReactNode;
  header: React.ReactNode;
  sidebar: React.ReactNode;
  sidebarNavIsOpen: boolean;
  globalFlyouts: React.ReactNode;
  globalModals: React.ReactNode;
  showOldOrNewUiRoute: "new" | "old";
}

const ApplicationLayout = ({
  children,
  header,
  sidebar,
  sidebarNavIsOpen,
  globalFlyouts,
  globalModals,
  showOldOrNewUiRoute,
}: ApplicationLayoutProps) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <>
        {header}
        <EuiPageTemplate
          grow={true}
          panelled={false}
          paddingSize="none"
          style={{ minBlockSize: `max(460px, ${MobileInnerPageContentHeight})` }}
        >
          <EuiPageTemplate.Sidebar sticky={false} paddingSize="none">
            {sidebar}
          </EuiPageTemplate.Sidebar>
          {children}
          <ApplicationToastList />
          {globalFlyouts}
          {globalModals}
        </EuiPageTemplate>
      </>
    );
  }
  return (
    <>
      {header}
      <EuiPageTemplate
        grow={true}
        style={{
          height: showOldOrNewUiRoute === "new" ? PageContentHeight : undefined,
          minBlockSize: 0,
        }}
        panelled={false}
        data-testid="management-app"
      >
        <EuiPageTemplate.Sidebar sticky={false} paddingSize="none" minWidth={sidebarNavIsOpen ? "240px" : "64px"}>
          {sidebar}
        </EuiPageTemplate.Sidebar>
        {children}
        <ApplicationToastList />
        {globalFlyouts}
        {globalModals}
      </EuiPageTemplate>
    </>
  );
};

export { ApplicationLayout };
