import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiLink } from "@elastic/eui";

import URL from "@pm-shared/utils/url";
import { Link } from "react-router-dom";
import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";
import { PageHeader } from "@pm-frontend/shared/components/PageHeader/PageHeader";
import { DetailViewsPageHeaderProps } from "./DetailViewsPageHeader";

const DesktopDetailViewsPageHeader = ({
  subtitle,
  title,
  icon,
  primaryAction,
  secondaryActions,
  prependBadgeRow,
  badges,
  iconBadges,
  backButtonUrl,
  backButtonLinkProps,
  backButtonOnClick,
  backButtonAriaLabel,
  backButtonText,
  linkActions,
  isInactive = false,
  inactiveAction,
  "data-testid": dataTestId,
  primaryButtonLoading,
  extraContentRow,
  bottomTray,
}: DetailViewsPageHeaderProps) => {
  let backButtonLinkElement: React.ReactNode;
  if (backButtonUrl) {
    backButtonLinkElement = (
      <Link to={LinkHelper.normalize(backButtonUrl)} {...(backButtonOnClick ? { onClick: backButtonOnClick } : {})}>
        <EuiFlexGroup direction="row" responsive={false} gutterSize="s">
          <EuiFlexItem grow={false}>
            <EuiIcon type={URL.getStatic("icons/arrow_back.svg")} aria-label={backButtonAriaLabel} size="original" />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>{backButtonText}</EuiFlexItem>
        </EuiFlexGroup>
      </Link>
    );
  } else if (backButtonLinkProps) {
    backButtonLinkElement = (
      <Link {...backButtonLinkProps} {...(backButtonOnClick ? { onClick: backButtonOnClick } : {})}>
        <EuiFlexGroup direction="row" responsive={false} gutterSize="s">
          <EuiFlexItem grow={false}>
            <EuiIcon
              type={URL.getStatic("icons/arrow_back.svg")}
              aria-label={backButtonAriaLabel}
              size="original"
              style={{ minHeight: "20px", maxHeight: "20px" }}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>{backButtonText}</EuiFlexItem>
        </EuiFlexGroup>
      </Link>
    );
  } else if (backButtonOnClick) {
    backButtonLinkElement = (
      <EuiFlexGroup
        direction="row"
        responsive={false}
        gutterSize="s"
        onClick={backButtonOnClick}
        style={{ cursor: "pointer" }}
      >
        <EuiFlexItem grow={false}>
          <EuiIcon
            type={URL.getStatic("icons/arrow_back.svg")}
            aria-label={backButtonAriaLabel}
            size="original"
            style={{ minHeight: "20px", maxHeight: "20px" }}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiLink>{backButtonText}</EuiLink>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
  return (
    <>
      <EuiFlexItem grow={false}>
        <EuiFlexGroup direction="column" alignItems="stretch" justifyContent="flexStart" gutterSize="l">
          <EuiFlexItem grow={false}>
            <EuiFlexGroup alignItems="flexStart" style={{ height: "20px" }}>
              {backButtonLinkElement}
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <PageHeader
              subtitle={subtitle}
              title={title}
              icon={icon}
              linkActions={linkActions}
              primaryAction={primaryAction}
              secondaryActions={secondaryActions}
              inactiveAction={inactiveAction}
              prependBadgeRow={prependBadgeRow}
              badges={badges}
              iconBadges={iconBadges}
              isInactive={isInactive}
              data-testid={dataTestId}
              primaryButtonLoading={primaryButtonLoading}
              extraContentRow={extraContentRow}
              bottomTray={bottomTray}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </>
  );
};

export { DesktopDetailViewsPageHeader };
