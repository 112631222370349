import React from "react";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalProps,
  EuiText,
} from "@elastic/eui";

import { colors } from "@pm-frontend/styles";
import { PmEmptyButton, PmEmptyButtonProps } from "../Buttons/PmEmptyButton";
import { PmFilledButton, PmFilledButtonProps } from "../Buttons/PmFilledButton";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DistributiveOmit<T, K extends PropertyKey> = T extends any ? Omit<T, K> : never;

type PmFormModalProps = {
  title?: string;
  body?: React.ReactNode;
  "data-testid"?: string;
  form: JSX.Element;
  secondaryButtonText?: string;
  hideSecondaryButton?: boolean;
  altActionButtonProps?: DistributiveOmit<
    PmEmptyButtonProps,
    "hasBorder" | "padding" | "textSize" | "fontWeight" | "color"
  >;
  closeModal: () => void;
  maxWidth?: EuiModalProps["maxWidth"];
} & (
  | {
      formId: NonNullable<PmFilledButtonProps["formId"]>;
      primaryButtonText: PmFilledButtonProps["text"];
      primaryButtonLoading: PmFilledButtonProps["isLoading"];
      primaryButtonColor?: "primary" | "danger";
      primaryButtonProps?: never;
      confirmButtonDataTestId?: string;
    }
  | {
      // I'd like to move these props to pass button props in a single object
      // but keeping existing type for backward compatibility
      primaryButtonText?: never;
      primaryButtonLoading?: never;
      primaryButtonColor?: never;
      formId?: never;
      confirmButtonDataTestId?: never;
      primaryButtonProps: {
        color?: "primary" | "danger";
        text: PmFilledButtonProps["text"];
        isLoading: PmFilledButtonProps["isLoading"];
        isEnabled?: PmFilledButtonProps["isEnabled"];
        formId: NonNullable<PmFilledButtonProps["formId"]>;
        "data-testid"?: PmFilledButtonProps["data-testid"];
      };
    }
);

const parseString = (str: string) => {
  return <EuiText style={{ color: colors.neutrals.gray800, fontSize: "16px", padding: "0px" }}>{str}</EuiText>;
};

const parseBody = (body: React.ReactNode): React.ReactNode => {
  if (!body) {
    return;
  } else if (typeof body === "string") {
    return parseString(body);
  } else if (Array.isArray(body)) {
    // add <br/> between elements
    const result: React.ReactNodeArray = [];
    body.forEach((ele, index) => {
      if (typeof ele === "string") {
        result.push(parseString(ele));
      } else {
        result.push(ele);
      }
      if (index < body.length - 1) {
        result.push(<br />);
      }
    }, [] as React.ReactNode);
    return result;
  } else {
    return body;
  }
};

const PmFormModal = ({
  closeModal,
  "data-testid": dataTestId,
  title,
  body,
  form,
  secondaryButtonText = "Cancel",
  altActionButtonProps,
  confirmButtonDataTestId,
  hideSecondaryButton = false,
  maxWidth = "448px",
  ...rest
}: PmFormModalProps) => {
  let bodyContent = body;
  if (typeof body === "string") {
    bodyContent = (
      <EuiText style={{ color: colors.neutrals.gray800, fontSize: "16px", padding: "0px" }}>{parseBody(body)}</EuiText>
    );
  }
  const primaryButtonProps: PmFilledButtonProps = rest.primaryButtonProps
    ? rest.primaryButtonProps
    : {
        formId: rest.formId,
        isLoading: rest.primaryButtonLoading,
        text: rest.primaryButtonText,
        color: rest.primaryButtonColor,
        "data-testid": confirmButtonDataTestId,
      };

  return (
    <EuiModal
      className="pmFormModal"
      onClose={closeModal}
      style={{ padding: "24px", gap: "16px", position: "relative", marginTop: "22%" }}
      maxWidth={maxWidth}
      data-testid={dataTestId}
    >
      {title ? (
        <EuiModalHeader style={{ padding: "0px" }}>
          <EuiText color={colors.neutrals.gray900} style={{ fontSize: "24px", fontWeight: "700" }}>
            {title}
          </EuiText>
        </EuiModalHeader>
      ) : null}
      <EuiModalBody style={{ padding: "0px" }}>
        <EuiFlexGroup direction="column" style={{ gap: "16px" }}>
          {bodyContent ? <EuiFlexItem grow={true}>{bodyContent}</EuiFlexItem> : null}
          <EuiFlexItem grow={true}>{form}</EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalBody>
      <EuiModalFooter style={{ padding: "0px" }}>
        <EuiFlexGroup
          direction="row"
          alignItems="flexStart"
          style={{ gap: "16px", backgroundColor: colors.brand.white }}
        >
          <EuiFlexItem grow={false}>
            <PmFilledButton {...primaryButtonProps} />
          </EuiFlexItem>
          {altActionButtonProps && <PmEmptyButton {...altActionButtonProps} color="primary" hasBorder={true} />}
          {!hideSecondaryButton && (
            <EuiFlexItem grow={false}>
              <PmEmptyButton onClick={closeModal} text={secondaryButtonText} />
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </EuiModalFooter>
    </EuiModal>
  );
};
export { PmFormModal, PmFormModalProps };
