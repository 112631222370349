import React, { useState } from "react";
import { EuiFlexGroup, EuiFlexItem, EuiSelectable, EuiSelectableOption, EuiSuperSelect } from "@elastic/eui";
import { useHistory, useLocation } from "react-router-dom";

import { PmFormStyling } from "../../Forms/PmFormStyling";
import {
  BaseSelectableFilterClass,
  PmSelectableFilterAllowedOperators,
  PmSelectableFilterOperatorValue,
  PM_SELECTABLE_FILTER_INPUT_TYPES,
  PM_SELECTABLE_FILTER_OPERATORS,
} from "./BaseSelectableFilterClass";
import { SavedFilterObject } from "../BaseFilterClasses";
import { PmFilterButtonsCommitButtons } from "../subcomponents/PmFilterButtonsCommitControls";

interface PmSelectableFilterProps {
  filter: BaseSelectableFilterClass;
  savedFilter: SavedFilterObject;
  currentOperatorAndValue: PmSelectableFilterOperatorValue;
  closePopover: () => void;
  isMobile: boolean;
}

const PmSelectableFilter = ({
  filter,
  closePopover,
  savedFilter,
  currentOperatorAndValue,
  isMobile,
}: PmSelectableFilterProps) => {
  const history = useHistory();
  const location = useLocation();

  const [pendingOperatorAndValue, setPendingOperatorAndValue] = useState<NonNullable<PmSelectableFilterOperatorValue>>(
    () => {
      return (
        currentOperatorAndValue || {
          type: PM_SELECTABLE_FILTER_INPUT_TYPES.SELECT,
          operator: PM_SELECTABLE_FILTER_OPERATORS.ANY_OF,
          filterType: BaseSelectableFilterClass.type,
          options: filter.getDefaultMappedOptions(),
        }
      );
    }
  );

  const onOperatorChange = (newOperator: PmSelectableFilterAllowedOperators) => {
    const newOperatorAndValue = filter.getNewValueFromNewOperator({
      newOperator,
      currentPendingOperatorAndValue: pendingOperatorAndValue,
    });
    setPendingOperatorAndValue(newOperatorAndValue);
  };

  const onOptionsChange = (newOptions: Array<EuiSelectableOption<string>>) => {
    setPendingOperatorAndValue((state) => ({ ...state, options: newOptions }));
  };
  const allowedOperatorOptions = filter.getAllowedOperatorOptions();
  return (
    <PmFormStyling error={undefined}>
      <EuiFlexGroup
        direction="column"
        gutterSize="m"
        style={{ width: isMobile ? `min(80vw, ${filter.getPopoverWidth()})` : filter.getPopoverWidth() }}
      >
        {allowedOperatorOptions.length > 1 && (
          <EuiFlexItem grow={false}>
            <EuiSuperSelect
              options={allowedOperatorOptions}
              valueOfSelected={pendingOperatorAndValue.operator}
              onChange={onOperatorChange}
              aria-label="Select an operator"
            />
          </EuiFlexItem>
        )}
        {"options" in pendingOperatorAndValue && (
          <EuiFlexItem grow={false} data-testid="selected-values">
            <EuiSelectable
              options={pendingOperatorAndValue.options}
              onChange={onOptionsChange}
              allowExclusions={false}
              singleSelection={filter.getIsSingleSelect()}
            >
              {(list, search) => (
                <EuiFlexItem grow={true}>
                  {search}
                  {list}
                </EuiFlexItem>
              )}
            </EuiSelectable>
          </EuiFlexItem>
        )}
        <EuiFlexItem grow={false}>
          <PmFilterButtonsCommitButtons
            onApplyClick={filter.getOnApplyClick({
              location,
              savedFilter,
              history,
              closePopover,
              pendingOperatorAndValue,
            })}
            onClearClick={filter.getOnClearClick({ closePopover, location, savedFilter, history })}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </PmFormStyling>
  );
};

export { PmSelectableFilter };
