import React, { useState } from "react";
import { EuiIcon, EuiIconProps, EuiPopover, EuiToolTip, EuiToolTipProps } from "@elastic/eui";

import URL from "@pm-shared/utils/url";
import { useIsMobile } from "../hooks/useIsMobile";

interface PmInfoIconProps {
  tooltipProps?: Partial<Omit<EuiToolTipProps, "children">>;
  size?: EuiIconProps["size"];
  iconAriaLabel: string;
}

const ICON_URL = URL.getStatic("icons/questionmark.svg");

type InfoIconProps = Pick<PmInfoIconProps, "size" | "iconAriaLabel">;

const InfoIconButton = ({ size, iconAriaLabel, onClick }: InfoIconProps & { onClick: () => void }) => {
  return <EuiIcon onClick={onClick} type={ICON_URL} size={size || "original"} aria-label={iconAriaLabel} />;
};

const InfoIcon = ({ size, iconAriaLabel }: InfoIconProps) => {
  return <EuiIcon type={ICON_URL} size={size || "original"} aria-label={iconAriaLabel} />;
};

/**
 * Convenience component for rendering info icons with tooltips
 */
const PmInfoIcon = ({ tooltipProps, size, iconAriaLabel }: PmInfoIconProps) => {
  const isMobile = useIsMobile();
  const [isMobilePopoverOpen, setIsMobilePopoverOpen] = useState(false);
  // on mobile EUI tooltips don't work, so we use a popover
  if (isMobile && tooltipProps) {
    const togglePopover = () => setIsMobilePopoverOpen((state) => !state);
    return (
      <EuiPopover
        button={<InfoIconButton iconAriaLabel={iconAriaLabel} size={size} onClick={togglePopover} />}
        isOpen={isMobilePopoverOpen}
        closePopover={() => setIsMobilePopoverOpen(false)}
      >
        {tooltipProps.content}
      </EuiPopover>
    );
  }

  if (tooltipProps) {
    return (
      <EuiToolTip {...tooltipProps}>
        <InfoIcon size={size} iconAriaLabel={iconAriaLabel} />
      </EuiToolTip>
    );
  }

  return <InfoIcon size={size} iconAriaLabel={iconAriaLabel} />;
};

export { PmInfoIcon, PmInfoIconProps };
