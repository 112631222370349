export const RouteUrls = {
  // an old UI page
  accountSettings: "/account-settings/user/",
  schedulingSettings: "/account-settings/scheduling/",
  chooseAccount: "/choose-account/",
  dashboard: `/dashboard/`,
  // an old UI page - currently same url as any meld detail
  estimateDetails: ({ id }: { id: number | string }, parentMeldId: number) =>
    `/meld/${id}/summary/?parentMeldID=${parentMeldId}`,
  expenditureDetails: ({ id }: { id: number | string }) => `/melds/expenditures/${id}/summary/`,
  expenditureList: `/melds/expenditures/`,
  feedback: `/feedback/`,
  insightsOverview: `/insights/overview/`,
  integrations: `/integrations/`,
  integrationPartnerDetail: ({ id }: { id: number | string }) => `/integrations/${id}/summary/`,
  invoiceDetails: ({ id }: { id: number | string }) => `/melds/payments/${id}/summary/`,
  invoiceList: `/melds/payments/`,
  // an old UI page
  meldCalendarList: `/calendar/melds/`,
  // an old UI page
  meldCalendar: ({ id }: { id: number | string }) => `/calendar/melds/${id}/`,
  // an old UI page
  meldCalendarBook: ({ id }: { id: number }) => `/calendar/melds/${id}/book/`,
  meldCalendarMeldAvailabilities: ({ id }: { id: number | string }) => `calendar/melds/${id}/availability/`,
  // an old UI page
  meldClone: ({ id }: { id: number }) => `/meld/${id}/clone/`,
  meldDetails: ({ id }: { id: number }) => `/meld/${id}/summary/`,
  meldDetailsActivity: ({ id }: { id: number }) => `/meld/${id}/activity/`,
  meldDetailsChat: ({ id }: { id: number }) => `/meld/${id}/messages/`,
  meldDetailsWorkLog: ({ id }: { id: number }) => `/meld/${id}/log/`,
  meldDetailsContacts: ({ id }: { id: number }) => `/meld/${id}/contacts/`,
  meldDetailsRelated: ({ id }: { id: number }) => `/meld/${id}/related/`,
  meldDetailsReminders: ({ id }: { id: number }) => `/meld/${id}/reminders/`,
  meldDetailsRemindersAdd: ({ id }: { id: number }) => `/meld/${id}/add-reminder/`,
  meldDetailsRemindersEdit: ({ id: meldId }: { id: number }, reminderId: number) =>
    `/meld/${meldId}/reminders/${reminderId}/edit`,
  meldDetailsEstimates: ({ id }: { id: number }) => `/meld/${id}/estimates/`,
  // an old UI page
  meldEstimates: ({ id }: { id: number }) => `/meld/${id}/estimates/`,
  // an old UI page
  meldEdit: ({ id }: { id: number }) => `/meld/${id}/edit/`,
  // an old UI page
  meldEstimatesDetailView: (estimate_meld: number, original_meld: number) =>
    `/meld/${estimate_meld}/summary/?parentMeldID=${original_meld}`,
  // an old UI page
  meldEstimatesLineItemsView: (estimate_meld: number, original_meld: number) =>
    `/meld/${estimate_meld}/estimate-details/?parentMeldID=${original_meld}`,
  // an old UI page
  meldExpenditures: (expenditure_id: number) => `/melds/expenditures/${expenditure_id}/`,
  // an old UI page
  meldExpendituresCreate: ({ id }: { id: number }) => `/meld/${id}/expenditures/`,
  meldList: `/melds/melding/`,
  // an old UI page
  meldOwnerApproval: ({ id }: { id: number }) => `/meld/${id}/owner-approval/`,
  // an old UI page
  meldOwnerApprovalSummary: ({ id }: { id: number }) => `/meld/${id}/owner-approval-summary/`,
  // an old UI page
  meldPrint: ({ id }: { id: number }) => `/meld/${id}/print/`,
  // an old UI page
  meldCreateNewProject: `/projects/new/`,
  // an old UI page
  meldManagementAppt: ({ id }: { id: number }) => `/management-appointment/${id}/`,
  // an old UI page
  meldVendorAppt: ({ id }: { id: number }) => `/vendor-appointment/${id}/`,
  meldProjectList: `/projects/`,
  // an old UI page
  meldProject: ({ id }: { id: number }) => `/projects/${id}/summary/`,
  // an old UI page
  meldUploadInvoice: ({ id }: { id: number }) => `/meld/${id}/invoice/`,
  // an old UI page
  newMeld: `/melds/new-meld/`,
  // an old UI page
  newOwner: `/properties/owners/new/`,
  // an old UI page
  newProperty: `/properties/addresses/new/`,
  // an old UI page
  newResident: `/tenants/new/`,
  // an old UI page
  newUnit: `/properties/new/`,
  nexus: {
    nexusDashboard: `/nexus/dashboard/`,
    nexusRequestsList: `/nexus/requests/`,
    nexusMessagesList: `/nexus/messages/`,
    nexusAPIKeysList: `/nexus/api-keys/`,
    nexusBilling: `/nexus/billing/`,
    nexusSettings: `/nexus/settings/`,
    nexusUserManagement: `/nexus/user-management/`,
  },
  meldForms: {
    meldCreateForm: `/melds/new-meld/`,
  },
  // an old UI page
  notifications: `/notifications/`,
  ownerDetail: (id: number | string) => `/properties/owners/${id}/summary/`,
  ownerDetailEdit: (id: number | string) => `/properties/owners/${id}/edit/`,
  ownerList: `/properties/owners/`,
  propertyCarePlusList: `/owner-services/`,
  propertyCarePlusDetails: (pcpId: number | string) => `/owner-services/${pcpId}/`,
  propertyCarePlusDetailsEdit: (pcpId: number | string) => `/owner-services/${pcpId}/edit`,
  propertyDetail: ({ id }: { id: number | string }) => `/properties/addresses/${id}/summary/`,
  propertyDetailEdit: ({ id }: { id: number | string }) => `/properties/addresses/${id}/edit/`,
  propertyGroupDetail: ({ id }: { id: number }) => `/properties/groups/${id}/addresses/`,
  propertyGroupList: `/properties/groups/`,
  propertyList: `/properties/addresses/`,
  recurringMeldsList: `/melds/recurring/`,
  recurringMeldDetail: (id: number | string) => `/melds/recurring/${id}/`,
  // an old UI page
  reporting: "/reporting/",
  // new UI page change to just reporting
  reportingV2: "/reportingV2/",
  residentList: `/tenants/`,
  residentListInvited: `/tenants/invited/`,
  residentListRequests: `/tenants/requests/`,
  residentDetail: ({ id: residentId }: { id: number | string }) => `/tenants/${residentId}/`,
  residentDetailEdit: ({ id: residentId }: { id: number | string }) => `/tenants/${residentId}/edit/`,
  // an old UI page whose data is now on the main resident details page
  residentDetailMelds: ({ id: residentId }: { id: number | string }) => `/tenants/${residentId}/meld/`,
  servicePartnerList: `/service-partners/`,
  // an old UI page
  trainingVideos: `/users/videos/`,
  unitDetail: ({ id }: { id: number | string }) => `/properties/${id}/summary/`,
  unitDetailEdit: ({ id }: { id: number | string }) => `/properties/${id}/edit/`,
  unitDetailMelds: ({ id }: { id: number | string }) => `/properties/${id}/melds/`,
  unitDetailInvoices: ({ id }: { id: number | string }) => `/properties/${id}/invoices/`,
  unitDetailResidents: ({ id }: { id: number | string }) => `/properties/${id}/tenants/`,
  unitDetailContacts: ({ id }: { id: number | string }) => `/properties/${id}/contacts/`,
  unitList: `/properties/`,
  userManagement: `/users/`,
  vendorList: `/vendors/`,
  vendorDetail: (id: number | string) => `vendors/${id}/summary/`,
  vendorListPending: `/vendors/pending/`,
  vendorSearchList: `/vendors/search/`,
  vendorSearchDetail: (id: number | string) => `vendors/search/${id}/`,
  workflowList: `/workflows/list/`,
} as const;

// default queries used on list views and navigation links
export const meldListDefaultQuery = { saved_filter: "default" };
export const projectListDefaultQuery = { completed: "false", order_by: "due_date" };
export const invoiceListDefaultQuery = { saved_filter: "default" };
export const residentListDefaultQuery = { ordering: ["last_name", "first_name"] };
export const residentListInvitedDefaultQuery = { ordering: "name" };
