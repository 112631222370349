var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useState } from "react";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiTextColor,
  EuiContextMenuPanel,
  EuiContextMenuItem,
  EuiPopover,
  EuiBadge,
  EuiHorizontalRule,
  EuiImage,
  EuiTitle,
  EuiIcon,
  EuiLink
} from "@elastic/eui";
import URL from "@pm-shared/utils/url";
import { getIdFromText } from "@pm-frontend/shared/utils/idFromText";
import { PmBadge } from "@pm-frontend/shared/components/PmBadge";
import { BorderColor, colors } from "@pm-frontend/styles";
import { Link } from "react-router-dom";
import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";
import { SecondaryActionIconsSrc } from "@pm-frontend/shared/components/PageHeader/PageHeader";
import { PmFilledButton } from "../Buttons/PmFilledButton";
import { PmText } from "../Text/PmText";
import { PmIconBadge } from "@pm-frontend/shared/components/PmIconBadge";
const isEnabled = (obj) => !obj.hasOwnProperty("enabled") || obj.enabled;
const getMobilePrimaryActionProps = (primaryAction, secondaryActions, linkActions) => {
  if ((primaryAction == null ? void 0 : primaryAction.mobilePrimary) && isEnabled(primaryAction)) {
    return __spreadProps(__spreadValues({}, primaryAction), { isLoading: false });
  }
  if (secondaryActions) {
    for (const secondaryAction of secondaryActions) {
      if (secondaryAction.mobilePrimary && isEnabled(secondaryAction)) {
        if (typeof secondaryAction.href === "string") {
          return { text: secondaryAction.text, href: secondaryAction.href, isLoading: false };
        } else if (secondaryAction.onClick) {
          return { text: secondaryAction.text, onClick: secondaryAction.onClick, isLoading: false };
        }
      }
    }
  }
  if (linkActions) {
    for (const linkAction of linkActions) {
      if (linkAction.mobilePrimary && isEnabled(linkAction)) {
        return __spreadProps(__spreadValues({}, linkAction), { isLoading: false });
      }
    }
  }
};
const MobileDetailViewsPageHeader = ({
  subtitle,
  title,
  icon,
  primaryAction,
  secondaryActions,
  prependBadgeRow,
  badges,
  iconBadges,
  backButtonUrl,
  backButtonOnClick,
  backButtonAriaLabel,
  backButtonText,
  linkActions,
  isInactive = false,
  inactiveAction,
  extraContentRow,
  bottomTray,
  "data-testid": dataTestId,
  disableHorizontalRule = false,
  backButtonLinkProps
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const closeMenu = () => setIsMenuOpen(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const mobilePrimaryActionProps = getMobilePrimaryActionProps(primaryAction, secondaryActions, linkActions);
  const convertedPrimaryAction = primaryAction && !primaryAction.mobilePrimary ? __spreadValues(__spreadValues({}, primaryAction), primaryAction.dataTestId ? { dataTestId: primaryAction.dataTestId } : {}) : void 0;
  const convertedLinkActions = linkActions ? linkActions.map((linkAction) => {
    return __spreadValues(__spreadValues({}, linkAction), linkAction.dataTestId ? { dataTestId: linkAction.dataTestId } : {});
  }) : [];
  let mobileSecondaryActions = [];
  if (isInactive && inactiveAction) {
    mobileSecondaryActions = [
      {
        text: inactiveAction.text,
        onClick: inactiveAction.onClick
      }
    ];
  } else {
    mobileSecondaryActions = secondaryActions ? [convertedPrimaryAction, ...convertedLinkActions, ...secondaryActions].filter(Boolean) : [convertedPrimaryAction, ...convertedLinkActions].filter(Boolean);
  }
  const filteredSecondaryActions = mobileSecondaryActions == null ? void 0 : mobileSecondaryActions.filter(
    // eslint-disable-next-line no-prototype-builtins
    (action) => !action.hasOwnProperty("enabled") || action.enabled
  );
  const secondaryActionsTitle = /* @__PURE__ */ React.createElement(EuiText, { color: colors.neutrals.gray800, style: { fontWeight: 700 } }, "Actions");
  const renderMenuItems = (items = []) => items.filter(Boolean).map((item) => {
    const textOfItem = /* @__PURE__ */ React.createElement(
      EuiTextColor,
      {
        color: colors.neutrals.gray800,
        style: { maxWidth: "fit-content" },
        "data-testid": item && item.dataTestId ? item.dataTestId : void 0
      },
      item.text
    );
    let wrappedItem = textOfItem;
    if (item.href) {
      wrappedItem = /* @__PURE__ */ React.createElement(Link, { to: item.href, target: item.internalLink ? void 0 : "_blank", onClick: closeMenu }, textOfItem);
    } else if (item.linkToProps) {
      wrappedItem = /* @__PURE__ */ React.createElement(Link, { to: item.linkToProps, target: item.internalLink ? void 0 : "_blank", onClick: closeMenu }, textOfItem);
    }
    return /* @__PURE__ */ React.createElement(
      EuiContextMenuItem,
      {
        key: item.text,
        style: {
          padding: "0px",
          background: colors.neutrals.gray50,
          textDecoration: "none"
        }
      },
      /* @__PURE__ */ React.createElement(
        EuiFlexGroup,
        {
          direction: "row",
          alignItems: "flexStart",
          gutterSize: "s",
          style: { padding: "10px 12px" },
          wrap: false,
          onClick: item.onClick ? () => {
            if (item.onClick && typeof item.onClick === "function") {
              item.onClick();
            }
            closeMenu();
          } : void 0
        },
        /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, style: { padding: "2px", maxWidth: "fit-content" } }, /* @__PURE__ */ React.createElement(
          EuiImage,
          {
            src: item.icon ? SecondaryActionIconsSrc[item.icon] : SecondaryActionIconsSrc.grayExclamation,
            alt: "",
            size: "original",
            wrapperProps: { style: { maxWidth: "fit-content" } }
          }
        )),
        /* @__PURE__ */ React.createElement(
          EuiFlexItem,
          {
            grow: false,
            style: {
              fontFamily: "Open Sans",
              fontSize: "16px",
              lineHeight: "16px",
              maxWidth: "fit-content"
            }
          },
          wrappedItem
        )
      )
    );
  });
  const isSubtitleSimilar = (titleText, subtitleText) => {
    return subtitleText && (titleText == null ? void 0 : titleText.toLowerCase().includes(subtitleText == null ? void 0 : subtitleText.toLowerCase()));
  };
  let formattedSubtitle;
  if (subtitle && !isSubtitleSimilar(title, subtitle.text)) {
    if (subtitle.href) {
      formattedSubtitle = /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(Link, { to: subtitle.href }, /* @__PURE__ */ React.createElement(EuiLink, null, subtitle.text)));
    } else {
      formattedSubtitle = /* @__PURE__ */ React.createElement(EuiText, { size: "s" }, /* @__PURE__ */ React.createElement(EuiTextColor, { color: colors.neutrals.gray700 }, subtitle.text));
    }
  }
  let backButtonLinkElement;
  if (backButtonUrl) {
    backButtonLinkElement = /* @__PURE__ */ React.createElement(
      Link,
      __spreadValues({
        to: LinkHelper.normalize(backButtonUrl),
        style: { maxWidth: "fit-content" }
      }, backButtonOnClick ? { onClick: backButtonOnClick } : {}),
      /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", responsive: false, gutterSize: "s" }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiIcon, { type: URL.getStatic("icons/arrow_back.svg"), "aria-label": backButtonAriaLabel, size: "original" })), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, backButtonText))
    );
  } else if (backButtonLinkProps) {
    backButtonLinkElement = /* @__PURE__ */ React.createElement(
      Link,
      __spreadValues(__spreadProps(__spreadValues({}, backButtonLinkProps), {
        style: { maxWidth: "fit-content" }
      }), backButtonOnClick ? { onClick: backButtonOnClick } : {}),
      /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", responsive: false, gutterSize: "s" }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiIcon, { type: URL.getStatic("icons/arrow_back.svg"), "aria-label": backButtonAriaLabel, size: "original" })), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, backButtonText))
    );
  } else if (backButtonOnClick) {
    backButtonLinkElement = /* @__PURE__ */ React.createElement(EuiLink, { style: { maxWidth: "fit-content" }, onClick: backButtonOnClick }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", responsive: false, gutterSize: "s" }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiIcon, { type: URL.getStatic("icons/arrow_back.svg"), "aria-label": backButtonAriaLabel, size: "original" })), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, backButtonText)));
  }
  const actionsMenuButton = /* @__PURE__ */ React.createElement(
    EuiFlexGroup,
    {
      direction: "row",
      gutterSize: "s",
      responsive: false,
      onClick: toggleMenu,
      alignItems: "center",
      style: { cursor: "pointer" }
    },
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
      EuiIcon,
      {
        size: "original",
        type: URL.getStatic("icons/horizontal-dots-blue.svg"),
        "aria-label": "Open secondary action menu",
        color: colors.brand.meldBlue
      }
    )),
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(PmText, { color: colors.brand.meldBlue, textAlign: "center", fontSize: "p2" }, "More actions"))
  );
  return /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", style: { gap: "12px" } }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { alignItems: "stretch", direction: "column", style: { gap: "24px" }, wrap: false }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, style: { maxWidth: "fit-content" } }, backButtonLinkElement), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", responsive: false, gutterSize: "m", alignItems: "center" }, mobilePrimaryActionProps && /* @__PURE__ */ React.createElement(PmFilledButton, __spreadProps(__spreadValues({}, mobilePrimaryActionProps), { padding: "5.5px 12px" })), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, style: { maxWidth: "fit-content" } }, filteredSecondaryActions && filteredSecondaryActions.length > 0 && /* @__PURE__ */ React.createElement(
    EuiPopover,
    {
      "data-testid": "page-header-secondary-actions",
      id: getIdFromText(title),
      button: actionsMenuButton,
      isOpen: isMenuOpen,
      closePopover: closeMenu,
      panelPaddingSize: "s",
      anchorPosition: "downRight",
      style: { maxWidth: "fit-content" }
    },
    /* @__PURE__ */ React.createElement(
      EuiContextMenuPanel,
      {
        title: secondaryActionsTitle,
        items: renderMenuItems(filteredSecondaryActions)
      }
    )
  )))), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", style: { gap: "12px" } }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", style: { gap: "12px" } }, icon && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiIcon, { type: icon })), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, formattedSubtitle, /* @__PURE__ */ React.createElement(EuiText, null, /* @__PURE__ */ React.createElement(EuiTextColor, { color: colors.neutrals.gray900 }, /* @__PURE__ */ React.createElement(EuiTitle, null, /* @__PURE__ */ React.createElement("h2", __spreadValues({ style: { fontSize: "24px" } }, dataTestId ? { "data-testid": dataTestId } : {}), title))))), isInactive && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { gutterSize: "s" }, /* @__PURE__ */ React.createElement(EuiBadge, { color: colors.neutrals.gray700 }, /* @__PURE__ */ React.createElement(EuiTextColor, { color: colors.brand.white }, "Inactive")))), (prependBadgeRow || badges || iconBadges || extraContentRow) && /* @__PURE__ */ React.createElement(EuiFlexGroup, { gutterSize: "s", direction: "column" }, (prependBadgeRow || badges || iconBadges) && /* @__PURE__ */ React.createElement(EuiFlexGroup, { gutterSize: "s" }, prependBadgeRow && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
    EuiText,
    {
      color: colors.neutrals.gray800,
      style: {
        fontSize: "14px",
        fontWeight: "600",
        marginRight: "8px"
      },
      grow: false
    },
    prependBadgeRow
  )), badges && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { gutterSize: "s", alignItems: "center", responsive: false }, badges.map((badge) => /* @__PURE__ */ React.createElement(EuiFlexItem, { key: badge.text, grow: false }, /* @__PURE__ */ React.createElement(PmBadge, __spreadValues({}, badge)))))), iconBadges && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { gutterSize: "s", alignItems: "center" }, iconBadges.map((iconBadge) => /* @__PURE__ */ React.createElement(EuiFlexItem, { key: iconBadge.badgeText, grow: false }, /* @__PURE__ */ React.createElement(PmIconBadge, __spreadValues({}, iconBadge)))))))), extraContentRow && /* @__PURE__ */ React.createElement(EuiFlexGroup, { gutterSize: "s", alignItems: "center" }, extraContentRow)))))), !disableHorizontalRule && !bottomTray ? /* @__PURE__ */ React.createElement(EuiHorizontalRule, { margin: "s", size: "full", style: { marginBottom: "0px", backgroundColor: BorderColor } }) : /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", gutterSize: "s" }, bottomTray));
};
export { MobileDetailViewsPageHeader };
