type Result =
  | {
      ok: true;
      value: string | null;
    }
  | {
      ok: false;
      value: null;
    };

export class StorageUtils {
  /** Reads a value from localStorage.
   *
   * Returns a Result type with an `ok` value that indicates if the `window.localStorage.getItem` call succeeded,
   * and the value (if any)
   */
  static getLocalStorageItem(key: string): Result {
    try {
      const storedValue = window.localStorage.getItem(key);
      return { ok: true, value: storedValue };
    } catch {
      return { ok: false, value: null };
    }
  }

  /** Writes value to localStorage.
   *
   * Returns a Result type with an `ok` value that indicates if the `window.localStorage.setItem` call succeeded
   */
  static setLocalStorageItem(key: string, item: { toString(): string }): Result {
    try {
      window.localStorage.setItem(key, item.toString());
      return { ok: true, value: null };
    } catch {
      return { ok: false, value: null };
    }
  }
}
