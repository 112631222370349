import React, { useState } from "react";
import {
  EuiFilterButton,
  EuiFilterGroup,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopover,
  EuiPopoverTitle,
  EuiSelectable,
} from "@elastic/eui";
import { PmEmptyButton } from "../Buttons/PmEmptyButton";
import { useLocation } from "react-router-dom";
import { PmSelectableFilter } from "./PmSelectableFilter/PmSelectableFilter";
import { PmComboboxFilter } from "./PmComboboxFilter/PmComboboxFilter";
import { PmDecimalFilter } from "./PmDecimalFilter/PmDecimalFilter";
import { PmDateFilter } from "./PmDateFilter/PmDateFilter";
import { FilterClassTypes } from "./BaseFilterClasses";
import { BaseSelectableFilterClass } from "./PmSelectableFilter/BaseSelectableFilterClass";
import { BaseComboboxFilterClass } from "./PmComboboxFilter/BaseComboboxFilterClass";
import { BaseDecimalFilterClass } from "./PmDecimalFilter/BaseDecimalFilterClass";
import { BaseDateFilterClass } from "./PmDateFilter/BaseDateFilterClass";
import { BaseSortFilterClass } from "./PmSortFilter/BaseSortFilterClass";
import { PmSortFilter } from "./PmSortFilter/PmSortFilter";
import { BaseSavedFiltersFilterClass } from "./PmSavedFiltersFilter/BaseSavedFiltersFilterClass";
import { PmSavedFiltersFilter } from "./PmSavedFiltersFilter/PmSavedFiltersFilter";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";

const ADD_FILTER_NAME = "add-filter-popover";

function isSimpleFilter(filter: FilterClassTypes): filter is BaseSelectableFilterClass {
  return (filter.constructor as typeof BaseSelectableFilterClass).type === "selectable_filter";
}

function isComplexFilter(filter: FilterClassTypes): filter is BaseComboboxFilterClass {
  return (filter.constructor as typeof BaseComboboxFilterClass).type === "combobox_filter";
}

function isDecimalFilter(filter: FilterClassTypes): filter is BaseDecimalFilterClass {
  return (filter.constructor as typeof BaseDecimalFilterClass).type === "decimal_filter";
}

function isDateFilter(filter: FilterClassTypes): filter is BaseDateFilterClass {
  return (filter.constructor as typeof BaseDateFilterClass).type === "date_filter";
}

interface FilterButtonProps {
  isOpen: boolean;
  closePopover: () => void;
  filterName: string;
  initialFocus: string | undefined;
  onButtonClick: () => void;
  filterNumberBadgeCount: number | undefined;
  buttonText: string;
  children: React.ReactNode;
}

const FilterButtonWithPopover = ({
  isOpen,
  buttonText,
  closePopover,
  filterName,
  initialFocus,
  onButtonClick,
  filterNumberBadgeCount,
  children,
}: FilterButtonProps) => {
  return (
    <EuiPopover
      isOpen={isOpen}
      closePopover={closePopover}
      key={filterName}
      initialFocus={initialFocus}
      button={
        <EuiFilterButton
          grow={false}
          onClick={onButtonClick}
          numFilters={filterNumberBadgeCount}
          badgeColor="accent"
          data-testid={`meld-list-active-filter-${buttonText}`}
        >
          {buttonText}
        </EuiFilterButton>
      }
    >
      <>
        <EuiPopoverTitle>{buttonText}</EuiPopoverTitle>
        {children}
      </>
    </EuiPopover>
  );
};

interface PmListFiltersProps {
  sortFilter?: BaseSortFilterClass;
  savedFiltersFilter?: BaseSavedFiltersFilterClass;
  filters: FilterClassTypes[];
  onSaveClick: (() => void) | undefined;
  hideAddFilterButton?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  savedFilter: Record<string, any | any[]> | undefined;
}

/**
 * Displays the filter buttons on list views.
 *
 * @field
 * `filters` a stable array of filter classes. Often should be memo'd with useMemo,
 * as the classes only contain static configs and methods
 *
 * @field
 * `savedFiltersFilter` presents a filter button that allows for selecting a saved filter
 *
 * @field
 * `onSaveClick` - if defined the `Save` button is shown
 */
const PmListFilters = ({
  sortFilter,
  savedFiltersFilter,
  filters,
  onSaveClick,
  hideAddFilterButton,
  savedFilter,
}: PmListFiltersProps) => {
  const isMobile = useIsMobile();
  const location = useLocation();
  // only one filter can be open at a time
  const [openFilterName, setOpenFilterName] = useState<string | null>();

  // we only show filters with applied values or if the popover state is open
  // we push to this array when looping through the filters
  const missingFilters: Array<FilterClassTypes | BaseSortFilterClass> = [];

  const sortFilterCurrentValue = sortFilter && sortFilter.getSortSearchParamValue({ location });
  const shouldShowSortFilter =
    sortFilter &&
    (openFilterName === sortFilter.getFilterName() ||
      (sortFilterCurrentValue && sortFilter.getShouldShowFilter({ currentParamValue: sortFilterCurrentValue })));

  if (sortFilter && !shouldShowSortFilter) {
    missingFilters.push(sortFilter);
  }

  const shouldShowSavedFiltersFilter =
    savedFiltersFilter &&
    (openFilterName === savedFiltersFilter.getFilterName() || savedFiltersFilter.getShouldAlwaysShow());

  const savedFiltersFilterCurrentValue =
    savedFiltersFilter && savedFiltersFilter.getCurrentlyAppliedValue({ location });

  const getOnButtonClick = (filterName: string) => () =>
    setOpenFilterName((state) => {
      if (!state) {
        return filterName;
      } else {
        return null;
      }
    });

  return (
    <EuiFlexGroup direction="row" responsive={false} gutterSize="m" data-testid="list-view-filters">
      <EuiFlexItem grow={false}>
        <EuiFilterGroup fullWidth={true} style={{ flexWrap: "wrap" }}>
          {shouldShowSavedFiltersFilter && savedFiltersFilterCurrentValue && (
            <FilterButtonWithPopover
              isOpen={openFilterName === savedFiltersFilter.getFilterName()}
              closePopover={() => setOpenFilterName(null)}
              filterName={savedFiltersFilter.getFilterName()}
              onButtonClick={getOnButtonClick(savedFiltersFilter.getFilterName())}
              filterNumberBadgeCount={savedFiltersFilter.getAppliedFilterCount({
                currentValue: savedFiltersFilterCurrentValue,
              })}
              initialFocus={undefined}
              buttonText={savedFiltersFilter.getButtonText()}
            >
              <PmSavedFiltersFilter
                filter={savedFiltersFilter}
                currentValue={savedFiltersFilterCurrentValue}
                closePopover={() => setOpenFilterName(null)}
                isMobile={isMobile}
              />
            </FilterButtonWithPopover>
          )}
          {shouldShowSortFilter && (
            <FilterButtonWithPopover
              isOpen={openFilterName === sortFilter.getFilterName()}
              closePopover={() => setOpenFilterName(null)}
              filterName={sortFilter.getFilterName()}
              onButtonClick={getOnButtonClick(sortFilter.getFilterName())}
              filterNumberBadgeCount={sortFilter.getAppliedFilterCount({ currentValue: sortFilterCurrentValue })}
              initialFocus={undefined}
              buttonText={sortFilter.getButtonText()}
            >
              <PmSortFilter
                filter={sortFilter}
                closePopover={() => setOpenFilterName(null)}
                savedFilter={savedFilter}
                isMobile={isMobile}
              />
            </FilterButtonWithPopover>
          )}
          {filters.map((filter) => {
            let body: React.ReactNode;
            let filterNumberBadgeCount = 0;
            if (isSimpleFilter(filter)) {
              const currentOperatorAndValue = filter.getCurrentlyAppliedOperatorAndValue({ savedFilter, location });
              filterNumberBadgeCount = filter.getAppliedFilterCount({ currentOperatorAndValue });
              const shouldShowFilter =
                filter.getShouldAlwaysShow() || openFilterName === filter.getFilterName() || filterNumberBadgeCount > 0;
              if (!shouldShowFilter) {
                missingFilters.push(filter);
                return null;
              }
              body = (
                <PmSelectableFilter
                  filter={filter}
                  currentOperatorAndValue={currentOperatorAndValue}
                  savedFilter={savedFilter}
                  closePopover={() => setOpenFilterName(null)}
                  isMobile={isMobile}
                />
              );
            } else if (isComplexFilter(filter)) {
              const currentOperatorAndValue = filter.getCurrentlyAppliedOperatorAndValue({ savedFilter, location });
              filterNumberBadgeCount = filter.getAppliedFilterCount({ currentOperatorAndValue });
              const shouldShowFilter = openFilterName === filter.getFilterName() || filterNumberBadgeCount > 0;
              if (!shouldShowFilter) {
                missingFilters.push(filter);
                return null;
              }
              body = (
                <PmComboboxFilter
                  filter={filter}
                  currentOperatorAndValue={currentOperatorAndValue}
                  savedFilter={savedFilter}
                  closePopover={() => setOpenFilterName(null)}
                  isMobile={isMobile}
                />
              );
            } else if (isDecimalFilter(filter)) {
              const currentOperatorAndValue = filter.getCurrentlyAppliedOperatorAndValue({ savedFilter, location });
              filterNumberBadgeCount = filter.getAppliedFilterCount({ currentOperatorAndValue });
              const shouldShowFilter = openFilterName === filter.getFilterName() || filterNumberBadgeCount > 0;
              if (!shouldShowFilter) {
                missingFilters.push(filter);
                return null;
              }
              body = (
                <PmDecimalFilter
                  filter={filter}
                  currentOperatorAndValue={currentOperatorAndValue}
                  savedFilter={savedFilter}
                  closePopover={() => setOpenFilterName(null)}
                  isMobile={isMobile}
                />
              );
            } else if (isDateFilter(filter)) {
              const currentOperatorAndValue = filter.getCurrentlyAppliedOperatorAndValue({ savedFilter, location });
              filterNumberBadgeCount = filter.getAppliedFilterCount({ currentOperatorAndValue });
              const shouldShowFilter = openFilterName === filter.getFilterName() || filterNumberBadgeCount > 0;
              if (!shouldShowFilter) {
                missingFilters.push(filter);
                return null;
              }
              body = (
                <PmDateFilter
                  filter={filter}
                  currentOperatorAndValue={currentOperatorAndValue}
                  savedFilter={savedFilter}
                  closePopover={() => setOpenFilterName(null)}
                  isMobile={isMobile}
                />
              );
            }

            return (
              <FilterButtonWithPopover
                isOpen={openFilterName === filter.getFilterName()}
                closePopover={() => setOpenFilterName(null)}
                key={filter.getFilterName()}
                initialFocus={isComplexFilter(filter) ? "#filter-to-focus" : undefined}
                onButtonClick={getOnButtonClick(filter.getFilterName())}
                filterNumberBadgeCount={filterNumberBadgeCount}
                buttonText={filter.getButtonText()}
                filterName={filter.getFilterName()}
              >
                {body}
              </FilterButtonWithPopover>
            );
          })}
          {!hideAddFilterButton && (
            <EuiPopover
              isOpen={openFilterName === ADD_FILTER_NAME}
              closePopover={() => setOpenFilterName(null)}
              initialFocus="#filter-add-filter-search"
              button={
                <EuiFilterButton grow={false} onClick={() => setOpenFilterName(ADD_FILTER_NAME)}>
                  + Add Filter
                </EuiFilterButton>
              }
            >
              <EuiSelectable
                searchable={true}
                options={missingFilters.map((filter) => ({ label: filter.getButtonText() }))}
                searchProps={{
                  id: "filter-add-filter-search",
                }}
                onChange={(newOptions) => {
                  const newFilterOpt = newOptions.find((newOpt) => newOpt.checked === "on");
                  if (!newFilterOpt) {
                    return;
                  }
                  const newFilter = missingFilters.find((filt) => filt.getButtonText() === newFilterOpt?.label);
                  if (!newFilter) {
                    return;
                  }

                  setOpenFilterName(newFilter.getFilterName());
                }}
                allowExclusions={false}
                singleSelection={true}
                style={{ width: isMobile ? "min(80vw, 300px)" : "300px" }}
              >
                {(list, search) => (
                  <EuiFlexItem grow={true}>
                    {search}
                    {list}
                  </EuiFlexItem>
                )}
              </EuiSelectable>
            </EuiPopover>
          )}
        </EuiFilterGroup>
      </EuiFlexItem>
      {onSaveClick && (
        <EuiFlexItem grow={false}>
          <PmEmptyButton text="Save" onClick={onSaveClick} />
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  );
};

export { PmListFilters, PmListFiltersProps };
