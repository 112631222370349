var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import moment from "moment";
import { create } from "zustand";
import { shouldShowCalendarRedesignDueToDesktop } from "@pm-assets/js/utils/redesign-routing";
import { useCalendarMapStateStore } from "./mapStore";
import { useCalendarTimeFrameStateStore } from "./timeFrameStore";
import { useCalendarDragAndDropStateStore } from "./dragDropStore";
import { StorageUtils } from "@pm-frontend/shared/utils/storage-utils";
import { useCalendarDraftModeStateStore } from "./draftModeStore";
const CALENDAR_SELECTED_VENDOR_AGENTS_LOCAL_STORAGE_KEY = `meld-calendar-selected-agents-${window.PM.user.id}-${window.PM.user.multitenantId}`;
const setInitialSelectedVendorsOrAgents = (state) => {
  StorageUtils.setLocalStorageItem(CALENDAR_SELECTED_VENDOR_AGENTS_LOCAL_STORAGE_KEY, JSON.stringify(state));
};
const getInitialSelectedVendorsOrAgents = () => {
  let result = { agents: [], vendors: [] };
  const { value } = StorageUtils.getLocalStorageItem(CALENDAR_SELECTED_VENDOR_AGENTS_LOCAL_STORAGE_KEY);
  if (value) {
    const parsed = JSON.parse(value);
    if (Array.isArray(parsed.agents) && Array.isArray(parsed.vendors)) {
      result = parsed;
    }
  }
  return result;
};
const useCalendarStateStore = create((set) => ({
  activePane: shouldShowCalendarRedesignDueToDesktop.matches ? { type: "meldsToSchedule" } : { type: "mobile-null" },
  selectedVendorsAgentsIds: getInitialSelectedVendorsOrAgents(),
  scheduledSegments: [],
  dragAndDropState: null,
  pendingResidentAvailabilities: [],
  pendingRecommendedMeld: null,
  residentAvailabilitiesMeldId: null,
  altEventSelectedAgentsTime: null,
  priorRightPaneType: null,
  appointmentToReschedule: null,
  actions: {
    setResidentAvailabilityMeldId: (meldId) => set(() => ({ residentAvailabilitiesMeldId: meldId })),
    setPendingRecommendEvent: (event) => set({ pendingRecommendedMeld: event }),
    setSelectedVendorsAgents: (selected) => {
      setInitialSelectedVendorsOrAgents(selected);
      set((state) => {
        if (state.pendingRecommendedMeld) {
          const currentAgentId = state.pendingRecommendedMeld.personaId;
          if (!selected.agents.some((agentId) => agentId === currentAgentId)) {
            return { selectedVendorsAgentsIds: selected, activePane: { type: "meldsToSchedule" } };
          }
        }
        return { selectedVendorsAgentsIds: selected };
      });
    },
    addSelectedVendorsAgents: (agentsVendorsToAdd) => {
      set((state) => {
        const result = {
          agents: state.selectedVendorsAgentsIds.agents,
          vendors: state.selectedVendorsAgentsIds.vendors
        };
        agentsVendorsToAdd.agents.forEach((agent) => {
          if (!result.agents.includes(agent)) {
            result.agents.push(agent);
          }
        });
        agentsVendorsToAdd.vendors.forEach((vendor) => {
          if (!result.vendors.includes(vendor)) {
            result.vendors.push(vendor);
          }
        });
        return { selectedVendorsAgentsIds: result };
      });
    },
    resetState: () => set({
      pendingRecommendedMeld: null,
      pendingResidentAvailabilities: [],
      residentAvailabilitiesMeldId: null
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addScheduledSegment: (segment) => set((state) => ({
      scheduledSegments: [...state.scheduledSegments, segment]
    })),
    removeScheduledSegment: (id) => set((state) => ({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      scheduledSegments: state.scheduledSegments.filter((segment) => segment.id !== id)
    })),
    // pending resident availability placeholders
    addPendingResidentAvailability: (pendingEventsToAdd) => set((state) => {
      return {
        pendingResidentAvailabilities: [
          ...state.pendingResidentAvailabilities,
          ...pendingEventsToAdd.map(
            (event) => ({
              type: "pending_offered_availability",
              start: event.start,
              end: event.end,
              coordinates: void 0,
              startDate: new Date(event.start),
              endDate: new Date(event.end),
              start_moment: moment(event.start),
              end_moment: moment(event.end),
              description: "Offer this time to resident",
              personaId: event.agentId,
              personaType: "agent",
              key: event.tempId + event.agentId,
              tempId: event.tempId
            })
          )
        ]
      };
    }),
    removePendingResidentAvailability: (tempId) => set((state) => {
      return {
        pendingResidentAvailabilities: [...state.pendingResidentAvailabilities].filter(
          (e) => e.type !== "pending_offered_availability" || e.tempId !== tempId
        )
      };
    }),
    upsertPendingResidentAvailability: ({ tempId, start, end, agents }) => set((state) => {
      let updatedAnEvent = false;
      const updatedAvailabilities = state.pendingResidentAvailabilities.map((existingEvent) => {
        if (existingEvent.type !== "pending_offered_availability" || existingEvent.tempId !== tempId) {
          return existingEvent;
        }
        updatedAnEvent = true;
        const updatedEvent = __spreadValues({}, existingEvent);
        updatedEvent.end_moment = end.clone();
        updatedEvent.end = end.toISOString();
        updatedEvent.start_moment = start.clone();
        updatedEvent.start = start.toISOString();
        return updatedEvent;
      });
      if (!updatedAnEvent) {
        agents.forEach((agent) => {
          updatedAvailabilities.push({
            type: "pending_offered_availability",
            coordinates: void 0,
            start: start.toISOString(),
            startDate: start.toDate(),
            end: end.toISOString(),
            endDate: end.toDate(),
            start_moment: start,
            end_moment: end,
            description: "Offer this time to resident",
            personaId: agent.id,
            personaType: "agent",
            key: tempId + agent.id,
            tempId
          });
        });
      }
      return { pendingResidentAvailabilities: updatedAvailabilities };
    }),
    clearPendingResidentAvailabilities: () => set({ pendingResidentAvailabilities: [] }),
    initializeAltEventSelectedAgentsTime: ({ agents, selectedTime, eventDescription }) => {
      set(() => ({ altEventSelectedAgentsTime: { selectedAgents: agents, selectedTime, eventDescription } }));
    },
    // update alt event data
    setAltEventSelectedAgentsTime: ({ newAgents, newSelectedTime, eventId, eventDescription }) => set((state) => {
      var _a, _b, _c, _d, _e, _f;
      const selectedAgents = newAgents || ((_a = state.altEventSelectedAgentsTime) == null ? void 0 : _a.selectedAgents) || [];
      const selectedDate = (newSelectedTime && "date" in newSelectedTime ? newSelectedTime.date : (_b = state.altEventSelectedAgentsTime) == null ? void 0 : _b.selectedTime.date) || /* @__PURE__ */ new Date();
      const selectedStart = newSelectedTime && "start" in newSelectedTime ? newSelectedTime.start : (_c = state.altEventSelectedAgentsTime) == null ? void 0 : _c.selectedTime.start;
      const selectedEnd = newSelectedTime && "end" in newSelectedTime ? newSelectedTime.end : (_d = state.altEventSelectedAgentsTime) == null ? void 0 : _d.selectedTime.end;
      if (!newAgents && !newSelectedTime) {
        return { altEventSelectedAgentsTime: null };
      }
      return {
        altEventSelectedAgentsTime: {
          selectedTime: { date: selectedDate, start: selectedStart, end: selectedEnd },
          eventId: eventId || ((_e = state.altEventSelectedAgentsTime) == null ? void 0 : _e.eventId),
          eventDescription: eventDescription || ((_f = state.altEventSelectedAgentsTime) == null ? void 0 : _f.eventDescription),
          selectedAgents
        }
      };
    }),
    updateOnRightPaneChange: (arg) => {
      set(() => {
        const newState = {};
        if (arg.shouldClearPendingAltEvent) {
          newState.altEventSelectedAgentsTime = null;
        }
        if (arg.shouldClearPendingRecommendedMeldEvent) {
          newState.pendingRecommendedMeld = null;
        }
        return newState;
      });
    },
    setAppointmentToReschedule: (newAppt) => set({ appointmentToReschedule: newAppt })
  }
}));
const useCalendarStatePendingRecommendedMeld = () => useCalendarStateStore((state) => state.pendingRecommendedMeld);
const useCalendarStateAltEventSelectedAgentsTime = () => useCalendarStateStore((state) => state.altEventSelectedAgentsTime);
const useCalendarStatePendingOfferedAvailabilities = () => useCalendarStateStore((state) => state.pendingResidentAvailabilities);
const useCalendarStateMeldResidentAvailabilities = () => useCalendarStateStore((state) => state.residentAvailabilitiesMeldId);
const useCalendarStateSelectedVendorAgentIds = (isMobile) => useCalendarStateStore((state) => {
  if (isMobile) {
    if (state.selectedVendorsAgentsIds.agents.length > 0) {
      return { agents: [state.selectedVendorsAgentsIds.agents[0]], vendors: [] };
    } else if (state.selectedVendorsAgentsIds.vendors.length > 0) {
      return { agents: [], vendors: [state.selectedVendorsAgentsIds.vendors[0]] };
    }
  }
  return state.selectedVendorsAgentsIds;
});
const getCalendarStateActions = () => useCalendarStateStore.getState().actions;
const useCalendarStateActions = () => useCalendarStateStore((state) => state.actions);
const useCalendarStateScheduledSegments = () => useCalendarStateStore((state) => state.scheduledSegments);
const useGetAppointmentToReschedule = () => useCalendarStateStore((state) => state.appointmentToReschedule);
const resetCalendarState = () => {
  useCalendarStateStore.getState().actions.resetState();
  useCalendarTimeFrameStateStore.getState().actions.resetState();
  useCalendarMapStateStore.getState().actions.resetState();
  useCalendarDragAndDropStateStore.getState().actions.resetState();
  useCalendarDraftModeStateStore.getState().actions.resetState();
};
export {
  getCalendarStateActions,
  useCalendarStatePendingOfferedAvailabilities,
  useCalendarStateMeldResidentAvailabilities,
  useCalendarStateAltEventSelectedAgentsTime,
  useCalendarStatePendingRecommendedMeld,
  useCalendarStateActions,
  useCalendarStateSelectedVendorAgentIds,
  useCalendarStateScheduledSegments,
  useGetAppointmentToReschedule,
  CALENDAR_SELECTED_VENDOR_AGENTS_LOCAL_STORAGE_KEY,
  resetCalendarState
};
