import { AbbreviatedAgentListView } from "@pm-frontend/shared/types/api/manager/serializers/abbreviated_agent_list_view";
import { CurrentAgentDetailViewSerializer } from "@pm-frontend/shared/types/api/manager/serializers/current_agent_detail_view_serializer";
import { ApiUrls } from "@pm-frontend/shared/utils/api-urls";
import { apiFetch, apiPatch } from "@pm-frontend/shared/utils/apiFetch";
import { LinkHelper } from "@pm-shared/utils/link";
import { useQuery } from "@tanstack/react-query";
import { AgentListViewSerializer } from "@pm-frontend/shared/types/api/manager/serializers/agent_list_view_serializer";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toastMessages, useAddToast } from "@pm-frontend/shared/store/toast";
import { SchedulingSettingsDetail } from "@pm-frontend/shared/types/api/manager/serializers/scheduling_settings_detail_view_serializer";

const agentKeys = {
  all: ["agents"],
  me: () => [...agentKeys.all, "me"],
  abbreviated: () => [...agentKeys.all, "abbreviated"],
  detail: (id: string) => [...agentKeys.all, id],
  scheduling: () => [...agentKeys.all, "scheduling"],
  departmentTitle: () => [...agentKeys.all, "departmentTitle"],
} as const;

const useGetAllAgentsAbbreviated = () => {
  return useQuery<AbbreviatedAgentListView>({
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.agentAbbrevList)),
    queryKey: agentKeys.abbreviated(),
    cacheTime: Infinity,
  });
};

const useGetAgentMe = (options: { enabled: boolean } = { enabled: true }) => {
  return useQuery<CurrentAgentDetailViewSerializer>({
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.agentMeDetail)),
    queryKey: agentKeys.me(),
    cacheTime: Infinity,
    enabled: options.enabled,
  });
};

const useGetAgentDetail = (agentId: number) => {
  return useQuery<CurrentAgentDetailViewSerializer>({
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.agentDetail(agentId.toString()))),
    queryKey: agentKeys.detail(agentId.toString()),
    cacheTime: Infinity,
  });
};

const useGetAgentSchedulingDetail = (options: { enabled: boolean } = { enabled: true }) => {
  return useQuery<SchedulingSettingsDetail>({
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.schedulingSettings)),
    queryKey: agentKeys.scheduling(),
    cacheTime: Infinity,
    enabled: options.enabled,
  });
};

const useGetManagementAgents = () => {
  return useQuery<AgentListViewSerializer[]>({
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.agentRegistrationTrueList)),
    queryKey: agentKeys.all,
    cacheTime: Infinity,
  });
};

interface AgentDepartmentTitleFormData {
  department: string;
  title: string;
  update_all: boolean;
}

const useSetAgentDetails = (agent: CurrentAgentDetailViewSerializer) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: agentKeys.departmentTitle(),
    mutationFn: (formData: AgentDepartmentTitleFormData) =>
      apiPatch(LinkHelper.normalize(ApiUrls.agentMeDetail), formData),
    onSuccess: () => {
      addToast({
        text: toastMessages.modalSuccess,
        color: "success",
      });
      return queryClient.invalidateQueries(agentKeys.detail(agent.id.toString()));
    },
    onError: () => {
      addToast({
        text: toastMessages.modalFailure,
        color: "danger",
      });
    },
  });
};

export {
  agentKeys,
  useGetAllAgentsAbbreviated,
  useGetAgentMe,
  useGetManagementAgents,
  useGetAgentSchedulingDetail,
  AgentDepartmentTitleFormData,
  useSetAgentDetails,
  useGetAgentDetail,
};
