import React from "react";
import { Global, css } from "@emotion/react";
import { colors } from "@pm-frontend/styles";
// meaningless change

const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        /* wrapping old UI in EUI broke some chakra styling */
        .chakra-switch__track {
          border: solid;
          border-width: 1px;
          border-color: #1175cc;
          margin: "auto";
        }

        /* wrapping old UI in EUI broke some chakra styling */
        .chakra-checkbox__control {
          border-style: solid;
          border-width: 2px;
          border-color: #1175cc;
        }

        /* wrapping old UI in EUI broke some chakra styling */
        .eui-wrapped-chakra-button {
          border: solid;
          border-width: 2px;
          border-color: #1175cc;
        }

        /* wrapping old UI in EUI broke some chakra styling */
        .chakra-input::placeholder {
          opacity: 0.7;
          color: #35393d !important;
        }
        /* to prevent 'Support' button from overlapping content
        this may need to be done differently when the full layout is 
        finished */
        .euiPage {
          padding: 16px 16px 50px 16px !important;
        }
        .euiPage > * {
          word-break: break-word;
        }
        .euiFieldSearch {
          background-color: #ffffff !important;
        }
        .euiTable .euiTableRow:nth-child(even) {
          background-color: #fcfcfc;
        }
        .pmCallOut {
          background-color: #f5f5f5 !important;
        }
        .euiTableRow:hover {
          background-color: inherit;
        }
        .euiFieldSearch:focus {
          outline: none !important;
          box-shadow: 0 0 0 1px rgb(0 119 204 / 10%) !important;
        }
        .euiFormControlLayoutClearButton {
          background-color: ${colors.brand.meldBlue} !important;
        }
        .pmBadge {
          font-weight: 600 !important;
        }
        .euiTableHeaderCell {
          :not(.euiTableHeaderButton-isSorted) {
            .euiTableCellContent {
              .euiTableCellContent__text {
                color: ${colors.neutrals.gray800};
                font-size: 12px;
                text-decoration: none;
              }
            }
          }
          .euiTableHeaderButton-isSorted {
            .euiTableCellContent {
              .euiTableCellContent__text {
                color: ${colors.brand.meldBlue};
                font-size: 12px;
                text-decoration: none;
              }
            }
          }
        }
        .euiTableRowCell {
          .euiTableCellContent {
            padding: 13px 8px;
            font-size: 14px !important;
            color: ${colors.neutrals.gray800};

            .euiText {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .euiModalFooter button {
          block-size: 40px;
          font-size: 14px;
        }
        .euiButtonIcon:hover {
          background-color: rgba(0, 0, 0, 0) !important;
        }
        .pmConfirmModal {
          .euiModalBody__overflow {
            padding-inline: 0px !important;
          }
        }
        .pmFormModal {
          .euiModalBody__overflow {
            padding-inline: 0px !important;
          }
        }
        .euiLink {
          color: ${colors.brand.meldBlue} !important;
        }
        .preWhiteSpace {
          white-space: pre-wrap;
          white-space: -moz-pre-wrap;
        }
        .euiTabs {
          :not(.euiTab-isSelected) {
            .euiTab__content {
              font-weight: 400;
            }
          }
          .euiTab-isSelected {
            flex-grow: 0;
            .euiTab__content {
              font-weight: 700;
              color: ${colors.brand.meldBlue};
            }
          }
        }
        .euiPopover {
          button.euiButtonIcon:hover {
            transform: none;
            background: ${colors.brand.white};
          }
        }
        input[type="search"].euiFieldSearch {
          font-family: "Open Sans", Helvetica, Roboto, Arial, sans-serif;
        }
        /* Necessary because we are nesting EuiPage within the nav components */
        .euiOverlayMask {
          inset-block-start: 0px !important;
        }
        .euiFlyout__closeButton {
          transform: none !important;
          svg {
            block-size: 20px;
            inline-size: 20px;
          }
        }
        .euiModal__closeIcon {
          transform: none !important;
          svg {
            block-size: 20px;
            inline-size: 20px;
          }
        }
        /* EuiComboBox options live outsite of the EuiForm, so styling here */
        .euiComboBoxTitle {
          padding: 7px !important;
          font-weight: 700 !important;
          font-size: 12px !important;
          color: ${colors.neutrals.gray800} !important;
        }
        .euiFilterSelectItem {
          .euiComboBoxOption__content {
            padding: 2px;
            font-size: 14px;
            line-height: 20px;
          }
        }
        .euiFilterSelectItem:not(:disabled) > .euiComboBoxOption__content {
          color: ${colors.neutrals.gray700};
        }
        .euiFilterSelectItem:disabled > .euiComboBoxOption__content {
          color: ${colors.neutrals.gray500};
        }
        /* This was causing issues when in <PmFormStyling> so moving here */
        .euiFormRow {
          label {
            font-size: 16px;
            font-weight: 700;
          }
          :not(.euiFormLabel-isFocused) {
            label {
              color: ${colors.neutrals.gray900};
            }
          }
        }
        /* EuiAvatar doesn't allow styling text natively */
        .pm-header-settings-avatar {
          span {
            color: ${colors.neutrals.gray900};
            font-size: 15px;
            font-weight: 700;
          }
        }
        /* EuiAvatar doesn't allow styling text natively */
        .pm-header-avatar {
          span {
            color: ${colors.neutrals.gray900};
            font-size: 12px;
            font-weight: 700;
          }
        }
        .chatMessageHighlight::selection {
          background: ${colors.neutrals.gray700};
          color: ${colors.brand.white};
        }
        /* large file viewer overlay mask has unecessary padding */
        .euiOverlayMask.file-preview-overlay-header {
          padding-block-end: 0;
        }
      `}
    />
  );
};

export { GlobalStyles };
