import { create } from "zustand";
import { CalendarDraggingEventType } from "../utils/click-drag-and-drop-utils";

type CalendarDragAndDropState =
  | ({
      id: number;
    } & (
      | {
          type: "draggingEvent";
          subType: CalendarDraggingEventType;
        }
      | {
          type: "resizingEvent" | "draggingMeld";
        }
    ))
  | null;

interface CalendarDragDropState {
  dragAndDropState: CalendarDragAndDropState;
  actions: {
    startDraggingMeld: (id: number) => void;
    startDraggingEvent: (id: number, subType: CalendarDraggingEventType) => void;
    stopDragging: VoidFunction;
    startResizingEvent: (id: number) => void;
    stopResizingEvent: VoidFunction;
    resetState: VoidFunction;
  };
}

const useCalendarDragAndDropStateStore = create<CalendarDragDropState>((set) => ({
  dragAndDropState: null,
  actions: {
    startDraggingMeld: (id) => set({ dragAndDropState: { type: "draggingMeld", id } }),
    startDraggingEvent: (id, subType) => set({ dragAndDropState: { type: "draggingEvent", id, subType } }),
    stopDragging: () => set({ dragAndDropState: null }),
    startResizingEvent: (id) => set({ dragAndDropState: { type: "resizingEvent", id } }),
    stopResizingEvent: () => set({ dragAndDropState: null }),
    resetState: () => set({ dragAndDropState: null }),
  },
}));

const useCalendarDragAndDropState = () => useCalendarDragAndDropStateStore((state) => state.dragAndDropState);
const useCalendarDragAndDropStateActions = () => useCalendarDragAndDropStateStore((state) => state.actions);

export {
  useCalendarDragAndDropState,
  useCalendarDragAndDropStateActions,
  useCalendarDragAndDropStateStore,
  CalendarDragAndDropState,
};
