import { useLocation } from "react-router-dom";
import { addDays, startOfDay, subDays } from "date-fns";

import { getCalendarOrMapState } from "../utils/hooks";
import { create } from "zustand";
import { StorageUtils } from "@pm-frontend/shared/utils/storage-utils";

const DEFAULT_MIN_MULTI_DAY_COLUMN_VIEW_IN_PX = 120;

const MIN_MULTI_DAY_COLUMN_WIDTH_VALUE_IN_PX = 20;
const MAX_MULTI_DAY_COLUMN_WIDTH_VALUE_IN_PX = 200;

const MIN_MULTI_DAY_COLUMN_WIDTH_LOCAL_STORAGE_KEY = `meld-calendar-min-multi-day-column-width-${window.PM.user.id}-${window.PM.user.multitenantId}`;

const getInitialMultiDayMinColumnWidth = (): number => {
  const { ok, value: rawValue } = StorageUtils.getLocalStorageItem(MIN_MULTI_DAY_COLUMN_WIDTH_LOCAL_STORAGE_KEY);
  if (!ok) {
    return DEFAULT_MIN_MULTI_DAY_COLUMN_VIEW_IN_PX;
  } else if (!rawValue) {
    return DEFAULT_MIN_MULTI_DAY_COLUMN_VIEW_IN_PX;
  } else {
    const parsedValue = Number(rawValue);
    if (Number.isNaN(parsedValue)) {
      return DEFAULT_MIN_MULTI_DAY_COLUMN_VIEW_IN_PX;
    }
    if (parsedValue < MIN_MULTI_DAY_COLUMN_WIDTH_VALUE_IN_PX || parsedValue > MAX_MULTI_DAY_COLUMN_WIDTH_VALUE_IN_PX) {
      return DEFAULT_MIN_MULTI_DAY_COLUMN_VIEW_IN_PX;
    }
    return parsedValue;
  }
};

const setInitialMultiDayMinColumnWidth = (value: number) => {
  StorageUtils.setLocalStorageItem(MIN_MULTI_DAY_COLUMN_WIDTH_LOCAL_STORAGE_KEY, value.toString());
};

const TIMEFRAME_LOCAL_STORAGE_KEY = `meld-calendar-timeframe-${window.PM.user.id}-${window.PM.user.multitenantId}`;

const CALENDAR_TIMEFRAMES = {
  ONE_DAY: 1,
  ONE_DAY_VERTICAL: "one-day-vertical",
  THREE_DAY: 3,
  FIVE_DAY: 5,
  WEEK: "week",
} as const;

type CALENDAR_TIMEFRAMES_TYPE = typeof CALENDAR_TIMEFRAMES[keyof typeof CALENDAR_TIMEFRAMES];

const setInitialSelectedTimeFrame = (timeframe: CALENDAR_TIMEFRAMES_TYPE) => {
  for (const entry of Object.entries(CALENDAR_TIMEFRAMES)) {
    if (timeframe === entry[1]) {
      StorageUtils.setLocalStorageItem(TIMEFRAME_LOCAL_STORAGE_KEY, entry[0]);
      return;
    }
  }
};

const getInitialSelectedTimeFrame = (): CALENDAR_TIMEFRAMES_TYPE => {
  const defaultValue = CALENDAR_TIMEFRAMES.ONE_DAY;
  const { value } = StorageUtils.getLocalStorageItem(TIMEFRAME_LOCAL_STORAGE_KEY);
  if (!value) {
    return defaultValue;
  } else {
    return CALENDAR_TIMEFRAMES[value as keyof typeof CALENDAR_TIMEFRAMES] || defaultValue;
  }
};

const getIncrementDecrementDays = (selectedTimeFrame: CALENDAR_TIMEFRAMES_TYPE): number => {
  switch (selectedTimeFrame) {
    case CALENDAR_TIMEFRAMES.WEEK:
      return 7;
    case CALENDAR_TIMEFRAMES.ONE_DAY_VERTICAL:
      return 1;
    default:
      return selectedTimeFrame;
  }
};

interface CalendarTimeFrameState {
  minMultiDayColumnWidth: number;
  selectedDate: number;
  selectedCalendarTimeFrame: CALENDAR_TIMEFRAMES_TYPE;
  actions: {
    // date and timeframe
    setSelectedDate: (newDate: number, isMobile: boolean) => void;
    // some screens override the selected timeframe state, so we take it as an input
    incrementDate: (selectedTimeFrame: CALENDAR_TIMEFRAMES_TYPE) => void;
    decrementDate: (selectedTimeFrame: CALENDAR_TIMEFRAMES_TYPE) => void;
    setDateToToday: () => void;
    setCalendarSelectedTimeFrame: (newTimeFrame: CALENDAR_TIMEFRAMES_TYPE) => void;
    setMinMultiDayColumnWidth: (arg0: number) => void;
    resetState: () => void;
  };
}

const useCalendarTimeFrameStateStore = create<CalendarTimeFrameState>((set) => ({
  selectedDate: startOfDay(new Date()).valueOf(),
  selectedCalendarTimeFrame: getInitialSelectedTimeFrame(),
  minMultiDayColumnWidth: getInitialMultiDayMinColumnWidth(),
  actions: {
    setSelectedDate: (newDate) => {
      set({ selectedDate: newDate });
    },
    incrementDate: (selectedCalendarTimeFrame) =>
      set((state) => ({
        selectedDate: addDays(
          new Date(state.selectedDate),
          getIncrementDecrementDays(selectedCalendarTimeFrame)
        ).valueOf(),
      })),
    decrementDate: (selectedCalendarTimeFrame) =>
      set((state) => ({
        selectedDate: subDays(
          new Date(state.selectedDate),
          getIncrementDecrementDays(selectedCalendarTimeFrame)
        ).valueOf(),
      })),
    setDateToToday: () =>
      set({
        selectedDate: startOfDay(new Date()).valueOf(),
      }),
    setCalendarSelectedTimeFrame: (newTimeFrame) => {
      setInitialSelectedTimeFrame(newTimeFrame);
      set({ selectedCalendarTimeFrame: newTimeFrame });
    },
    setMinMultiDayColumnWidth: (newWidth) => {
      if (newWidth < MIN_MULTI_DAY_COLUMN_WIDTH_VALUE_IN_PX) {
        return;
      } else if (newWidth > MAX_MULTI_DAY_COLUMN_WIDTH_VALUE_IN_PX) {
        return;
      } else {
        setInitialMultiDayMinColumnWidth(newWidth);
        set({ minMultiDayColumnWidth: newWidth });
      }
    },
    resetState: () =>
      set({
        selectedDate: startOfDay(new Date()).valueOf(),
      }),
  },
}));

const useCalendarStateSelectedDate = () => useCalendarTimeFrameStateStore((state) => state.selectedDate);
const useCalendarStateSelectedTimeFrame = (isMobile: boolean) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const calendarOrMap = getCalendarOrMapState(searchParams, isMobile);

  return useCalendarTimeFrameStateStore((state) => {
    if (isMobile) {
      return CALENDAR_TIMEFRAMES.FIVE_DAY;
    } else if (calendarOrMap === "large_map") {
      return CALENDAR_TIMEFRAMES.ONE_DAY;
    } else {
      return state.selectedCalendarTimeFrame;
    }
  });
};

const useCalendarTimeFrameStateActions = () => useCalendarTimeFrameStateStore((state) => state.actions);
const useCalendarStateMinMultiDayColumnWidth = () =>
  useCalendarTimeFrameStateStore((state) => state.minMultiDayColumnWidth);

export {
  useCalendarStateSelectedDate,
  useCalendarStateSelectedTimeFrame,
  useCalendarTimeFrameStateActions,
  useCalendarStateMinMultiDayColumnWidth,
  useCalendarTimeFrameStateStore,
  MIN_MULTI_DAY_COLUMN_WIDTH_VALUE_IN_PX,
  MAX_MULTI_DAY_COLUMN_WIDTH_VALUE_IN_PX,
  CALENDAR_TIMEFRAMES,
  CALENDAR_TIMEFRAMES_TYPE,
};
