import moment, { Moment } from "moment";
import { startOfWeek } from "date-fns";

import { SchedulableMeldDetailViewSerializer } from "@pm-frontend/shared/types/api/meld/serializers/scheduable_meld_detail_view_serializer";
import { colors } from "@pm-frontend/styles";
import { GetAssignedMaintenanceReturn } from "@pm-frontend/shared/utils/assignment-utils";
import { CalendarPaneState } from "./hooks";
import { AbbreviatedAgentListView } from "@pm-frontend/shared/types/api/manager/serializers/abbreviated_agent_list_view";
import { AbbreviatedVendorSerializer } from "@pm-frontend/shared/types/api/vendor/serializers/abbreviated_vendor_serializer";
import { AltEventSelectedAgentsTimes } from "@pm-frontend/routes/Calendar/stores/calendarStateStore";
import { CALENDAR_TIMEFRAMES, CALENDAR_TIMEFRAMES_TYPE } from "../stores/timeFrameStore";
import { getCanRescheduleAppointment } from "@pm-frontend/shared/utils/appointment-utils";
import { StorageUtils } from "@pm-frontend/shared/utils/storage-utils";

const CALENDAR_INTEGRATION_BANNER_LOCAL_STORAGE_KEY = `meld-calendar-integration-banner-m-${window.PM.user.id}-${window.PM.user.multitenantId}`;

/**
 * Get the beginning day of the calendar timeframe as a JS Date. Either the selected date,
 * or in the `week` view the Monday of the week of the selected date
 */
export const getFirstColumnMoment = (selectedTimeFrame: CALENDAR_TIMEFRAMES_TYPE, selectedDate: number): Moment => {
  return selectedTimeFrame === CALENDAR_TIMEFRAMES.WEEK
    ? moment(selectedDate).startOf("isoWeek")
    : moment(selectedDate);
};
/**
 * Get the beginning day of the calendar timeframe as a moment object. Either the selected date,
 * or in the `week` view the Monday of the week of the selected date
 */
export const getFirstColumnDate = (selectedTimeFrame: CALENDAR_TIMEFRAMES_TYPE, selectedDate: number): Date => {
  return selectedTimeFrame === CALENDAR_TIMEFRAMES.WEEK
    ? startOfWeek(new Date(selectedDate), { weekStartsOn: 1 })
    : new Date(selectedDate);
};
/**
 * Get the number of columns to be shown in the multi-day calendar from
 * the selected time frame
 */
export const getNumberCalendarDateColumns = (selectedTimeFrame: CALENDAR_TIMEFRAMES_TYPE): number => {
  switch (selectedTimeFrame) {
    case CALENDAR_TIMEFRAMES.WEEK:
      return 5;
    case CALENDAR_TIMEFRAMES.ONE_DAY_VERTICAL:
      return 1;
    default:
      return selectedTimeFrame;
  }
};

function moreThanFourMonthsAgo(date: string | number) {
  const MONTHS = 1000 * 60 * 60 * 720 * 4;
  const monthsAgo = Date.now() - MONTHS;

  return date < monthsAgo;
}

export const getCalendarIntegrationBannerStateFromLocalStorage = (): boolean => {
  const { ok, value } = StorageUtils.getLocalStorageItem(CALENDAR_INTEGRATION_BANNER_LOCAL_STORAGE_KEY);
  if (!ok) {
    return false;
  } else if (value === null) {
    return true;
  } else if (moreThanFourMonthsAgo(value)) {
    // Show every 4 Months
    return true;
  } else {
    return false;
  }
};

export const setCalendarIntegrationBannerStateFromLocalStorage = (state: string) => {
  StorageUtils.setLocalStorageItem(CALENDAR_INTEGRATION_BANNER_LOCAL_STORAGE_KEY, state);
};

export const currentlyAssignedBorder = `2px solid ${colors.brand.darkHover}`;

const doesSegmentMatchAppt = (
  segment: { event: { dtstart: string; dtend?: string | null } },
  appointment: {
    availability_segment: {
      event: {
        dtstart: string;
        dtend?: string | null | undefined;
      };
    } | null;
  }
) => {
  if (
    segment.event.dtstart &&
    segment.event.dtend &&
    appointment.availability_segment?.event.dtstart &&
    appointment.availability_segment.event.dtend
  ) {
    return (
      segment.event.dtstart === appointment.availability_segment.event.dtstart &&
      segment.event.dtend === appointment.availability_segment.event.dtend
    );
  }
  return false;
};

// we consider a segment scheduled if an appointment has a segment with the same times
export const isSegmentScheduled = (
  segment: { event: { dtstart: string; dtend?: string | null } },
  meld: SchedulableMeldDetailViewSerializer
): boolean => {
  if (meld.managementappointment.length > 0) {
    return meld.managementappointment.some((appt) => doesSegmentMatchAppt(segment, appt));
  } else if (meld.vendorappointment.length > 0) {
    return meld.vendorappointment.some((appt) => doesSegmentMatchAppt(segment, appt));
  }
  return false;
};

interface IsEventAvailabilityProps {
  managementavailabilitysegment?: {
    scheduled_management_appointment: object | null | undefined;
  };
  vendoravailabilitysegment?: {
    scheduled_vendor_appointment: number | null | undefined;
  };
}

/**
 * The events list endpoints returns all scheduled events and availabilities
 * this function can be used to filter
 */
export const isEventAvailability = (event: IsEventAvailabilityProps): boolean => {
  if (event.managementavailabilitysegment?.scheduled_management_appointment) {
    if (event.managementavailabilitysegment.scheduled_management_appointment === null) {
      return true;
    } else {
      return typeof event.managementavailabilitysegment.scheduled_management_appointment !== "object";
    }
  } else {
    return typeof event.vendoravailabilitysegment?.scheduled_vendor_appointment !== "number";
  }
};

export const getCellBackgroundColor = (isInPast: boolean): string => {
  if (isInPast) {
    return colors.neutrals.gray200;
  } else {
    return colors.brand.white;
  }
};

export const getScheduleFormMinStartTime = (
  event: {
    date: Moment;
    startTime: Moment | undefined;
    endTime: Moment | undefined;
  },
  now: Moment
) => {
  // events in the past have all options disabled
  // this can probably be removed if we move past events out of the form
  if (event.date.isBefore(now, "day")) {
    return event.date.clone().endOf("day");
  } else if (event.date.isSame(now, "day")) {
    const result = event.date.clone();
    result.hours(now.hours());
    result.minutes(now.minutes());
    result.seconds(0);
    // on the day of we use the current time
    return result;
  } else {
    // in the future we allow all times
    return event.date.clone().startOf("day");
  }
};

export const getScheduleFormMaxEndTime = (
  event: {
    date: Moment;
    startTime: Moment | undefined;
    endTime: Moment | undefined;
  },
  now: Moment
) => {
  // events in the past have all options disabled
  // this can probably be removed if we move past events out of the form
  if (event.date.isBefore(now, "day")) {
    return event.date.clone().endOf("day");
  }
  // we use the current time plus 8 hours
  // (or end of the day if that would take us into tomorrow)
  if (event.startTime) {
    const result = event.startTime.clone().add(8, "hours");

    if (result.isSame(event.date, "day")) {
      return result;
    }
  }
  return event.date.clone().endOf("day");
};

export const getScheduleFormMinEndTime = (
  event: {
    date: Moment;
    startTime: Moment | undefined;
    endTime: Moment | undefined;
  },
  now: Moment
) => {
  // events in the past have all options disabled
  // this can probably be removed if we move past events out of the form
  if (event.date.isBefore(now, "day")) {
    return event.date.clone().endOf("day");
  }
  if (event.startTime) {
    return event.startTime.clone();
  }
  return event.date.clone().startOf("day");
};

export const getCurrentlyActiveAgentsVendors = (
  assignees: GetAssignedMaintenanceReturn<{ id: number }, { id: number }, { id: number }>,
  rightPaneState: CalendarPaneState,
  selectedAgents: AbbreviatedAgentListView,
  selectedVendors: AbbreviatedVendorSerializer[],
  altEventSelectedAgentsTimes: AltEventSelectedAgentsTimes | null
): { agents: Array<{ id: number }>; vendors: Array<{ id: number }> } => {
  if (
    assignees &&
    (rightPaneState.type === "meldDetails" ||
      rightPaneState.type === "agentMelds_details" ||
      rightPaneState.type === "recommendedMelds_details" ||
      rightPaneState.type === "offerAvailabilities" ||
      rightPaneState.type === "vendorMelds_details")
  ) {
    if (assignees.type === "ManagementAgent") {
      return {
        agents: selectedAgents.filter((agent) => assignees.in_house_servicers.some((a) => a.id === agent.id)),
        vendors: [],
      };
    } else if (assignees.type === "Vendor") {
      if (selectedVendors.some((v) => v.id === assignees.vendor.id)) {
        return { vendors: selectedVendors.filter((v) => v.id === assignees.vendor.id), agents: [] };
      }
    }
  } else if (assignees && rightPaneState.type === "offerAvailabilities") {
    if (assignees.type === "ManagementAgent") {
      return {
        agents: selectedAgents.filter((agent) => assignees.in_house_servicers.some((a) => a.id === agent.id)),
        vendors: [],
      };
    }
  } else if (altEventSelectedAgentsTimes && rightPaneState.type === "alternativeEvent") {
    return {
      agents: selectedAgents.filter((agent) =>
        altEventSelectedAgentsTimes.selectedAgents.some((agentId) => agentId === agent.id)
      ),
      vendors: [],
    };
  }
  return { agents: [], vendors: [] };
};

type FutureAppointment =
  | NonNullable<SchedulableMeldDetailViewSerializer["managementappointment"][number]>
  | NonNullable<SchedulableMeldDetailViewSerializer["vendorappointment"][number]>;

export const getFutureAppointments = (meld: SchedulableMeldDetailViewSerializer): FutureAppointment[] => {
  const result: FutureAppointment[] = [];
  if (meld.managementappointment) {
    meld.managementappointment.forEach((appt) => {
      if (appt.availability_segment && getCanRescheduleAppointment(appt)) {
        result.push(appt);
      }
    });
  }
  if (meld.vendorappointment) {
    meld.vendorappointment.forEach((appt) => {
      if (appt.availability_segment && getCanRescheduleAppointment(appt)) {
        result.push(appt);
      }
    });
  }

  return result;
};
